import React from "react";

import CUCourse from "../Components/CUCourse";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const AddNewCourse = () => {
  return (
    <DashboardBase>
      <CUCourse />
    </DashboardBase>
  );
};

export default AddNewCourse;
