import React from "react";
import { useHistory } from "react-router";

import styles from "./styles.module.scss";

interface NavButtonProps {
  destination: string;
  image: string;
  title: string;
}

const NavButton: React.FC<NavButtonProps> = ({ destination, image, title }) => {
  const history = useHistory();

  return (
    <div className={styles.container} onClick={() => history.push(destination)}>
      <img className={styles.icon} src={image} alt="test" />
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default NavButton;
