import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";

import { useActivities, useAuth } from "../../stores";
import ActivityGroupList from "./Components/ActivityGroupList";
import ActivityNavigation from "../Components/ActivityNavigation";
import ButtonWithImage from "../../common/ButtonWithImage";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import Icon from "../../assets/images/back.svg";
import { toJS } from "mobx";
const ActivityGroups = () => {
  const { courseId, academicTermId, activityId }: any = useParams();
  const { activityTitle, getActivity, activity } = useActivities();
  const { courseName } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!activity.id) {
      getActivity(courseId, activityId);
    }
  }, []);

  return (
    <DashboardBase
      componentName={courseName}
      isActivity={true}
      location={activityTitle}
    >
      <ActivityNavigation isGroups={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCard={true}
          isCoursesTabs={true}
        >
          <Row className="justify-content-end mb-2">
            <Col md="auto" xs="auto">
              <ButtonWithImage
                title="Back"
                onClick={() => {
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/activities`
                  );
                }}
                icon={Icon}
              />
            </Col>
          </Row>
          <ActivityGroupList />
        </Card>
      </ActivityNavigation>
    </DashboardBase>
  );
};

export default observer(ActivityGroups);
