import React, { useEffect } from "react";
import cc from "classcat";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";

import { ActivityValidation } from "../../../../models/state.models";
import ArrowRightIcon from "../../../../assets/images/arrow-left-gray.svg";
import CardSharpCorners from "../../../../common/CardSharpCorners";
import styles from "./styles.module.scss";
import { useActivities } from "../../../../stores";

const SectionNames = (props: any) => {
  const history = useHistory();
  const { courseId, academicTermId, activityId, sectionId }: any = useParams();

  const {
    setSelectedActivitySection,
    selectedActivitySection,
    activity,
    searchActivityList,
    getActivityValidation,
  } = useActivities();

  return (
    <Col className={styles.wrap}>
      {props.list &&
        props.list.map((section: ActivityValidation, key: number) => (
          <CardSharpCorners
            isFullScreen={false}
            isWithOutTopMargin={false}
            key={section.code}
          >
            <Row
              className={
                cc([
                  styles.pointer + " justify-content-between p-2",
                  {
                    [styles.selectedNameWrapper +
                    " justify-content-between py-2"]:
                      (props.isFromUrl && section.code === sectionId) ||
                      (!props.isFromUrl &&
                        section.code === selectedActivitySection),
                  },
                ]) + " p-3"
              }
              onClick={() => {
                setSelectedActivitySection(section.code);
                if (props.isFromUrl) {
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/activitySectionsValidation/${section.code}`
                  );
                } else {
                  getActivityValidation(
                    courseId,
                    section.code,
                    activity.Id ?? searchActivityList.id ?? activityId,
                    false
                  );
                }
              }}
            >
              <p className="mr-3">{section.value} </p>
              <img
                className={styles.iconSize}
                src={ArrowRightIcon}
                alt="arrow"
              />
            </Row>
          </CardSharpCorners>
        ))}
    </Col>
  );
};

export default observer(SectionNames);
