import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { Scheduler, User } from "../../../../models/state.models";
import {
  useAuth,
  useCourses,
  useDialog,
  useScreenSize,
} from "../../../../stores";
import Button from "../../../../common/Button";
import Card from "../../../../common/Card";
import ScoreBoard from "../../../Components/ScoreBoard";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import LatestStudentTable from "../CourseOverview/Components/LatestStudentTable";
import styles from "./styles.module.scss";
import { BASE_URL } from "../../../../utilities";

const CourseOverview = () => {
  const history = useHistory();
  const { courseId }: any = useParams();
  const { changeStatusForCourse, course, getCourseDetails } = useCourses();

  const { isTabs } = useScreenSize();
  const { showDialog } = useDialog();
  const { selectedAcademicTermId, role } = useAuth();

  const [courseStatus, setCourseStatus] = useState(1);

  useEffect(() => {
    getCourseDetails(courseId).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (resp.message.includes("authorized")) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Errror"
          );
        }
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  }, [getCourseDetails, courseId]);

  useEffect(() => {
    if (course.status) {
      setCourseStatus(course.status);
    } else {
      setCourseStatus(1);
    }
  }, [course]);

  const changeCourseStatus = () => {
    changeStatusForCourse(courseId, courseStatus).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Course status succesfully changed", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  const filterSchedulesList = () => {
    const filterSchedules = course.schedules.reduce(
      (unique: any, o: Scheduler) => {
        if (
          !unique.some(
            (obj: Scheduler) =>
              obj.dayOfWeek === o.dayOfWeek &&
              obj.hourFrom === o.hourFrom &&
              obj.hourTo === o.hourTo
          )
        ) {
          unique.push(o);
        }
        return unique;
      },
      []
    );
  };

  return (
    <Card
      isFullScreen={true}
      isWithOutTopMargin={true}
      isNavigation={true}
      isCoursesTabs={true}
      isDetails={true}
    >
      <Row className="justify-content-between align-items-center pb-3 m-0 mb-3">
        <Col
          xs={12}
          className={
            styles.headingWrapper + " d-flex align-items-center mb-2 px-2"
          }
          md="auto"
        >
          <img
            className={styles.avatar}
            src={`${BASE_URL}File/GetFile/${course.imageFileName}`}
            alt="File"
          />
          <h2 className={styles.title}>Welcome to {course.title}</h2>
        </Col>
        {role === "Professor" && (
          <Col>
            <Row className="align-items-baseline justify-content-end">
              <Col xs={7} md="auto" className="pl-0">
                <SelectWithLabel
                  label="Course status"
                  name="courseStatus"
                  onChange={(event: any) => {
                    setCourseStatus(event.target.value);
                  }}
                  values={[
                    { name: "Draft", id: 1 },
                    { name: "PrePublish", id: 2 },
                    { name: "Published", id: 3 },
                    ,
                  ]}
                  value={courseStatus}
                />
              </Col>
              <Col xs={5} md="auto">
                <Button
                  isExtraSmall={true}
                  title="Change"
                  onClick={() => {
                    changeCourseStatus();
                  }}
                  type="button"
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className={styles.courseOverviewWrapper + " row"}>
        <Col md="auto" sm={12} className={isTabs ? "pr-5 m-0" : "mb-3"}>
          {/* <Col sm={12} className={cc(["pr-5 m-0", { ["pr-0"]: !isTabs }])}> */}
          <ul className={styles.listCourseBaseDeatils}>
            <li className={styles.itemCoruseDetails}>
              <p className={styles.label}>Code</p>
              <p>{course.courseCode}</p>
            </li>
            <li className={styles.itemCoruseDetails}>
              <p className={styles.label}>Year</p>
              <p>{course.academicTermYear}</p>
            </li>
            <li className={styles.itemCoruseDetails}>
              <p className={styles.label}>Term</p>
              <p>{course.academicTermName}</p>
            </li>
          </ul>
          <p className={styles.label}>Duration</p>
          <p className={styles.duration}>
            {course.startDate + "-" + course.endDate}
          </p>
        </Col>
        <Col className={styles.courseDetailsSectionWrapper} md="auto" sm={12}>
          <p className={styles.label}>Co-instructors</p>
          {course &&
            course.coursUsers &&
            course.coursUsers.map((user: User) => (
              <p key={user.id}>
                {user.roleCode === "prof" &&
                  (user.fullName ? user.email : user.email)}
              </p>
            ))}
        </Col>
        <Col className={styles.courseDetailsSectionWrapper} md="auto" sm={12}>
          <p className={styles.label}>Teaching Assistants</p>

          {course &&
            course.coursUsers &&
            course.coursUsers.map((user: User) => (
              <p key={user.id}>
                {user.roleCode === "tcAss" &&
                  (user.fullName !== " " ? user.fullName : user.email)}
              </p>
            ))}
        </Col>
      </div>

      <Row>
        <Col md={12} className={styles.scoreOverviewTitle}>
          <h3>Score overview</h3>
        </Col>
        <ScoreBoard
          title="Individual student"
          isFirst={true}
          isCourse={true}
          currency={course.currencyName}
        />
        <ScoreBoard
          title="By group"
          isCourse={true}
          currency={course.currencyName}
        />
      </Row>
      {role != "Student" && course && course.latestSubmission && (
        <>
          <h4 className="my-4">Latest students applications</h4>
          <LatestStudentTable list={course} />
        </>
      )}
    </Card>
  );
};

export default observer(CourseOverview);
