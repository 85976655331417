import React from "react";
import CUUser from "../Components/CUUser";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const AddNewUser = () => {
  return (
      <DashboardBase componentName="Add new user" havePadding={true}>
        <CUUser />
      </DashboardBase>
  );
};

export default AddNewUser;
