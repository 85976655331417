import React from "react";
import moment from "moment";
import { action, makeObservable, observable, runInAction } from "mobx";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import {
  GradebookDTO,
  CourseSectionList,
  CourseUserGradebookDTO,
  StudentGradebook,
  GradebookHistoryDTO,
} from "../../models/state.models";
import {
  AssignmentRequestDTO,
  GradebookCourseUserRequest,
  GradebookRequest,
  GradebookSaveDTO,
  SubmittedAssignmentRequestDTO,
} from "../../models/request.models";
import { FetchApi } from "../../utilities";

class GradebookStore {
  @observable status: string = "inital";
  @observable message: string = "";
  @observable isSuccessRequest = false;
  @observable isGradebookStoreLoading: boolean = false;
  @observable selectedSectionForPoints: number = 0;
  @observable gradebook: GradebookDTO = {
    assignmmentList: [],
    studentList: [],
  };
  @observable gradebookCopy: GradebookDTO = {
    assignmmentList: [],
    studentList: [],
  };
  @observable courseName: string = "";
  @observable courseSectionList: CourseSectionList[] = [];
  @observable gradebookStudents: CourseUserGradebookDTO[] = [];
  @observable gradebookStudentsCopy: CourseUserGradebookDTO[] = [];
  @observable courseUserGradebookList: CourseUserGradebookDTO[] = [];
  @observable courseUserGradebookListCopy: CourseUserGradebookDTO[] = [];
  @observable assignmentCodeList: AssignmentRequestDTO[] = [];
  @observable gradebookList: CourseUserGradebookDTO[] = [];
  @observable gradebookSaveDTO: GradebookSaveDTO[] = [];
  @observable selectedFilterSectionValue: number = 0;
  @observable maxAttempts: number = 0;
  @observable studentGradebookList: StudentGradebook[] = [];
  @observable gradebookHistoryDTO: GradebookHistoryDTO = {
    assignmentID: 0,
    courseUserID: 0,
    assignmentName: "",
    studentName: "",
    pointsList: [],
    assignmentPoints: 0,
  };
  @observable submittedAssignmentDTO: SubmittedAssignmentRequestDTO = {
    id: 0,
    submittedAssignmentID: 0,
    courseID: 0,
    assignmentID: 0,
    courseUserID: 0,
    points: 0,
    previousPoints: 0,
    status: 0,
    note: "",
    passedDate: "",
  };
  @observable selectedAssignmentID: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async getGradebookList(request: GradebookRequest) {
    this.isGradebookStoreLoading = true;
    const { message, status, data, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/GradebookApi/GetGradebookList",
      params: request,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.gradebook = data;
        this.gradebookStudents = data.studentList;
        this.gradebookStudentsCopy = data.studentList;
        this.courseName = breadCrumb.courseName;
      } else {
        this.gradebook = {
          assignmmentList: [],
          studentList: [],
        };
        this.gradebookStudents = [];
        this.gradebookStudentsCopy = [];
      }
    });
    return { status, message };
  }

  @action.bound
  async getCourseSectionList(courseID: number) {
    this.isGradebookStoreLoading = true;
    const { message, status, breadCrumb, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseAndSectionCodeList",
      params: {
        courseID: courseID,
      },
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.courseSectionList = data.filter(
          (x: any) => x.codeInt !== courseID
        );
      } else {
        this.courseSectionList = [];
      }
    });
    return { status, message, breadCrumb };
  }

  @action.bound
  async getCourseUserList(request: GradebookCourseUserRequest) {
    this.isGradebookStoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/GradebookApi/GetCourseUserList",
      params: request,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.courseUserGradebookList = data;
        this.courseUserGradebookListCopy = data;
      } else {
        this.courseUserGradebookList = [];
      }
    });
    return { status, message };
  }

  @action.bound
  async getAssignmentCodeList(assignmentRequest: AssignmentRequestDTO) {
    this.isGradebookStoreLoading = true;
    const { message, status, data, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/GetAssignmentCodeList",
      params: assignmentRequest,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.assignmentCodeList = data;
      } else {
        this.assignmentCodeList = [];
      }
    });
    return { status, message, breadCrumb };
  }

  @action.bound
  async saveGradebookList(params: GradebookSaveDTO) {
    this.isGradebookStoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/GradebookApi/SaveGradebookList",
      params: params,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
        this.gradebookSaveDTO = data;
      } else {
        this.gradebookSaveDTO = [];
      }
    });
    return { status, message };
  }

  @action.bound
  async getGradebookHistory(request: SubmittedAssignmentRequestDTO) {
    this.isGradebookStoreLoading = true;
    const { status, data, message, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/GradebookApi/GetGradebookHistory",
      params: request,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
        this.gradebookHistoryDTO = data;
      } else {
        this.gradebookHistoryDTO = {
          assignmentID: 0,
          courseUserID: 0,
          assignmentName: "",
          studentName: "",
          pointsList: [],
          assignmentPoints: 0,
        };
      }
    });
    return { status, message, breadCrumb };
  }

  @action.bound
  async saveAssignmentAttempt(request: SubmittedAssignmentRequestDTO) {
    this.isGradebookStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/SaveAssignmentAttempt",
      params: request,
    });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
      }
    });
    return { status, message };
  }

  @action.bound
  async getGradebookForStudent(id: number) {
    this.isGradebookStoreLoading = true;
    const { message, status, data, additionalData, breadCrumb }: any =
      await FetchApi({
        method: "POST",
        url: "/GradebookApi/GetGradebookForStudent",
        params: { CourseID: id },
      });
    this.isGradebookStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.courseName = breadCrumb.courseName;
        this.maxAttempts = additionalData;
        this.studentGradebookList = data;
      }
    });
    return { message: message, status: status };
  }

  @action.bound
  handlerInputPoints = (event: React.ChangeEvent<HTMLInputElement>) => {
    let studentID = Number(event.target.id);
    let student = this.courseUserGradebookList.find(
      (x) => x.courseUserID === studentID
    );
    if (student) {
      student.points = Number(event.target.value);
    }
  };

  @action.bound
  resetAssignmentAttempt = () => {
    this.submittedAssignmentDTO = {
      id: 0,
      submittedAssignmentID: 0,
      courseID: 0,
      assignmentID: 0,
      courseUserID: 0,
      points: 0,
      previousPoints: 0,
      status: 0,
      note: "",
      passedDate: "",
    };
  };

  @action.bound
  resetInput = () => {
    for (let student of this.courseUserGradebookList) {
      student.points = 0;
    }
  };

  @action.bound
  setSelectedSectionForPoint = (sectionID: number) => {
    this.selectedSectionForPoints = sectionID;
  };

  @action.bound
  resetStudentsList = () => {
    this.courseUserGradebookList = [];
    this.courseUserGradebookListCopy = [];
    this.selectedAssignmentID = 0;
  };

  @action.bound
  handlerFilterStudentSelected = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let selectedValue = Number(event.target.value);
    if (selectedValue === 1) {
      this.courseUserGradebookList = this.courseUserGradebookListCopy.filter(
        (x) => x.isBuyBacks === true
      );
    } else {
      this.courseUserGradebookList = this.courseUserGradebookListCopy;
    }
  };

  @action.bound
  filterHandlerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filter = event.target.value;
    if (filter && filter !== "") {
      this.gradebookStudents = this.gradebookStudentsCopy.filter((s) =>
        s.studentName.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      this.gradebookStudents = this.gradebookStudentsCopy;
    }
  };

  @action.bound
  handlerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.submittedAssignmentDTO.points = parseInt(event.target.value);
  };

  @action.bound
  handleDateChange(date: MaterialUiPickersDate) {
    this.submittedAssignmentDTO.passedDate = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  setSelectedAssignmentID = (id: number) => {
    this.selectedAssignmentID = id;
  };

  @action.bound
  setIsEditAttempt = (id: number, courseID: number) => {
    let historyAttempt = this.gradebookHistoryDTO;
    let founded = historyAttempt.pointsList.find(
      (x) => x.submittedAssignmentID === id
    );
    if (founded) {
      this.submittedAssignmentDTO.assignmentID = historyAttempt.assignmentID;
      this.submittedAssignmentDTO.submittedAssignmentID = id;
      this.submittedAssignmentDTO.passedDate = founded.passedDate;
      this.submittedAssignmentDTO.points = founded.points;
      this.submittedAssignmentDTO.courseUserID = historyAttempt.courseUserID;
      this.submittedAssignmentDTO.courseID = courseID;
    }
  };
}

export default GradebookStore;
