import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../../../stores";
import Card from "../../../../common/Card";
import CourseNavigation from "../../../Components/CourseNavigation";
import DashboardBase from "../../../Dashboard/Components/DashboardBase";
import StudentAssignmentDetails from "../StudentAssignmentDetails";

const StudentAssignmentActivity = () => {
  const { courseName } = useAuth();

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isGradeBook={true} />
      <Card isFullScreen={true} isWithOutTopMargin={true}>
        <StudentAssignmentDetails />
      </Card>
    </DashboardBase>
  );
};

export default observer(StudentAssignmentActivity);
