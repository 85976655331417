import React from "react";

import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import { AttendanceForList } from "../../../../models/state.models";
import AttendanceRow from "../AttendanceRow";
import styles from "./styles.module.scss";

const AttendanceTable = ({ list }: any) => {
  return (
    <table key="applications-table" className={styles.table}>
      <thead>
        <tr>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("name")}>
              Activity applied
              {list.orderBy === "name" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("_status")}>
              Status
              {list.orderBy === "_status" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("createdOn")}>
              Submission Date
              {list.orderBy === "createdOn" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("applicationDateTime")}>
              Validation Date
              {list.orderBy === "applicationDateTime" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>

          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((applications: AttendanceForList) => (
            <AttendanceRow application={applications} />
          ))}
      </tbody>
    </table>
  );
};

export default AttendanceTable;
