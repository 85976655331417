import React, { createContext, useContext } from "react";
import { useLocalStore } from "mobx-react";

import AuthStore from "./AuthStore";
import CoursesStore from "./CoursesStore";
import SectionStore from "./SectionStore";
import ActivityStore from "./ActivityStore";
import SectionGroupsStore from "./SectionGroups";
import AssignemntStore from "./AssignmentStore";
import DialogNotificationStore from "./DialogNotificationStore";
import BaseTableStore from "./BaseTableStore";
import GradebookStore from "./GradebookStore";
import SchedulerStore from "./SchedulerStore";
import ScoreStore from "./ScoreStore";
import ScreenSizeStore from "./ScreenSizeStore";

export interface StoreContextProps {
  authStore: AuthStore;
  coursesStore: CoursesStore;
  sectionStore: SectionStore;
  activityStore: ActivityStore;
  sectionGroupsStore: SectionGroupsStore;
  assignemntStore: AssignemntStore;
  dialogNotificationStore: DialogNotificationStore;
  baseTableStore: BaseTableStore;
  gradebookStore: GradebookStore;
  schedulerStore: SchedulerStore;
  scoreStore: ScoreStore;
  screenSizeStore: ScreenSizeStore;
}
export const StoreContext = createContext<StoreContextProps | null>(null);

export const StoreProvider: React.FC = ({ children }: any) => {
  const authStore = new AuthStore();
  const coursesStore = new CoursesStore();
  const sectionStore = new SectionStore();
  const activityStore = new ActivityStore();
  const sectionGroupsStore = new SectionGroupsStore();
  const assignemntStore = new AssignemntStore();
  const dialogNotificationStore = new DialogNotificationStore();
  const baseTableStore = new BaseTableStore();
  const gradebookStore = new GradebookStore();
  const schedulerStore = new SchedulerStore();
  const scoreStore = new ScoreStore();
  const screenSizeStore = new ScreenSizeStore();

  return (
    <StoreContext.Provider
      value={useLocalStore(() => ({
        authStore,
        coursesStore,
        sectionStore,
        activityStore,
        sectionGroupsStore,
        assignemntStore,
        dialogNotificationStore,
        baseTableStore,
        gradebookStore,
        schedulerStore,
        scoreStore,
        screenSizeStore,
      }))}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useAuth = () => {
  const { authStore }: any = useContext(StoreContext);
  return authStore;
};

export const useCourses = () => {
  const { coursesStore }: any = useContext(StoreContext);
  return coursesStore;
};

export function useSection() {
  const { sectionStore }: any = useContext(StoreContext);
  return sectionStore;
}

export const useActivities = () => {
  const { activityStore }: any = useContext(StoreContext);
  return activityStore;
};

export const useSectionGroups = () => {
  const { sectionGroupsStore }: any = useContext(StoreContext);
  return sectionGroupsStore;
};

export const useAssignments = () => {
  const { assignemntStore }: any = useContext(StoreContext);
  return assignemntStore;
};

export const useDialog = () => {
  const { dialogNotificationStore }: any = useContext(StoreContext);
  return dialogNotificationStore;
};

export const useBaseTable = () => {
  const { baseTableStore }: any = useContext(StoreContext);
  return baseTableStore;
};

export const useGradebookStore = () => {
  const { gradebookStore }: any = useContext(StoreContext);
  return gradebookStore;
};

export const useSchedulerStore = () => {
  const { schedulerStore }: any = useContext(StoreContext);
  return schedulerStore;
};

export const useScoreStore = () => {
  const { scoreStore }: any = useContext(StoreContext);
  return scoreStore;
};

export const useScreenSize = () => {
  const { screenSizeStore }: any = useContext(StoreContext);
  return screenSizeStore;
};
