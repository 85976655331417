import * as Yup from "yup";

export const GroupModalSchema = Yup.object().shape({
    name: Yup.string()
    .required('This field is required')
    // groupLabel: Yup.string()
    //   .required('This field is required'),
    // description: Yup.string()
    // .required('This field is required')
})
