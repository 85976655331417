import React, { useEffect } from "react";
import cc from "classcat";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";

import { AcademicTerm } from "../../../../../../models/state.models";
import { BASE_URL } from "../../../../../../utilities";
import { useAuth, useCourses } from "../../../../../../stores";
import dashboardIcon from "../../../../../../assets/images/dashboard.svg";
import defaultUserImg from "../../../../../../assets/images/default-user.png";
import logoutIcon from "../../../../../../assets/images/log-out.svg";
import SelectWithoutLabel from "../../../../../../common/SelectWithOutLabel";
import styles from "./styles.module.scss";

interface UserHeaderProps {
  childComponetName?: string;
  childLocation?: string;
  isActivity?: boolean;
  isAssignment?: boolean;
  isPurple?: boolean;
}

const UserHeader: React.FC<UserHeaderProps> = ({
  childComponetName,
  childLocation,
  isActivity,
  isAssignment,
  isPurple,
}) => {
  const { academicTermId }: any = useParams();
  const { academicTerms, selectedCourseTitle } = useCourses();
  const {
    changeSelectedAcademicTermId,
    selectedAcademicTermId,
    getAcademicTermID,
  } = useAuth();
  const { isLogin, logOut, userName, userImage } = useAuth();

  const history = useHistory();

  const logOutUser = () => {
    logOut().then((resp: any) => {
      if (resp.message === "success") {
        history.push("/");
      }
    });
  };

  const handleOnSelectChange = (e: any) => {
    changeSelectedAcademicTermId(
      e.target.value,
      filterAcademicTerm(e).start,
      filterAcademicTerm(e).end
    );
    history.push(`/academicTerms/${e.target.value}/courses`);
    // setNeedsReloadTable(true);
  };

  useEffect(() => {
    if (isLogin === "false") {
      history.push("/");
    }
  }, [isLogin, history]);

  useEffect(() => {
    if (academicTermId === undefined) {
      changeSelectedAcademicTermId(selectedAcademicTermId);
      getAcademicTermID();
    } else {
      changeSelectedAcademicTermId(academicTermId);
    }
  }, []);

  const filterAcademicTerm = (e: any) => {
    const temp = academicTerms.find((aTerm: AcademicTerm) => {
      return aTerm.id === e.target.value;
    });
    return temp;
  };

  return (
    <div
      className={
        cc([styles.headerContainer, { [styles.purpleBg]: isPurple }]) +
        " row p-0 m-0 py-3"
      }
    >
      {!isPurple && (
        <div className={styles.childComponentNameWrapper + " col-auto"}>
          <img alt="Profile" src={dashboardIcon} />

          <p className={styles.headerLabel}>{childComponetName}</p>
          {/* {selectedCourseTitle && (
            <div className={styles.locationWrapper}>
              <p className={styles.section}>Course:</p>
              <p className={styles.locationLabel}>{selectedCourseTitle}</p>
            </div>
          )} */}
          {childLocation && (
            <div className={styles.locationWrapper}>
              <p className={styles.section}>
                {isActivity
                  ? "Activity:"
                  : isAssignment
                  ? "Assignment:"
                  : "Section:"}
              </p>
              <p className={styles.locationLabel}>{childLocation}</p>
            </div>
          )}
          {childComponetName === "Courses" && (
            <div className={styles.childComponentNameWrapper}>
              <SelectWithoutLabel
                defaultValue={academicTermId}
                isAcademicTerm
                onChange={handleOnSelectChange}
                value={academicTermId}
                values={academicTerms}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={
          cc([styles.userInfoWrapper, { [styles.purpleBg]: isPurple }]) +
          " col-auto"
        }
      >
        <img
          alt="Profile"
          className={styles.profileImage}
          placeholder={defaultUserImg}
          src={
            userImage ? `${BASE_URL}File/GetFile/${userImage}` : defaultUserImg
          }
          onClick={() => history.push("/profile/edit")}
        />
        <p
          className={cc([styles.userInfo, { [styles.whiteFont]: isPurple }])}
          onClick={() => history.push("/profile/edit")}
        >
          {userName}
        </p>
        <img
          alt="Logout"
          className={styles.logoutImg}
          onClick={logOutUser}
          src={logoutIcon}
        />
      </div>
    </div>
  );
};

export default observer(UserHeader);
