import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { ConfirmationDetails } from "../../../../models/state.models";
import { SaveSubmittedActivity } from "../../../../models/request.models";
import { useActivities, useDialog } from "../../../../stores";
import { useHistory, useParams } from "react-router";
import Button from "../../../../common/Button";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import TextArea from "../../../../common/TextArea";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";

interface ConfirmationModalProps {
  confirmation: ConfirmationDetails;
  excused: boolean;
  handleCancel: () => void;
  handleRedirection: (student: any) => void;
  isPresence: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  confirmation,
  excused,
  handleCancel,
  isPresence,
  handleRedirection,
}) => {
  const [description, setDescription] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [overrideCredit, setOverrideCredit] = useState<string>("");
  const { courseId, submittedActivityId }: any = useParams();
  const history = useHistory();
  const { saveConfirmation } = useActivities();
  const { showDialog } = useDialog();

  const handleSubmit = () => {
    const request: SaveSubmittedActivity = {
      CourseID: courseId,
      SubmittedActivityID: submittedActivityId,
      Status: excused ? 3 : 4,
      ProfessorNote: description,
      OverrideCredit: parseInt(overrideCredit),
    };
    saveConfirmation(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog(
          "Activity changed succesfully",
          null,
          null,
          false,
          "Notification"
        );
        handleRedirection(confirmation);
        handleCancel();
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  return (
    <div className={styles.addGroupWrapper + " p-5"}>
      <Row>
        {isPresence ? (
          <Col>
            <h2>{excused ? "Excuse Presence" : "Unexcuse Presnce"}</h2>
          </Col>
        ) : (
          <Col>
            <h2>{excused ? "Accept Activity" : "Deny Activity"}</h2>
          </Col>
        )}
      </Row>
      {!excused && !isPresence && (
        <Row className="mt-5">
          <Col>
            <SelectWithLabel
              label="Response"
              name="response"
              placeholder="Select response"
              value={response !== "" ? response : -1}
              values={[
                { name: "Insufficient data", id: "1" },
                { name: "Submitted after due date", id: "2" },
                { name: "Missed/wrong topic", id: "3" },
              ]}
              onChange={(e: any) => {
                setResponse(e.target.value);
              }}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col>
          <TextArea
            label="comment"
            value={description}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(event?.target.value)
            }
          />
        </Col>
      </Row>
      {excused && !isPresence && (
        <Row className="mt-3">
          <Col>
            <TextInput
              label="Override credit worth"
              name="creditWorth"
              value={overrideCredit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOverrideCredit(event.target.value);
              }}
            />
          </Col>
        </Row>
      )}
      <Row className="justify-content-end + m-0 mt-5">
        <Col>
          <Button
            isDialogCancel={true}
            onClick={handleCancel}
            title="Cancel"
            type="button"
          />
        </Col>
        <Col>
          {excused ? (
            <Button
              isDialog={true}
              title={isPresence ? "Excused" : "Accept"}
              type="submit"
              onClick={() => handleSubmit()}
            />
          ) : (
            <Button
              isDeny={true}
              title={isPresence ? "Unexcused" : "Deny"}
              type="submit"
              onClick={() => handleSubmit()}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmationModal;
