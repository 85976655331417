import React from "react";

import RecentPointsSubmitted from "../RecentPointsSubmitted";
import styles from "./styles.module.scss";

const ActivityStudentTable = ({ list }: any) => {
  return (
    <table key="activity-table" className={styles.table}>
      <thead>
        <tr>
          <td></td>
          <td>Name</td>
          <td>Submitted date</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.latestSubmissions &&
          list.latestSubmissions.map((student: any) => (
            <RecentPointsSubmitted student={student} />
          ))}
      </tbody>
    </table>
  );
};

export default ActivityStudentTable;
