import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { GradebookCourseUserRequest } from "../../../../models/request.models";
import { useGradebookStore } from "../../../../stores";
import ArrowRightIcon from "../../../../assets/images/arrow-left-gray.svg";
import styles from "./styles.module.scss";

const AssignmentCodeList = () => {
  const { getCourseUserList, assignmentCodeList, setSelectedAssignmentID } =
    useGradebookStore();
  const [activatedLink, setActivatedLink] = useState<number>(0);

  const { courseId, sectionId }: any = useParams();

  const getStudentList = (number: any) => {
    let assignemntID = number;
    setSelectedAssignmentID(assignemntID);
    let request: GradebookCourseUserRequest = {
      id: 0,
      courseUserID: 0,
      sectionID: sectionId,
      courseID: courseId,
      assignmentID: assignemntID,
    };
    getCourseUserList(request);
  };

  return (
    <>
      {assignmentCodeList &&
        assignmentCodeList.map((assignment: any) => {
          return (
            <div
              className={
                activatedLink === assignment.codeInt
                  ? styles.assignmentWrapperOnClickStyle
                  : styles.assignmentWrapper
              }
              id={assignment.codeInt}
              onClick={(e) => {
                getStudentList(assignment.codeInt);
                setActivatedLink(assignment.codeInt);
              }}
            >
              <p className={styles.locationLabel}>{assignment.value}</p>
              <p className={styles.section}>
                {"max: " + assignment.additionalData}
              </p>
              <img
                className={styles.iconSize}
                src={ArrowRightIcon}
                alt="arrow"
              />
            </div>
          );
        })}
    </>
  );
};

export default observer(AssignmentCodeList);
