import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { SectionUserCourseList } from "../../../../../../models/state.models";
import {
  useSectionGroups,
  useAuth,
  useScreenSize,
} from "../../../../../../stores";
import ArrowRightIcon from "../../../../../../assets/images/arrow-left.svg";
import arrowDownIcon from "../../../../../../assets/images/arrow-down-gray.svg";
import arrowUpIcon from "../../../../../../assets/images/arrow-up-gray.svg";
import ButtonWithImage from "../../../../../../common/ButtonWithImage";
import StudentMainGroupItem from "../StudentMainGroupItem";
import TextInput from "../../../../../../common/TextInput";
import CustomCheckbox from "../../../../../../common/CustomCheckbox";
import styles from "./styles.module.scss";

interface MainGroupProps {
  openMoveStudentModal: () => void;
  studentList: SectionUserCourseList[];
}

const MainGroup: React.FC<MainGroupProps> = ({
  openMoveStudentModal,
  studentList,
}) => {
  const { role } = useAuth();
  const {
    setIsGroupEdit,
    clearCheckedStudents,
    searchStudent,
    selectAllCourseUsers,
  } = useSectionGroups();

  const { isTabs } = useScreenSize();

  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isListVisible, setIsListVisible] = useState(isTabs);

  const openStudentModalHandler = () => {
    setIsGroupEdit(false);
    openMoveStudentModal();
  };
  const clearAllHandler = () => {
    clearCheckedStudents();
  };

  const filterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchStudent(event.target.value);
  };

  return (
    <div className={styles.studentCard + " p-4 "}>
      <Row className={styles.header}>
        <Col md={6} xs="auto">
          <h2>Students</h2>
        </Col>
        {role !== "Assistant" && isListVisible && (
          <Col md={6} className="pl-0" xs="auto">
            <ButtonWithImage
              title="Move selected to "
              icon={ArrowRightIcon}
              isSmall={true}
              onClick={openStudentModalHandler}
            />
          </Col>
        )}
        {!isTabs && (
          <Col xs="auto">
            <img
              className={styles.iconWrapper}
              src={isListVisible ? arrowUpIcon : arrowDownIcon}
              onClick={() => setIsListVisible(!isListVisible)}
              alt={isListVisible ? "hide" : "show"}
            />
          </Col>
        )}
      </Row>
      {isListVisible && (
        <>
          <Row>
            <Col md={12} className=" mt-3">
              <TextInput onChange={filterHandler} />
            </Col>
          </Row>
          <Row>
            {role !== "Assistant" && (
              <>
                {" "}
                <Col md="auto" className={styles.clearAllWrapperText}>
                  <p onClick={clearAllHandler}>Clear All</p>
                </Col>
              </>
            )}
          </Row>

          {role !== "Assistant" && (
            <Row className=" justify-content-end">
              <Col md="auto">
                <CustomCheckbox
                  checked={isSelectedAll}
                  label="Select all?"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsSelectedAll(event.target.checked);
                    selectAllCourseUsers(event.target.checked);
                  }}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ul className={styles.studentList + " my-2"}>
                {studentList &&
                  studentList.map((student: SectionUserCourseList) => (
                    <StudentMainGroupItem key={student.id} student={student} />
                  ))}
              </ul>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default observer(MainGroup);
