import React from "react";

import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";
import AttendanceTable from "../AttendanceTable";
import { useParams } from "react-router";

const AttendanceRecord = () => {
  const { courseId, userId }: any = useParams();
  return (
    <BaseTable
      list={{
        url: "/CourseUserApi/GetStudentApplicationListForProfessor",
        data: { userID: userId, courseId: courseId },
        filterParameters: [
          { name: "Activity Name", dataType: "string", operator: "like" },
        ],
        orderBy: "CreatedOn",
        orderByDirection: "desc",
        OrderByExecuted: false,
      }}
    >
      <TableFilter isWithoutButton={true} />

      <AttendanceTable />
      <TablePager />
    </BaseTable>
  );
};

export default AttendanceRecord;
