import React from "react";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const Notifications = () => {
  return (
    <DashboardBase componentName="Notifications">
      <Card>
        <p>Notifications</p>
      </Card>
    </DashboardBase>
  );
};

export default Notifications;
