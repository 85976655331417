import React, { useState, useEffect } from "react";
import cc from "classcat";
import moment from "moment";
import { useHistory, useParams } from "react-router";

import viewIcon from "../../../../assets/images/view.svg";
import { AttendanceForList } from "../../../../models/state.models";
import styles from "./styles.module.scss";

export interface AttendanceRowProps {
  application: AttendanceForList;
}

const AttendanceRow: React.FC<AttendanceRowProps> = ({ application }) => {
  const history = useHistory();
  const { courseId, academicTermId }: any = useParams();

  const handleClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/activities/${application.activityID}/students/${application.submittedActivityID}/confirmation`
    );
  };

  return (
    <tr onClick={handleClick} className={styles.tableRow}>
      <td data-label="Name">{application.name}</td>
      <td
        className={cc([
          styles.tableCell,
          {
            [styles.pendingColor]: application.status === "Pending",
          },
          {
            [styles.redText]:
              application.status === "Professor Rejected" ||
              application.status === "Denied" ||
              application.status === "System Rejected",
          },
          {
            [styles.greenText]:
              application.status === "Professor Validated" ||
              application.status === "System Validated",
          },
        ])}
        data-label="Status"
      >
        {application.status}
      </td>
      <td data-label="Submission Date">
        {moment(application.createdOn).format("MM-DD-YYYY")}
      </td>
      <td data-label="Validation Date">
        {moment(application.applicationDateTime).format("MM-DD-YYYY")}
      </td>
      <td>
        <div className={styles.iconCell}>
          <img
            alt="View"
            className={styles.icon}
            src={viewIcon}
            onClick={handleClick}
          />
        </div>
      </td>
    </tr>
  );
};

export default AttendanceRow;
