import React from "react";
import { action, makeObservable, observable, runInAction } from "mobx";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

import {
  Course,
  ActivityDetails,
  ActivityForList,
  ActivityValidation,
  ActivityValidationTerm,
  ConfirmationDetails,
  File,
  GroupLists,
  Groups,
  ScheduleDay,
  SearchText,
  ActivitySchedulerEdit,
  ActivityScheduler,
  Scheduler,
  SectionList,
  Student,
  ValidationSubmit,
  SectionOptionList,
  ValidationTerm,
  Visibility,
} from "../../models/state.models";
import {
  SaveActivityRequest,
  SaveActivityValidation,
  SaveSubmittedActivity,
  ValidationSubmitRequest,
} from "../../models/request.models";
import { FetchApi, handleFileSubmit } from "../../utilities";
import {
  UseAddUtcOffsetWithDate,
  removeOffset,
  addWorkDayOffset,
} from "../../utils/timeUtils";

class ActivityStore {
  @observable status: string = "inital";

  @observable loading: boolean = false;

  @observable message: string = "";

  @observable searching: boolean = false;

  @observable ID: number = 0;

  @observable currencyName: string = "";

  @observable activityStoreLoading: boolean = false;

  @observable searchActivityList: ActivityForList[] = [];

  @observable totalCountOfActivities: number = 0;

  @observable hasUnsavedChanges: boolean = false;

  @observable activityFiles: File[] = [];

  @observable text: string = "";

  @observable sectionTitle: string = "";

  @observable courseTitle: string = "";

  @observable activityTitle: string = "";

  @observable isIndividual: boolean = false;

  @observable foundText: Visibility[] = [];

  @observable activityValidationCodeList: ActivityValidation[] = [];

  @observable activityListForCopy: SectionList[] = [];

  @observable SectionListForCopy: SectionList[] = [];

  @observable listOfDocumentsForActivity: File[] = [];

  @observable StudentGroupList: GroupLists[] = [];

  @observable studentList: Student[] = [];

  @observable groupName: string = "";

  @observable GroupList: Groups[] = [];

  @observable selectedStudents: Student[] = [];

  @observable StudentsID: number[] = [];

  @observable tempList: any[] = [];

  @observable isAcitivityValidationAdded: boolean = false;

  @observable scheduleDays: ScheduleDay[] = [];

  @observable SubmittedActivityStudentList: [] = [];

  @observable studentWaitingList: number[] = [];

  @observable sectionList: SectionOptionList[] = [];

  @observable activity: ActivityDetails = {
    courseId: 0,
    name: "",
    credit: "",
    accommodationRate: 0,
    isIndividual: true,
    isInClass: true,
    isPresence: false,
    description: "",
    isAccommodated: false,
    validationType: 1,
    validationText: "",
    unresolvedSubmissions: 0,
    startDate: "",
    endDate: "",
    hourTo: "",
    hourFrom: "",
    validationTermList: [],
    latestSubmissions: [],
    activityFiles: [],
    activityVisibilityList: [],
    schedules: [],
  };

  @observable activityValidation: ActivityValidationTerm = {
    activityID: 0,
    activityName: "",
    isPresence: false,
    validationTermList: [],
    validationTermDateFrom: "",
    validationTermDateTo: "",
    activityStartDate: "",
    activityEndDate: "",
    activityHourTo: "",
    activityHourFrom: "",
  };

  @observable validationTerm: ValidationTerm = {
    id: 0,
    validationTerm: "",
    scheduleDayID: 0,
    scheduleDate: "",
    ValidationTermDateFrom: "",
    ValidationTermDateTo: "",
  };

  @observable validationSubmit: ValidationSubmit = {
    groupID: undefined,
    validationPhrase: "",
    validationType: 1,
    validationFiles: [],
    comment: "",
    dateID: 0,
    selectedStudents: [],
  };

  @observable confirmation: ConfirmationDetails = {
    activityEndDate: "",
    activityID: 0,
    activityName: "",
    activityStartDate: "",
    activityType: "",
    credit: 0,
    fileList: [],
    id: 0,
    studentList: [],
    studentName: "",
    studentNote: "",
    userID: 0,
    validationTerm: "",
    submittedDate: "",
    validatedFor: "",
  };

  @observable activitySections: ActivityValidation[] = [];

  @observable selectedActivitySection: number = 0;

  @observable activityId: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async saveActivity(params: SaveActivityRequest) {
    this.activityStoreLoading = true;
    const { data, status, totalActivitiCount, message }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/SaveActivity",
      params,
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.searchActivityList = data;
        this.totalCountOfActivities = totalActivitiCount;
      }
    });

    return { status, message, data };
  }

  @action.bound
  async getActivity(courseId: number, activityId: number) {
    this.activityStoreLoading = true;
    const { data, status, message, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/GetActivity",
      params: { courseID: courseId, activityId: activityId },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.activity = data;

        const { endDate, time, startDate, timeStart } = UseAddUtcOffsetWithDate(
          data.endDate,
          data.hourTo,
          data.startDate,
          data.hourFrom
        );
        this.activity.hourTo = moment(time, ["h:mm A"]);
        this.activity.hourFrom = moment(timeStart, ["h:mm A"]);
        this.activity.startDate = startDate;
        this.activity.endDate = endDate;
        this.isIndividual = data.isIndividual;
        this.listOfDocumentsForActivity = data.activityFiles;
        this.validationSubmit.validationType = data.validationType;
        this.currencyName = data.currencyName;
        if (data.schedules) {
          this.activity.schedules = data.schedules.map(
            (value: ActivitySchedulerEdit) => {
              let removeTimeOffset = removeOffset(
                value.hourFrom,
                value.hourTo,
                value.dayOfWeek
              );
              const formatedScheduleValue: ActivityScheduler = {
                dayOfWeek: removeTimeOffset.dayOfWeek,
                hourFrom: removeTimeOffset.hourFrom.format("h:mm A"),
                hourTo: removeTimeOffset.hourTo.format("h:mm A"),
                id: value.id,
                isDeletable: value.isDeletable,
                courseName: value.sectionName,
                courseID: value.sectionID,
              };
              return formatedScheduleValue;
            }
          );
        }
      }
    });
    return { status, message, breadCrumb };
  }

  @action.bound
  async getActivitySectionNames(courseId: number, activityId: number) {
    this.activityStoreLoading = true;
    const { data, status, message, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetSectionWhereActivityExists",
      params: { courseID: courseId, activityId: activityId },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.activitySections = data;
      }
    });
    return { status, message, breadCrumb };
  }

  @action.bound
  async deleteActivity(courseId: number, activityId: number) {
    this.activityStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/DeleteActivity",
      params: { courseID: courseId, ID: activityId },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
    });
    return { status, message };
  }

  @action.bound
  async getText(text: SearchText) {
    this.text = text.SearchText;
    const { data, status } = await FetchApi({
      method: "POST",
      url: "/ActivityApi/SearchForActivityVisibility",
      params: text,
    });
    runInAction(() => {
      if (status === "success") {
        this.searching = false;
        this.foundText = data;
      }
    });
  }

  @action.bound
  async attachFileHandler(e: any, fileType: number) {
    this.loading = true;
    const file = e.target.files[0];
    const { data, status } = await handleFileSubmit(e, file, true);

    if (status === "success" && data) {
      this.loading = false;
      const fileID: number = data.id;
      let tempList: any = this.validationSubmit.validationFiles;

      this.listOfDocumentsForActivity.push({
        fileID: fileID,
        type: fileType,
        name: e.target.files[0].name,
        fileName: e.target.files[0].fileName,
      });
      if (fileType !== 3) {
        this.activity.activityFiles = this.listOfDocumentsForActivity;
        this.activity.isAccommodated = !this.activity.isAccommodated;
        this.activity.isAccommodated = !this.activity.isAccommodated;
      } else {
        tempList.push({
          fileID: fileID,
        });
        this.validationSubmit.validationFiles = this.listOfDocumentsForActivity;
        this.validationSubmit.validationFiles = tempList;
      }
    }
  }

  @action.bound
  async getStudentList(courseGroupID: number, courseID: number) {
    this.clearCheckedStudents();
    this.validationSubmit.groupID = courseGroupID;
    this.activityStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/GetStudentForActivityGroup",
      params: { CourseGroupID: courseGroupID, CourseID: courseID },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.StudentsID = [];
        this.studentList = data;
      }
    });
    return { status, message };
  }

  @action.bound
  async getValidationDetails(courseID: number, activityID: number) {
    this.activityStoreLoading = true;
    const { data, status, message, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/GetSubmittedActivity",
      params: {
        courseID: courseID,
        submittedActivityID: activityID,
      },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.confirmation = data;
        this.currencyName = data.currencyName;
      }
    });
    return { message: message, status: status, breadCrumb };
  }

  @action.bound
  async saveConfirmation(params: SaveSubmittedActivity) {
    this.activityStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/activityApi/ChangeSubmittedActivityStatus",
      params,
    });
    runInAction(() => {
      this.activityStoreLoading = false;
    });
    return { status: status, message: message };
  }

  @action.bound
  async getStudentGruopList(courseID: number) {
    this.activityStoreLoading = true;
    this.GroupList = [];
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/GetActivityGroupCodeList",
      params: { CourseID: courseID },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.StudentGroupList = data;
      }
    });
    return { status, message };
  }

  @action.bound
  async activitySubmit(params: ValidationSubmitRequest) {
    this.activityStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/activityApi/SaveSubmittedActivity",
      params,
    });
    runInAction(() => {
      this.activityStoreLoading = false;
    });

    return { status, message };
  }

  @action.bound
  async getPresenceActivityDays(courseID: number, activityID: number) {
    this.activityStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/ScheduleApi/GetScheduleDayListForCurrentUser",
      params: { CourseID: courseID, ActivityID: activityID },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.sortScheduleDay(data);
      }
    });
    return { status, message };
  }

  @action.bound
  async getSectionsForActivityDetails(courseID: number) {
    this.activityStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetSectionListForActivityDetail",
      params: { CourseID: courseID },
    });
    runInAction(() => {
      this.activityStoreLoading = false;
      if (status === "success") {
        this.sectionList = data;
      }
    });
    return { status, message };
  }

  @action.bound
  async saveValidation(params: SaveActivityValidation) {
    //  this.loading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/SaveActivityValidationTerm",
      params,
    });
    runInAction(() => {
      //   this.loading = false;
      this.status = status;
      this.message = message;
    });

    return { status, message };
  }

  @action.bound
  async saveValidationDate(params: SaveActivityValidation) {
    this.loading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/SaveActivityValidationTermDates",
      params,
    });
    runInAction(() => {
      this.loading = false;
      this.status = status;
      this.message = message;
    });

    return { status, message };
  }

  @action.bound
  async deleteValidation(courseId: number, validation: number) {
    //this.loading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/DeleteActivityValidationTerm",
      params: { courseID: courseId, ActivityValidationTermID: validation },
    });
    //this.loading = false;
    return { status, message };
  }

  @action.bound
  async getActivityValidationCodeList(CourseId: number, SectionId: number) {
    this.loading = true;
    this.activityStoreLoading = true;
    const { status, data, message, additionalData }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/GetActivityCodeList",
      params: { CourseID: CourseId, SectionID: SectionId },
    });
    this.activityStoreLoading = false;
    this.loading = false;
    if (status === "success") {
      this.activityValidationCodeList = data;
      this.sectionTitle = additionalData.sectionName;
      this.courseTitle = additionalData.courseName;
    }
    return { status, message };
  }

  @action.bound
  async getActivityValidation(
    courseId: number,
    sectionId: number,
    activityId: number,
    load: boolean
  ) {
    if (!load) {
      this.activityStoreLoading = true;
      this.loading = true;
    }
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/GetActivityValidationTerm",
      params: {
        CourseID: courseId,
        SectionID: sectionId,
        ActivityID: activityId,
      },
    });
    runInAction(() => {
      if (!load) {
        this.loading = false;
        this.activityStoreLoading = false;
      }
      if (status === "success") {
        this.activityValidation = data;
        console.log("mobx", data.activityFormatedEndDate);
        console.log("mobx", data.activityFormatedStartDate);
        const { endDate, time, startDate, timeStart } = UseAddUtcOffsetWithDate(
          data.activityFormatedEndDate,
          this.activityValidation.activityHourTo,
          data.activityFormatedStartDate,
          this.activityValidation.activityHourFrom
        );
        this.activityValidation.validationTermDateFrom = startDate;
        this.activityValidation.validationTermDateTo = endDate;
        this.activityValidation.activityHourTo = time;
        this.activityListForCopy = data.sameTypeActivities;
        this.SectionListForCopy = data.courseSections;
      }
    });
    return { status, message };
  }

  @action.bound
  async copyActivityTerms(
    activityId: number,
    sectionId: number,
    copyActivityId: number,
    copySectionId: number
  ) {
    this.activityStoreLoading = true;
    const { data, message }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/CopyActivityValidationTerms",
      params: {
        copyToActivityID: activityId,
        copyFromActivityID: copyActivityId,
        copyToSectionID: sectionId,
        copyFromSectionID: copySectionId,
      },
    });
    runInAction(() => {
      this.loading = false;
      this.activityStoreLoading = false;
      if (data) {
      }
    });
    return { message: message, status: data };
  }

  @action.bound
  removeActivityFile = (id: number) => {
    this.listOfDocumentsForActivity = this.listOfDocumentsForActivity.filter(
      (file: File) => file.fileID !== id
    );
    this.validationSubmit.validationFiles =
      this.validationSubmit.validationFiles.filter(
        (file: File) => file.fileID !== id
      );
  };

  @action.bound
  changeSearching = (change: boolean) => {
    this.searching = change;
  };

  @action.bound
  handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.groupName = event.target.value;
  }

  @action.bound
  clearCheckedStudents = () => {
    this.validationSubmit.selectedStudents = [];
    this.StudentsID = [];
  };

  @action.bound
  handleGroupCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    user: Student
  ) {
    let isSelected = false;
    let selectedStudentID = user.id;
    this.tempList = this.validationSubmit.selectedStudents;
    isSelected = event.target.checked;
    if (isSelected === true) {
      this.StudentsID.push(user.id);
      this.tempList.push(user);
      this.validationSubmit.selectedStudents = this.selectedStudents;
      this.validationSubmit.selectedStudents = this.tempList;
    } else {
      this.validationSubmit.selectedStudents =
        this.validationSubmit.selectedStudents.filter(
          (user: Student) => user.id !== selectedStudentID
        );
      this.StudentsID = this.StudentsID.filter(
        (id: number) => id !== selectedStudentID
      );
    }
  }

  @action.bound
  handleRemoveStudent(remove: Student) {
    this.validationSubmit.selectedStudents =
      this.validationSubmit.selectedStudents.filter(
        (user: Student) => user.id !== remove.id
      );
    this.StudentsID = this.StudentsID.filter((id: number) => id != remove.id);
  }

  @action.bound
  changeDateAndTimeUTCOffset = (
    hourTo: string,
    startDate: string,
    endDate: string
  ) => {
    this.activity.startDate = startDate;
    this.activity.endDate = endDate;
    this.activity.hourTo = moment(hourTo, ["h:mm A"]);
  };

  @action.bound
  setSearchActivityList = (searchList: ActivityForList[]) => {
    this.searchActivityList = searchList;
  };

  @action.bound
  setActivityTitle = (name: string) => {
    this.activityTitle = name;
  };

  @action.bound
  setIsIndividual = (state: boolean) => {
    this.isIndividual = state;
  };

  @action.bound
  setActivityValidationAdded = (val: boolean) => {
    this.isAcitivityValidationAdded = val;
  };

  @action.bound
  setSelectedActivitySection = (val: number) => {
    this.selectedActivitySection = val;
  };

  @action.bound
  getSelectedActivitySection = () => {
    return this.selectedActivitySection;
  };

  @action.bound
  setActivityId = (val: number) => {
    this.activityId = val;
  };

  @action.bound
  setSubmittedActivityStudentList = (list: []) => {
    this.SubmittedActivityStudentList = list;
    // **Create student list for next validations
    this.studentWaitingList = [];
    list &&
      list.map((student: any) =>
        student.status === 0 || student.status === 2
          ? this.studentWaitingList.push(student.id)
          : null
      );
  };

  @action.bound
  removeStudentWaitingList = (studentId: number) => {
    this.studentWaitingList = this.studentWaitingList.filter(
      (id: number) => id != studentId
    );
    return this.studentWaitingList[0];
  };

  @action.bound
  handleActivityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.activity[event.target.name] = event.target.value;
  };

  @action.bound
  sortScheduleDay = (list: ScheduleDay[]) => {
    list.sort(function (a: ScheduleDay, b: ScheduleDay) {
      return moment(a.startTime).diff(moment(b.startTime));
    });
    list.map((schedule: ScheduleDay) => {
      const { hourFrom, hourTo, dayOfWeek } = removeOffset(
        schedule.hourFrom,
        schedule.hourTo,
        schedule.dayOfWeek
      );
      const workDate = addWorkDayOffset(schedule.startTime);
      schedule.hourFrom = moment(hourFrom).format(" hh:mm A");
      schedule.hourTo = moment(hourTo).format(" hh:mm A");
      schedule.dayOfWeek = dayOfWeek;
      schedule.workDate = moment(workDate).format("MM.DD.YYYY");
    });

    this.scheduleDays = list.filter(
      (time: ScheduleDay) => time.submitted == false
    );
  };

  @action.bound
  handleActivitySubmitInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.validationSubmit[event.target.name] = event.target.value;
  };

  @action.bound
  handleDate = (id: number) => {
    this.validationSubmit.dateID = id;
  };

  @action.bound
  setEditDate = (list: ValidationTerm) => {
    this.validationTerm.ValidationTermDateFrom = list.ValidationTermDateFrom;
    this.validationTerm.ValidationTermDateTo = list.ValidationTermDateTo;
  };

  @action.bound
  handleActivityTermInputChange = (value: string) => {
    this.validationTerm.validationTerm = value;
  };

  @action.bound
  handleActivitySelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.activity[event.target.name] = event.target.value;
  }

  @action.bound
  handleBooleanChange(event: React.ChangeEvent<HTMLSelectElement>) {
    event.target.value === "1"
      ? (this.activity[event.target.name] = true)
      : (this.activity[event.target.name] = false);
  }

  @action.bound
  handleDateChange(date: MaterialUiPickersDate, name: string) {
    this.activity[name] = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  handleValidationDateChange(date: MaterialUiPickersDate, name: string) {
    this.validationSubmit[name] = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  handleDateTermChange(date: MaterialUiPickersDate, name: string) {
    this.validationTerm[name] = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  handleCheckboxActivity(event: React.ChangeEvent<HTMLInputElement>) {
    this.activity[event.target.name] = !this.activity[event.target.name];
  }

  @action.bound
  addActivityVisibility(user: Visibility) {
    this.activity.activityVisibilityList.push(user);
  }

  @action.bound
  addFile(file: File) {
    let tempList = this.activity.activityFiles;
    tempList.push(file);
    this.activity.activityFiles = tempList;
  }

  @action.bound
  removeActivityVisibility(remove: Visibility) {
    this.activity.activityVisibilityList =
      this.activity.activityVisibilityList.filter((user: Visibility) =>
        remove.courseUserID !== undefined
          ? user.courseUserID !== remove.courseUserID
          : user.courseGroupID !== remove.courseGroupID
      );
  }

  @action.bound
  addSectionTime(time: Scheduler) {
    this.activity.schedules.push(time);
  }

  @action.bound
  removeSectionTime(time: Scheduler) {
    this.activity.schedules = this.activity.schedules.filter(
      (value: Scheduler) => value.id !== time.id
    );
  }

  @action.bound
  handleDefaultCourseTerm(course: Course) {
    this.activity.startDate = course.startDate;
    this.activity.endDate = course.endDate;
  }

  @action.bound resetActivity() {
    this.listOfDocumentsForActivity = [];
    this.activity = {
      courseId: 0,
      name: "",
      credit: "",
      accommodationRate: 0,
      isIndividual: true,
      isInClass: true,
      isPresence: false,
      description: "",
      validationType: 1,
      isAccommodated: false,
      validationText: "",
      unresolvedSubmissions: 0,
      startDate: "",
      endDate: "",
      hourTo: "",
      hourFrom: "",
      validationTermList: [],
      latestSubmissions: [],
      activityFiles: [],
      activityVisibilityList: [],
      schedules: [],
    };
  }

  @action.bound resetActivityValidationCode() {
    this.activityValidationCodeList = [];
  }

  @action.bound validationSubmitReset() {
    this.scheduleDays = [];
    this.GroupList = [];
    this.studentList = [];
    this.StudentsID = [];
    this.validationSubmit = {
      groupID: undefined,
      validationPhrase: "",
      validationFiles: [],
      comment: "",
      dateID: 0,
      selectedStudents: [],
    };
  }

  @action.bound
  resetScheduler() {
    this.activity.schedules = [];
  }
}

export default ActivityStore;
