import React from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../stores";
import ActivityList from "./Components/ActivityList";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const Activities = () => {
  const { courseName } = useAuth();

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isActivities={true}>
        <Card isFullScreen={true} isWithOutTopMargin={true} isNavigation={true} isCoursesTabs={true}>
          <ActivityList />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(Activities);
