import React, { useEffect } from "react";

import ActivityGroupTableRow from "../ActivityGroupTableRow";
import { useActivities } from "../../../../stores";
import styles from "./styles.module.scss";

const ActivityGroupTable = ({ list }: any) => {
  const { setSubmittedActivityStudentList } = useActivities();

  useEffect(() => {
    setSubmittedActivityStudentList(list.entityList);
  }, []);

  return (
    <table key="activity-table" className={styles.table}>
      <thead>
        <tr>
          <td></td>
          <td>
            <div
            // className={styles.headerCell}
            // onClick={() => list.changeOrderBy("name")}
            >
              Name
              {/* {list.orderBy === "name" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>
          <td>Date</td>
          <td>
            <div
            // className={styles.headerCell}
            // onClick={() => list.changeOrderBy("credit")}
            >
              Validation Status
              {/* {list.orderBy === "credit" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>
          <td>
            <div
            // className={styles.headerCell}
            // onClick={() => list.changeOrderBy("documents")}
            >
              Documents
              {/* {list.orderBy === "documents" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((group: any) => (
            <ActivityGroupTableRow group={group} key={group.id} />
          ))}
      </tbody>
    </table>
  );
};

export default ActivityGroupTable;
