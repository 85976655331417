import React from "react";
import { useParams, useHistory } from "react-router";
import { observer } from "mobx-react";

import BaseTable from "../../../Components/BaseTable";
import SectionTable from "../SectionTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";
import { useAuth, useDialog } from "../../../../stores";

const SectionsList = () => {
  const { courseId, academicTermId }: any = useParams();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  return (
    <>
      {role != "Student" ? (
        <BaseTable
          list={{
            url: "/CourseApi/GetCourseSectionList",
            filterParameters: [
              { name: "ID", dataType: "int", operator: "=" },
              { name: "Title", dataType: "string", operator: "like" },
            ],
          }}
        >
          <TableFilter
            buttonDestination={`/academicTerms/${academicTermId}/courses/${courseId}/sections/add`}
            buttonTitle="Add new section"
          ></TableFilter>
          <SectionTable />
          <TablePager />
        </BaseTable>
      ) : (
        showDialog(
          "User not authorized",
          `/academicTerms/${academicTermId}/courses`,
          history,
          true,
          "Errror"
        )
      )}
    </>
  );
};

export default observer(SectionsList);
