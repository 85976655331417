import React from "react";

import Activities from "../../../Pages/Activities";
import ActivityConfirmation from "../../../Pages/ActivityConfirmation";
import ActivityDetails from "../../../Pages/ActivityDetails";
import ActivityGroups from "../../../Pages/ActivityGroups";
import ActivitySectionsValidation from "../../../Pages/ActivitySectionsValidation";
import ActivityStudents from "../../../Pages/ActivityStudents";
import AddNewActivity from "../../../Pages/AddNewActivity";
import EditActivity from "../../../Pages/EditActivity";
import PrivateRoute from "../../PrivateRoute";

const ActivityRoutes = () => {
  return (
    <>
      <PrivateRoute
        component={Activities}
        path="/academicTerms/:academicTermId/courses/:courseId/activities"
        exact
      />
      <PrivateRoute
        component={AddNewActivity}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/add"
      />
      <PrivateRoute
        component={EditActivity}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/edit/:activityId"
      />

      <PrivateRoute
        component={ActivityDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/details"
        exact
      />
      <PrivateRoute
        component={ActivityGroups}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/groups"
      />
      <PrivateRoute
        component={ActivityStudents}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/students"
        exact
      />
      <PrivateRoute
        component={ActivitySectionsValidation}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/activitySectionsValidation"
        exact
      />

      <PrivateRoute
        component={ActivitySectionsValidation}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/activitySectionsValidation/:sectionId"
      />

      <PrivateRoute
        component={ActivityConfirmation}
        path="/academicTerms/:academicTermId/courses/:courseId/activities/:activityId/students/:submittedActivityId/confirmation"
      />
    </>
  );
};

export default ActivityRoutes;
