import React from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";

import ActivityTable from "../ActivityTable";
import TableFilter from "../../../Components/TableFilter";
import BaseTable from "../../../Components/BaseTable";
import TablePager from "../../../Components/TablePager";

const ActivityList = () => {
  const { courseId, academicTermId }: any = useParams();

  return (
    <BaseTable
      list={{
        url: "/ActivityApi/GetActivitieList",
        filterParameters: [
          { name: "ID", dataType: "int", operator: "=" },
          { name: "Name", dataType: "string", operator: "like" },
        ],
      }}
    >
      <TableFilter
        buttonDestination={`/academicTerms/${academicTermId}/courses/${courseId}/activities/add`}
        buttonTitle="Add new Activity"
      ></TableFilter>

      <ActivityTable />
      <TablePager />
    </BaseTable>
  );
};

export default observer(ActivityList);
