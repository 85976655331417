import { action, makeObservable, observable } from "mobx";

class BaseTableStore {
  @observable needsReloadTable: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setNeedsReloadTable(value: boolean) {
    this.needsReloadTable = value;
  }
}

export default BaseTableStore;
