import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";

import Button from "../../../../../../common/Button";
import ColorSelect from "../../../../../../common/ColorPicker";
import TextArea from "../../../../../../common/TextArea";
import TextInput from "../../../../../../common/TextInput";
import { useDialog, useSectionGroups } from "../../../../../../stores";
import { GroupModalSchema } from "../AddGroupModalSchema";
import { useParams } from "react-router";
import styles from "./styles.module.scss";

interface AddGroupModalProps {
  handleCancel: () => void;
}

const AddGroupModal: React.FC<AddGroupModalProps> = ({ handleCancel }) => {
  const { showDialog } = useDialog();
  const { sectionId, courseId }: any = useParams();
  const {
    getCourseGroupForList,
    handleSectionGroupInputChange,
    handlerCheckboxGroupType,
    handlerColorPickerChange,
    saveCourseGroup,
    sectionGroup,
    resetSectionGroup,
    isGroupDataEdit,
  } = useSectionGroups();

  useEffect(() => {
    if (isGroupDataEdit === false) {
      resetSectionGroup();
    }
  }, [isGroupDataEdit]);

  const handleSubmit = () => {
    sectionGroup.courseID = sectionId;
    saveCourseGroup(sectionGroup).then((resp: any) => {
      if (resp.status === "success") {
        showDialog(
          isGroupDataEdit
            ? "Changes saved succesfully"
            : "Group added succesfully",
          null,
          null,
          false
        );
        getCourseGroupForList(sectionId, courseId);
        handleCancel();
      }
    });
  };

  return (
    <Formik
      initialValues={sectionGroup}
      validationSchema={GroupModalSchema}
      onSubmit={(values, actions) => {
        handleSubmit();
        actions.setSubmitting(false);
      }}
    >
      {({ errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form>
            <div className={styles.addGroupWrapper + " p-5"}>
              <Row>
                <Col>
                  <TextInput
                    errorMessage={errors.name}
                    label="Group Name"
                    name="name"
                    onChange={(e) => {
                      handleChange(e);
                      handleSectionGroupInputChange(e);
                    }}
                    touched={touched.name}
                    value={sectionGroup.name}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <ColorSelect
                    hideTextfield={true}
                    inputFormats={[]}
                    label="Group Label"
                    name="groupLabel"
                    onChange={(e: any) => {
                      handlerColorPickerChange(e);
                      setFieldValue("groupLabel", e.css.backgroundColor);
                    }}
                    touched={touched.groupLabel}
                    value={sectionGroup.groupLabel}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <label className={styles.labelWrapper}>Primary ?</label>
                  <Checkbox
                    className={styles.checkBox}
                    id="isPrimary"
                    onChange={handlerCheckboxGroupType}
                    defaultChecked={
                      isGroupDataEdit === true
                        ? sectionGroup.type === 1
                          ? true
                          : false
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <label className={styles.labelWrapper}>
                    Accesibility Group{" "}
                  </label>
                  <Checkbox
                    className={styles.checkBox}
                    onChange={handlerCheckboxGroupType}
                    defaultChecked={
                      isGroupDataEdit === true
                        ? sectionGroup.isAccessibility
                        : false
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <TextArea
                    value={sectionGroup.description}
                    label="Description"
                    name="description"
                    onChange={(e) => {
                      handleChange(e);
                      handleSectionGroupInputChange(e);
                    }}
                  />
                  {touched.description && errors.description ? (
                    <p className={styles.errorMsg}> {errors.description} </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="justify-content-end + m-0 mt-5">
                <Col>
                  <Button
                    isDialogCancel={true}
                    onClick={handleCancel}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col>
                  <Button
                    isDialog={true}
                    title={isGroupDataEdit ? "Save" : "Add Group"}
                    type="submit"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(AddGroupModal);
