import React, { useState } from "react";
import { useParams } from "react-router";

import { AssignmentStudent } from "../../../../models/state.models";
import { BASE_URL } from "../../../../utilities";
import { useAssignments, useDialog } from "../../../../stores";
import Button from "../../../../common/Button";
import CustomModal from "../../../../common/CustomModal";
import defaultUser from "../../../../assets/images/default-user.png";
import StudetModal from "../StudentModal";
import styles from "./style.module.scss";

interface StudentRowProps {
  list: any;
  student: AssignmentStudent;
}

const StudentRow: React.FC<StudentRowProps> = ({ student, list }) => {
  const { changeSubmittedAssignmentStatus } = useAssignments();
  const { courseId }: any = useParams();
  const { showDialog } = useDialog();

  const [ModalVisiblity, setModalVisiblity] = useState(false);
  const [note, setNote] = useState("");

  const changeStatus = (status: number) => {
    changeSubmittedAssignmentStatus(courseId, student.id, status, note).then(
      (resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog("Status changed successsfully", null, null, false);
          list.refresh();
        } else {
          showDialog(resp.message, null, null, true, "Error");
        }
      }
    );
  };

  return (
    <tr>
      <td className={styles.contentWrapper}>
        <img
          className={styles.avatarIcon}
          src={
            student.imageFileName
              ? `${BASE_URL}File/GetFile/${student.imageFileName}`
              : defaultUser
          }
        />
      </td>
      <td className={styles.buttonWrapper}>{student.studentName}</td>
      <td>{student.submittedDate}</td>
      <td className={styles.buttonWrapper}>
        <Button
          title="deny"
          type="deny"
          isDeny={true}
          onClick={() => setModalVisiblity(true)}
        />
      </td>
      <td className={styles.buttonWrapper}>
        <Button title="hold" type="hold" isHold={true} />
      </td>
      <td className={styles.buttonWrapper}>
        <Button
          isDialog={true}
          title="allow"
          type="allow"
          onClick={() => {
            setNote("");
            changeStatus(1);
          }}
        />
      </td>
      <CustomModal
        handleClose={() => setModalVisiblity(false)}
        open={ModalVisiblity}
      >
        <StudetModal
          handleCancel={() => {
            setModalVisiblity(false);
            setNote("");
          }}
          handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setNote(event?.target.value);
          }}
          handleClick={() => changeStatus(2)}
          value={note}
        />
      </CustomModal>
    </tr>
  );
};

export default StudentRow;
