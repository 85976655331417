import React from 'react'
import { ColorPicker } from 'material-ui-color';

import styles from "./styles.module.scss";



const ColorSelect: React.FC<any> = ({
    label,
    ...props
}) => {
    return (
        <div>
            <label className = {styles.pickerLabelInput}>
                {label}
                <div className = {styles.pickerInputWrapper}>
                    <ColorPicker
                    {...props}
                    />
                </div>           
            </label> 
        </div>
    );    
};

export default ColorSelect;