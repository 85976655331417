import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../../stores";

const PrivateRoute = ({ comp, ...rest }: any) => {
  const { isLogin } = useAuth();

  return (
    <>
      {isLogin === "true" && <Route component={comp} {...rest} />}
      {isLogin === "false" && <Redirect to="/" />}
    </>
  );
};

export default PrivateRoute;
