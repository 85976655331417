import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";

import { AssignmentSchema } from "./Components/AssignmentSchema";
import { SaveAssignmentRequest } from "../../../models/request.models";
import Button from "../../../common/Button";
import CustomCheckbox from "../../../common/CustomCheckbox";
import DatePicker from "../../../common/DatePicker";
import TextArea from "../../../common/TextArea";
import TextInput from "../../../common/TextInput";
import {
  useAssignments,
  useAuth,
  useDialog,
  useCourses,
} from "../../../stores";
import styles from "./styles.module.scss";

interface CUAssignmentsProps {
  isEdit?: boolean;
}

const CUAssignments: React.FC<CUAssignmentsProps> = ({ isEdit }) => {
  const history = useHistory();
  const { courseId, idAssignment, academicTermId }: any = useParams();
  const { showDialog } = useDialog();
  const { selectedAcademicTermId, role } = useAuth();

  const { course, getCourseDetails } = useCourses();

  const {
    assignment,
    getAssignment,
    handleAssignmentInputChange,
    handleCheckboxActivity,
    handleDateChange,
    resetAssignment,
    saveAssignment,
  } = useAssignments();

  useEffect(() => {
    if (isEdit && role != "Student") {
      getCourseDetails(courseId);
      getAssignment(idAssignment, courseId).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Errror"
            );
          }
          showDialog(resp.message, null, null, true, "Error");
        }
      });
    } else {
      resetAssignment();
    }
  }, [getAssignment, courseId]);

  useEffect(() => {
    if (role != "Student") {
      if (!isEdit) {
        getCourseDetails(courseId);
      }
    } else {
      showDialog(
        "User not authorized",
        `/academicTerms/${selectedAcademicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
  }, []);

  const handleSubmit = () => {
    const request: SaveAssignmentRequest = {
      AdditionalBuyBackRequirements: assignment.additionalBuyBackRequirements,
      buyBackDateTo: assignment.isBuyBack ? assignment.buyBackDateTo : null,
      BuyBackNumber: assignment.isLimitedBuyBacks
        ? assignment.buyBackNumber
        : null,
      CourseID: courseId,
      dateTo: assignment.dateTo,
      IsBuyBack: assignment.isBuyBack,
      IsEntireSemester: assignment.isEntireSemester,
      IsExtraCredit: assignment.isExtraCredit,
      MaxBuyBackPrice: assignment.maxBuyBackPrice,
      MaxPoints: assignment.maxPoints,
      MinBuyBackPrice: assignment.minBuyBackPrice,
      Name: assignment.name,
    };
    if (isEdit) {
      request.id = idAssignment;
    }

    saveAssignment(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        resetAssignment();
        if (isEdit) {
          showDialog(
            "Assignment changed succesfully",
            `/academicTerms/${academicTermId}/courses/${courseId}/assignments`,
            history,
            false
          );
        } else {
          showDialog(
            "Assignment added succesfully",
            `/academicTerms/${academicTermId}/courses/${courseId}/assignments`,
            history,
            false
          );
        }
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleAssignmentInputChange(event);
  };

  return (
    <Formik
      initialValues={{ ...assignment }}
      enableReinitialize
      validationSchema={AssignmentSchema}
      onSubmit={(values) => {
        if (values) {
          handleSubmit();
        }
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <Col className="pt-4">
            <h2 className="mb-4">
              {isEdit ? "Edit Assignment" : "Add new Assignment"}
            </h2>
            <Form>
              <Row>
                <Col md={6} className="pr-5">
                  <Row className="mb-4">
                    <Col>
                      <TextInput
                        errorMessage={errors.name}
                        label="Assignment Name"
                        name="name"
                        onBlur={handleBlur}
                        touched={touched.name}
                        value={values.name}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                  </Row>
                  <TextInput
                    errorMessage={errors.maxPoints}
                    label="Assignment point value"
                    name="maxPoints"
                    onBlur={handleBlur}
                    touched={touched.maxPoints}
                    value={values.maxPoints}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      textIntputHandleChange(event, handleChange)
                    }
                  />
                  <Row className="mt-4 align-items-end">
                    <Col md={8}>
                      <DatePicker
                        endDate={course.endDate}
                        errorMessage={errors.dateTo}
                        labelPar="Assignment Due Date"
                        name="dateTo"
                        onBlur={handleBlur}
                        placeholder="mm/dd/yyyy"
                        startDate={course.startDate}
                        touched={touched.dateTo}
                        value={values.dateTo || ""}
                        onChange={(date) => {
                          handleDateChange(date, "dateTo");
                          setFieldValue("dateTo", date);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <CustomCheckbox
                        checked={assignment.isExtraCredit}
                        label="extra credit"
                        name="isExtraCredit"
                        onChange={(event: any) => handleCheckboxActivity(event)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="pl-5 mt-4">
                  <Row>
                    <Col>
                      <CustomCheckbox
                        checked={values.isBuyBack}
                        label=" Assignment is eligible to be bought back"
                        name="isBuyBack"
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          handleChange(event);
                          handleCheckboxActivity(event);
                        }}
                      />
                    </Col>
                  </Row>
                  {assignment.isBuyBack && (
                    <>
                      <Row className="my-4">
                        <Col md={6}>
                          <TextInput
                            errorMessage={errors.buyBackNumber}
                            label="Limit number of buybacks"
                            name="buyBackNumber"
                            onBlur={handleBlur}
                            disabled={!assignment.isLimitedBuyBacks}
                            touched={touched.buyBackNumber}
                            value={
                              assignment.isLimitedBuyBacks
                                ? values.buyBackNumber
                                : 0
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => textIntputHandleChange(event, handleChange)}
                          >
                            <CustomCheckbox
                              checked={values.isLimitedBuyBacks}
                              name="isLimitedBuyBacks"
                              onChange={(event: any) => {
                                handleChange(event);
                                handleCheckboxActivity(event);
                              }}
                            />
                          </TextInput>
                        </Col>

                        <Col md={6}>
                          <DatePicker
                            endDate={course.endDate}
                            errorMessage={errors.buyBackDateTo}
                            labelPar="Entire semester"
                            name="buyBackDateTo"
                            onBlur={handleBlur}
                            disabled={assignment.isEntireSemester}
                            placeholder="mm/dd/yyyy"
                            startDate={course.startDate}
                            touched={touched.buyBackDateTo}
                            value={values.buyBackDateTo || ""}
                            onChange={(date) => {
                              handleDateChange(date, "buyBackDateTo");
                              setFieldValue("buyBackDateTo", date);
                            }}
                          >
                            <CustomCheckbox
                              checked={values.isEntireSemester}
                              name="isEntireSemester"
                              onChange={(event: any) => {
                                setFieldValue("buyBackDateTo", course.endDate);
                                handleDateChange(
                                  course.endDate,
                                  "buyBackDateTo"
                                );
                                handleCheckboxActivity(event);
                              }}
                            />
                          </DatePicker>
                        </Col>
                      </Row>

                      <Row className="my-4 justify-content">
                        <Col md={6}>
                          <TextInput
                            errorMessage={errors.minBuyBackPrice}
                            label="Minimum Transaction Cost"
                            name="minBuyBackPrice"
                            onBlur={handleBlur}
                            touched={touched.minBuyBackPrice}
                            value={values.minBuyBackPrice}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => textIntputHandleChange(event, handleChange)}
                          />
                        </Col>
                        <Col md={6}>
                          <TextInput
                            errorMessage={errors.maxBuyBackPrice}
                            label="Maximum Transaction Cost"
                            name="maxBuyBackPrice"
                            onBlur={handleBlur}
                            touched={touched.maxBuyBackPrice}
                            value={values.maxBuyBackPrice}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => textIntputHandleChange(event, handleChange)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextArea
                            label="Additional buyback requirements"
                            name="additionalBuyBackRequirements"
                            value={values.additionalBuyBackRequirements}
                            onChange={handleAssignmentInputChange}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 justify-content-md-end">
                <Col md="auto" className="px-2 ">
                  <Button
                    isDialogCancel={true}
                    onClick={() => {
                      resetAssignment();
                      history.goBack();
                    }}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col md="auto" className="px-2">
                  <Button
                    isDialog={true}
                    title={isEdit ? "Save" : "Add Assignment"}
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        );
      }}
    </Formik>
  );
};

export default observer(CUAssignments);
