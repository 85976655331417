import React from "react";
import { observer } from "mobx-react";

import { useAuth, useActivities } from "../../stores";
import Card from "../../common/Card";
import Confirmation from "./Components/Confirmation";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const ActivityConfirmation = () => {
  const { confirmation } = useActivities();
  const { courseName } = useAuth();

  return (
    <DashboardBase
      componentName={courseName}
      isActivity={true}
      location={confirmation.activityName}
    >
      <Card isFullScreen={true} isWithOutTopMargin={false} isCard={true}>
        <Confirmation />
      </Card>
    </DashboardBase>
  );
};

export default observer(ActivityConfirmation);
