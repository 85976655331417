import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";

import { useAuth, useScreenSize } from "../../../stores";
import ButtonWithImage from "../../../common/ButtonWithImage";
import plusIcon from "../../../assets/images/plus.svg";
import searchIcon from "../../../assets/images/search-white.svg";
import filterIcon from "../../../assets/images/filter-toggler-btn.png";
import TextInput from "../../../common/TextInput";
import SelectWithLabel from "../../../common/SelectWithLabel";
import styles from "./style.module.scss";

interface TableFilterProps {
  buttonDestination?: string;
  buttonTitle?: string;
  isWithoutButton?: boolean;
  list?: any;
}

const TableFilter: React.FC<TableFilterProps> = ({
  buttonDestination,
  buttonTitle,
  isWithoutButton,
  list,
}: any) => {
  const history = useHistory();
  const { role } = useAuth();

  const { isTabs } = useScreenSize();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isColapsableFilterVisible, setisColapsableFilterVisible] = useState(
    !isTabs
  );

  useEffect(() => {
    if (isTabs) {
      setisColapsableFilterVisible(false);
      setIsFilterVisible(true);
    } else {
      setisColapsableFilterVisible(true);
      setIsFilterVisible(false);
    }
  }, [isTabs]);

  const StatusSelectValue = [
    { name: "All", id: 0 },
    { name: "Active", id: true },
    { name: "InActive", id: false },
  ];

  const SelectValue = [
    { name: "Activity", id: "Activity" },
    { name: "Spend opportunty", id: "Spend opportunity" },
    { name: "Extra Credit", id: "Extra Credit" },
    { name: "All", id: 0 },
  ];

  const UserRolesSelectValue = [
    { name: "All	", id: 0 },
    { name: "Professor", id: "prof" },
    { name: "Student", id: "stud" },
    { name: "Teaching Assistant	", id: "tcAss" },
  ];

  const changeLableName = (name: string) => {
    switch (name) {
      case "CourseCode":
        return "Course Code";
      case "IsActive":
        return "Status";
      case "UserRoles":
        return "User roles";

      default:
        return name;
    }
  };

  return (
    <Row
      className={
        styles.courseFilterWrapper +
        " justify-content-between align-items-end mb-2"
      }
      key="table-filter"
    >
      <Col className={styles.courseFilterHolder}>
        {isColapsableFilterVisible && (
          <Row
            onClick={() => setIsFilterVisible(!isFilterVisible)}
            className={styles.searchCoursesToggler + " justify-content-between"}
          >
            <Col xs="auto">Filter Courses</Col>
            <Col xs="auto">
              <img className={styles.icon} src={filterIcon} />
            </Col>
          </Row>
        )}
        {isFilterVisible && (
          <Row className="align-items-end py-4">
            {list.filterParameters.map((value: any, index: number) => {
              var lableName = changeLableName(value.name);

              return role !== "Student" ||
                (role === "Student" && value.name !== "ID") ? (
                <Col md={4} xl={3} key={index}>
                  {value.type && value.type === "select" ? (
                    <SelectWithLabel
                      label={lableName}
                      isSmall={true}
                      name="avatarFileID"
                      onChange={(event: any) => {
                        event.target.value === 0
                          ? list.changeFilterParameter(value.name, "")
                          : list.changeFilterParameter(
                              value.name,
                              event.target.value
                            );
                      }}
                      values={
                        value.name === "IsActive"
                          ? StatusSelectValue
                          : value.name === "UserRoles"
                          ? UserRolesSelectValue
                          : SelectValue
                      }
                      value={
                        list.getFilterParameter(value.name).value === "" ||
                        list.getFilterParameter(value.name).value === undefined
                          ? 0
                          : list.getFilterParameter(value.name).value
                      }
                    />
                  ) : (
                    <TextInput
                      isSearch={true}
                      label={lableName}
                      placeholder={"Search " + lableName}
                      id="id_filter_id"
                      type={value.dataType}
                      value={list.getFilterParameter(value.name).value}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          list.refresh();
                        }
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        list.changeFilterParameter(value.name, e.target.value)
                      }
                    />
                  )}
                </Col>
              ) : (
                <></>
              );
            })}

            <Col className={styles.filterButtonWrapper}>
              <ButtonWithImage
                icon={searchIcon}
                title="Filter"
                onClick={list.refresh}
                isFilter={true}
              />
            </Col>

            {!isWithoutButton && role !== "Student" && isTabs && (
              <Col className={styles.addCourseButtonWrapper} md="auto">
                <ButtonWithImage
                  icon={plusIcon}
                  title={buttonTitle}
                  onClick={() => {
                    history.push(buttonDestination);
                  }}
                  isAddCourse={true}
                />
              </Col>
            )}
          </Row>
        )}
      </Col>
      {/* {!isWithoutButton && (
        <>
          <Col md={1}>
            <Button title="Refresh" onClick={list.refresh} />
          </Col>
          <Col md={1}>
            <Button title="Close" onClick={list.close} />
          </Col> */}
      {!isWithoutButton && role !== "Student" && !isTabs && (
        <Col className={styles.addCourseButtonWrapper} md="auto">
          <ButtonWithImage
            icon={plusIcon}
            title={buttonTitle}
            onClick={() => {
              history.push(buttonDestination);
            }}
            isAddCourse={true}
          />
        </Col>
      )}
    </Row>
  );
};

export default TableFilter;
