import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";

import { BASE_URL } from "../../../../utilities";
import { CourseUser } from "../../../../models/state.models";
import { useDialog, useSection, useAuth } from "../../../../stores";
import AddStudent from "../AddStudent";
import defaultUser from "../../../../assets/images/default-user.png";
import deleteIcon from "../../../../assets/images/trash.svg";
import editIcon from "../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../Components/MessageBoxWithButtons";
import viewIcon from "../../../../assets/images/view.svg";
import styles from "./styles.module.scss";

interface StudentsTableRowProps {
  student: CourseUser;
  list: any;
}
const StudentRow: React.FC<StudentsTableRowProps> = ({ student, list }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { courseId, sectionId, academicTermId }: any = useParams();
  const { deleteStudentFromSection, handlerEditStudentRow } = useSection();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  const [isEditStudentVisible, setIsEditStudentVisible] = useState(true);

  const handleConfirm = () => {
    deleteStudentFromSection(courseId, student.id).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Student successfully deleted", null, null, false);
        list.refresh();
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/students/student/${student.id}/${student.userID}`
    );
  };

  return (
    <>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete ${student.email} student?`}
        handleClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isOpenDialog}
      />
      {isEditStudentVisible ? (
        <tr onClick={onTableDetailsClick}>
          <td className={styles.contentWrapper} data-label="Name">
            <ul className=" col p-0 ">
              <li className=" d-flex align-items-center ">
                <img
                  src={
                    student.imageFileName
                      ? `${BASE_URL}File/GetFile/${student.imageFileName}`
                      : defaultUser
                  }
                  className={styles.userIcon}
                />
                <p className={styles.nameFont + " pl-2"}>{student.fullName}</p>
              </li>
            </ul>
          </td>
          <td className={styles.emailWrapper} data-label="Email">
            <p>{student.email}</p>
          </td>
          <td className={styles.contentWrapper} data-label="Primary group name">
            <p>{student.primaryGroupName}</p>
          </td>
          <td className={styles.contentWrapper} data-label="Credits earned">
            {student.creditsEarned}
          </td>
          <td className={styles.contentWrapper} data-label="Credits spent">
            {student.creditsSpent ? student.creditsSpent : 0}
          </td>
          <td
            className={styles.contentWrapper}
            data-label="Currentcourse grade"
          >
            {student.gradePercentage != null && student.gradePercentage}
          </td>
          <td className={styles.contentWrapper} data-label="Number of absences">
            {student.absences}
          </td>
          <td>
            <div className={styles.iconsWrapper}>
              {role === "Professor" && (
                <img
                  alt="Edit"
                  className={styles.icon}
                  src={editIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditStudentVisible(!isEditStudentVisible);
                  }}
                />
              )}
              <img
                alt="View"
                src={viewIcon}
                className={styles.icon}
                // onClick={() =>
                //   history.push(
                //     `/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/students/student`
                //   )
                // }
              />
              {role === "Professor" && (
                <img
                  alt="Delete"
                  src={deleteIcon}
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenDialog(true);
                  }}
                />
              )}
            </div>
          </td>
        </tr>
      ) : (
        <AddStudent
          isEdit={true}
          list={list}
          student={student}
          visibility={isEditStudentVisible}
          visibleRow={setIsEditStudentVisible}
        />
      )}
    </>
  );
};

export default observer(StudentRow);
