import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import AvatarEditor from "react-avatar-editor";
import { observer } from "mobx-react";
import { Form, Formik } from "formik";

import { BASE_URL } from "../../../../utilities";
import {
  EditUserRequest,
  SaveNewPassword,
} from "../../../../models/request.models";
import { useDialog, useAuth } from "../../../../stores";
import { UserSchema, PasswordSchema } from "../UserSchema";
import Button from "../../../../common/Button";
import Card from "../../../../common/Card";
import defaultUser from "../../../../assets/images/default-user.png";
import editIcon from "../../../../assets/images/edit.svg";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";

const EditProfileForm = () => {
  const { showDialog } = useDialog();
  const history = useHistory();
  const editorRef: any = useRef(null);

  const {
    changeNewPassword,
    editPassword,
    editUser,
    getCurrentUser,
    getUser,
    handleInputEditPassword,
    handleInputEditUser,
    ID,
    resetEditPassword,
    selectedAcademicTermId,
    uploadHandler,
    userForEdit,
  } = useAuth();

  const [profileImage, setProfileImage] = useState<any>(
    `${BASE_URL}File/GetFile/${userForEdit.imageFileName}`
  );
  const [isProfile, setIsProfile] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<boolean>(false);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setProfileImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputEditPassword(e);
  };

  useEffect(() => {
    getUser(ID).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  }, [getUser, ID]);

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleInputEditUser(event);
  };

  const handleSaveProfilePic = () => {
    if (isProfile) {
      editorRef.current.getImage().toBlob(function (blob: any) {
        blob.name = profileImage.name;
        uploadHandler(null, blob).then((resp: any) => {
          if (resp.status === "success") {
            if (resp.status === "success") {
              handleSubmit();
            }
          }
        });
      }, "image/png");
    } else {
      handleSubmit();
    }
  };

  const handleChangePassword = () => {
    if (editPassword.password === editPassword.repeatPassword) {
      const request: SaveNewPassword = {
        ID: ID,
        OldPassword: editPassword.oldPassword,
        NewPassword: editPassword.password,
        ConfirmedPassword: editPassword.repeatPassword,
      };

      changeNewPassword(request).then((resp: any) => {
        if (resp.status === "success") {
          if (resp.status === "success" && !resp.message) {
            showDialog("Password changed succesfully", null, null, false);
            history.push(`/academicTerms/${selectedAcademicTermId}/courses/`);
          } else {
            showDialog(resp.message, null, null, false);
          }
        } else {
          showDialog(resp.message, null, null, false);
        }
      });
      console.log("password request: ", JSON.stringify(request));
    }
  };

  const handleSubmit = () => {
    const request: EditUserRequest = {
      UserID: ID,
      FirstName: userForEdit.firstName,
      LastName: userForEdit.lastName,
      MiddleName: userForEdit.middleName,
      Email: userForEdit.email,
      Telephone: userForEdit.telephone,
      UserRoleCode: userForEdit.userRoleCode,
      IsActive: userForEdit.isActive,
      ImageFileID: userForEdit.imageFileID,
      ImageFileName: userForEdit.ImageFileName,
    };

    editUser(request).then((resp: any) => {
      if (resp.status === "success") {
        if (resp.status === "success" && !resp.message) {
          showDialog("Profile saved succesfully", null, null, false);
          getCurrentUser();
          history.push(`/academicTerms/${selectedAcademicTermId}/courses/`);
        } else {
          showDialog(resp.message, null, null, false);
        }
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
    console.log("save request: ", JSON.stringify(request));
  };

  return (
    <Formik
      initialValues={!newPassword ? { ...userForEdit } : { ...editPassword }}
      enableReinitialize
      validationSchema={!newPassword ? UserSchema : PasswordSchema}
      onSubmit={(values) => {
        if (values) {
          if (newPassword) {
            handleChangePassword();
          } else {
            handleSaveProfilePic();
          }
        }
      }}
    >
      {({ errors, touched, values, handleChange, handleBlur }) => {
        return (
          <Form className={styles.formWrapper}>
            <Col className={styles.padding + " pt-4 pb-2 "}>
              <h2 className={styles.title}>Edit Profile</h2>
              <Card isProfileEdit={true}>
                <Col className="p-0">
                  <Row className={styles.avatarWrapper + " d-inline-flex "}>
                    <Col>
                      {isProfile ? (
                        <AvatarEditor
                          ref={editorRef}
                          image={profileImage}
                          width={120}
                          height={120}
                          border={1}
                          color={[255, 255, 255]} // RGBA
                          rotate={0}
                          borderRadius={100}
                        />
                      ) : (
                        <img
                          alt="Profile image"
                          className={styles.profilePhoto}
                          src={
                            userForEdit.imageFileID
                              ? `${BASE_URL}File/GetFile/${userForEdit.imageFileName}`
                              : defaultUser
                          }
                        />
                      )}
                    </Col>
                    <Col className=" mt-5">
                      <div className="form-group">
                        <div className=" custom-file">
                          <input
                            accept="image/*"
                            className=" custom-file-input"
                            id="profilePic"
                            name="profilePicture"
                            type="file"
                            onChange={(image) => {
                              uploadImage(image);
                              setIsProfile(true);
                            }}
                          />
                          <label
                            htmlFor="profilePic"
                            className={
                              styles.cursor +
                              " custom-file-background cursor-pointer justify-content-start"
                            }
                          >
                            <img src={editIcon} alt="Edit" />
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row className={styles.nameWrapper + " pt-3 d-inline-flex"}>
                    <Col md="auto" className="mt-3">
                      <TextInput
                        errorMessage={errors.lastName}
                        label="Family Name"
                        name="lastName"
                        onBlur={handleBlur}
                        touched={touched.lastName}
                        value={values.lastName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                    <Col md="auto" className="mt-3">
                      <TextInput
                        errorMessage={errors.firstName}
                        label="Given Name"
                        name="firstName"
                        onBlur={handleBlur}
                        touched={touched.firstName}
                        value={values.firstName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                    <Col md="auto" className="mt-3">
                      <TextInput
                        errorMessage={errors.name}
                        label="Middle Name"
                        name="middleName"
                        touched={touched.name}
                        value={values.middleName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row className={styles.contentWrapper}>
                    <Col>
                      <Row className=" pt-3">
                        <Col>
                          <TextInput
                            label="Email Address"
                            name="email"
                            disabled={true}
                            value={userForEdit.email}
                          />
                        </Col>
                      </Row>
                      <Row className=" pt-3">
                        <Col>
                          <TextInput
                            errorMessage={errors.telephone}
                            label="Mobile"
                            name="telephone"
                            onBlur={handleBlur}
                            touched={touched.telephone}
                            value={values.telephone}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => textIntputHandleChange(event, handleChange)}
                          />
                        </Col>
                      </Row>
                      <Row className={styles.newPasswordRow}>
                        <Col>
                          {newPassword ? (
                            <>
                              <Row className=" pt-3">
                                <Col>
                                  <TextInput
                                    errorMessage={errors.oldPassword}
                                    label="Old Password"
                                    name="oldPassword"
                                    onBlur={handleBlur}
                                    touched={touched.oldPassword}
                                    type="password"
                                    value={editPassword.oldPassword}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      upload(event);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className=" pt-3">
                                <Col>
                                  <TextInput
                                    errorMessage={errors.password}
                                    label="New Password"
                                    name="password"
                                    onBlur={handleBlur}
                                    touched={touched.password}
                                    type="password"
                                    value={editPassword.password}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      upload(event);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className=" pt-3">
                                <Col>
                                  <TextInput
                                    errorMessage={errors.repeatPassword}
                                    label="Repeat Password"
                                    name="repeatPassword"
                                    onBlur={handleBlur}
                                    touched={touched.repeatPassword}
                                    type="password"
                                    value={editPassword.repeatPassword}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      upload(event);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className=" d-md-flex justify-content-sm-end">
                                  <Row>
                                    <Col>
                                      <Button
                                        isDialogCancel={true}
                                        title="Cancel"
                                        type="button"
                                        onClick={() => {
                                          setNewPassword(false);
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        isDialog={true}
                                        title="Save"
                                        type="submit"
                                        onClick={() => {
                                          // handleChangePassword();
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row className={" pt-3 pr-4 justify-content-end"}>
                              <p
                                className={styles.cursor}
                                onClick={() => {
                                  resetEditPassword();
                                  setNewPassword(true);
                                }}
                              >
                                Change Password
                              </p>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br />
                  <Row className=" flex-reverse-row">
                    <Col className=" d-md-flex justify-content-sm-end pb-3">
                      <Row>
                        <Col>
                          <Button
                            isDialogCancel={true}
                            onClick={() => {
                              history.push(
                                `/academicTerms/${selectedAcademicTermId}/courses/`
                              );
                            }}
                            title="Cancel"
                            type="button"
                          />
                        </Col>
                        <Col>
                          <Button
                            isDialog={true}
                            title="Save"
                            type="submit"
                            onClick={() => setNewPassword(false)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Col>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(EditProfileForm);
