import React from "react";

import AddNewUser from "../../../Pages/AddNewUser";
import EditUser from "../../../Pages/EditUser";
import Users from "../../../Pages/Users";
import { useAuth } from "../../../stores";
import PrivateRoute from "../../PrivateRoute";

const UserRoutes = () => {
  const { role, isSuperAdmin } = useAuth();
  return (
    <>
      {" "}
      {(role === "Administrator" ||
        (isSuperAdmin === true && role === "Professor")) && (
        <PrivateRoute component={AddNewUser} path="/user/add" />
      )}
      {(role === "Administrator" ||
        (isSuperAdmin === true && role === "Professor")) && (
        <PrivateRoute component={EditUser} path="/user/edit/:userId" />
      )}
      {(role === "Administrator" ||
        (isSuperAdmin === true && role === "Professor")) && (
        <PrivateRoute component={Users} path="/users" exact />
      )}
    </>
  );
};

export default UserRoutes;
