import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { SectionForList } from "../../../../models/state.models";
import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import SectionRow from "../SectionRow";
import { useAuth, useDialog } from "../../../../stores";
import styles from "./styles.module.scss";

const SectionTable = ({ list }: any) => {
  const { role, selectedAcademicTermId } = useAuth();
  const { showDialog } = useDialog();
  const history: any = useHistory();

  useEffect(() => {
    if (role == "Student") {
      showDialog(
        "User not authorized",
        `/academicTerms/${selectedAcademicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
  });

  return (
    <table key="section-table" className={styles.table}>
      <thead>
        <tr>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("title")}
            >
              Section Title
              {list.orderBy === "title" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("id")}
            >
              ID
              {list.orderBy === "id" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>

          <td>
            <div
            // className={styles.headerCell}
            // onClick={() => list.changeOrderBy("Applicants")}
            >
              Students
              {/* {list.orderBy === "Applicants" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>

          <td>
            <div
            // className={styles.headerCell}
            // onClick={() => list.changeOrderBy("CourseUser")}
            >
              Teaching Assistant
              {/* {list.orderBy === "CourseUser" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((section: SectionForList) => (
            <SectionRow list={list} section={section} key={section.id} />
          ))}
      </tbody>
    </table>
  );
};

export default SectionTable;
