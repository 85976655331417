import { action, makeObservable, observable, runInAction } from "mobx";
import React from "react";
import { handleFileSubmit } from "../../utilities";

import {
  ActivateUserRequest,
  LoginRequest,
  SaveNewPassword,
  RegistrationRequest,
} from "../../models/request.models";
import {
  EditPassword,
  ResetPassword,
  UserActivation,
  UserForEdit,
  UserForList,
} from "../../models/state.models";
import { FetchApi } from "../../utilities";

class AuthStore {
  @observable isLogin: string = "undefined";

  @observable status: string = "inital";

  @observable message: string | null = null;

  @observable userName: string = "";

  @observable ID: number | null = null;

  @observable isAdminLoggedAsUser: boolean = false;

  @observable hasActiveSubscription: false = false;

  @observable role: string = "";

  @observable isGetUserCalled: boolean = false;

  @observable needConfirmation: boolean = false;

  @observable hasPassword = false;

  @observable selectedUserId: number = 0;

  @observable totalCountOfUsers: number = 0;

  @observable authStoreLoading: boolean = false;

  @observable selectedAcademicTermId: number = -1;
  @observable isSuperAdmin: boolean = false;

  @observable academicTermEndDate: string = "";

  @observable academicTermStartDate: string = "";

  @observable userImage: string = "";

  @observable roleCodes: string[] = [];

  @observable courseName: string = "";

  @observable sectionName: string = "";

  @observable resetPassword: ResetPassword = {
    password: "",
    repeatPassword: "",
  };

  @observable editPassword: EditPassword = {
    password: "",
    repeatPassword: "",
    oldPassword: "",
  };

  @observable userForActivation: UserActivation = {
    firstName: "",
    lastName: "",
    middleName: "",
    password: "",
    repeatPassword: "",
  };

  @observable selectedUser: UserForList = {
    firstName: "",
    lastName: "",
    id: 0,
    userRoleCode: "",
    telephone: "",
    email: "",
    isActive: true,
  };

  @observable usersList: UserForList[] = [];

  @observable user: UserForList = {
    email: "",
    firstName: "",
    id: 0,
    isActive: true,
    lastName: "",
    telephone: "",
    userRole: "",
    userRoleCode: "",
  };

  @observable userForEdit: UserForEdit = {
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    telephone: "",
    isActive: false,
    userRole: "",
    userRoleCode: "",
    imageFileID: 0,
    imageFileName: "",
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async logOut() {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/AccountApi/LogOut",
    });
    this.authStoreLoading = false;
    if (status === "success") {
      this.isLogin = "false";
      this.status = "";
      this.message = "";
      this.userName = "";
      this.ID = null;
      this.isAdminLoggedAsUser = false;
      this.hasActiveSubscription = false;
      this.role = "";
      this.resetMessage();
      return { status, message };
    }
  }

  @action.bound
  async login(params: LoginRequest) {
    this.authStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/AccountApi/Login",
      params,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
      if (this.status === "success") {
        const {
          roleCode,
          fullName,
          userID,
          hasActiveSubscription,
          isAdminLoggedAsUser,
          academicTermID,
          academicTermStartDate,
          academicTermEndDate,
          roleCodes,
          isSuperAdmin,
        } = data;
        this.selectedAcademicTermId = academicTermID;
        this.isLogin = "true";
        this.userName = fullName;
        this.ID = userID;
        this.roleCodes = roleCodes;
        this.academicTermStartDate = academicTermStartDate;
        this.academicTermEndDate = academicTermEndDate;
        this.hasActiveSubscription = hasActiveSubscription;
        this.isAdminLoggedAsUser = isAdminLoggedAsUser;
        this.isSuperAdmin = isSuperAdmin;
        switch (roleCode) {
          case "Admin":
            this.role = "Administrator";
            break;

          case "prof":
            this.role = "Professor";
            break;

          case "stud":
            this.role = "Student";
            break;

          case "tcAss":
            this.role = "Assistant";
            break;

          default:
            break;
        }
      }
    });
    return { status, message };
  }

  @action.bound
  async getCurrentUser() {
    this.authStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/AccountApi/GetCurrentUser",
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      if (this.status === "success") {
        const {
          roleCode,
          fullName,
          userID,
          hasActiveSubscription,
          isAdminLoggedAsUser,
          academicTermID,
          imageFileName,
          academicTermStartDate,
          academicTermEndDate,
          isSuperAdmin,
        } = data;
        this.selectedAcademicTermId = academicTermID;
        this.isLogin = "true";
        this.userName = fullName;
        this.ID = userID;
        this.academicTermStartDate = academicTermStartDate;
        this.academicTermEndDate = academicTermEndDate;
        this.hasActiveSubscription = hasActiveSubscription;
        this.isAdminLoggedAsUser = isAdminLoggedAsUser;
        this.userImage = imageFileName;
        this.isSuperAdmin = isSuperAdmin;
        switch (roleCode) {
          case "Admin":
            this.role = "Administrator";
            break;

          case "prof":
            this.role = "Professor";
            break;

          case "stud":
            this.role = "Student";
            break;

          case "tcAss":
            this.role = "Assistant";
            break;

          default:
            break;
        }
        this.isGetUserCalled = true;
      } else if (status === "error") {
        this.isGetUserCalled = true;
      }
    });
    return { status, message };
  }

  @action.bound
  setAcademicTermID(selectedAcademicTermId: number) {
    this.selectedAcademicTermId = selectedAcademicTermId;
  }

  @action.bound
  getAcademicTermID() {
    return this.selectedAcademicTermId;
  }

  @action.bound
  async registerUser(params: RegistrationRequest) {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/AccountApi/Register",
      params,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
      if (status === "success") {
        this.needConfirmation = true;
      }
    });

    return { status, message };
  }

  @action.bound
  async activateUser(params: ActivateUserRequest) {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/AccountApi/activateuser",
      params,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
    });
    // this.hasPassword = data.hasPassword ? data.hasPassword : true;

    return { status, message };
  }

  @action.bound
  async changePassword(
    password: string,
    changePasswordConfirmationLink: string
  ) {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/AccountApi/SetRecoveryPassword",
      params: {
        PasswordRecoveryLink: changePasswordConfirmationLink,
        Password: password,
        RetypePassword: password,
      },
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
    });
    // this.hasPassword = data.hasPassword ? data.hasPassword : true;

    return { status, message };
  }

  @action.bound
  async changeNewPassword(params: SaveNewPassword) {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/UserApi/ChangeUserPassword",
      params,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
    });
    // this.hasPassword = data.hasPassword ? data.hasPassword : true;

    return { status, message };
  }

  @action.bound
  async sendRecoveryEmail(email: string) {
    this.authStoreLoading = true;
    const { status, message } = await FetchApi({
      method: "POST",
      url: "/AccountApi/SendRecoveryEmail",
      params: {
        Email: email,
      },
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
    });
    // this.hasPassword = data.hasPassword ? data.hasPassword : true;

    return { status, message };
  }

  @action.bound
  async getAllUsers(page: number) {
    this.authStoreLoading = true;
    const { status, data, totalCount } = await FetchApi({
      method: "POST",
      url: "/UserApi/GetUsers",
      filterParams: {
        filterPArameters: [],
        page: page,
        pageSize: 10,
      },
    });
    runInAction(() => {
      this.authStoreLoading = false;
      if (status === "success") {
        this.usersList = data;
        this.totalCountOfUsers = totalCount;
      }
    });
  }

  @action.bound
  async getUser(id: number) {
    this.authStoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/UserApi/GetUser",
      params: id,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      if (status === "success") {
        this.user = data;
        this.userForEdit = data;
      }
    });

    return { status, message };
  }

  @action.bound
  async editUser(params: RegistrationRequest) {
    this.authStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/UserApi/EditUser",
      params: params,
    });
    runInAction(() => {
      this.authStoreLoading = false;
      this.status = status;
      this.message = message;
    });
    return { message, status };
  }

  @action.bound
  async uploadHandler(e: any, file: any) {
    const { data, status } = await handleFileSubmit(e, file, true);
    if (status === "success" && data) {
      this.userForEdit.imageFileID = data.id;
      this.userForEdit.imageFileName = data.fileName;
    }
    return { status };
  }

  @action.bound
  setSelectedId(id: number) {
    this.selectedUserId = id;
  }

  @action.bound
  setSectionName = (name: string) => {
    this.sectionName = name;
  };

  @action.bound
  setCourseName = (name: string) => {
    this.courseName = name;
  };

  @action.bound
  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.user[event.target.name] = event.target.value;
  }

  @action.bound
  handleSelectChange(event: any) {
    this.user[event.target.name] = event.target.value;
  }

  @action.bound
  handleInputChangeUserActivation(event: React.ChangeEvent<HTMLInputElement>) {
    this.userForActivation[event.target.name] = event.target.value;
  }

  @action.bound
  handleInputChangeChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
    this.resetPassword[event.target.name] = event.target.value;
  }

  @action.bound
  handleInputEditUser(event: React.ChangeEvent<HTMLInputElement>) {
    this.userForEdit[event.target.name] = event.target.value;
  }

  @action.bound
  handleInputEditPassword(event: React.ChangeEvent<HTMLInputElement>) {
    this.editPassword[event.target.name] = event.target.value;
  }

  @action.bound
  resetMessage() {
    this.message = null;
  }

  @action.bound
  changeSelectedAcademicTermId(id: any, startDate: string, endDate: string) {
    this.selectedAcademicTermId = id;
    if (startDate && endDate) {
      this.academicTermStartDate = startDate;
      this.academicTermEndDate = endDate;
    }
  }

  @action.bound
  resetRoleCodes() {
    this.roleCodes = [];
  }

  @action.bound
  setIsGetUserCalled(newValue: boolean) {
    this.isGetUserCalled = newValue;
  }

  @action.bound
  resetUser() {
    this.user.id = 0;
    this.user.firstName = "";
    this.user.lastName = "";
    this.user.email = "";
    this.user.telephone = "";
    this.user.isActive = true;
    this.user.userRole = "";
    this.user.userRoleCode = "";
  }

  @action.bound
  resetEditPassword() {
    this.editPassword = {
      password: "",
      repeatPassword: "",
      oldPassword: "",
    };
  }
}

export default AuthStore;
