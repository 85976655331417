import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import { CircularProgress } from "@material-ui/core";
import Icon from "../../../assets/images/back.svg";
import { Form, Formik, isEmptyArray, isEmptyChildren } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import cc from "classcat";
import Select from "react-select";

import { ActivitySchema } from "./Components/ActivitySchema";
import ButtonWithImage from "../../../common/ButtonWithImage";
import CustomModal from "../../../common/CustomModal";
import { BASE_URL } from "../../../utilities";

import {
  UseRemoveUtcOffsetWithDate,
  convertIntToDay,
  addOffset,
} from "../../../utils/timeUtils";

import { SaveActivityRequest } from "../../../models/request.models";
import {
  useDialog,
  useActivities,
  useAuth,
  useCourses,
  useSchedulerStore,
} from "../../../stores/";
import {
  Visibility,
  File,
  ListsForSchedulerSelect,
  SelectValue,
  ActivityScheduler,
} from "../../../models/state.models";
import AutocompleteSelect from "../../../common/AutocompleteSelect";
import Button from "../../../common/Button";
import plusIcon from "../../../assets/images/plus.svg";
import CustomCheckbox from "../../../common/CustomCheckbox";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import DatePicker from "../../../common/DatePicker";
import deleteIcon from "../../../assets/images/delete.svg";
import SelectWithLabel from "../../../common/SelectWithLabel";
import TextArea from "../../../common/TextArea";
import TextInput from "../../../common/TextInput";
import TimePicker from "../../../common/TimePicker";
import styles from "./styles.module.scss";
import StepProgressBar from "./Components/StepProgressBar";
import Validations from "../../ActivityValidations/Components/Validations";
import SectionNames from "../../ActivitySectionsValidation/Component/SectionNames";
import { EMPTY_ARRAY } from "mobx/dist/internal";

interface CUActivityProps {
  isEdit?: boolean;
}

const CUActivity: React.FC<CUActivityProps> = ({ isEdit }) => {
  const history = useHistory();
  const { activityId, courseId, academicTermId, sectionId }: any = useParams();
  const { showDialog } = useDialog();
  const { selectedAcademicTermId, role, setCourseName } = useAuth();

  const [documentName, setDocumentName] = useState<string>("");
  const [selecetedUsers, setSelecetedUsers] = useState<Visibility[]>([]);
  const [valFileName, setValFileName] = useState<string>("");
  const [activityTimeFrom, setActivityTimeFrom] = useState<
    MaterialUiPickersDate | string | null | Date
  >(moment("12:00 ", ["h:mm A"]));
  let timeout: any = null;
  const [activityTimeTo, setActivityTimeTo] = useState<
    MaterialUiPickersDate | string | null | Date
  >(moment("12:45 ", ["h:mm A"]));

  const [timeToErrorMessage, setTimeToErrorMessage] = useState<string>("");
  const [timeToTouched, setTimeToTouched] = useState<boolean>(false);

  const [activityErrorMessage, setActivityErrorMessage] = useState<string>("");
  const [sectionTouched, setSectionTouched] = useState<boolean>(false);
  const [filtredSectionList, setFiltredSectionList] = useState<SelectValue[]>(
    []
  );
  const [scheduleId, setScheduleId] = useState<number>(0);
  const [sectionDays, setSectionDays] = useState<number>();

  const [sectionTimeFrom, setSectionTimeFrom] = useState<
    MaterialUiPickersDate | string | null
  >(moment("12:00 ", ["h:mm A"]));
  const [sectionTimeTo, setSectionTimeTo] = useState<
    MaterialUiPickersDate | string | null | Date
  >(moment("12:45 ", ["h:mm A"]));
  const [selectedSection, setSelectedSection] = useState<number>(-1);
  const [selectedSections, setSelectedSections] = useState<number[]>();
  const [timeFromErrorMessage, setTimeFromErrorMessage] = useState<string>("");
  const [timeFromTouched, setTimeFromTouched] = useState<boolean>(false);
  const [ModalVisiblity, setModalVisiblity] = useState(false);
  const [editDay, setEditDay] = useState<number>(-1);
  const [scheduler, setScheduler] = useState<ActivityScheduler>();
  const [deletable, setDeletable] = useState<boolean>(true);
  const [addDaysOfTheWeek, setAddDaysOfTheWeek] = useState<boolean>(true);

  const [daysOfWeekError, setDaysOfWeekError] = useState<boolean>(false);
  const DaysOfWeek = [
    { value: "1", label: "Monday", id: "1" },
    { value: "2", label: "Tuesday", id: "2" },
    { value: "3", label: "Wednesday", id: "3" },
    { value: "4", label: "Thursday", id: "4" },
    { value: "5", label: "Friday", id: "5" },
    { value: "6", label: "Saturday", id: "6" },
    { value: "7", label: "Sunday", id: "7" },
  ];

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const { sectionList, getSectionList } = useSchedulerStore();
  const {
    activity,
    addActivityVisibility,
    attachFileHandler,
    changeSearching,
    foundText,
    getActivity,
    getText,
    handleActivityInputChange,
    handleBooleanChange,
    handleCheckboxActivity,
    handleDateChange,
    handleDefaultCourseTerm,
    listOfDocumentsForActivity,
    loading,
    removeActivityFile,
    removeActivityVisibility,
    resetActivity,
    saveActivity,
    searchActivityList,
    activitySections,
    searching,
    addSectionTime,
    activityValidation,
    getActivitySectionNames,
    removeSectionTime,
    selectedActivitySection,
    setSelectedActivitySection,
  } = useActivities();

  function setDaysOfWeek(data: any) {
    setSelectedOptions(data);
  }

  const { academicTerms, getAcademicTerms, getCourseDetails, course } =
    useCourses();

  useEffect(() => {
    setFiltredSectionList(
      sectionList.map((section: ListsForSchedulerSelect) => {
        return { id: section.codeInt, name: section.value };
      })
    );
    if (filtredSectionList.length > 0) {
      setSelectedSection(filtredSectionList[0].id);
    }
  }, [sectionList]);

  useEffect(() => {
    if (academicTerms.length === 0 && !isEdit) {
      getAcademicTerms();
      getCourseDetails(courseId);
    }
  }, []);

  useEffect(() => {
    if (role != "Professor") {
      showDialog(
        "User not authorized",
        `/academicTerms/${selectedAcademicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
  });

  useEffect(() => {
    getSectionList(courseId);
    if (isEdit) {
      getCourseDetails(courseId);
      getActivity(courseId, activityId).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Error"
            );
          }
          showDialog(resp.message, null, null, true, "Error");
        }
        if (resp.status === "success") {
          setCourseName(resp.breadCrumb.courseName);
        }
      });
      setActivityTimeFrom(null);
      setActivityTimeTo(null);
    } else {
      resetActivity();
    }
  }, [getActivity, activityId]);

  useEffect(() => {
    if (!isEdit) {
      handleDefaultCourseTerm(course);
    }
  }, [course]);
  useEffect(() => {
    getActivity(courseId, activityId).then((resp: any) => {});

    getActivitySectionNames(courseId, activityId).then((resp: any) => {});
  }, [getActivity, activityId, sectionId]);

  const handleType = (e: any, type: number) => {
    type === 1
      ? setValFileName(e.target.files[0].name)
      : setDocumentName(e.target.files[0].name);
  };

  const getSearchUser = (text: string) => {
    if (text !== "") {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        getText({ CourseID: courseId, SearchText: text });
        activity.lastText = activity.text;
      }, 2000);
    }
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleActivityInputChange(event);
  };

  useEffect(() => {
    getActivitySectionNames(courseId, activity.id ?? activityId);
  }, [activity, activity.id]);

  const handleSubmit = () => {
    const { time, endDate, startDate, timeStart } = UseRemoveUtcOffsetWithDate(
      activity.endDate,
      activityTimeTo === null ? activity.hourTo : activityTimeTo,
      activity.startDate,
      activityTimeFrom === null ? activity.hourFrom : activityTimeFrom
    );

    const formatedSchedulesList = activity.schedules.map(
      (schedule: ActivityScheduler) => {
        let addTimeOffset = addOffset(
          schedule.hourFrom,
          schedule.hourTo,
          schedule.dayOfWeek
        );
        const newValue: ActivityScheduler = {
          dayOfWeek: addTimeOffset.dayOfWeek,
          hourFrom: addTimeOffset.hourFrom,
          hourTo: addTimeOffset.hourTo,
          courseID: schedule.courseID,
          id: schedule.id,
        };
        return newValue;
      }
    );

    const request: SaveActivityRequest = {
      CourseId: courseId,
      Name: activity.name,
      Credit: parseInt(activity.credit),
      AccommodationRate: parseInt(activity.accommodationRate),
      IsIndividual: activity.isIndividual,
      IsInClass: activity.isInClass,
      IsPresence: activity.isPresence,
      IsAccommodated: activity.isAccommodated,
      Description: activity.description,
      ValidationType: parseInt(activity.validationType),
      ValidationText: activity.validationText,
      StartDate: startDate,
      EndDate: endDate,
      ActivityFiles: listOfDocumentsForActivity,
      ActivityVisibilities: activity.activityVisibilityList,
      ScheduleList: formatedSchedulesList,
    };
    if (!activity.isPresence) {
      request.HourTo = time;
      request.HourFrom = timeStart;
    }
    if (isEdit || activity.id) {
      request.id = activityId ?? activity.id;
    }

    saveActivity(request).then((resp: any) => {
      if (resp.status === "success") {
        if (isEdit) {
          if (resp.status === "success" && !resp.message) {
            /* showDialog(
              "Activity changed succesfully",
              null,
              null,
              false,
              "Notification"
            );*/
            // history.goBack();

            getActivity(courseId, activity.id);

            setSelectedActivitySection(0);
            updateStep(currentStep + 1);
          } else {
            showDialog(resp.message, null, null, false);
          }
        } else {
          if (resp.status === "success" && !resp.message) {
            isEdit = true;
            updateStep(currentStep + 1);
            setSelectedActivitySection(0);
            showDialog(
              "Activity saved succesfully, please continue with validation terms..",
              null,
              null,
              false
            );
            getActivity(courseId, resp.data.id);
            //history.goBack();
          } else {
            showDialog(resp.message, null, null, false);
          }
        }
        resetActivity();
      } else {
        showDialog(resp.message, null, null, false);
        if (resp.data != null) {
          history.push(
            `/academicTerms/${academicTermId}/courses/${courseId}/activities/edit/${resp.data.id}`
          );
        }
      }
    });
  };

  const editTime = (value: ActivityScheduler) => {
    setSelectedSection(value.courseID);
    setDaysOfWeek(
      DaysOfWeek.filter(function (item) {
        if (parseInt(item.value) === value.dayOfWeek) return item;
      })
    );

    setSectionTimeFrom(moment(value.hourFrom, ["h:mm A"]));
    setSectionTimeTo(moment(value.hourTo, ["h:mm A"]));
    //setSectionDays(value.dayOfWeek);
    setScheduler(value);
    setScheduleId(value.id);
  };

  const findSectionName = (id: number) => {
    const value = filtredSectionList.find(
      (section: SelectValue) => section.id === id
    );
    return value?.name;
  };

  const addSection = () => {};

  const addTime = () => {
    let validation = true;

    if (selectedOptions.length < 1 && activity.isPresence) {
      setDaysOfWeekError(true);
      validation = false;
    }

    if (sectionTimeFrom === null) {
      setTimeFromErrorMessage("Enter section start time");
      setTimeFromTouched(true);
      validation = false;
    }
    if (sectionTimeTo === null) {
      setTimeToErrorMessage("Enter section end time");
      setTimeToTouched(true);
      validation = false;
    }
    if (moment(sectionTimeTo).isBefore(moment(sectionTimeFrom))) {
      setTimeToErrorMessage("Hours from can't be bigger than hours to");
      setTimeToTouched(true);
      validation = false;
    }
    if (selectedSection === -1) {
      setActivityErrorMessage("Select section");
      setSectionTouched(true);
      validation = false;
    }
    if (validation) {
      setTimeFromErrorMessage("");
      setTimeToErrorMessage("");
      setActivityErrorMessage("");
      setSectionTouched(false);
      setTimeFromTouched(false);
      setTimeToTouched(false);
      setDaysOfWeekError(false);

      if (!activity.isPresence) {
        addSectionTime({
          dayOfWeek: -1,
          hourFrom: sectionTimeFrom,
          hourTo: sectionTimeTo,
          courseID: selectedSection,
          courseName: findSectionName(selectedSection),
          id: scheduleId !== 0 ? scheduleId : null,
          isDeletable: deletable,
        });
      } else {
        selectedOptions.map((item: any) => {
          addSectionTime({
            dayOfWeek: parseInt(item.value),
            hourFrom: sectionTimeFrom,
            hourTo: sectionTimeTo,
            courseID: selectedSection,
            courseName: findSectionName(selectedSection),
            id: scheduleId !== 0 ? scheduleId : null,
            isDeletable: deletable,
          });
        });
      }

      setSelectedSection(-1);
      setSectionTimeFrom(moment("12:00 ", ["h:mm A"]));
      setSectionTimeTo(moment("12:45 ", ["h:mm A"]));
      setSelectedOptions([]);
    }
  };

  const labelArray = ["Step 1", "Step 2", "Step 3"];
  const [currentStep, updateCurrentStep] = useState(1);

  function updateStep(step: any) {
    updateCurrentStep(step);
  }

  return (
    <div>
      <Formik
        initialValues={{ ...activity }}
        enableReinitialize
        validationSchema={ActivitySchema}
        onSubmit={(values) => {
          if (values) {
            //handleSubmit();
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          isValid,
          handleChange,
          setFieldValue,
          setTouched,
          validateForm,
          setFieldTouched,
          validateField,
          handleBlur,
        }) => {
          return (
            <Form>
              <Row className={styles.CUActivityWrapper + " mt-4 "}>
                <Col md={6}>
                  <Row>
                    <Col md={12} className="px-3">
                      <StepProgressBar
                        labelArray={labelArray}
                        currentStep={currentStep}
                        updateStep={updateStep}
                      ></StepProgressBar>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  {(() => {
                    switch (currentStep) {
                      case 1:
                        return (
                          <>
                            <Row>
                              <Col md={6}>
                                <TextInput
                                  errorMessage={errors.name}
                                  touched={touched.name}
                                  label="Activity Name"
                                  name="name"
                                  onBlur={handleBlur}
                                  value={values.name}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    textIntputHandleChange(event, handleChange)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={3}>
                                <TextInput
                                  errorMessage={errors.credit}
                                  touched={touched.credit}
                                  label="Credits"
                                  onBlur={handleBlur}
                                  name="credit"
                                  value={values.credit}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    textIntputHandleChange(event, handleChange)
                                  }
                                />
                              </Col>
                              <Col
                                md={3}
                                className={
                                  styles.customCheckBoxWrapper + " mt-4"
                                }
                              >
                                <CustomCheckbox
                                  label="Accommodation rate applicable"
                                  name="isAccommodated"
                                  checked={values.isAccommodated}
                                  onChange={(event: any) => {
                                    handleChange(event);
                                    handleCheckboxActivity(event);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={6}>
                                <TextArea
                                  label="Description"
                                  name="description"
                                  value={values.description}
                                  onChange={handleActivityInputChange}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={6}>
                                <div className="form-group">
                                  <p className={styles.accName + " pb-2"}>
                                    Accompanying documents
                                  </p>
                                  <div className=" custom-file">
                                    <input
                                      type="file"
                                      className=" custom-file-input"
                                      id="activityFiles2"
                                      name="activityFiles"
                                      accept=".pdf, .doc, docx, image/*"
                                      onChange={(e) => {
                                        handleType(e, 2);
                                        attachFileHandler(e, 2);
                                      }}
                                    />
                                    <label
                                      htmlFor="activityFiles2"
                                      className={
                                        styles.rounded4 +
                                        " custom-file-label cursor-pointer"
                                      }
                                    >
                                      <span>{documentName}</span>
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="px-4">
                              {" "}
                              <Col md={6}>
                                <div
                                  className={
                                    styles.listLimitWrapper +
                                    " col-12 pt-0 pb-3 px-0"
                                  }
                                >
                                  {loading && (
                                    <div
                                      style={{
                                        width: "100%",
                                        minHeight: "300px",
                                        position: "absolute",
                                      }}
                                    >
                                      <CircularProgress
                                        className={styles.progressBar}
                                      />
                                    </div>
                                  )}
                                  {listOfDocumentsForActivity.map(
                                    (file: File, index: number) =>
                                      file.type === 2 && (
                                        <div
                                          className={
                                            styles.courseActivityLimitWrapper
                                          }
                                          key={index}
                                        >
                                          <p
                                            className={cc([
                                              {
                                                [styles.noCursor]:
                                                  file.fileName === undefined,
                                              },
                                              {
                                                [styles.activityLimit]:
                                                  file.fileName,
                                              },
                                            ])}
                                            onClick={() =>
                                              file.fileName &&
                                              window.open(
                                                `${BASE_URL}File/GetFile/${file.fileName}`,
                                                "_blank"
                                              )
                                            }
                                          >
                                            {file.name
                                              ? file.name
                                              : documentName}
                                          </p>
                                          <img
                                            src={deleteIcon}
                                            alt="Delete"
                                            className={styles.deleteIcon}
                                            onClick={() => {
                                              removeActivityFile(file.fileID);
                                            }}
                                          />
                                        </div>
                                      )
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        );
                      case 2:
                        return (
                          <>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col
                                    md={6}
                                    className={
                                      styles.datePickerHolder +
                                      " " +
                                      styles.singleTimePckCol
                                    }
                                  >
                                    <DatePicker
                                      onBlur={handleBlur}
                                      errorMessage={errors.startDate}
                                      touched={touched.startDate}
                                      value={
                                        moment(
                                          values.startDate,
                                          "MM/DD/YYYY"
                                        ) || null
                                      }
                                      startDate={course.startDate}
                                      endDate={course.endDate}
                                      labelPar="From"
                                      placeholder="mm/dd/yyyy"
                                      onChange={(date) => {
                                        handleDateChange(date, "startDate");
                                        setFieldValue("startDate", date);
                                      }}
                                    />
                                  </Col>

                                  <Col
                                    md={6}
                                    className={
                                      styles.datePickerHolder +
                                      " " +
                                      styles.singleTimePckCol
                                    }
                                  >
                                    <DatePicker
                                      onBlur={handleBlur}
                                      errorMessage={errors.endDate}
                                      touched={touched.endDate}
                                      value={
                                        moment(values.endDate, "MM/DD/YYYY") ||
                                        null
                                      }
                                      startDate={course.startDate}
                                      endDate={course.endDate}
                                      labelPar="To"
                                      placeholder="mm/dd/yyyy"
                                      onChange={(date) => {
                                        handleDateChange(date, "endDate");
                                        setFieldValue("endDate", date);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {!values.isPresence && (
                                  <Row className="mt-3">
                                    <Col
                                      md={6}
                                      className={
                                        styles.datePickerHolder +
                                        " " +
                                        styles.singleTimePckCol
                                      }
                                    >
                                      <TimePicker
                                        topLabel="Start time"
                                        errorMessage={timeFromErrorMessage}
                                        touched={timeFromTouched}
                                        name="activityTimeFrom"
                                        onChange={(time: any) => {
                                          setActivityTimeFrom(time);
                                        }}
                                        value={
                                          activityTimeFrom === null
                                            ? activity.hourFrom
                                            : activityTimeFrom
                                        }
                                      />
                                    </Col>

                                    <Col
                                      md={6}
                                      className={
                                        styles.datePickerHolder +
                                        " " +
                                        styles.singleTimePckCol
                                      }
                                    >
                                      <TimePicker
                                        topLabel="End time"
                                        errorMessage={timeToErrorMessage}
                                        touched={timeToTouched}
                                        name="activityTimeTo"
                                        onChange={(time: any) => {
                                          setActivityTimeTo(time);
                                        }}
                                        value={
                                          activityTimeTo === null
                                            ? activity.hourTo
                                            : activityTimeTo
                                        }
                                      />
                                    </Col>
                                  </Row>
                                )}
                                <Row className={" mt-4"}>
                                  <Col md={12}>
                                    <SelectWithLabel
                                      errorMessage={activityErrorMessage}
                                      touched={sectionTouched}
                                      label="Section"
                                      name="sectionId"
                                      placeholder="Select section "
                                      value={selectedSection || ""}
                                      onChange={(event: any) => {
                                        setSelectedSection(event.target.value);
                                      }}
                                      values={filtredSectionList}
                                    />
                                  </Col>
                                </Row>
                                {!activity.isPresence && (
                                  <>
                                    <Row className="justify-content-end">
                                      <Col className="mt-3" md="auto">
                                        <ButtonWithImage
                                          icon={plusIcon}
                                          isSmall={true}
                                          title={"add section"}
                                          type="button"
                                          onClick={() => {
                                            addTime();
                                          }}
                                        />
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col
                                        className={
                                          styles.sectionListItemWrapper +
                                          " mt-3 px-0 mx-3 px-0"
                                        }
                                      >
                                        {values.schedules &&
                                          values.schedules.map(
                                            (
                                              value: ActivityScheduler,
                                              index: number
                                            ) => {
                                              findSectionName(value.courseID);
                                              return (
                                                <div
                                                  className={
                                                    editDay === value.id
                                                      ? styles.courseDayEdit
                                                      : styles.sectionDaySelectedWrapper
                                                  }
                                                  key={index}
                                                >
                                                  <p
                                                    className={styles.itemTime}
                                                    onClick={() => {
                                                      setDeletable(
                                                        value.isDeletable ===
                                                          undefined
                                                          ? true
                                                          : value.isDeletable
                                                      );
                                                      editTime(value);
                                                      setEditDay(value.id);
                                                    }}
                                                  >
                                                    {value.courseName}
                                                  </p>
                                                  <img
                                                    alt="Delete"
                                                    className={
                                                      styles.deleteSchedulerIcon
                                                    }
                                                    src={deleteIcon}
                                                    onClick={() => {
                                                      setScheduler(value);
                                                      setDeletable(
                                                        value.isDeletable ===
                                                          undefined
                                                          ? true
                                                          : value.isDeletable
                                                      );
                                                      setModalVisiblity(true);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                <Row>
                                  <Col
                                    md={12}
                                    className={
                                      styles.customCheckBoxWrapper + " mt-4"
                                    }
                                  >
                                    <CustomCheckbox
                                      label="Recurring"
                                      name="isPresence"
                                      checked={values.isPresence}
                                      onChange={(event: any) => {
                                        activity.schedules = [];
                                        handleChange(event);
                                        handleCheckboxActivity(event);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    md={12}
                                    className={
                                      styles.customCheckBoxWrapper +
                                      " mt-4 mb-4"
                                    }
                                  >
                                    <CustomCheckbox
                                      label="Track Student"
                                      name="isPresence"
                                      checked={values.isPresence}
                                      onChange={(event: any) => {
                                        handleChange(event);
                                        handleCheckboxActivity(event);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {values.isPresence && (
                                  <>
                                    <Row>
                                      <Col
                                        md={6}
                                        className={
                                          "col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7" +
                                          styles.dropdownContainer
                                        }
                                      >
                                        <label
                                          className={styles.label + "mt-4"}
                                        >
                                          Days
                                        </label>
                                        <div>
                                          <Select
                                            className={styles.multiSelectInput}
                                            classNamePrefix="react-select"
                                            options={DaysOfWeek}
                                            placeholder="Select days.."
                                            closeMenuOnSelect={false}
                                            value={selectedOptions}
                                            onChange={setDaysOfWeek}
                                            isSearchable={false}
                                            menuPosition="fixed"
                                            isMulti
                                          />
                                        </div>
                                        {daysOfWeekError && (
                                          <p className={styles.dayErrorText}>
                                            Select minimum one day{" "}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6} className={styles.singleCol}>
                                        <TimePicker
                                          topLabel="From"
                                          errorMessage={timeFromErrorMessage}
                                          touched={timeFromTouched}
                                          name="sectionTimeFrom"
                                          onChange={(date: any) => {
                                            setSectionTimeFrom(date);
                                          }}
                                          value={sectionTimeFrom}
                                        />
                                      </Col>
                                      <Col md={6} className={styles.singleCol}>
                                        <TimePicker
                                          topLabel="To"
                                          errorMessage={timeToErrorMessage}
                                          touched={timeToTouched}
                                          name="sectionTimeTo"
                                          onChange={(date: any) => {
                                            setSectionTimeTo(date);
                                          }}
                                          value={sectionTimeTo}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="justify-content-end">
                                      <Col className="mt-3" md="auto">
                                        <ButtonWithImage
                                          icon={plusIcon}
                                          isSmall={true}
                                          title={
                                            editDay != -1
                                              ? "update time"
                                              : "add time"
                                          }
                                          type="button"
                                          onClick={() => {
                                            editDay != -1
                                              ? removeSectionTime(scheduler)
                                              : setEditDay(-1);
                                            addTime();
                                            setEditDay(-1);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        className={
                                          styles.sectionListItemWrapper +
                                          " mt-3 px-0 mx-3 px-0"
                                        }
                                      >
                                        {values.schedules &&
                                          values.schedules.map(
                                            (
                                              value: ActivityScheduler,
                                              index: number
                                            ) => {
                                              findSectionName(value.courseID);
                                              return (
                                                <div
                                                  className={
                                                    editDay === value.id
                                                      ? styles.courseDayEdit
                                                      : styles.sectionDaySelectedWrapper
                                                  }
                                                  key={index}
                                                >
                                                  <p
                                                    className={styles.itemTime}
                                                    onClick={() => {
                                                      setDeletable(
                                                        value.isDeletable ===
                                                          undefined
                                                          ? true
                                                          : value.isDeletable
                                                      );
                                                      editTime(value);
                                                      setEditDay(value.id);
                                                    }}
                                                  >
                                                    {value.courseName +
                                                      " - " +
                                                      convertIntToDay(
                                                        value.dayOfWeek
                                                      ) +
                                                      " - " +
                                                      moment(value.hourFrom, [
                                                        "h:mm A",
                                                      ]).format("h:mm A") +
                                                      "-" +
                                                      moment(value.hourTo, [
                                                        "h:mm A",
                                                      ]).format("h:mm A")}
                                                  </p>
                                                  <img
                                                    alt="Delete"
                                                    className={
                                                      styles.deleteSchedulerIcon
                                                    }
                                                    src={deleteIcon}
                                                    onClick={() => {
                                                      setScheduler(value);
                                                      setDeletable(
                                                        value.isDeletable ===
                                                          undefined
                                                          ? true
                                                          : value.isDeletable
                                                      );
                                                      setModalVisiblity(true);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <AutocompleteSelect
                                      multiple
                                      isSearching={searching}
                                      errorMessage={
                                        errors.activityVisibilityList
                                      }
                                      touched={touched.activityVisibilityList}
                                      name="activityVisibilityList"
                                      getOptionLabel={(option) =>
                                        option.studentName ||
                                        "" ||
                                        option.groupName
                                      }
                                      options={foundText}
                                      label="Limit activity view"
                                      value={selecetedUsers}
                                      variant="outlined"
                                      onInputChange={(event: any) => {
                                        changeSearching(true);
                                        getSearchUser(
                                          event &&
                                            event.target &&
                                            event.target.value
                                        );
                                      }}
                                      onChange={(_, option) => {
                                        setSelecetedUsers(option);
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    md="auto"
                                    className="align-self-end mt-5 mt-md-0"
                                  >
                                    <Button
                                      isExtraSmall={true}
                                      title="add"
                                      type="button"
                                      onClick={() => {
                                        selecetedUsers.map(
                                          (user: Visibility, index: number) => {
                                            user.courseUserID !== undefined
                                              ? addActivityVisibility({
                                                  courseUserID:
                                                    user.courseUserID,
                                                  studentName: user.studentName,
                                                })
                                              : addActivityVisibility({
                                                  courseGroupID:
                                                    user.courseGroupID,
                                                  groupName: user.groupName,
                                                });
                                          }
                                        );
                                        setSelecetedUsers([]);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row className="px-4">
                                  <div
                                    className={
                                      styles.listLimitWrapper +
                                      " col-12 mt-3 pt-0 pb-3 "
                                    }
                                  >
                                    {values.activityVisibilityList &&
                                      values.activityVisibilityList.map(
                                        (user: Visibility, index: number) => (
                                          <div
                                            className={
                                              styles.courseActivityLimitWrapper
                                            }
                                            key={index}
                                          >
                                            <p className={styles.activityLimit}>
                                              {user.courseUserID !== undefined
                                                ? user.studentName
                                                : user.groupName}
                                            </p>
                                            <img
                                              src={deleteIcon}
                                              alt="Delete"
                                              className={styles.deleteIcon}
                                              onClick={() => {
                                                user.courseUserID !== undefined
                                                  ? removeActivityVisibility({
                                                      courseUserID:
                                                        user.courseUserID,
                                                    })
                                                  : removeActivityVisibility({
                                                      courseGroupID:
                                                        user.courseGroupID,
                                                    });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                  </div>
                                </Row>
                                <Row className="mt-4">
                                  <Col md={6}>
                                    <SelectWithLabel
                                      label="Type"
                                      name="isIndividual"
                                      value={activity.isIndividual ? "1" : "2"}
                                      onChange={handleBooleanChange}
                                      values={[
                                        { name: "Individual", id: "1" },
                                        { name: "Group", id: "2" },
                                      ]}
                                    />
                                  </Col>
                                  <Col md={6} className="mt-4">
                                    <SelectWithLabel
                                      name="isInClass"
                                      value={activity.isInClass ? "1" : "2"}
                                      onChange={handleBooleanChange}
                                      values={[
                                        { name: "in class", id: "1" },
                                        { name: "out of class", id: "2" },
                                      ]}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        );
                      case 3:
                        return (
                          <Col md={12}>
                            <Row className="justify-content-end pt-2">
                              <Col md="auto">
                                <ButtonWithImage
                                  title="Back"
                                  onClick={() => {
                                    history.push(
                                      `/academicTerms/${academicTermId}/courses/${courseId}/activities`
                                    );
                                  }}
                                  icon={Icon}
                                />
                              </Col>
                            </Row>
                            <Row className=" p-0 m-0">
                              {activitySections.length === 0 ? (
                                <Col>
                                  <h1 className="mt-3">
                                    Currently there are no sections for this
                                    activity.
                                  </h1>
                                </Col>
                              ) : (
                                <Col md={12}>
                                  <Row>
                                    <Col
                                      md="auto"
                                      className={styles.wrapper + " pt-2 pl-0"}
                                    >
                                      <Col className={styles.wrap} md={12}>
                                        <SectionNames
                                          list={activitySections}
                                          isEdit={isEdit}
                                        />
                                      </Col>
                                    </Col>
                                    <Col>
                                      {selectedActivitySection !== 0 && (
                                        <Validations
                                          list={activityValidation}
                                          isFromUrl={false}
                                          activityId={searchActivityList.id}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        );
                      default:
                        return null;
                    }
                  })()}
                </Col>
              </Row>
              <Row className=" mx-0 justify-content-end ">
                {currentStep === 1 ? (
                  <Col md="auto" className="px-2">
                    <Button
                      isDialogCancel={true}
                      onClick={() => {
                        resetActivity();
                        history.goBack();
                      }}
                      title="Cancel"
                      type="button"
                    />
                  </Col>
                ) : (
                  <Col md="auto" className="px-2">
                    <Button
                      isDialogCancel={true}
                      onClick={() => {
                        updateStep(currentStep - 1);
                      }}
                      title="Previous"
                      type="button"
                    />
                  </Col>
                )}
                {currentStep !== 3 ? (
                  <Col md="auto" className="px-2">
                    <Button
                      isDialog={true}
                      title={currentStep === 2 ? "Save & Next" : "Next"}
                      onClick={() => {
                        setFieldTouched("name", true);
                        setFieldTouched("credit", true);
                        if (currentStep !== 1) {
                          handleSubmit();
                        } else {
                          validateForm().then((err: any) => {
                            if (
                              err.name === undefined &&
                              err.credit === undefined
                            ) {
                              updateStep(currentStep + 1);
                            }
                          });
                        }
                      }}
                      type="button"
                    />
                  </Col>
                ) : (
                  <Col md="auto" className="px-2">
                    <Button
                      isDialog={true}
                      title="Confirm"
                      //There is part for saving the validation terms
                      type="button"
                      onClick={() => {
                        history.push(
                          `/academicTerms/${academicTermId}/courses/${courseId}/activities/${
                            activity.Id ?? searchActivityList.id
                          }/details`
                        );
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
      <CustomModal
        open={ModalVisiblity}
        handleClose={() => setModalVisiblity(false)}
      >
        <ConfirmDeleteModal
          isDeletable={deletable}
          handleCancel={() => setModalVisiblity(false)}
          confirmation={() => {
            setModalVisiblity(false);
            removeSectionTime(scheduler);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default observer(CUActivity);
