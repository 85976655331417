import React from "react";
import { observer } from "mobx-react";

import { useAuth, useActivities } from "../../stores";
import ActivityNavigation from "../Components/ActivityNavigation";
import ActivityOverview from "./Components/ActivityOverview";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const ActivityDetails = () => {
  const { role, courseName } = useAuth();
  const { activity } = useActivities();

  return (
    <DashboardBase
      componentName={courseName}
      isActivity={true}
      location={activity.name}
    >
      {role !== "Student" && (
        <ActivityNavigation isActivityDetails={true}>
          <ActivityOverview />
        </ActivityNavigation>
      )}
      {role === "Student" && <ActivityOverview />}
    </DashboardBase>
  );
};

export default observer(ActivityDetails);
