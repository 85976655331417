import React from "react";

import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import { ActivityForList } from "../../../../models/state.models";
import ActivityTableRow from "../ActivityTableRow";
import { useAuth, useCourses } from "../../../../stores";
import styles from "./styles.module.scss";

const ActivityTable = ({ list }: any) => {
  const { role } = useAuth();
  const { course } = useCourses();

  return (
    <table key="activity-table" className={styles.table}>
      <thead>
        <tr>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("name")}
            >
              Activity Name
              {list.orderBy === "name" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>

          {role !== "Student" && (
            <td>
              <div
                className={styles.headerCell}
                onClick={() => list.changeOrderBy("id")}
              >
                ID
                {list.orderBy === "id" && (
                  <img
                    src={
                      list.orderByDirection === "desc"
                        ? iconArrowDown
                        : iconArrowUp
                    }
                    alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                    className={styles.arrow}
                  />
                )}
              </div>
            </td>
          )}

          <td>Type</td>
          <td className="text-center">
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("credit")}
            >
              {course.currencyName ? course.currencyName : "Tokens"}
              {list.orderBy === "credit" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          {role !== "Student" && (
            <>
              <td
                className="text-center"
                // onClick={() => list.changeOrderBy("credit")}
              >
                Pending
                {/* {list.orderBy === "credit" &&
            (list.orderByDirection === "desc" ? "↓" : "↑")} */}
              </td>
              <td
                className="text-center"
                // onClick={() => list.changeOrderBy("credit")}
              >
                Rejections
                {/* {list.orderBy === "credit" &&
          (list.orderByDirection === "desc" ? "↓" : "↑")} */}
              </td>
            </>
          )}
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("startDate")}
            >
              Duration
              {list.orderBy === "startDate" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((activity: ActivityForList) => (
            <ActivityTableRow
              list={list}
              activity={activity}
              key={activity.id}
            />
          ))}
      </tbody>
    </table>
  );
};

export default ActivityTable;
