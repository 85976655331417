import React from "react";
import cc from "classcat";

import styles from "./styles.module.scss";

// type ButtonProps = {
//   title: string;
//   green?: boolean;
// } & React.HTMLProps<HTMLButtonElement>;

const Button: React.FC<any> = ({
  isDialog,
  isDialogCancel,
  isExtraSmall,
  isGreen,
  isSmall,
  isDeny,
  isEditButton,
  isHold,
  isSmallCancel,
  isPurchase,
  title,
  isSelect,
  isAdd,
  isDialogDeny,
  ...props
}) => {
  return (
    <div
      className={cc([
        styles.buttonWrapper,
        { [styles.dialogButtonWrapper]: isDialog || isDialogCancel },
        { [styles.smallButtonWrapper]: isSmall },
        { [styles.extraSmallButtonWrapper]: isExtraSmall },
        { [styles.smallCancelWrapper]: isSmallCancel },
        { [styles.denyWrapper]: isDeny },
        { [styles.selectWrapper]: isSelect },
        { [styles.extraSmallButtonWrapper]: isEditButton },
        { [styles.holdWrapper]: isHold || isAdd },
      ])}
    >
      <button
        className={cc([
          styles.btnStyle,
          { [styles.dialog]: isDialog || isDialogCancel },
          { [styles.greenBg]: isGreen },
          { [styles.dialogCancelButton]: isDialogCancel || isSmallCancel },
          { [styles.smallWithoutPadding]: isSmall },
          { [styles.deny]: isDeny },
          { [styles.dialog]: isSelect },
          { [styles.hold]: isHold || isAdd },
          { [styles.extraSmallButton]: isExtraSmall },
          { [styles.smallEditButton]: isEditButton },
          { [styles.denyBtn]: isDialogDeny },
          { [styles.purchase]: isPurchase },
        ])}
        {...props}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
