import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";

import Button from "../../../../common/Button";
import TextInput from "../../../../common/TextInput";
import { EMAIL } from "../../../../constants/RegularExpressions";
import { useAuth, useDialog } from "../../../../stores";
import BaseLogin from "../../../Components/BaseLogin";
import styles from "./styles.module.scss";

const ForgetPasswordForm = () => {
  const history = useHistory();
  const { sendRecoveryEmail } = useAuth();
  const { showDialog } = useDialog();

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);

  const validateCredentials = () => {
    if (!EMAIL.test(email)) {
      setEmailErrorMessage("Enter valid email addres ");
      setEmailTouched(true);

      return false;
    }
    setEmailErrorMessage("");
    setEmailTouched(false);
    return true;
  };

  const handleSubmit = () => {
    if (validateCredentials()) {
      sendRecoveryEmail(email).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog(
            "Email has been sent to your mailbox",
            `/`,
            history,
            false
          );
        } else {
          showDialog(resp.message, null, null, true, "Error");
        }
      });
    }
  };

  return (
    <BaseLogin>
      <h1 className={styles.header}>Forgot password?</h1>
      <h1 className={styles.header}>
        Please enter you email and send recovery email
      </h1>
      <TextInput
        isLogin={true}
        errorMessage={emailErrorMessage}
        touched={emailTouched}
        name="email"
        placeholder="Email"
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(event.target.value)
        }
      />
      <Row className="mt-3">
        <Col md={6}>
          <Button
            isDialogCancel={true}
            title="Cancel"
            type="submit"
            onClick={() => history.goBack()}
          />
        </Col>
        <Col md={6}>
          <Button
            isDialog={true}
            title="Send"
            type="submit"
            onClick={() => handleSubmit()}
          />
        </Col>
      </Row>
    </BaseLogin>
  );
};

export default ForgetPasswordForm;
