import React from "react";
import CourseNavigation from "../Components/CourseNavigation";

import { useCourses } from "../../stores";
import CourseOverview from "./Components/CourseOverview";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const CourseDetails = () => {
  const { course } = useCourses();

  return (
    <DashboardBase componentName={course.title}>
      <CourseNavigation isCourseDetails={true}>
        <CourseOverview />
      </CourseNavigation>
    </DashboardBase>
  );
};

export default CourseDetails;
