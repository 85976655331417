import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import GradebookActivity from "../GradeBook/Components/GradebookActivity";

const GradebookAddPoints = () => {
  const { courseName } = useAuth();

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isGradeBook={true} />
      <Card isFullScreen={true} isWithOutTopMargin={true} isCoursesTabs={true}>
        <GradebookActivity />
      </Card>
    </DashboardBase>
  );
};

export default observer(GradebookAddPoints);
