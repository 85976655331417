import React from "react";

import styles from "./styles.module.scss";

type TextAreaProps = {
  label?: string;
} & React.HTMLProps<HTMLTextAreaElement>;

const TextArea: React.FC<TextAreaProps> = ({ label, ...props }) => {
  return (
    <>
      <label className={styles.label}>
        {label}
        <textarea className={styles.input} {...props} />
      </label>
    </>
  );
};

export default TextArea;
