import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import Button from "../../../../../common/Button";
import styles from "./styles.module.scss";

interface ConfirmDeleteModalProps {
  handleCancel: () => void;
  confirmation: () => void;
  isDeletable: boolean;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  confirmation,
  handleCancel,
  isDeletable,
}) => {
  return (
    <div className={styles.modalWrapper}>
      <Row className="m-3">
        <Col className="text-center">
          {isDeletable ? (
            <p>Do you want to delete this schedule?</p>
          ) : (
            <p>Cant delete schedule with existing submissions.</p>
          )}
        </Col>
      </Row>
      <Row className="justify-content-end + m-3">
        <Col>
          <Button
            isDialogCancel={true}
            onClick={handleCancel}
            title="Cancel"
            type="button"
          />
        </Col>
        {isDeletable && (
          <Col>
            <Button
              isDialog={true}
              title="Delete"
              type="submit"
              onClick={confirmation}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ConfirmDeleteModal;
