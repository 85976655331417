import React, { FormEvent, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";

import BaseLogin from "../../../Components/BaseLogin";
import Button from "../../../../common/Button";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import Icon from "../../../../assets/images/back.svg";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import TextInput from "../../../../common/TextInput";
import { EMAIL } from "../../../../constants/RegularExpressions";
import { useAuth, useCourses, useDialog } from "../../../../stores";
import styles from "./styles.module.scss";
import SelectWithoutLabel from "../../../../common/SelectWithOutLabel";
import { AcademicTerm } from "../../../../models/state.models";

const LoginForm = () => {
  const [isLoginApiCalled, setIsLoginApiCalled] = useState(false);
  const [isLoginApiSecondTimeCalled, setIsLoginApiSecondTimeCalled] =
    useState(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [passWordTouched, setPassWordTouched] = useState<boolean>(false);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [academicTermId, setAcademicTermId] = useState<number>(0);

  const [hasRoleCodes, setHasRoleCodes] = useState(false);
  const [roleCode, setRoleCode] = useState("");
  const [roleCodesForSelect, setRoleCodesForSelect] = useState([]);

  const {
    login,
    resetRoleCodes,
    selectedAcademicTermId,
    setAcademicTermID,
    roleCodes,
    getAcademicTermID,
  } = useAuth();

  const { academicTerms, getAcademicTerms } = useCourses();

  useEffect(() => {
    getAcademicTerms();
  }, []);

  useEffect(() => {
    getAcademicTermID();
  }, [selectedAcademicTermId]);
  const history = useHistory();
  const { showDialog } = useDialog();

  const validateCredentials = (params: any) => {
    let isValid: boolean = true;
    if (!EMAIL.test(params.UserName)) {
      setEmailErrorMessage("Enter valid email addres ");
      setEmailTouched(true);
      isValid = false;
    }
    if (params.Password.length < 3) {
      setPasswordErrorMessage("Enter password");
      setPassWordTouched(true);
      isValid = false;
    }
    return isValid;
  };

  const myLogin = (event: FormEvent<HTMLFormElement>, params: any) => {
    event.preventDefault();
    if (validateCredentials(params)) {
      setIsLoginApiCalled(false);
      resetRoleCodes();
      setEmailErrorMessage("");
      setEmailTouched(false);
      setPasswordErrorMessage("");
      setPassWordTouched(false);
      getAcademicTermID();
      login(params).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          if (roleCode !== "") {
            setIsLoginApiSecondTimeCalled(true);
          }
          setIsLoginApiCalled(true);
        } else {
          showDialog(resp.message, null, null);
        }
      });
    }
  };

  useEffect(() => {
    if (selectedAcademicTermId !== -1 && isLoginApiCalled) {
      if (roleCodes.length < 2) {
        history.push(`/academicTerms/${academicTermId}/courses`);
      } else {
        setRoleCodesForSelect(
          roleCodes.map((role: string) => {
            switch (role) {
              case "tcAss":
                return { name: "Teaching Assistant", id: role };
              case "prof":
                return { name: "Professor", id: role };
              case "stud":
                return { name: "Student", id: role };
            }
          })
        );
        setHasRoleCodes(true);
      }
    }
    if (selectedAcademicTermId !== -1 && isLoginApiSecondTimeCalled) {
      history.push(`/academicTerms/${academicTermId}/courses`);
    }
  }, [isLoginApiCalled]);

  const filterAcademicTerm = (e: any) => {
    const temp = academicTerms.find((aTerm: AcademicTerm) => {
      return aTerm.id === e.target.value;
    });
    return temp;
  };
  const handleOnSelectChange = (e: any) => {
    setAcademicTermID(e.target.value);
    setAcademicTermId(e.target.value);
  };
  return (
    <BaseLogin>
      <h1 className={styles.header}>Sign in to Vineyard</h1>
      <form
        action="#"
        onSubmit={(event) => {
          if (hasRoleCodes) {
            myLogin(event, {
              UserName: email,
              Password: password,
              selectedRole: roleCode,
            });
          } else {
            myLogin(event, { UserName: email, Password: password });
          }
        }}
      >
        <p>
          {hasRoleCodes === true && (
            <ButtonWithImage
              onClick={() => {
                setHasRoleCodes(false);
              }}
              icon={Icon}
              isButtonWithoutText
            />
          )}
        </p>
        <div className="minHeight">
          {!hasRoleCodes ? (
            <>
              <TextInput
                errorMessage={emailErrorMessage}
                touched={emailTouched}
                isLogin={true}
                name="email"
                placeholder="johndoe@mail.com"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                  if (!EMAIL.test(email)) {
                    setEmailErrorMessage("");
                    setEmailTouched(false);
                  }
                }}
              />

              <TextInput
                errorMessage={passwordErrorMessage}
                touched={passWordTouched}
                isLogin={true}
                name="password"
                placeholder="password"
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                  if (password.length > 2) {
                    setPasswordErrorMessage("");
                    setPassWordTouched(false);
                  }
                }}
              />
              <div className={styles.academicTermWraper}>
                <SelectWithoutLabel
                  defaultValue={academicTermId}
                  isAcademicTerm
                  onChange={(event) => {
                    handleOnSelectChange(event);
                  }}
                  value={selectedAcademicTermId}
                  values={academicTerms}
                />
              </div>
            </>
          ) : (
            <SelectWithLabel
              label="Login as?"
              value={roleCode}
              isLogin={true}
              onChange={(event: any) => setRoleCode(event.target.value)}
              values={roleCodesForSelect}
            />
          )}
          <Link to="/resetPassword" className={styles.link}>
            <p>
              {hasRoleCodes === false && (
                <>
                  <p className={styles.forgetPasswordLink}>
                    Forgot your password?
                  </p>
                </>
              )}
            </p>
          </Link>

          <Button title="Log in" type="submit" />
        </div>
      </form>
    </BaseLogin>
  );
};

export default withRouter(observer(LoginForm));
