import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import styles from "./styles.module.scss";
import Button from "../../../common/Button";


export default function MessageBox(props: { open: any; title: any; message: any; callback: any; }) {
  const [open, setOpen] = React.useState(props.open || false);
  const [title, setTitle] = React.useState(props.title || '');
  const [message, setMessage] = React.useState(props.message || '');
  const [callback, setCallback] = React.useState<Function | null>(props.callback ? () => props.callback : null);

  const handleClose = () => {
    setOpen(false);
    if (callback && typeof callback === 'function') callback();
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={styles.dialog}>
        <DialogTitle id="customized-dialog-title">
          <Typography>{title}</Typography>
          <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" title="Close" />
        </DialogActions>
      </Dialog>
    </>
  );
}
