import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import moment from "moment";

import { useActivities, useDialog, useAuth } from "../../../../stores";
import { ValidationTerm } from "../../../../models/state.models";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import copyIcon from "../../../../assets/images/file-svg.svg";
import PresenceTerm from "../PresenceTerm";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import styles from "./styles.module.scss";
import { toJS } from "mobx";

const PresenceActivity = ({ list }: any) => {
  console.log("Presence:", list);
  const {
    activityListForCopy,
    copyActivityTerms,
    getActivityValidation,
    SectionListForCopy,
  } = useActivities();
  const { showDialog } = useDialog();
  const { role } = useAuth();
  const { courseId, sectionId, activityId }: any = useParams();

  const [activityCopyID, setActivityCopyID] = useState<string>("");
  const [sectionCopyID, setSectionCopyID] = useState<string>("");
  const [isError, setIsError] = useState<string>("");
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const handleCopy = () => {
    if (sectionCopyID == sectionId && activityCopyID == activityId) {
      setIsError("cant copy");
      setIsTouched(true);
    } else {
      if (sectionCopyID !== "" && activityCopyID !== "") {
        copyActivityTerms(
          activityId,
          sectionId,
          activityCopyID,
          sectionCopyID
        ).then((resp: any) => {
          if (resp.status && !resp.message) {
            getActivityValidation(courseId, sectionId, activityId);
          } else {
            showDialog(resp.message, null, null);
          }
        });
      }
    }
  };

  let sortedList = list.validationTermList.slice();

  sortedList.sort(function (a: any, b: any) {
    return moment(a.hourFrom).diff(moment(b.hourFrom));
  });

  return (
    <Row>
      <Col>
        <Row>
          <Col className=" pl-0 pb-4 pt-3">
            <h4>{list.activityName}</h4>
          </Col>
        </Row>
        {role !== "Assistant" && (
          <>
            <Row>
              <p>Copy validation terms</p>
            </Row>
            <Row className=" align-items-end">
              <Col md="auto">
                <SelectWithLabel
                  isSectionTitle={true}
                  errorMessage={isError}
                  touched={isTouched}
                  name="sectionNameForCopy"
                  placeholder="Section"
                  value={sectionCopyID !== "" ? sectionCopyID : "-1"}
                  values={SectionListForCopy}
                  onChange={(event: any) => {
                    setSectionCopyID(event.target.value);
                  }}
                />
              </Col>

              <Col md="auto">
                <SelectWithLabel
                  name="activityNameForCopy"
                  placeholder="Activity"
                  value={activityCopyID !== "" ? activityCopyID : "-1"}
                  values={activityListForCopy}
                  onChange={(event: any) => {
                    setActivityCopyID(event.target.value);
                  }}
                />
              </Col>
              <Col md="auto">
                <ButtonWithImage
                  title="Copy"
                  type="button"
                  icon={copyIcon}
                  isExtraSmall={true}
                  onClick={() => {
                    handleCopy();
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className={styles.listItemWrapper + " mt-4 align-items-start"}>
          <Col lg={12} xl={7} className={styles.scroll}>
            {list &&
              sortedList.map((term: ValidationTerm, key: any) => (
                <PresenceTerm term={term} activityID={list.activityID} />
              ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default observer(PresenceActivity);
