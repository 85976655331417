import React from "react";
import { observer } from "mobx-react";

import { useSectionGroups } from "../../stores";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import GroupLists from "./Components/GroupLists";
import SectionNavigation from "../Components/SectionNavigation";

const SectionGroups = () => {
  const { sectionTitle, courseTitle } = useSectionGroups();

  return (
    <DashboardBase componentName={courseTitle} location={sectionTitle}>
      <SectionNavigation isGroups={true} >
        <GroupLists />
      </SectionNavigation>
    </DashboardBase>
  );
};

export default observer(SectionGroups);
