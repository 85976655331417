import React from "react";
import { observer } from "mobx-react-lite";

import TextInput from "../../../../common/TextInput";
import { CourseUserGradebookDTO } from "../../../../models/state.models";
import { useGradebookStore } from "../../../../stores";
import checkIcon from "../../../../assets/images/check.svg";
import styles from "./styles.module.scss";

interface StudentGradebookPointRowProps {
  student: CourseUserGradebookDTO;
}

const StudentGradebookPointRow: React.FC<StudentGradebookPointRowProps> = ({
  student,
}) => {
  const { handlerInputPoints } = useGradebookStore();

  return (
    <tr>
      <td className={styles.tableBorderWrapper}>{student.studentName}</td>
      <td className={styles.tableBorderWrapper}>
        {student.currentPoints ? student.currentPoints : 0}
      </td>
      <td className={styles.tableBorderWrapper}>
        <TextInput
          id={student.courseUserID.toString()}
          onChange={handlerInputPoints}
          value={student.points ? student.points : 0}
        />
      </td>
      {student.isBuyBacks ? (
        <td className={styles.tableBorderWrapper}>
          <div>
            <img alt="check" className={styles.icon} src={checkIcon} />
          </div>
        </td>
      ) : (
        <td className={styles.tableBorderWrapper}></td>
      )}
    </tr>
  );
};

export default observer(StudentGradebookPointRow);
