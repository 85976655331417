import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import cc from "classcat";

import Button from "../../../../common/Button";
import CustomModal from "../../../../common/CustomModal";
import Modal from "../Modal";
import styles from "./styles.module.scss";

interface SpendOpportunitiesCardProps {
  description: string;
  id: number;
  isPurchased: boolean;
  title: string;
  tokens: number;
  currencyName: string;
}

const SpendOpportunitiesCard: React.FC<SpendOpportunitiesCardProps> = ({
  description,
  id,
  isPurchased,
  title,
  currencyName,
  tokens,
}) => {
  const [ModalVisiblity, setModalVisiblity] = useState(false);
  return (
    <>
      <div
        className={cc([
          styles.card + " py-5 px-2 mx-3 my-3",
          { [styles.purchased]: isPurchased },
        ])}
      >
        <Row>
          <Col>
            <h2>{title}</h2>
          </Col>
        </Row>
        <Row>
          <Col className={styles.description}>
            <p className={" mt-4 mb-3 px-3 text-center"}>{description}</p>
          </Col>
        </Row>
        <Row className="pt-4 mt-auto">
          <Col>
            <p className={styles.tokens}>
              {currencyName ? currencyName : "Tokens"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.sum}>{tokens}</p>
          </Col>
        </Row>
        <Row className="">
          <Col className=" ">
            <Button
              type="button"
              title="Purchase"
              isPurchase={isPurchased}
              onClick={() => {
                isPurchased
                  ? setModalVisiblity(false)
                  : setModalVisiblity(true);
              }}
              isDialog={true}
            />
          </Col>
        </Row>
      </div>
      <CustomModal
        open={ModalVisiblity}
        handleClose={() => setModalVisiblity(false)}
      >
        <Modal handleCancel={() => setModalVisiblity(false)} itemId={id} />
      </CustomModal>
    </>
  );
};

export default SpendOpportunitiesCard;
