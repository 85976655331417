import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import { useGradebookStore } from "../../../../stores";
import { StudentGradebook } from "../../../../models/state.models";
import GradebookStudnetTableRow from "../GradebookStudnetTableRow";
import styles from "./styles.module.scss";

const GradebookStudentView: React.FC<any> = () => {
  const { courseId }: any = useParams();

  const { getGradebookForStudent, studentGradebookList, maxAttempts } =
    useGradebookStore();
  var attempts: number = 0;

  useEffect(() => {
    getGradebookForStudent(courseId);
  }, [courseId]);

  return (
    <>
      <Row className="pb-5 pt-4">
        <Col>
          <h2>Gradebook</h2>
        </Col>
      </Row>
      <table className={styles.gradebookTable}>
        <thead>
          <tr className=" d-flex">
            <td className={styles.firstCellWrapper}>Assignment</td>
            <td className={styles.cellWrapper}>My grade</td>
            {Array.from(Array(maxAttempts)).map(() => (
              <td className={styles.cellWrapper}>
                Attempt {(attempts = attempts + 1) && attempts}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {studentGradebookList &&
            studentGradebookList.map((attempt: StudentGradebook) => {
              return (
                <GradebookStudnetTableRow key={attempt.id} attempt={attempt} />
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default observer(GradebookStudentView);
