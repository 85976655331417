import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router";

import Card from "../../../../common/Card";
import { useCourses } from "../../../../stores";
import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import CourseTable from "../CourseTable";
import TablePager from "../../../Components/TablePager";

const CoursesList = () => {
  const { getAcademicTerms, setSelectedCourseTitle, resetCourse } =
    useCourses();
  const { academicTermId }: any = useParams();

  useEffect(() => {
    resetCourse();
    getAcademicTerms();
    setSelectedCourseTitle("");
  }, [getAcademicTerms]);

  return (
    <Card isFullScreen={true} isMobileCourses={true}>
      <BaseTable
        list={{
          url: "/CourseApi/GetCourseList",
          filterParameters: [
            { name: "CourseCode", dataType: "string", operator: "like" },
            { name: "Title", dataType: "string", operator: "like" },
          ],
        }}
      >
        <TableFilter
          buttonDestination={`/academicTerms/${academicTermId}/courses/add`}
          buttonTitle="Add new course"
        ></TableFilter>
        <CourseTable />
        <TablePager />
      </BaseTable>
    </Card>
  );
};

export default observer(CoursesList);
