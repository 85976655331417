import React from "react";
import { Checkbox } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { BASE_URL } from "../../../../../../utilities";
import { SectionUserCourseList } from "../../../../../../models/state.models";
import { useSectionGroups, useAuth } from "../../../../../../stores";
import defaultUser from "../../../../../../assets/images/default-user.png";
import styles from "./styles.module.scss";

interface StudentMainGroupItemProps {
  student: SectionUserCourseList;
}

const StudentMainGroupItem: React.FC<StudentMainGroupItemProps> = ({
  student,
}) => {
  const { handlerSectionGroupCheckboxChange, selectedStudentsID } =
    useSectionGroups();
  const { role } = useAuth();

  return (
    <li className={styles.listItem + " py-2"}>
      <img
        className={styles.icon}
        src={
          student.imageFileName
            ? `${BASE_URL}File/GetFile/${student.imageFileName}`
            : defaultUser
        }
      />
      <p className={styles.nameFont + " overflow-auto pl-2"}>
        {student.fullName ? student.fullName : student.email}
      </p>
      {role !== "Assistant" && (
        <Checkbox
          checked={selectedStudentsID.includes(student.id)}
          className={styles.checkBox + " ml-auto"}
          id={student.id.toString()}
          onChange={handlerSectionGroupCheckboxChange}
        />
      )}
    </li>
  );
};

export default observer(StudentMainGroupItem);
