import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import Button from "../../../../common/Button";
import TextArea from "../../../../common/TextArea";
import styles from "./styles.module.scss";

interface StudentModalProps {
  handleCancel: () => void;
  handleClick: () => void;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
}

const StudentModal: React.FC<StudentModalProps> = ({
  handleCancel,
  handleClick,
  handleChange,
  value,
}) => {
  return (
    <div className={styles.addGroupWrapper + " p-5"}>
      <Row>
        <Col>
          <h2>Deny Purchase</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TextArea value={value} label="Comment" onChange={handleChange} />
        </Col>
      </Row>

      <Row className="justify-content-end + m-0 mt-5">
        <Col>
          <Button
            isDialogCancel={true}
            onClick={handleCancel}
            title="Cancel"
            type="button"
          />
        </Col>
        <Col>
          <Button
            isDialog={true}
            title="Deny"
            type="submit"
            isDialogDeny={true}
            onClick={handleClick}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StudentModal;
