import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";

import Login from "../Pages/Login";
import MessageBox from "../Pages/Components/MessageBox";
import Routes from "./Routes";
import { TestList } from "../testListControl";
import {
  useAssignments,
  useAuth,
  useCourses,
  useGradebookStore,
  useSchedulerStore,
  useSection,
  useSectionGroups,
  useActivities,
  useScoreStore,
  useScreenSize,
} from "../stores";
import ActivateUser from "../Pages/ActivateUser";
import ForgetPassword from "../Pages/ForgetPassword";
import Loader from "../common/Loader";

const CustomRouter = () => {
  const [isProgressBarVisible, setIsProgressBarVisibile] = useState(false);
  const { authStoreLoading } = useAuth();
  const { isSectionStoreLoading } = useSectionGroups();
  const { isSectionLoading } = useSection();
  const { isLoading } = useCourses();
  const { assignmentStoreLoading } = useAssignments();
  const { isGradebookStoreLoading } = useGradebookStore();
  const { activityStoreLoading } = useActivities();
  const { isSchedulerLoading } = useSchedulerStore();
  const { isScoreLoading } = useScoreStore();
  const { size, setSize } = useScreenSize();

  useEffect(() => {
    if (
      authStoreLoading ||
      isLoading ||
      isSectionStoreLoading ||
      isSectionLoading ||
      assignmentStoreLoading ||
      isGradebookStoreLoading ||
      activityStoreLoading ||
      isSchedulerLoading ||
      isScoreLoading
    ) {
      setIsProgressBarVisibile(true);
    } else {
      setIsProgressBarVisibile(false);
    }
  }, [
    isLoading,
    authStoreLoading,
    isSectionStoreLoading,
    isSectionLoading,
    activityStoreLoading,
    assignmentStoreLoading,
    isGradebookStoreLoading,
    isSchedulerLoading,
    isScoreLoading,
  ]);

  const handleResize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    setSize(window.innerWidth);
  }, [size]);

  useEffect(() => {
    setSize(window.innerWidth);
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <MessageBox />
      {isProgressBarVisible && <Loader />}

      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/activateuser/:uid" component={ActivateUser} />
        <Route path="/passwordrecovery/:uid" component={ActivateUser} />
        <Route path="/resetPassword" component={ForgetPassword} />

        {/*
          <Route component={withRouter(TestList)} path="/testList/:academicTermID/test2/:courseID" exact />
          <Route component={withRouter(TestList)} path="/testList/:academicTermID/test2" exact />
          <Route component={withRouter(TestList)} path="/testList/:academicTermID" exact />
          <Route component={withRouter(TestList)} path="/testList" exact />
          */}
        <Route
          component={TestList}
          path="/testList/:academicTermID/test2/:courseID"
          exact
        />
        <Route
          component={TestList}
          path="/testList/:academicTermID/test2"
          exact
        />

        <Route component={TestList} path="/testList/:academicTermID" exact />
        <Route component={TestList} path="/testList" exact />

        <Routes />
      </Switch>
    </>
  );
};

export default observer(CustomRouter);
