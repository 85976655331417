import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import cc from "classcat";

import { useAuth, useScreenSize } from "../../../stores";
import TabNavigationItem from "../TabNavigationItem";

interface CourseNavigationProps {
  children?: React.ReactNode;
  isActivities?: boolean;
  isApplications?: boolean;
  isAssignments?: boolean;
  isCourseDetails?: boolean;
  isGradeBook?: boolean;
  isScheduler?: boolean;
  isSection?: boolean;
  isSpendOpportunities?: boolean;
}

const CourseNavigation: React.FC<CourseNavigationProps> = ({
  children,
  isActivities,
  isApplications,
  isAssignments,
  isCourseDetails,
  isGradeBook,
  isScheduler,
  isSection,
  isSpendOpportunities,
}) => {
  const { courseId, academicTermId }: any = useParams();

  const { role } = useAuth();
  const { isTabs } = useScreenSize();

  return (
    <>
      <Col className={cc([" p-0", { ["mt-4"]: isTabs }])}>
        <Row className="mx-0">
          <TabNavigationItem
            component={children}
            isDetails={true}
            isSelected={isCourseDetails}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/details`}
            title="Course Details"
            index={0}
          />

          {role !== "Student" && (
            <TabNavigationItem
              component={children}
              isSelected={isSection}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/sections`}
              title="Sections"
              index={2}
            />
          )}

          <TabNavigationItem
            component={children}
            isSelected={isActivities}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/activities`}
            title="Activities"
            index={1}
          />

          {role !== "Student" && (
            <TabNavigationItem
              component={children}
              isSelected={isAssignments}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/assignments`}
              title="Assignments"
              index={3}
            />
          )}

          {role === "Student" && (
            <TabNavigationItem
              component={children}
              isSelected={isSpendOpportunities}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/SpendOpportunities`}
              title="Spend Opportunities"
              index={2}
            />
          )}

          <TabNavigationItem
            component={children}
            isSelected={isGradeBook}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/gradebook`}
            title="Gradebook"
            index={role === "Student" ? 3 : 4}
          />

          {role === "Student" && (
            <TabNavigationItem
              component={children}
              isSelected={isApplications}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/applications`}
              title="Applications"
              index={4}
            />
          )}

          <TabNavigationItem
            component={children}
            isSelected={isScheduler}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/scheduler`}
            title="Scheduler"
            index={5}
          />
        </Row>
      </Col>
      {isTabs && children}
    </>
  );
};

export default CourseNavigation;
