import React from "react";
import { Col, Row } from "react-bootstrap";

import SelectWithoutLabel from "../../../../common/SelectWithOutLabel";
import { useGradebookStore } from "../../../../stores";
import { observer } from "mobx-react-lite";
import StudentGradebookPoint from "../StudentGradebookPoint";
import AssignmentCodeList from "../AssignmentCodeList";
import styles from "./styles.module.scss";

const GradebookActivity = () => {
  const {
    handlerFilterStudentSelected,
    assignmentCodeList,
    selectedAssignmentID,
  } = useGradebookStore();

  return (
    <Row className="justify-content-between px-3">
      <Col md="auto" className={styles.assignmentMainContent}>
        <AssignmentCodeList />
      </Col>
      <Col>
        <Row>
          <Col md={10} className=" my-3">
            <span className={styles.activityMainTitle}>
              {assignmentCodeList.map((assignment: any) => {
                if (assignment.codeInt === selectedAssignmentID) {
                  return assignment.value;
                }
              })}
            </span>
            <div className={styles.selectInputWrapper}>
              <SelectWithoutLabel
                onChange={handlerFilterStudentSelected}
                values={[
                  { label: "All Students", value: 0 },
                  { label: "Students with buybacks", value: 1 },
                ]}
              />
            </div>
          </Col>
        </Row>
        <StudentGradebookPoint />
      </Col>
    </Row>
  );
};

export default observer(GradebookActivity);
