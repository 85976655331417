import React, { useEffect, useState, useRef } from "react";
import {
  ScheduleComponent,
  Inject,
  Day,
  Week,
  Month,
  TimelineMonth,
  ViewDirective,
  ViewsDirective,
  EventSettingsModel,
} from "@syncfusion/ej2-react-schedule";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { observer } from "mobx-react";
import { Col } from "react-bootstrap";

import { useAuth, useDialog, useSchedulerStore } from "../../../../stores";
import CustomModal from "../../../../common/CustomModal";
import CUScheduleModal from "../CUScheduleModal";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import { ListsForSchedulerSelect } from "../../../../models/state.models";
import styles from "./styles.module.scss";

interface SchedulerComponentProps {
  isStudent: boolean;
}

const SchedulerComponent: React.FC<SchedulerComponentProps> = ({
  isStudent,
}) => {
  const { courseId }: any = useParams();
  const {
    changeDataForSchedule,
    deleteEvent,
    getCourseCodeList,
    getEventsList,
    parsedData,
    resetParesdList,
    saveEvent,
    courseList,
    handleSelecdCourseChange,
    selectedCourse,
    resetSchedule,
    schedule,
  } = useSchedulerStore();

  const { showDialog } = useDialog();
  const { selectedAcademicTermId, setCourseName } = useAuth();
  const history = useHistory();

  const [id, setId] = useState(courseId);
  const [selectedView, setSelectedView] = useState("Month");
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [needsReloadScheduler, setNeedsReloadScheduler] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [ModalVisiblity, setModalVisiblity] = useState(false);
  const [filtredCourseList, setFiltredCourseList] = useState([]);

  const scheduler = useRef<ScheduleComponent>(null);

  const [dataForScheduler, setDataForScheduler] = useState<EventSettingsModel>({
    dataSource: [],
  });

  useEffect(() => {
    resetParesdList();
    if (isStudent) {
      getCourseCodeList();
    }
    getEventsList(
      courseId,
      `${currentYear}-${currentMonth}-1`,
      `${currentMonth === 12 ? currentYear + 1 : currentYear}-${
        currentMonth === 12 ? 1 : currentMonth + 1
      }-1`
    ).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Error"
          );
        }
        showDialog(resp.message, null, null, false);
      }
      if (resp.status === "success" && resp.breadCrumb != null) {
        setCourseName(resp.breadCrumb.courseName);
      }
    });

    return () => {
      resetSchedule();
    };
  }, []);
  useEffect(() => {
    setNeedsReloadScheduler(false);
    if (!isFirstLoading) {
      getEventsList(
        id && id,
        `${currentYear}-${currentMonth}-1`,
        `${currentMonth === 12 ? currentYear + 1 : currentYear}-${
          currentMonth === 12 ? 1 : currentMonth + 1
        }-1`
      ).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Error"
            );
          }
          showDialog(resp.message, null, null, false);
        }
      });
    }
    setIsFirstLoading(false);
  }, [currentMonth, needsReloadScheduler]);

  useEffect(() => {
    setDataForScheduler({ dataSource: parsedData });
  }, [parsedData]);

  useEffect(() => {
    setFiltredCourseList(
      courseList.map((section: ListsForSchedulerSelect) => {
        return { id: section.codeInt, name: section.value };
      })
    );
  }, [courseList]);

  const handleCourseChange = (event: any) => {
    handleSelecdCourseChange(event);
    setId(event.target.value);
    setNeedsReloadScheduler(true);
  };

  const handleNavigation = (data: any) => {
    if (data.action === "date") {
      setCurrentYear(moment(data.currentDate).year());
      setCurrentMonth(moment(data.currentDate).month() + 1);
    } else if (data.action === "view") {
      setSelectedView(data.currentView);
    }
  };

  const saveScheduleDay = () => {
    saveEvent().then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Schedule saved successfully", null, null, false);
        setNeedsReloadScheduler(true);
      } else {
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  };

  const deleteScheduleDay = () => {
    deleteEvent(schedule.id, courseId).then((resp: any) => {
      if ((resp.status = "success" && !resp.message)) {
        showDialog("Event successfully deleted", null, null, false);
        setModalVisiblity(false);
        setNeedsReloadScheduler(true);
      } else {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Errror"
          );
        }
        console.log("Component", JSON.stringify(resp));
        showDialog(resp.message, null, null, true, "Error");
      }
    });
    setNeedsReloadScheduler(true);
  };

  const deleteSchedule = (id: number) => {};

  const onEventComplete = (event: any) => {
    switch (event.requestType) {
      case "eventCreated":
        addEvent(event.data);
        break;
      case "eventChanged":
        editEvent(event.data);
        break;
      case "eventRemoved":
        deleteSchedule(event.data[0].Id);
        break;
    }
  };

  const addEvent = (data: any) => {};
  const editEvent = (data: any) => {};

  const onPopupOpen = (args: any) => {
    changeDataForSchedule(args.data);
    if (args.type === "QuickInfo" || args.type === "ViewEventInfo") {
      args.cancel = true;
      var dialogObj = args.element.ej2_instances[0];
      dialogObj.hide();
      // var currentAction = args.target.classList.contains("e-work-cells")
      //   ? "Add"
      //   : "Save";
      // scheduleRef.current.openEditor(args.data, currentAction);
      setModalVisiblity(true);
    }
    if (args.type === "Editor") {
      setModalVisiblity(true);
      args.cancel = true;
    }
  };

  return (
    <>
      {isStudent && (
        <Col md={2} className="mb-2">
          <SelectWithLabel
            isCourseCode={true}
            label="Courses"
            name="selectedCourse"
            placeholder="Select Course"
            value={selectedCourse}
            onChange={handleCourseChange}
            values={filtredCourseList}
          />
        </Col>
      )}
      <ScheduleComponent
        className={styles.scheduler}
        currentView="Month"
        ref={scheduler}
        eventSettings={dataForScheduler}
        actionComplete={onEventComplete}
        navigating={handleNavigation}
        popupOpen={onPopupOpen}
        actionBegin={(args: any) => console.log("type", args.type)}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Day, Week, Month, TimelineMonth]} />
      </ScheduleComponent>

      <CustomModal
        open={ModalVisiblity}
        handleClose={() => setModalVisiblity(false)}
      >
        <CUScheduleModal
          handleDelete={deleteScheduleDay}
          handleCancel={() => setModalVisiblity(false)}
          handleRefresh={() => setNeedsReloadScheduler(true)}
        />
      </CustomModal>
    </>
  );
};

export default observer(SchedulerComponent);
