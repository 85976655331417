import React from "react";
import { observer } from "mobx-react";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import ApplicationOverview from "./Components/ApplicationOverview";
import { useCourses } from "../../stores";

const ApplicationDetails = () => {
  const { courseTitle } = useCourses();
  return (
    <DashboardBase componentName={courseTitle}>
      <CourseNavigation isApplications={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCoursesTabs={true}
          isDetails={true}
        >
          <ApplicationOverview />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(ApplicationDetails);
