import React from "react";

import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import CUAssignments from "../Components/CUAssignments";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const AddNewAssignment = () => {
  return (
    <DashboardBase componentName="Assignment">
      <CourseNavigation isAssignments={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCoursesTabs={true}
          isDetails={true}
        >
          <CUAssignments />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default AddNewAssignment;
