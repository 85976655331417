import React from "react";
import AddNewCourse from "../../../Pages/AddNewCourse";
import ApplicationDetails from "../../../Pages/ApplicationDetails";
import Applications from "../../../Pages/Applications";
import CourseDetails from "../../../Pages/CourseDetails";
import Courses from "../../../Pages/Courses";
import EditCourse from "../../../Pages/EditCourse";
import Scheduler from "../../../Pages/Scheduler";
import PrivateRoute from "../../PrivateRoute";

const CourseRoutes = () => {
  return (
    <>
      <PrivateRoute
        component={Courses}
        path="/academicTerms/:academicTermId/courses"
        exact
      />
      <PrivateRoute
        component={AddNewCourse}
        path="/academicTerms/:academicTermId/courses/add"
        exact
      />

      <PrivateRoute
        component={EditCourse}
        path="/academicTerms/:academicTermId/courses/edit/:courseId"
      />

      <PrivateRoute
        component={CourseDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/details"
        exact
      />

      <PrivateRoute
        component={Scheduler}
        path="/academicTerms/:academicTermId/courses/:courseId/scheduler"
      />

      <PrivateRoute
        component={Applications}
        path="/academicTerms/:academicTermId/courses/:courseId/applications"
        exact
      />
      <PrivateRoute
        component={ApplicationDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/applications/:applicationId/spendOpportunitiesDetails"
      />
      <PrivateRoute
        component={ApplicationDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/applications/:applicationId/activityDetails"
      />

      <PrivateRoute
        component={ApplicationDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/applications/:applicationId/extraCredit"
      />
    </>
  );
};

export default CourseRoutes;
