import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import moment from "moment";

import { useDialog, useSection, useAuth } from "../../../../stores";
import editIcon from "../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../Components/MessageBoxWithButtons";
import {
  SectionForList,
  TimesOfTheWeek,
} from "../../../../models/state.models";
import trashIcon from "../../../../assets/images/trash.svg";
import viewIcon from "../../../../assets/images/view.svg";
import styles from "./styles.module.scss";
import { Col, Row } from "react-bootstrap";
import { removeOffset, convertIntToDay } from "../../../../utils/timeUtils";

interface SectionRowProps {
  section: SectionForList;
  list: any;
}

const SectionRow: React.FC<SectionRowProps> = ({ section, list }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { courseId, academicTermId }: any = useParams();
  const { deleteSection } = useSection();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/sections/${section.id}/details`
    );
  };

  const handleConfirm = () => {
    deleteSection(section.id).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Section successfully  deleted", null, null, false);
        list.refresh();
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  return (
    <>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete ${section.title} section?`}
        handleClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isOpenDialog}
      />
      <tr onClick={onTableDetailsClick}>
        <td className={styles.tableCol} data-label="Section title">
          {section.title}
        </td>
        <td className={styles.tableCol} data-label="ID">
          {section.id}
        </td>

        <td className={styles.tableCol} data-label="Applicants">
          <Row className="align-items-center">
            <Col xs="auto">{section.students}</Col>
            <Col xs="auto" className="d-flex align-items-end">
              <img alt="View" src={viewIcon} className={styles.icon} />
            </Col>
          </Row>
        </td>
        <td className={styles.tableCol} data-label="Teaching Assistant">
          {section.teachingAssistants.map((value: string) => (
            <p key={value}>{value}</p>
          ))}
        </td>
        <td>
          <div className={styles.iconsWrapper}>
            {role === "Professor" && (
              <img
                alt="Edit"
                src={editIcon}
                className={styles.iconWithCursor}
                onClick={(e: any) => {
                  e.stopPropagation();
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/sections/edit/${section.id}`
                  );
                }}
              />
            )}
            <img
              alt="View"
              src={viewIcon}
              className={styles.icon}
              onClick={onTableDetailsClick}
            />{" "}
            {role === "Professor" && (
              <img
                alt="delete"
                src={trashIcon}
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenDialog(true);
                }}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default SectionRow;
