import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";

import styles from "./styles.module.scss";

export type SelectInputProps = {
  isAcademicTerm?: boolean;
  placeholder?: string;
  values?: any;
} & SelectProps;

const SelectWithoutLabel: React.FC<SelectInputProps> = ({
  isAcademicTerm,
  placeholder,
  values,
  ...props
}: any) => {
  return (
    <div className={styles.selectContainer}>
      <Select className={styles.select} {...props}>
        {placeholder && (
          <MenuItem
            className={styles.defaultOption}
            selected={true}
            value={placeholder}
          >
            {placeholder}
          </MenuItem>
        )}
        {values.map((value: any, index: number) => (
          <MenuItem
            value={isAcademicTerm ? value.id : value.value}
            key={index}
            className={styles.item}
          >
            {isAcademicTerm ? value.fullName : value.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectWithoutLabel;
