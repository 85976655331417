import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import { CircularProgress } from "@material-ui/core";

import { SaveActivityValidation } from "../../../../models/request.models";
import { useDialog, useActivities, useAuth } from "../../../../stores";
import cancelIcon from "../../../../assets/images/cross.png";
import check from "../../../../assets/images/check.svg";
import edit from "../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../Components/MessageBoxWithButtons";
import TextInput from "../../../../common/TextInput";
import trash from "../../../../assets/images/trash.svg";
import styles from "./styles.module.scss";

const Terms = ({ term, list }: any) => {
  const { courseId, sectionId, activityId }: any = useParams();
  const {
    deleteValidation,
    getActivityValidation,
    searchActivityList,
    selectedActivitySection,
    isAcitivityValidationAdded,
    saveValidation,
    setActivityValidationAdded,
  } = useActivities();
  const { role } = useAuth();

  const { showDialog } = useDialog();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [validationName, setValidationName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>("");

  const handleConfirm = () => {
    setLoading(true);
    deleteValidation(courseId, term.id).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        getActivityValidation(
          courseId,
          sectionId ?? selectedActivitySection,
          activityId ?? searchActivityList.id,
          true
        ).then((resp: any) => {
          setLoading(false);
        });
      } else {
        setLoading(false);
        showDialog(resp.message, null, null);
      }
    });
  };
  const handleSave = () => {
    const request: SaveActivityValidation = {
      ActivityID: activityId ?? searchActivityList.id,
      CourseID: courseId,
      SectionID: sectionId ?? selectedActivitySection,
      ValidationTerm: validationName,
      id: term.id,
      ValidationTermDateFrom: term.validationTermDateFrom,
      ValidationTermDateTo: term.validationTermDateTo,
    };
    if (validationName !== term.validationTerm) {
      if (Duplicate()) {
        setIsEdit(true);
        setIsTouched(true);
        setIsError("Duplicated term");
      } else {
        setLoading(true);
        setIsEdit(false);
        setIsTouched(false);
        saveValidation(request).then((resp: any) => {
          if (resp.status === "success" && !resp.message) {
            getActivityValidation(
              courseId,
              sectionId ?? selectedActivitySection,
              activityId ?? searchActivityList.id,
              true
            ).then((resp: any) => {
              setValidationName("");
              setLoading(false);
            });
          } else {
            if (resp.status === "error" && resp.message) {
              if (resp.message.includes("Validation failed")) {
                setIsEdit(true);
                setIsTouched(true);
                setIsError("long term");
              } else {
                showDialog(resp.message, null, null);
              }
            }
            setLoading(false);
          }
        });
      }
    }
  };

  const Duplicate = () => {
    let duplicates = [];
    duplicates = list.validationTermList.filter(
      (temp: any) => temp.validationTerm === validationName
    );
    if (duplicates.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isAcitivityValidationAdded) {
      setIsEdit(false);
      setActivityValidationAdded(false);
    }
  }, [isAcitivityValidationAdded]);

  return (
    <>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete validation?`}
        handleClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isOpenDialog}
      />
      <Row className={styles.listItemWrapper}>
        <Col md="auto">
          {isEdit ? (
            <TextInput
              isValEdit={true}
              errorMessage={isError}
              touched={isTouched}
              name="validationTerm"
              value={validationName}
              onChange={(event: any) => {
                setValidationName(event.target.value);
              }}
            />
          ) : (
            <div className=" py-4">
              <p className={styles.smallFont}>
                {loading ? validationName : term.validationTerm}
              </p>
            </div>
          )}
        </Col>
        {role !== "Assistant" && (
          <Col>
            <Row className=" justify-content-end">
              <img
                alt="edit"
                className={styles.icon}
                hidden={loading}
                src={isEdit ? check : edit}
                onClick={(event) => {
                  if (isEdit && validationName !== "") {
                    handleSave();
                  } else {
                    setIsEdit(!isEdit);
                    setValidationName(term.validationTerm);
                  }
                }}
              />
              <img
                alt="delete"
                hidden={loading}
                className={styles.icon + " mr-4"}
                src={isEdit ? cancelIcon : trash}
                onClick={() => {
                  if (isEdit) {
                    setIsEdit(false);
                    setIsTouched(false);
                  } else {
                    setIsOpenDialog(true);
                  }
                }}
              />
              {loading && (
                <CircularProgress size={15} className={styles.progressBar} />
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default observer(Terms);
