import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import {
  StudentAttempt,
  StudentGradebook,
} from "../../../../models/state.models";
import styles from "./styles.module.scss";

interface GradebookStudnetTableRowProps {
  attempt: StudentGradebook;
}

const GradebookStudnetTableRow: React.FC<GradebookStudnetTableRowProps> = ({
  attempt,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [date, setDate] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <tr className=" d-flex">
      <td className={styles.firstCellWrapper} data-label="Assignment Name">
        <p>{attempt.assignmentName}</p>
      </td>
      <td className={styles.cellWrapper} data-label="Grade">
        <div>{attempt.grade}</div>
      </td>
      {attempt.attemptList &&
        attempt.attemptList.map((point: StudentAttempt, index: number) => (
          <>
            <td
              className={styles.cellWrapper}
              data-label={`Attempt ${index + 1}`}
            >
              <div>
                <div
                  className={styles.cursor + " d-inline-flex"}
                  onClick={(event: any) => {
                    setAnchorEl(event.target);
                    setOpen(!open);
                    setDate(point.passedDate);
                  }}
                >
                  <p>{point.points}</p>
                  &nbsp;&nbsp;
                  <p
                    className={
                      point.pointsDifference < 0
                        ? styles.pointsRed
                        : styles.pointsGreen
                    }
                  >
                    {point.pointsDifference !== 0 &&
                      (point.pointsDifference > 0
                        ? index !== 0
                          ? "+" + point.pointsDifference
                          : null
                        : point.pointsDifference)}
                  </p>
                </div>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography>
                    <div className=" p-3">
                      <div>
                        <p className={styles.popupFont}>Attempt date</p>
                      </div>
                      <div>
                        <p className={styles.dateFont}>{date}</p>
                      </div>
                    </div>
                  </Typography>
                </Popover>
              </div>
            </td>
          </>
        ))}
    </tr>
  );
};

export default observer(GradebookStudnetTableRow);
