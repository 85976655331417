import React, { useEffect } from "react";

import DashboardBase from "../Dashboard/Components/DashboardBase";
import CoursesList from "./Components/CoursesList";

const Courses = () => {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <DashboardBase componentName="Courses" havePadding={false}>
      <CoursesList />
    </DashboardBase>
  );
};

export default Courses;
