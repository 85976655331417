import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import { BASE_URL } from "../../../../utilities";
import { File } from "../../../../models/state.models";
import { useActivities, useDialog, useAuth } from "../../../../stores";
import Button from "../../../../common/Button";
import viewIcon from "../../../../assets/images/view.svg";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import ConfirmationModal from "../ConfirmationModal";
import CustomModal from "../../../../common/CustomModal";
import fileIcon from "../../../../assets/images/file-svg.svg";
import Icon from "../../../../assets/images/back.svg";
import styles from "./styles.module.scss";

const Confirmation = () => {
  const { courseId, activityId, academicTermId, submittedActivityId }: any =
    useParams();
  const history = useHistory();
  const {
    getValidationDetails,
    confirmation,
    currencyName,
    removeStudentWaitingList,
  } = useActivities();
  const { showDialog } = useDialog();
  const { setCourseName, setSectionName } = useAuth();

  const [excused, setExcused] = useState(false);
  const [ModalVisiblity, setModalVisiblity] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getValidationDetails(courseId, submittedActivityId).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized") ||
          resp.message.includes("Authentication error")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${academicTermId}/courses`,
            history,
            true,
            "Error"
          );
        }
        showDialog(resp.message, null, null, true, "Error");
      }
      if (resp.status === "success") {
        setCourseName(resp.breadCrumb.courseName);
        setSectionName(confirmation.activityName);
      }
    });
  }, [activityId, submittedActivityId]);

  const handleClose = () => {
    setOpen(false);
  };

  const redirectToNextItem = (student: any) => {
    const next = removeStudentWaitingList(student.id);
    if (next) {
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/students/${next}/confirmation`
      );
    } else {
      if (confirmation.activityType != "Group") {
        history.push(
          `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/students`
        );
      } else {
        history.push(
          `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/groups`
        );
      }
    }
  };

  return (
    <Col className=" pl-5">
      <Row className="justify-content-end pb-3 m-0">
        <ButtonWithImage
          title="Back"
          onClick={() => {
            history.goBack();
          }}
          icon={Icon}
        />
      </Row>
      <Row className=" justify-content-start pb-5">
        <Col md={2} className={styles.rightBorder + " pl-0"}>
          <h2>{confirmation.activityName}</h2>{" "}
          <p>{confirmation.activityType}</p>
        </Col>
        <Col md={2} className={styles.rightBorder + " pl-5"}>
          <p>Duration</p>
          <p>
            {confirmation.activityStartDate.substr(
              0,
              confirmation.activityStartDate.length - 6
            )}
            -
            {confirmation.activityEndDate.substr(
              0,
              confirmation.activityEndDate.length - 6
            )}
          </p>
        </Col>

        <Col md={2} className=" pl-5">
          <p>{currencyName}</p>
          <p className={styles.greenText}>{confirmation.credit}</p>
        </Col>
      </Row>
      <Row className={styles.topBorder + " pt-5 pb-5"}>
        <Col md={5} className={styles.rightBorder}>
          <Row className=" justify-content-start pb-5">
            <Col
              md={3}
              className={
                confirmation.activityType === "Attendance"
                  ? styles.rightBorder + " pl-0"
                  : " pl-0"
              }
            >
              <p>Submission date</p>
              <p>
                {confirmation.submittedDate.substr(
                  0,
                  confirmation.submittedDate.length - 6
                )}
              </p>
            </Col>
            {confirmation.activityType === "Attendance" && (
              <Col md={4} className=" pl-5">
                <p>Validation date</p>
                <p>
                  {confirmation.validatedFor.substr(
                    0,
                    confirmation.validatedFor.length - 6
                  )}
                </p>
              </Col>
            )}
          </Row>
          <Row>
            <h2>
              {confirmation.activityType === "Group" ? "Students" : "Student"}
            </h2>
          </Row>
          <Row
            className={
              styles.borderBottom + " mb-5 mr-5 pt-3 align-items-center"
            }
          >
            {confirmation.activityType !== "Group" ? (
              <p className=" pt-2 pb-2">{confirmation.studentName}</p>
            ) : confirmation.studentList.length <= 2 ? (
              confirmation.studentList.map((value: any, index: any) => (
                <p className=" pt-2 pb-2">
                  {value.studentName}
                  {index !== confirmation.studentList.length - 1 ? "," : ""}
                  &nbsp;&nbsp;
                </p>
              ))
            ) : (
              <>
                {confirmation.studentList.map((value: any, index: any) => (
                  <p className=" pt-2 pb-2">
                    {index <= 2 && value.studentName}
                    {index < 2 ? ", " : ""}
                    &nbsp;
                  </p>
                ))}
                <p>{confirmation.studentList.length > 3 ? "..." : ""}</p>
                {confirmation.studentList.length > 3 ? (
                  <div className=" ml-auto">
                    <img
                      alt="View"
                      className={styles.icon}
                      src={viewIcon}
                      onClick={(event: any) => {
                        setAnchorEl(event.target);
                        setOpen(!open);
                      }}
                    />

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                    >
                      <Typography>
                        {confirmation.studentList.map((value: any) => (
                          <p className=" px-4 py-2">{value.studentName}</p>
                        ))}
                      </Typography>
                    </Popover>
                  </div>
                ) : null}
              </>
            )}
          </Row>
          <Row className=" pb-2">
            <h4>
              {confirmation.activityType === "Attendance"
                ? "Submitted for validation:"
                : "Validation phrase"}
            </h4>
          </Row>
          <Row
            className={
              styles.borderBottom + " mb-5 mr-5 pt-3 align-items-center"
            }
          >
            <p className={styles.greyFont + " pt-2 pb-2"}>
              {confirmation.validationTerm}
            </p>
          </Row>
          <Row className=" pb-2">
            <h4>Validation files</h4>
          </Row>
          {confirmation.fileList &&
            confirmation.fileList.map((file: File, index: number) => (
              <Row className=" pt-3" key={index}>
                <img
                  className={styles.fileCursor + " pr-3"}
                  src={fileIcon}
                  alt="File"
                  onClick={() =>
                    file.fileName &&
                    window.open(
                      `${BASE_URL}File/GetFile/${file.fileName}`,
                      "_blank"
                    )
                  }
                />
                <p
                  className={styles.fileCursor}
                  onClick={() =>
                    file.fileName &&
                    window.open(
                      `${BASE_URL}File/GetFile/${file.fileName}`,
                      "_blank"
                    )
                  }
                >
                  {file.name}
                </p>
              </Row>
            ))}
        </Col>
        <Col>
          <Row className="justify-content-center ">
            <Col md={8} className={styles.commentCard + " "}>
              <Row className=" pb-3">
                <Col>
                  <h4>Student Comment</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>{confirmation.studentNote}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className=" justify-content-end pt-5">
        <Col md={2}>
          <Button
            isDeny={true}
            title={
              confirmation.activityType === "Attendance" ? "Unexcused" : "Deny"
            }
            type="unexcused"
            onClick={() => {
              setExcused(false);
              setModalVisiblity(true);
            }}
          />
        </Col>
        <Col md={2}>
          <Button
            title={
              confirmation.activityType === "Attendance" ? "Excused" : "Accept"
            }
            type="excused"
            onClick={() => {
              setExcused(true);
              setModalVisiblity(true);
            }}
          />
        </Col>
      </Row>
      <CustomModal
        open={ModalVisiblity}
        handleClose={() => setModalVisiblity(false)}
      >
        <ConfirmationModal
          handleCancel={() => setModalVisiblity(false)}
          handleRedirection={(student) => redirectToNextItem(student)}
          excused={excused}
          confirmation={confirmation}
          isPresence={confirmation.activityType === "Attendance" ? true : false}
        />
      </CustomModal>
    </Col>
  );
};

export default Confirmation;
