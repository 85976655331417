import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import Button from "../../../../common/Button";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import defaultUserImage from "../../../../assets/images/default-user.png";
import Icon from "../../../../assets/images/back.svg";
import Select from "../../../../common/SelectWithLabel";
import TextInput from "../../../../common/TextInput";
import { useDialog, useSection, useAuth } from "../../../../stores";
import { BASE_URL } from "../../../../utilities";
import styles from "./styles.module.scss";

const StudentInfo = () => {
  const { courseId, academicTermId, sectionId, userId, CourseUserID }: any =
    useParams();
  const history = useHistory();
  const { role } = useAuth();

  const [credits, setCredits] = useState("");
  const [note, setNote] = useState("");

  const [creditsErrorMessage, setCreditsErrorMessage] = useState("");
  const [creditsTouched, setCreditsTouched] = useState(false);
  const [noteErrorMessage, setNoteErrorMessage] = useState("");
  const [noteTouched, setNoteTouched] = useState(false);

  const [needsReload, setNeedsReload] = useState(false);

  const {
    getSectionStudent,
    resetStudentDetails,
    studentDetails,
    addCourseUSerCredit,
    updateAccommodationRate,
  } = useSection();

  const { showDialog } = useDialog();

  useEffect(() => {
    setNeedsReload(false);
    if (role != "Student") {
      getSectionStudent(CourseUserID, userId, sectionId).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          showDialog(resp.message, null, null, true, "Error");
        }
        return () => {
          resetStudentDetails();
        };
      });
    } else {
      showDialog(
        "User not authorized",
        `/academicTerms/${academicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
  }, [needsReload]);

  const validateAddCredits = () => {
    let valid = true;
    if (credits === "") {
      setCreditsErrorMessage("Enter credits");
      setCreditsTouched(true);
      valid = false;
    } else {
      setCreditsErrorMessage("");
      setCreditsTouched(false);
      valid = true;
    }
    if (note === "") {
      setNoteErrorMessage("Enter note");
      setNoteTouched(true);
      valid = false;
    } else {
      setNoteErrorMessage("");
      setNoteTouched(false);
      valid = true;
    }
    return valid;
  };

  const addCredits = () => {
    if (validateAddCredits()) {
      addCourseUSerCredit(sectionId, CourseUserID, credits, note).then(
        (resp: any) => {
          if (resp.status === "success" && !resp.message) {
            showDialog("Credits successfully added", null, null, false);
            setNeedsReload(true);
            setCredits("");
            setNote("");
          } else {
            showDialog(resp.messsage, null, null, true, "Error");
          }
        }
      );
    }
  };

  const handleOnChangeAccomodationRate = (e: any) => {
    updateAccommodationRate(sectionId, CourseUserID, e.target.value).then(
      (resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog(
            "Accommodation rate changed successfully",
            null,
            null,
            false
          );
          setNeedsReload(true);
        } else {
          showDialog(resp.message, null, null, true, "Error");
        }
      }
    );
  };

  return (
    <>
      <Row className="justify-content-end">
        <ButtonWithImage
          title="Back"
          onClick={() => {
            history.push(
              `/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/students`
            );
          }}
          icon={Icon}
        />
      </Row>
      <Row className="justify-content-end">
        <Col>
          <Row className="justify-content-start pb-5">
            <Col md="auto" className="d-flex justify-content-end">
              <img
                alt="student"
                src={
                  studentDetails.imageFileName
                    ? `${BASE_URL}File/GetFile/${studentDetails.imageFileName}`
                    : defaultUserImage
                }
                className={styles.profileImage}
              />
            </Col>
            <Col md="auto">
              <h2>{studentDetails.studentName}</h2>
              <p>{studentDetails.userEmail}</p>
            </Col>

            {studentDetails.studentID && (
              <Col md="auto" className={styles.colWrapper}>
                <p>{studentDetails.studentID}</p>
              </Col>
            )}
            <Col md="auto" className={styles.colWrapper}>
              <p>{studentDetails.groupName}</p>
            </Col>
          </Row>
          <Row className="justify-content-start pb-5">
            <Col md="auto">
              <p className={styles.smallSize}>Credits earned</p>
              <p className={styles.mediumSize}>
                {studentDetails.creditsEarned}
              </p>
            </Col>
            <Col md="auto" className={styles.colWrapper}>
              <p className={styles.smallSize}>Credits spend</p>
              <p className={styles.mediumSize}>
                {studentDetails.creditsSpent === undefined
                  ? 0
                  : studentDetails.creditsSpent}
              </p>
            </Col>
            <Col md="auto" className={styles.colWrapper}>
              <p className={styles.smallSize}>Credits owned</p>
              <p className={styles.mediumSize}>{studentDetails.creditsOwned}</p>
            </Col>
            <Col md="auto" className={styles.colWrapper}>
              <p className={styles.smallSize}>Current course grade</p>
              <p className={styles.mediumSize}>
                {studentDetails.currentCourseGrade === undefined
                  ? 0
                  : studentDetails.currentCourseGrade}
              </p>
            </Col>
            <Col md="auto" className={styles.colWrapper}>
              <p className={styles.smallSize}>Unexcused absences</p>
              <p className={styles.mediumSize}>
                {studentDetails.unexcusedAbsenceNo === undefined
                  ? 0
                  : studentDetails.unexcusedAbsenceNo}
              </p>
            </Col>
            <Col md="auto" className={styles.colWrapper}>
              <p className={styles.smallSize}>Excused absences</p>
              <p className={styles.mediumSize}>
                {studentDetails.excusedAbsenceNo === undefined
                  ? 0
                  : studentDetails.excusedAbsenceNo}
              </p>
            </Col>
          </Row>
          <Row className=" justify-content-left">
            <Col md="auto" className={styles.borderTop + " pt-3"}>
              <Select
                label="Accommondation rate"
                value={studentDetails.accommodationRate}
                onChange={handleOnChangeAccomodationRate}
                values={[
                  { name: "1", id: 1 },
                  { name: "1.5", id: 1.5 },
                  { name: "2", id: 2 },
                  { name: "2.5", id: 2.5 },
                  { name: "3", id: 3 },
                ]}
              />
            </Col>

            <Col md="auto" className={styles.borderTop + " pt-3"}>
              <TextInput
                errorMessage={creditsErrorMessage}
                touched={creditsTouched}
                label="Add Credits"
                name="credits"
                type="number"
                value={credits}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCredits(event.target.value)
                }
              />
            </Col>
            <Col md="auto" className={styles.borderTop + " pt-3"}>
              <TextInput
                errorMessage={noteErrorMessage}
                touched={noteTouched}
                label="Note"
                name="note"
                value={note}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setNote(event.target.value)
                }
              />
            </Col>
            <Col md="auto" className="d-flex align-items-end">
              <Button isExtraSmall={true} title="Add" onClick={addCredits} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StudentInfo;
