import React, { useCallback, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import { useAssignments } from "../../../../stores";
import { AssignmentsForList } from "../../../../models/state.models";
import AssignmentRow from "../AssignmentRow";
import Button from "../../../../common/Button";
import arrayMove from "../ArrayMove";
import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";

const AssignmentTable = ({ list }: any) => {
  const { handleChangeAssignmentOrder } = useAssignments();
  const SortableCont = SortableContainer(({ children }: any) => {
    return <tbody>{children}</tbody>;
  });

  const [items, setItems] = useState(list.entityList);
  const SortableItem = SortableElement((props: any) => (
    <AssignmentRow {...props} />
  ));

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems: any) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  return (
    <>
      <Row>
        <Col sm="auto" className=" pb-3">
          <Button
            name="saveOrder"
            id="saveOrder"
            title={"Save Order"}
            isDialog={true}
            onClick={() => {
              handleChangeAssignmentOrder(items);
            }}
          />
        </Col>
      </Row>
      <table key="assignment-table" className={styles.table}>
        <thead>
          <tr>
            <td>
              <div
                className={styles.headerCell}
                onClick={() => list.changeOrderBy("name")}
              >
                Assignment Title
                {list.orderBy === "name" && (
                  <img
                    src={
                      list.orderByDirection === "desc"
                        ? iconArrowDown
                        : iconArrowUp
                    }
                    alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                    className={styles.arrow}
                  />
                )}
              </div>
            </td>
            <td>Number of Purchases</td>
            <td>Unresolved Purchases</td>
            <td>Average points</td>
            <td>Assignment due date</td>
            <td>Allowed for buyback</td>
            <td />
          </tr>
        </thead>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass={styles.helperContainerClass}
          useDragHandle={true}
        >
          {items.map((assignment: AssignmentsForList, index: any) => (
            <SortableItem
              index={index}
              list={list}
              assignment={assignment}
              key={assignment.id}
            />
          ))}
        </SortableCont>
      </table>
    </>
  );
};

export default AssignmentTable;
