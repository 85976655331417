import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import moment from "moment";

import {
  ListsForSchedulerSelect,
  Scheduler,
  SelectValue,
} from "../../../models/state.models";
import {
  useAuth,
  useDialog,
  useSchedulerStore,
  useSection,
} from "../../../stores";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import CustomModal from "../../../common/CustomModal";
import { addOffset, convertIntToDay } from "../../../utils/timeUtils";
import { SaveSectionRequest } from "../../../models/request.models";
import { SectionSchema } from "./Components/SectionSchema";
import Button from "../../../common/Button";
import ButtonWithImage from "../../../common/ButtonWithImage";
import deleteIcon from "../../../assets/images/delete.svg";
import MultipleSelector from "../../../common/MultipleSelector";
import plusIcon from "../../../assets/images/plus.svg";
import SelectWithLabel from "../../../common/SelectWithLabel";
import TextInput from "../../../common/TextInput";
import TimePicker from "../../../common/TimePicker";
import styles from "./styles.module.scss";

interface CUSectionProps {
  isEdit?: boolean;
}

const CUSection: React.FC<CUSectionProps> = ({ isEdit }) => {
  const { activityList, getPrsenceActivityCodeList } = useSchedulerStore();
  const { sectionId, courseId, academicTermId }: any = useParams();
  const { selectedAcademicTermId, role, setCourseName } = useAuth();
  const history = useHistory();

  const [activityErrorMessage, setActivityErrorMessage] = useState<string>("");
  const [activityTouched, setActivityTouched] = useState<boolean>(false);
  const [filtredActivityList, setFiltredActivityList] = useState<SelectValue[]>(
    []
  );
  const [scheduleId, setScheduleId] = useState<number>(0);
  const [sectionDays, setSectionDays] = useState<string>("1");
  const [sectionTimeFrom, setSectionTimeFrom] = useState<
    MaterialUiPickersDate | string | null
  >(moment("12:00 ", ["h:mm A"]));
  const [sectionTimeTo, setSectionTimeTo] = useState<
    MaterialUiPickersDate | string | null | Date
  >(moment("12:45 ", ["h:mm A"]));
  const [selectedActivity, setSelectedActivity] = useState<number>(-1);
  const [timeFromErrorMessage, setTimeFromErrorMessage] = useState<string>("");
  const [timeFromTouched, setTimeFromTouched] = useState<boolean>(false);
  const [timeToErrorMessage, setTimeToErrorMessage] = useState<string>("");
  const [timeToTouched, setTimeToTouched] = useState<boolean>(false);
  const [ModalVisiblity, setModalVisiblity] = useState(false);
  const [editDay, setEditDay] = useState<number>(-1);
  const [scheduler, setScheduler] = useState<Scheduler>();
  const [deletable, setDeletable] = useState<boolean>(true);

  const {
    addSectionTime,
    addSectionUser,
    getSectionForEdit,
    handleSectionInputChange,
    removeSectionTime,
    removeSectionUser,
    resetSection,
    saveSection,
    section,
  } = useSection();

  const { showDialog } = useDialog();

  useEffect(() => {
    if (role == "Professor") {
      getPrsenceActivityCodeList(courseId);
      if (isEdit) {
        getSectionForEdit(sectionId, courseId).then((resp: any) => {
          if (resp.status === "error" && resp.message) {
            if (
              resp.message.includes("not found") ||
              resp.message.includes("authorized")
            ) {
              showDialog(
                resp.message,
                `/academicTerms/${selectedAcademicTermId}/courses`,
                history,
                true,
                "Errror"
              );
            }
            showDialog(resp.message, null, null, true, "Error");
          }
          if (resp.status === "success") {
            setCourseName(resp.breadCrumb.courseName);
          }
        });
      } else {
        resetSection();
      }
    } else {
      showDialog(
        "User not authorized",
        `/academicTerms/${selectedAcademicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
  }, [getSectionForEdit, sectionId]);

  useEffect(() => {
    setFiltredActivityList(
      activityList.map((activity: ListsForSchedulerSelect) => {
        return { id: activity.codeInt, name: activity.value };
      })
    );
    if (filtredActivityList.length > 0) {
      setSelectedActivity(filtredActivityList[0].id);
    }
  }, [activityList]);

  const addTime = () => {
    let validation = true;
    if (sectionTimeFrom === null) {
      setTimeFromErrorMessage("Enter section start time");
      setTimeFromTouched(true);
      validation = false;
    }
    if (sectionTimeTo === null) {
      setTimeToErrorMessage("Enter section end time");
      setTimeToTouched(true);
      validation = false;
    }
    if (moment(sectionTimeTo).isBefore(moment(sectionTimeFrom))) {
      setTimeToErrorMessage("Hours from can't be bigger than hours to");
      setTimeToTouched(true);
      validation = false;
    }
    if (selectedActivity === -1) {
      setActivityErrorMessage("Select activity");
      setActivityTouched(true);
      validation = false;
    }
    if (validation) {
      setTimeFromErrorMessage("");
      setTimeToErrorMessage("");
      setActivityErrorMessage("");
      setActivityTouched(false);
      setTimeFromTouched(false);
      setTimeToTouched(false);

      addSectionTime({
        dayOfWeek: parseInt(sectionDays),
        hourFrom: sectionTimeFrom,
        hourTo: sectionTimeTo,
        activityID: selectedActivity,
        activityName: findActivityName(selectedActivity),
        id: scheduleId !== 0 ? scheduleId : null,
        isDeletable: deletable,
      });
      setSelectedActivity(-1);
      setSectionTimeFrom(moment("12:00 ", ["h:mm A"]));
      setSectionTimeTo(moment("12:45 ", ["h:mm A"]));
      setSectionDays("1");
    }
  };

  const addAssistantUser = (value: string) => {
    addSectionUser({ email: value, roleCode: "tcAss" });
  };

  const handleSubmit = () => {
    const formatedSchedulesList = section.schedules.map(
      (schedule: Scheduler) => {
        let addTimeOffset = addOffset(
          schedule.hourFrom,
          schedule.hourTo,
          schedule.dayOfWeek
        );
        const newValue: Scheduler = {
          dayOfWeek: addTimeOffset.dayOfWeek,
          hourFrom: addTimeOffset.hourFrom,
          hourTo: addTimeOffset.hourTo,
          id: schedule.id,
          activityID: schedule.activityID,
        };
        return newValue;
      }
    );

    const request: SaveSectionRequest = {
      Title: section.title,
      ValidationTimeBefore: section.validationTimeBefore,
      ValidationTimeAfter: section.validationTimeAfter,
      ParentID: courseId,
      ScheduleList: formatedSchedulesList,
      TeachingAssistanList: section.teachingAssistanList,
      CoInstructorList: section.coInstructorList,
    };

    if (isEdit) {
      request.id = sectionId;
    }

    saveSection(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        if (isEdit) {
          showDialog(
            "Section changed succesfully",
            `/academicTerms/${academicTermId}/courses/${courseId}/sections`,
            history,
            false
          );
        } else {
          showDialog(
            "Section added succesfully",
            `/academicTerms/${academicTermId}/courses/${courseId}/sections`,
            history,
            false
          );
        }
      } else if (resp.status === "success" && resp.message) {
        showDialog(resp.message, null, null, false);
      } else if (resp.status === "error") {
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  };

  const findActivityName = (id: number) => {
    const value = filtredActivityList.find(
      (activity: SelectValue) => activity.id === id
    );
    return value?.name;
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleSectionInputChange(event);
  };

  const editTime = (value: Scheduler) => {
    setSectionTimeFrom(moment(value.hourFrom, ["h:mm A"]));
    setSectionTimeTo(moment(value.hourTo, ["h:mm A"]));
    setSectionDays(String(value.dayOfWeek));
    setScheduler(value);
    setSelectedActivity(value.activityID);
    setScheduleId(value.id);
  };

  return (
    <div>
      <Formik
        initialValues={{ ...section }}
        enableReinitialize
        validationSchema={SectionSchema}
        onSubmit={(values) => {
          handleSubmit();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => {
          return (
            <Form>
              <Row className=" p-0 pt-4 ">
                <Col md={6} className="pr-lg-5">
                  <TextInput
                    onBlur={handleBlur}
                    errorMessage={errors.title}
                    touched={touched.title}
                    label="Section Title"
                    name="title"
                    value={values.title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      textIntputHandleChange(event, handleChange)
                    }
                  />
                  {/*<Row className={styles.inputsHolder + " mt-4"}>
                    <Col md={3} className={styles.singleCol}>
                      <SelectWithLabel
                        label="Days of the Week"
                        value={sectionDays}
                        onChange={(event: any) =>
                          setSectionDays(event.target.value)
                        }
                        values={[
                          { name: "Monday", id: "1" },
                          { name: "Tuesday", id: "2" },
                          { name: "Wednesday", id: "3" },
                          { name: "Thursday", id: "4" },
                          { name: "Friday", id: "5" },
                          { name: "Saturday", id: "6" },
                          { name: "Sunday", id: "7" },
                        ]}
                      />
                    </Col>
                    <Col md={3} className={styles.singleCol}>
                      <SelectWithLabel
                        errorMessage={activityErrorMessage}
                        touched={activityTouched}
                        label="Activity"
                        name="activityId"
                        placeholder="Select activity "
                        value={selectedActivity || ""}
                        onChange={(event: any) => {
                          setSelectedActivity(event.target.value);
                        }}
                        values={filtredActivityList}
                      />
                    </Col>
                    <Col md={3} className={styles.singleCol}>
                      <TimePicker
                        topLabel="From"
                        errorMessage={timeFromErrorMessage}
                        touched={timeFromTouched}
                        name="sectionTimeFrom"
                        onChange={(date: any) => {
                          setSectionTimeFrom(date);
                        }}
                        value={sectionTimeFrom}
                      />
                    </Col>
                    <Col md={3} className={styles.singleCol}>
                      <TimePicker
                        topLabel="To"
                        errorMessage={timeToErrorMessage}
                        touched={timeToTouched}
                        name="sectionTimeTo"
                        onChange={(date: any) => {
                          setSectionTimeTo(date);
                        }}
                        value={sectionTimeTo}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col className="mt-3" md="auto">
                      <ButtonWithImage
                        icon={plusIcon}
                        isSmall={true}
                        title={editDay != -1 ? "update time" : "add time"}
                        type="button"
                        onClick={() => {
                          editDay != -1
                            ? removeSectionTime(scheduler)
                            : setEditDay(-1);
                          addTime();
                          setEditDay(-1);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={
                        styles.listItemWrapper + " mt-3 px-0 mx-3 px-0"
                      }
                    >
                      {section.schedules &&
                        section.schedules.map(
                          (value: Scheduler, index: number) => {
                            findActivityName(value.activityID);
                            return (
                              <div
                                className={
                                  editDay === value.id
                                    ? styles.courseDayEdit
                                    : styles.courseDaySelectedWrapper
                                }
                                key={index}
                              >
                                <p
                                  className={styles.itemTime}
                                  onClick={() => {
                                    setDeletable(
                                      value.isDeletable === undefined
                                        ? true
                                        : value.isDeletable
                                    );
                                    editTime(value);
                                    setEditDay(value.id);
                                  }}
                                >
                                  {value.activityName +
                                    " - " +
                                    convertIntToDay(value.dayOfWeek) +
                                    " - " +
                                    moment(value.hourFrom, ["h:mm A"]).format(
                                      "h:mm A"
                                    ) +
                                    "-" +
                                    moment(value.hourTo, ["h:mm A"]).format(
                                      "h:mm A"
                                    )}
                                </p>
                                <img
                                  alt="Delete"
                                  className={styles.deleteIcon}
                                  src={deleteIcon}
                                  onClick={() => {
                                    setScheduler(value);
                                    setDeletable(
                                      value.isDeletable === undefined
                                        ? true
                                        : value.isDeletable
                                    );
                                    setModalVisiblity(true);
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                    </Col>
                  </Row>*/}
                </Col>
                <Col md={6} className="pl-lg-5">
                  <MultipleSelector
                    textInputLabel="Teaching Assistant"
                    textInputName="assistantEmail"
                    list={section.teachingAssistanList}
                    removeValue={removeSectionUser}
                    firstRowClassName="align-items-baseline"
                    onButtonClick={addAssistantUser}
                  />
                  <Row className="my-3">
                    <Col>
                      <p>Attendance validation window</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextInput
                        errorMessage={errors.validationTimeBefore}
                        touched={touched.validationTimeBefore}
                        label="minutes before"
                        onBlur={handleBlur}
                        name="validationTimeBefore"
                        value={values.validationTimeBefore}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                    <Col md={6}>
                      <TextInput
                        errorMessage={errors.validationTimeAfter}
                        touched={touched.validationTimeAfter}
                        label="minutes after"
                        onBlur={handleBlur}
                        name="validationTimeAfter"
                        value={values.validationTimeAfter}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => textIntputHandleChange(event, handleChange)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-end mx-0 mt-4">
                <Col md="auto" className="px-2 ">
                  <Button
                    isDialogCancel={true}
                    onClick={() => {
                      resetSection();
                      history.goBack();
                    }}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col md="auto" className="px-2 ">
                  <Button
                    isDialog={true}
                    title={isEdit ? "Save" : "Add Section"}
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <CustomModal
        open={ModalVisiblity}
        handleClose={() => setModalVisiblity(false)}
      >
        <ConfirmDeleteModal
          isDeletable={deletable}
          handleCancel={() => setModalVisiblity(false)}
          confirmation={() => {
            setModalVisiblity(false);
            removeSectionTime(scheduler);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default observer(CUSection);
