import { useState, useEffect, useCallback } from "react";

export const useAsync = (
  asyncFunction: {
    ({ isSave }: { isSave: boolean }): Promise<void>;
    (arg0: any): Promise<any>;
  },
  immediate = true
) => {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [parameter, setParameter] = useState(null);
  const clearStatus = () => {
    setStatus("idle");
  };
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (param = null) => {
      setStatus("pending");
      setParameter(param);
      setValue(null);
      setError(null);
      return asyncFunction(param)
        .then((response) => {
          setValue(response);
          setStatus("success");
        })
        .catch((error) => {
          setError(
            error && error.message
              ? error.message
              : error
                ? JSON.stringify(error)
                : error
          );
          setStatus("error");
        });
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute(parameter);
    }
  }, [execute, immediate, parameter]);
  return { execute, status, value, error, clearStatus };
};
