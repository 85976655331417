import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { action, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";

import { SaveCourseRequest } from "../../models/request.models";
import {
  AcademicTerm,
  Avatar,
  Scheduler,
  CourseUser,
  CourseDetails,
  User,
  ApplicationsForList,
} from "../../models/state.models";
import { FetchApi } from "../../utilities";

class CoursesStore {
  @observable status: string = "inital";

  @observable message: string = "";

  @observable selectedCourseTitle: string = "";

  @observable academicTerms: AcademicTerm[] = [];
  @observable academicTermID: number = 0;

  @observable selectedCourseID: number = 0;

  @observable avatarList: Avatar[] = [];

  @observable totalCountOfCourses: number = 0;

  @observable totalCountofSections: number = 0;

  @observable isLoading: boolean = false;

  @observable courseTitle: string = "";

  @observable isClone: boolean = false;

  @observable application: ApplicationsForList = {
    applicationDateTime: "",
    applicationType: "",
    category: "",
    id: 0,
    name: "",
    status: "",
    note: "",
  };

  @observable course: CourseDetails = {
    id: 0,
    title: "",
    startDate: "",
    endDate: "",
    avatarFileID: 1,
    avatarName: "",
    currencyName: "",
    courseCode: "",
    maxBuybackPrice: "",
    courseIdentification: "",
    academicTermID: 0,
    academicTerm: "",
    academicTermName: "",
    academicTermYear: 0,
    validationTimeAfter: "",
    validationTimeBefore: "",
    hasSection: false,
    schedules: [],
    coInstructorList: [],
    teachingAssistanList: [],
    coursUsers: [],
    latestSubmission: [],
    status: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async saveCourse(params: SaveCourseRequest) {
    this.isLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: this.isClone ? "/CourseApi/CopyCourse" : "/CourseApi/SaveCourse",
      params,
    });
    this.isLoading = false;
    if (status === "success" && !message) {
      this.status = status;
      this.message = message;
    }

    return { status, message };
  }

  @action.bound
  async getAcademicTerms() {
    const { status, data, message }: any = await FetchApi({
      method: "POST",
      url: "/AcademicTermApi/GetAcademicTermList",
    });
    if (status === "success") {
      this.academicTerms = data;
    }
    return { status, message };
  }

  @action.bound
  async getCourseForEdit(id: number) {
    this.isLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseForEdit",
      params: { courseId: id },
    });
    this.isLoading = false;
    if (status === "success") {
      this.course = data;
    }

    return { status, message };
  }

  @action.bound
  async getCourseDetails(id: number) {
    this.isLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseDetails",
      params: { courseId: id },
    });
    this.isLoading = false;
    if (status === "success") {
      this.course = data;
      this.courseTitle = data.title;
    }

    return { status, message };
  }

  @action.bound
  async changeStatusForCourse(id: number, courseStatus: number) {
    this.isLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/ChangeCourseStatus",
      params: { CourseID: id, Status: courseStatus },
    });
    this.isLoading = false;
    // if (status === "success") {
    //   this.course = data;
    // }

    return { status, message };
  }

  @action.bound
  async getAvatars() {
    const { data, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetAvatars",
    });
    runInAction(() => {
      if (status === "success") {
        this.avatarList = data;
        this.course.avatarName = data[0].fileName;
        this.course.avatarFileID = data[0].id;
      }
    });

    return { status: status };
  }

  @action.bound
  async deleteCourse(id: number) {
    this.isLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/DeleteCourse",
      params: { courseID: id },
    });
    this.isLoading = false;
    return { status, message };
  }

  @action.bound
  async getApplicationDetails(
    courseId: number,
    ApplicationType: string,
    ID: number
  ) {
    this.isLoading = true;
    const { message, status, data, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/GetStudentApplication",
      params: { courseId, ApplicationType, ID },
    });
    this.isLoading = false;
    if (status === "success") {
      this.application = data;
      this.courseTitle = breadCrumb.courseName;
    }

    return { message: message, status: status };
  }

  @action.bound
  handleDefaultAcademicTerm(academicTerm: AcademicTerm) {
    this.course.academicTermID = academicTerm.id;
    this.course.academicTermName = academicTerm.name;
    this.course.academicTermYear = academicTerm.programYear;
  }

  @action.bound setSelectedCourseID(id: number) {
    this.selectedCourseID = id;
  }

  @action.bound
  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.course[event.target.name] = event.target.value;
  }

  @action.bound
  handleAvatarChange(val: string, id: number) {
    this.course.avatarName = val;
    this.course.avatarFileID = id;
  }

  @action.bound
  handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.course[event.target.name] = event.target.value;
    // * Dates do not have meaning here anymore
    // if (event.target.name === "academicTermID") {
    //   this.academicTerms.map((academicTermTemp: AcademicTerm) => {
    //     if (String(academicTermTemp.id) === event.target.value) {
    //       this.course.startDate = academicTermTemp.start;
    //       this.course.endDate = academicTermTemp.end;
    //     }
    //   });
    // }
  }

  @action.bound
  handleDateChange(date: MaterialUiPickersDate, name: string) {
    this.course[name] = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  addCourseTime(time: Scheduler) {
    this.course.schedules.push(time);
  }

  @action.bound
  removeCourseTime(time: Scheduler) {
    this.course.schedules = this.course.schedules.filter(
      (value: Scheduler) => value !== time
    );
  }

  @action.bound
  addCoInstructor(user: CourseUser) {
    this.course.coInstructorList.push(user);
  }

  @action.bound
  removeCoInstructor(userProp: CourseUser) {
    this.course.coInstructorList = this.course.coInstructorList.filter(
      (user: CourseUser) => user.email !== userProp.email
    );
  }

  @action.bound
  setIsClone(clone: boolean) {
    this.isClone = clone;
  }

  @action.bound
  addTeachingAssistan(user: CourseUser) {
    this.course.teachingAssistanList.push(user);
  }

  @action.bound
  removeTeachingAssistan(userProp: CourseUser) {
    this.course.teachingAssistanList = this.course.teachingAssistanList.filter(
      (user: CourseUser) => user.email !== userProp.email
    );
  }

  @action.bound
  addCourseUser(user: User) {
    this.course.coInstructorList.push(user);
  }

  @action.bound
  removeCourseUser(email: string) {
    this.course.coInstructorList = this.course.coInstructorList.filter(
      (user: User) => user.email !== email
    );
  }
  @action.bound
  setHasSection() {
    this.course.hasSection = !this.course.hasSection;
  }

  @action.bound
  setAcademicTermID(academicTermID: number) {
    this.academicTermID = academicTermID;
  }

  @action.bound
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action.bound
  setSelectedCourseTitle(value: string) {
    this.selectedCourseTitle = value;
  }

  @action.bound
  resetCourse() {
    this.isClone = false;
    this.course = {
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
      avatarFileID: 1,
      avatarName: "",
      currencyName: "",
      courseCode: "",
      maxBuybackPrice: "",
      courseIdentification: "",
      validationTimeBefore: "",
      validationTimeAfter: "",
      academicTermID: 0,
      academicTerm: "",
      academicTermName: "",
      academicTermYear: 0,
      hasSection: false,
      schedules: [],
      coInstructorList: [],
      teachingAssistanList: [],
      latestSubmission: [],
      status: 0,
    };
  }

  @action.bound
  resetApplication() {
    this.application = {
      applicationDateTime: "",
      applicationType: "",
      category: "",
      id: 0,
      name: "",
      status: "",
    };
  }
}

export default CoursesStore;
