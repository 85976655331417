import moment from "moment";
import * as Yup from "yup";

export const AssignmentSchema = Yup.object().shape({
  name: Yup.string().required("Enter assignment name"),

  maxPoints: Yup.number()
    .required("Enter point value")
    .typeError("must be number"),

  dateTo: Yup.string().when("isEntireSemester", {
    is: false,
    then: Yup.string().required("Enter assignment due date"),
  }),

  buyBackDateTo: Yup.string().when("isBuyBack", {
    is: true,
    then: Yup.string().test(
      "is-greater",
      "It must be greater then Assignment due date",
      function (value) {
        const { dateTo } = this.parent;
        return moment(value, "MM-DD-YYYY").isSameOrAfter(
          moment(dateTo, "MM-DD-YYYY")
        );
      }
    ),
  }),

  buyBackNumber: Yup.number().when("isLimitedBuyBacks", {
    is: true,
    then: Yup.number()
      .required("Enter limit for buybacks")
      .typeError("must be number"),
  }),

  minBuyBackPrice: Yup.number().when("isBuyBack", {
    is: true,
    then: Yup.number()
      .required("Enter minimum transaction cost")
      .typeError("must be number"),
  }),

  maxBuyBackPrice: Yup.number().when("isBuyBack", {
    is: true,
    then: Yup.number()
      .min(
        Yup.ref("minBuyBackPrice"),
        "Must be higher than minimum transaction cost"
      )
      .required("Enter maximum transaction cost")
      .typeError("must be number"),
  }),
});
