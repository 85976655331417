import React, { useState } from "react";

import ApplicationsList from "./Components/ApplicationsList";
import AttendanceRecord from "./Components/AttendanceRecord";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import StudentInfo from "./Components/StudentInfo";
import SectionNavigation from "../Components/SectionNavigation";
import StudentNavigation from "./Components/StudentNavigation";
import { Col } from "react-bootstrap";

const StudentPage = () => {
  const [selectedNav, setSelectedNav] = useState(0);

  return (
    <DashboardBase componentName="Student Page">
      <Col className="p-3">
        <Card isFullScreen={false} isWithOutTopMargin={true}>
          <StudentInfo />
        </Card>
        <StudentNavigation selectedNav={setSelectedNav} />

        <Card isFullScreen={false} isCoursesTabs={true}>
          {selectedNav == 0 ? <ApplicationsList /> : <AttendanceRecord />}
        </Card>
      </Col>
    </DashboardBase>
  );
};

export default StudentPage;
