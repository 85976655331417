import React, { useEffect, useState } from "react";
import cc from "classcat";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { useAuth, useScoreStore } from "../../../stores";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import styles from "./styles.module.scss";

interface ScoreBoardProsp {
  title: string;
  isFirst?: boolean;
  isCourse?: boolean;
  isSection?: boolean;
  currency?: string;
}

const ScoreBoard: React.FC<ScoreBoardProsp> = ({
  title,
  isFirst,
  isCourse,
  isSection,
  currency,
}) => {
  const { courseId, sectionId }: any = useParams();
  const {
    dashboardPoints,
    getScoreCalculationDetails,
    resetDashboardPoints,
    setScoreForStudent,
    highestName,
    lowestName,
    groupHighestName,
    groupLowestName,
  } = useScoreStore();
  const { role } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [highest, setHighest] = useState<boolean>(false);

  useEffect(() => {
    if (isCourse && isFirst && role != "Student") {
      getScoreCalculationDetails(courseId);
    }
    if (isSection && isFirst && role != "Student") {
      getScoreCalculationDetails(sectionId);
    }

    if (isCourse && isFirst && role === "Student") {
      setScoreForStudent(courseId);
    }
    return () => {
      resetDashboardPoints();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Col
      md="auto"
      className={cc([
        styles.scoreBoardContainer,
        { [styles.scoreBoardFirstElement]: isFirst },
      ])}
    >
      <h4 className={styles.title + " mb-4"}>{title}</h4>
      <Row>
        <Col md="auto" xs={3} className={styles.singleScoreCol}>
          <p className={styles.scoreLabel}>Highest</p>
          <p
            className={styles.scoreValue}
            onClick={(event: any) => {
              setHighest(true);
              setAnchorEl(event.target);
              setOpen(!open);
            }}
          >
            {isFirst
              ? dashboardPoints && dashboardPoints.individualHighest
              : dashboardPoints && dashboardPoints.groupHighest}
          </p>{" "}
          <div className={styles.hoverValue}></div>
        </Col>
        <Col md="auto" xs={3} className={styles.singleScoreCol}>
          <p className={styles.scoreLabel}>Average</p>
          <p className={styles.scoreValue}>
            {isFirst
              ? dashboardPoints && Math.round(dashboardPoints.individualAverage)
              : dashboardPoints && Math.round(dashboardPoints.groupAverage)}
          </p>

          <div className={styles.hoverValue}></div>
        </Col>
        <Col md="auto" xs={2} className={styles.singleScoreCol}>
          <p className={styles.scoreLabel}>Low</p>
          <p
            className={styles.scoreValue}
            onClick={(event: any) => {
              setHighest(false);
              setAnchorEl(event.target);
              setOpen(!open);
            }}
          >
            {isFirst
              ? dashboardPoints && dashboardPoints.individualLow
              : dashboardPoints && dashboardPoints.groupLow}
          </p>{" "}
          <div className={styles.hoverValue}></div>
        </Col>
        <Col md="auto" xs={4} className={styles.singleScoreCol}>
          <p className={styles.scoreLabel}>
            Overall {currency ? currency : "tokens"}
          </p>
          <p className={styles.scoreValue}>
            {isFirst
              ? dashboardPoints && dashboardPoints.individualOverallPoints
              : dashboardPoints && dashboardPoints.groupOverallPoints}
          </p>{" "}
          <div className={styles.hoverValue}></div>
        </Col>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography>
            <p className={styles.popup}>
              {title === "Individual student"
                ? highest
                  ? highestName
                  : lowestName
                : highest
                ? groupHighestName
                : groupLowestName}
            </p>
          </Typography>
        </Popover>
      </Row>
    </Col>
  );
};

export default observer(ScoreBoard);
