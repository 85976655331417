import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import styles from "./styles.module.scss";

interface CustomModalProps {
  children: React.ReactNode;
  handleClose: () => void;
  open: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  children,
  handleClose,
  open,
}: any) => {
  return (
    <Modal
      BackdropComponent={Backdrop}
      aria-describedby="transition-modal-description"
      aria-labelledby="transition-modal-title"
      className={styles.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
