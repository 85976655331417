import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter user name"),
  lastName: Yup.string().required("Enter user family name"),
  telephone: Yup.number().notRequired().min(1),
});

export const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Enter new password"),
  oldPassword: Yup.string().required("Enter old password"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password do not match")
    .required("Repeat new password"),
});
