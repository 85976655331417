import React from "react";
import { Checkbox, CheckboxProps } from "@material-ui/core";

import styles from "./styles.module.scss";

type CustomCheckboxProps = {
  label?: string;
} & Omit<CheckboxProps, "label">;

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, ...props }) => {
  return (
    <div>
      {label}
      <Checkbox className={styles.checkBox} {...props} />
    </div>
  );
};

export default CustomCheckbox;
