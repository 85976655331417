import * as Yup from "yup";

export const ActivateUserSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is requred"),
  lastName: Yup.string().required("First Name is requred"),
  middleName: Yup.string().notRequired(),
  password: Yup.string()
    .required("Password is required")
    .min(8, " 8 Chars is minimum."),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required"),
});
