import React from "react";
import { Dialog } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";

import Button from "../../../common/Button";
import styles from "./styles.module.scss";

interface MessageBoxWithButtonsProps {
  message: string;
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const MessageBoxWithButtons: React.FC<MessageBoxWithButtonsProps> = ({
  message,
  title,
  handleClose,
  isOpen,
  handleConfirm,
}) => {
  return (
    <Dialog onClose={handleClose} open={isOpen} className={styles.dialog}>
      <Row className="p-4">
        <Col>
          <h4>{title}</h4>

          <p>{message}</p>
          <Row className="justify-content-end + m-0 mt-5">
            <Col md={6}>
              <Button
                isDialogCancel={true}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleClose();
                }}
                title="No"
                type="button"
              />
            </Col>
            <Col md={6}>
              <Button
                isDialog={true}
                title="Yes"
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleConfirm();
                  handleClose();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
};

export default MessageBoxWithButtons;
