import React, { FormEvent, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { EMAIL } from "../../../constants/RegularExpressions";
import { RegistrationRequest } from "../../../models/request.models";
import { useAuth, useDialog } from "../../../stores";
import Button from "../../../common/Button";
import Card from "../../../common/Card";
import SelectWithLabel from "../../../common/SelectWithLabel";
import TextInput from "../../../common/TextInput";

interface CUUserProps {
  isEdit?: boolean;
}

const CUUser: React.FC<CUUserProps> = ({ isEdit }) => {
  const history = useHistory();
  const { userId }: any = useParams();

  const { closeDialog, showDialog } = useDialog();

  const {
    editUser,
    getUser,
    handleInputChange,
    handleSelectChange,
    registerUser,
    resetUser,
    user,
  } = useAuth();

  const validateValues = () => {
    if (!user.firstName || user.firstName.length === 0) {
      alert("Enter First Name");
      return false;
    }
    if (!user.lastName || user.lastName.length === 0) {
      alert("Enter Last Name");
      return false;
    }
    if (!EMAIL.test(user.email)) {
      alert("Enter valid email address");
      return false;
    }
    return true;
  };

  useEffect(() => {
    resetUser();
    if (isEdit) {
      getUser(userId);
    }
  }, [resetUser, getUser, userId, isEdit]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateValues()) {
      const request: RegistrationRequest = {
        Email: user.email,
        FirstName: user.firstName,
        IsActive: user.isActive,
        LastName: user.lastName,
        Telephone: user.telephone,
        UserRoleCode: user.userRoleCode != "" ? user.userRoleCode : "prof",
      };
      if (isEdit) {
        request.ID = userId;

        editUser(request).then((resp: any) => {
          if (resp.status === "success" && !resp.message) {
            showDialog("User successfully updated", `/users`, history, false);
          } else {
            showDialog(resp.message, null, null, false);
          }
        });
      } else {
        registerUser(request).then((resp: any) => {
          if (resp.status === "success" && !resp.message) {
            showDialog("User successfully added", `/users`, history, false);
          } else {
            showDialog(resp.message, null, null, false);
          }
        });
      }
    }
  };

  return (
    <Col className={"pt-4"}>
      <h2>{isEdit ? "Edit User" : "Add User"}</h2>
      <Card>
        <form
          action="#"
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <Col md={6}>
            <Row className="mb-4">
              <Col md={6}>
                <TextInput
                  isEdit={isEdit}
                  label="First Name"
                  name="firstName"
                  value={user.firstName}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  isEdit={isEdit}
                  label="Last Name"
                  name="lastName"
                  value={user.lastName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <TextInput
              isEdit={isEdit}
              label="Email Address"
              name="email"
              type="email"
              value={user.email}
              onChange={handleInputChange}
            />
            <Row>
              <Col className="mt-4">
                <TextInput
                  isEdit={isEdit}
                  label="Mobile (optional)"
                  name="telephone"
                  type="number"
                  value={user.telephone}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              {!isEdit && (
                <Col md={6}>
                  <SelectWithLabel
                    disabled
                    label="Role"
                    name="userRoleCode"
                    onChange={(event: any) => {
                      handleSelectChange(event);
                    }}
                    value="prof"
                    values={[
                      //{ name: "Admin", id: "admin" },
                      { name: "Professor", id: "prof" },
                      { name: "Student", id: "stud" },
                      { name: "Teaching Assistant", id: "tcAss" },
                    ]}
                  />
                </Col>
              )}

              {isEdit && (
                <Col md={6}>
                  <SelectWithLabel
                    label="Status"
                    name="isActive"
                    placeholder="Select status"
                    onChange={(event) => {
                      handleSelectChange(event);
                    }}
                    values={[
                      { name: "Inactive", id: false },
                      { name: "Active", id: true },
                    ]}
                    value={user.isActive ? "true" : "false"}
                  />
                </Col>
              )}
            </Row>
          </Col>

          <Row className="mt-4 justify-content-md-end">
            <Col md={6}>
              <Row className="mt-4 mx-0 pr-3 justify-content-md-end">
                <Col md={4} className="p-2">
                  <Button
                    isDialogCancel={true}
                    onClick={() => {
                      history.goBack();
                    }}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col md={4} className="p-2">
                  <Button
                    isDialog={true}
                    title={isEdit ? "Save" : "Add user"}
                    type="submit"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Card>
    </Col>
  );
};

export default observer(CUUser);
