import React from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SchedulerComponent from "./Components/SchedulerComponent";

const Scheduler = () => {
  const { courseName } = useAuth();

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isScheduler={true}>
        <Card isFullScreen={true} isWithOutTopMargin={true} isNavigation={true} isCoursesTabs={true}>
          <SchedulerComponent isStudent={false} />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(Scheduler);
