import React from "react";
import { observer } from "mobx-react";

import { useAuth, useSection } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import CUSection from "../Components/CUSection";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const EditSection = () => {
  const { courseName } = useAuth();
  const { section } = useSection();

  return (
    <DashboardBase componentName={courseName} location={section.title}>
      <CourseNavigation isSection={true} />
      <Card
        isFullScreen={true}
        isWithOutTopMargin={true}
        isCoursesTabs={true}
        isDetails={true}
      >
        <CUSection isEdit={true} />
      </Card>
    </DashboardBase>
  );
};

export default observer(EditSection);
