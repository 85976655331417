import React from "react";
import { observer } from "mobx-react";

import { useAuth, useGradebookStore } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import GradebookList from "./Components/GradebookList";
import GradebookStudentView from "./Components/GradebookStudentView";

const GradeBook = () => {
  const { role } = useAuth();
  const { courseName } = useGradebookStore();

  return (
    <DashboardBase componentName={courseName ? courseName : "Gradebook"}>
      <CourseNavigation isGradeBook={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isTableFullWidth={true}
          isNavigation={true}
          isCoursesTabs={true}
        >
          {role === "Student" ? <GradebookStudentView /> : <GradebookList />}
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(GradeBook);
