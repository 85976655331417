import React, { ReactNode } from "react";
import cc from "classcat";
import { FormikErrors, FormikTouched } from "formik";

import styles from "./styles.module.scss";

type TextInputProps = {
  errorMessage?:
    | string
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];

  isEdit?: boolean;
  isLogin?: boolean;
  isSearch?: boolean;
  isValEdit?: boolean;
  isInput?: boolean;
  isTimePicker?: boolean;
  children?: ReactNode;
  label?: string;
  maxWidth?: boolean;
  wrapperStyle?: any;
} & React.HTMLProps<HTMLInputElement>;

const TextInput: React.FC<TextInputProps> = ({
  errorMessage,
  touched,
  isEdit,
  isLogin,
  isValEdit,
  isSearch,
  isInput,
  isTimePicker,
  label,
  maxWidth,
  wrapperStyle,
  children,
  ...props
}) => {
  return (
    <div
      className={cc([
        styles.textInputWrapper,
        { [styles.timePickerWrapper]: isTimePicker },
        { [styles.searchWrapper]: isSearch },
        { [styles.validationEdit]: isValEdit },
        { [styles.loginInputWrapper]: isLogin },
        { [wrapperStyle]: wrapperStyle },
      ])}
    >
      <label className={styles.label}>
        <div className=" row align-items-center pl-3">
          {label}
          {children && children}
        </div>
        <input
          className={cc([
            styles.input,
            { [styles.labelMargin]: label },
            { [styles.inputEdit]: isEdit },
            { [styles.inputFile]: isInput },
            { [styles.searchInput]: isSearch },
            { [styles.errorWrapper]: touched && errorMessage },
          ])}
          {...props}
        />
      </label>
      {errorMessage && touched && (
        <p className={styles.errorMsg}>{errorMessage}</p>
      )}
    </div>
  );
};

export default TextInput;
