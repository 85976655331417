import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { SubmittedAssignmentRequestDTO } from "../../../../models/request.models";
import { useGradebookStore, useDialog, useAuth } from "../../../../stores";
import AddAttemptModal from "../AddAttemptModal";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import CustomModal from "../../../../common/CustomModal";
import editIcon from "../../../../assets/images/edit.svg";
import Iconback from "../../../../assets/images/arrow-right.svg";
import plusIcon from "../../../../assets/images/plus.svg";
import styles from "./styles.module.scss";

const StudentAssignmentDetails = () => {
  const history = useHistory();
  const [attemptModalVisibility, setAttemptModalVisibility] = useState(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const { courseId, idAssignment, courseUserID, academicTermId }: any =
    useParams();
  const {
    getGradebookHistory,
    gradebookHistoryDTO,
    setIsEditAttempt,
    resetAssignmentAttempt,
  } = useGradebookStore();

  const { showDialog } = useDialog();
  const { setCourseName } = useAuth();

  useEffect(() => {
    let prepareRequest: SubmittedAssignmentRequestDTO = {
      courseID: Number(courseId),
      assignmentID: Number(idAssignment),
      courseUserID: Number(courseUserID),
    };

    getGradebookHistory(prepareRequest).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${academicTermId}/courses`,
            history,
            true,
            "Error"
          );
        }
        showDialog(resp.message, null, null, true, "Error");
      }
      if (resp.status === "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
    });
  }, [getGradebookHistory]);

  const handlerEdit = (event: any) => {
    let submittedAssignmentID = Number(event.target.id);
    setIsEditAttempt(submittedAssignmentID, courseId);
    setAttemptModalVisibility(true);
  };
  let tempList: any[] = [];
  let emptyList: any[] = [];
  const confirmedList = (item: any) => {
    if (item.passedDate !== "") {
      tempList.push(item);
    } else {
      emptyList.push(item);
    }
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={8}>
          <p className={styles.mainTitle}>
            {gradebookHistoryDTO?.assignmentName}
          </p>
          <p className="mt-4">
            Points:{" "}
            {gradebookHistoryDTO.assignmentPoints
              ? gradebookHistoryDTO.assignmentPoints
              : "No information"}
          </p>
        </Col>
        <Col md={4}>
          <div className={styles.buttonMainWrapper}>
            <ButtonWithImage
              icon={Iconback}
              isDialogCancel={true}
              onClick={() => {
                history.goBack();
              }}
              title="Go back"
              type="button"
            />
          </div>
          <div className={styles.buttonMainWrapper}>
            <ButtonWithImage
              icon={plusIcon}
              title="add new attempt"
              type="button"
              onClick={() => {
                resetAssignmentAttempt();
                setAttemptModalVisibility(true);
              }}
            />
          </div>
          <CustomModal
            open={attemptModalVisibility}
            handleClose={() => setAttemptModalVisibility(false)}
          >
            <AddAttemptModal
              handleCancel={() => setAttemptModalVisibility(false)}
              refreshData={getGradebookHistory}
            />
          </CustomModal>
        </Col>
      </Row>
      <p>{gradebookHistoryDTO?.studentName}</p>
      <table className={styles.mainTable}>
        <thead>
          <tr className={styles.tableWrapper}>
            <td>Assignment History</td>
          </tr>
        </thead>
        <tbody className={styles.mainTableHolder}>
          {gradebookHistoryDTO &&
            gradebookHistoryDTO.pointsList &&
            gradebookHistoryDTO.pointsList.map((pointsList: any) => (
              <>{confirmedList(pointsList)}</>
            ))}

          {emptyList &&
            emptyList.map((pointsList: any) => (
              <>
                <tr>
                  <td>{pointsList.passedDate}</td>
                  <td>
                    {pointsList.points}
                    <span className={`${styles.diffPointsNegative}`}>
                      {pointsList.pointsDifference}
                    </span>
                    <img
                      alt="Edit"
                      className={styles.icon}
                      src={editIcon}
                      id={pointsList.submittedAssignmentID}
                      onClick={handlerEdit}
                    />
                  </td>
                </tr>
              </>
            ))}
          {tempList &&
            tempList.reverse() &&
            tempList.map((pointsList: any, index: number) => (
              <>
                <tr>
                  <td>{pointsList.passedDate}</td>
                  <td>
                    {pointsList.points}
                    <span
                      className={
                        pointsList.pointsDifference > 0
                          ? `${styles.diffPoints}`
                          : `${styles.diffPointsNegative}`
                      }
                    >
                      {index !== tempList.length - 1 &&
                      pointsList.pointsDifference > 0
                        ? "+"
                        : ""}
                      {index !== tempList.length - 1 &&
                        pointsList.pointsDifference}
                    </span>
                    <img
                      alt="Edit"
                      className={styles.icon}
                      src={editIcon}
                      id={pointsList.submittedAssignmentID}
                      onClick={handlerEdit}
                    />
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default observer(StudentAssignmentDetails);
