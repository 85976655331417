import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

import Button from "../../../../common/Button";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import MultipleSelector from "../../../../common/MultipleSelector";
import deleteIcon from "../../../../assets/images/delete.svg";
import TimePicker from "../../../../common/TimePicker";
import DatePicker from "../../../../common/DatePicker";
import { ScheduleSchema } from "./ScheduleSchema";
import { SaveScheduleRequest } from "../../../../models/request.models";
import { ListsForSchedulerSelect } from "../../../../models/state.models";
import CustomCheckbox from "../../../../common/CustomCheckbox";
import {
  useAuth,
  useDialog,
  useSchedulerStore,
  useSection,
} from "../../../../stores";
import styles from "./styles.module.scss";
import { addUtcOffset } from "../../../../utils/timeUtils";

interface CUScheduleModalProps {
  handleCancel: () => void;
  handleRefresh: () => void;
  handleDelete: () => void;
}

const CUScheduleModal: React.FC<CUScheduleModalProps> = ({
  handleCancel,
  handleRefresh,
  handleDelete,
}) => {
  const { role } = useAuth();
  const { courseId }: any = useParams();

  const [isStudent, setIsStudent] = useState(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [timeToErrorMessage, setTimeToErrorMessage] = useState<string>("");

  const {
    activityList,
    getPrsenceActivityCodeList,
    getSectionList,
    handleDateChange,
    handleInputChange,
    handleSectionTitleChange,
    handleSelectChange,
    handleTimeInputChange,
    resetSchedule,
    saveEvent,
    schedule,
    sectionList,
    getScheduleDay,
    handleCheckoBoxChange,
    addSchedulerUser,
    removeSchedulerUser,
    getAssitantsList,
  } = useSchedulerStore();

  const [filtredSectionList, setFiltredSectionList] = useState([]);
  const [filtredActivityList, setFiltredActivityList] = useState([]);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);

  const { addSectionUser, removeSectionUser, section } = useSection();

  const { showDialog } = useDialog();

  const addAssistantUser = (value: string) => {
    addSchedulerUser({ email: value, roleCode: "tcAss" });
  };

  const validate = () => {
    setIsValidated(true);
    if (moment(schedule.hourTo).isBefore(moment(schedule.hourFrom))) {
      setTimeToErrorMessage("Hours from can't be bigger than hours to");
      setIsValidated(false);
    }
  };

  const handleSubmit = () => {
    if (isValidated) {
      const requestData: SaveScheduleRequest = {
        CourseID: courseId,
        HourFrom: addUtcOffset(schedule.hourFrom),
        HourTo: addUtcOffset(schedule.hourTo),
        WorkDate: schedule.date,
        TeachingAssistanList: schedule.teachingAssistant,
      };
      if (schedule.id !== -1) {
        requestData.ScheduleDayID = schedule.id;
      }
      if (schedule.activityId !== -1) {
        requestData.ActivityID = schedule.activityId;
      }
      if (schedule.scheduleDayType !== -1) {
        requestData.Type = schedule.scheduleDayType;
      }
      if (schedule.sectionId !== -1) {
        requestData.SectionID = schedule.sectionId;
      }
      saveEvent(requestData).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog("Event Saved", null, null, false);

          handleRefresh();
          handleCancel();
        } else {
          showDialog(resp.message, null, null, true, "Error");
        }
      });
    }
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleInputChange(event);
  };

  useEffect(() => {
    if (role !== "Student") {
      getPrsenceActivityCodeList(courseId);
      getSectionList(courseId);

      if (schedule.id !== -1) {
        setIsSelectDisabled(true);
        getScheduleDay(courseId, schedule.id);
      }
    } else {
      setIsStudent(true);
    }
    return () => {
      resetSchedule();
    };
  }, []);

  useEffect(() => {
    setFiltredSectionList(
      sectionList.map((section: ListsForSchedulerSelect) => {
        return { id: section.codeInt, name: section.value };
      })
    );
  }, [sectionList]);

  useEffect(() => {
    setFiltredActivityList(
      activityList.map((activity: ListsForSchedulerSelect) => {
        return { id: activity.codeInt, name: activity.value };
      })
    );
  }, [activityList]);

  useEffect(() => {
    getAssitantsList(schedule.sectionId);
  }, [schedule.sectionId]);

  return (
    <Formik
      initialValues={{ ...schedule }}
      enableReinitialize
      validationSchema={ScheduleSchema}
      onSubmit={(values) => {
        validate();
        handleSubmit();
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur,
      }) => {
        return (
          <div className={styles.modalWrapper}>
            <Form>
              <Row className=" p-4 ">
                <Col md={isStudent ? 12 : 6} className="pr-5">
                  <Row className="justify-content-between">
                    <Col md={11}>
                      <h2>Title: {values.subject}</h2>
                    </Col>
                    <Col md={1}>
                      {isStudent && (
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          className={styles.delete}
                          onClick={handleCancel}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={6}>
                      <DatePicker
                        onBlur={handleBlur}
                        value={moment(values.date, "MM/DD/YYYY") || null}
                        labelPar="Date"
                        name="date"
                        disabled={isStudent}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        touched={touched.date}
                        errorMessage={errors.date}
                        placeholder="mm/dd/yyyy"
                        onChange={(date) => {
                          setFieldValue("date", date);
                          handleDateChange(date);
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <TimePicker
                        topLabel="From"
                        name="hourFrom"
                        disabled={isStudent}
                        errorMessage={errors.hourFrom}
                        touched={touched.hourFrom}
                        onChange={(date: any) => {
                          handleTimeInputChange(date, "hourFrom");
                          setFieldValue("hourFrom", date);
                        }}
                        value={values.hourFrom}
                      />
                    </Col>
                    <Col md={3}>
                      <TimePicker
                        topLabel="To"
                        errorMessage={timeToErrorMessage}
                        touched={!isValidated}
                        disabled={isStudent}
                        name="hourTo"
                        onChange={(date: any) => {
                          handleTimeInputChange(date, "hourTo");
                          setFieldValue("hourTo", date);
                        }}
                        value={values.hourTo}
                      />
                    </Col>
                  </Row>
                  {!isStudent && (
                    <Row className="mt-4">
                      <Col>
                        <SelectWithLabel
                          errorMessage={errors.sectionId}
                          touched={touched.sectionId}
                          label="Sections"
                          name="sectionId"
                          disabled={isSelectDisabled}
                          placeholder="Select section"
                          value={values.sectionId}
                          onChange={(event: any) => {
                            handleSectionTitleChange(event.target.value);
                            setFieldValue("sectionId", event.target.value);
                            handleSelectChange(event);
                          }}
                          values={filtredSectionList}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                {!isStudent && (
                  <Col md={6} className="pl-5">
                    <Row>
                      <Col>
                        <SelectWithLabel
                          errorMessage={errors.sectionId}
                          touched={touched.sectionId}
                          label="Attendance Activity"
                          name="activityId"
                          placeholder="Select activity "
                          value={values.activityId || ""}
                          onChange={(event: any) => {
                            setFieldValue("activityId", event.target.value);
                            handleSelectChange(event);
                          }}
                          values={filtredActivityList}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col>
                        <CustomCheckbox
                          checked={
                            schedule.scheduleDayType === 1 ? false : true
                          }
                          label="Is not attendance"
                          name="isExtraCredit"
                          onChange={(event: any) =>
                            handleCheckoBoxChange(event)
                          }
                        />
                      </Col>
                    </Row>

                    <MultipleSelector
                      textInputLabel="Teaching Assistant"
                      textInputName="assistantEmail"
                      list={schedule.teachingAssistant}
                      removeValue={removeSchedulerUser}
                      firstRowClassName="align-items-baseline mt-4"
                      onButtonClick={(value: string) => {
                        addSchedulerUser({ email: value, roleCode: "tcAss" });
                      }}
                    />
                  </Col>
                )}
              </Row>
              {!isStudent && (
                <Row className="justify-content-end mx-0 mt-4">
                  <Col md={2} className="px-2 ">
                    <Button
                      isDeny={true}
                      onClick={handleDelete}
                      title="Delete"
                      type="button"
                    />
                  </Col>
                  <Col md={2} className="px-2 ">
                    <Button
                      isDialogCancel={true}
                      onClick={handleCancel}
                      title="Cancel"
                      type="button"
                    />
                  </Col>
                  <Col md={2} className="px-2 ">
                    <Button isDialog={true} title="Save" type="submit" />
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default observer(CUScheduleModal);
