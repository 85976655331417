import moment from "moment";
import { Event } from "../../models/state.models";

export const convertToAmPm = (val: any) => {
  var timeSplit = val.split(":"),
    hours,
    minutes,
    meridian;
  hours = timeSplit[0];
  minutes = timeSplit[1];
  if (hours > 12) {
    meridian = "PM";
    hours -= 12;
  } else if (hours < 12) {
    meridian = "AM";
    if (hours === 0) {
      hours = 12;
    }
  } else {
    meridian = "PM";
  }
  return hours + ":" + minutes + " " + meridian;
};

export const convertIntToDay = (value: number) => {
  switch (value) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
  }
};

export const UseRemoveUtcOffsetWithDate = (
  endDate: any,
  time: any,
  startDate: any,
  timeStart: any
) => {
  let convertedStartTime = moment(
    startDate + " " + moment(timeStart, ["hh:mm"]).format("h:mm A"),
    ["MM.DD.YYYY h:mm A "]
  );
  let convertedEndTime = moment(
    endDate + " " + moment(time, ["hh:mm"]).format("h:mm A"),
    ["MM.DD.YYYY h:mm A "]
  );

  let tempGMTTime = convertedEndTime
    .subtract(moment().utcOffset(), "minutes")
    .format("hh:mm");
  let tempGMTEndDate = convertedEndTime
    .subtract(moment().utcOffset())
    .format("MM.DD.YYYY");
  let tempGMTTimeStart = convertedStartTime
    .subtract(moment().utcOffset(), "minutes")
    .format("hh:mm");
  let tempGMTStartDate = convertedStartTime
    .subtract(moment().utcOffset())
    .format("MM.DD.YYYY");

  return {
    endDate: tempGMTEndDate,
    time: tempGMTTime,
    startDate: tempGMTStartDate,
    timeStart: tempGMTTimeStart,
  };
};

export const UseAddUtcOffsetWithDate = (
  endDate: any,
  time: any,
  startDate: any,
  timeStart: any
) => {
  let convertedEndTime = moment(
    endDate + " " + moment(time, ["hh:mm"]).format("h:mm A"),
    ["MM.DD.YYYY h:mm A "]
  );
  let convertedStartTime = moment(
    startDate + " " + moment(timeStart, ["hh:mm"]).format("h:mm A"),
    ["MM.DD.YYYY h:mm A "]
  );

  let tempGMTTime = convertedEndTime
    .add(moment().utcOffset(), "minutes")
    .format("hh:mm A");
  let tempGMTEndDate = convertedEndTime
    .add(moment().utcOffset())
    .format("MM.DD.YYYY");
  let tempGMTTimeStart = convertedStartTime
    .add(moment().utcOffset(), "minutes")
    .format("hh:mm A");
  let tempGMTStartDate = convertedStartTime
    .add(moment().utcOffset())
    .format("MM.DD.YYYY");

  return {
    endDate: tempGMTEndDate,
    time: tempGMTTime,
    startDate: tempGMTStartDate,
    timeStart: tempGMTTimeStart,
  };
};

export const addUtcOffset = (time: any) => {
  let tempTime = moment(time, ["h:mm a"])
    .subtract(moment().utcOffset(), "minutes")
    .format("HH:mm");
  return tempTime;
};

export const removeUtcOffset = (time: any) => {
  let tempTime = moment(time, "h:mm A").add(moment().utcOffset(), "minutes");
  return tempTime;
};

export const removeActivityUtcOffset = (time: any) => {
  let test = time.substr(0, 10);
  let test2 = time.substr(11, 5);

  let convertedDate = moment(
    test + " " + moment(test2, ["hh:mm"]).format("h:mm A"),
    ["MM-DD-YYYY h:mm A "]
  );

  let GTMDate = convertedDate
    .add(moment().utcOffset(), "minutes")
    .format("MM-DD-YYYY hh:mm ");

  return GTMDate;
};

export const addOffset = (
  tempHourFrom: any,
  tempHourTo: any,
  dayOfWeek: number
) => {
  var offset = new Date().getTimezoneOffset();
  let moveDay = false;
  let temp1 = moment(tempHourFrom, ["h:mm a"]).subtract(
    moment().utcOffset(),
    "minutes"
  );

  let hourFrom = moment(tempHourFrom, ["h:mm a"])
    .subtract(moment().utcOffset(), "minutes")
    .format("HH:mm");

  let hourTo = moment(tempHourTo, ["h:mm a"])
    .subtract(moment().utcOffset(), "minutes")
    .format("HH:mm");

  if (offset > 0) {
    if (moment(tempHourFrom).hour() >= moment(temp1).hour()) {
      moveDay = true;
    }
  } else if (offset < 0) {
    if (moment(tempHourFrom).hour() <= moment(temp1).hour()) {
      moveDay = true;
    }
  }

  if (moveDay) {
    if (offset > 0) {
      if (dayOfWeek !== 7) {
        dayOfWeek += 1;
      } else {
        dayOfWeek = 1;
      }
    } else if (offset < 0) {
      if (dayOfWeek !== 1) {
        dayOfWeek -= 1;
      } else {
        dayOfWeek = 7;
      }
    }
  }

  return { hourFrom, hourTo, dayOfWeek };
};

export const removeOffset = (
  tempHourFrom: any,
  tempHourTo: any,
  dayOfWeek: number
) => {
  var offset = new Date().getTimezoneOffset();
  let moveDay = false;
  let temp1 = moment(tempHourFrom, "h:mm a").add(
    moment().utcOffset(),
    "minutes"
  );

  let hourFrom = moment(tempHourFrom, "h:mm a").add(
    moment().utcOffset(),
    "minutes"
  );

  let hourTo = moment(tempHourTo, "h:mm a").add(
    moment().utcOffset(),
    "minutes"
  );

  if (offset > 0) {
    if (moment(tempHourFrom, "h:mm A").hour() < moment(temp1).hour()) {
      moveDay = true;
    }
  }
  if (offset < 0) {
    if (moment(tempHourFrom, "h:mm A").hour() > moment(temp1).hour()) {
      moveDay = true;
    }
  }

  if (moveDay) {
    if (offset > 0) {
      if (dayOfWeek !== 1) {
        dayOfWeek -= 1;
      } else {
        dayOfWeek = 7;
      }
    } else if (offset < 0) {
      if (dayOfWeek !== 7) {
        dayOfWeek += 1;
      } else {
        dayOfWeek = 1;
      }
    }
  }

  return { hourFrom, hourTo, dayOfWeek };
};

export const schedulerTimeFromRemoveUtcOffset = (e: Event, isFrom: boolean) => {
  let dateTemp;
  if (isFrom) {
    dateTemp = new Date(
      moment(e.workDate).year(),
      parseInt(moment(e.workDate).format("MM")) - 1,
      parseInt(moment(e.workDate).format("DD")),
      moment(e.workDate + " " + e.hourFrom).hours(),
      moment(e.workDate + " " + e.hourFrom).minutes()
    );
  } else {
    dateTemp = new Date(
      moment(e.workDate).year(),
      parseInt(moment(e.workDate).format("MM")) - 1,
      parseInt(moment(e.workDate).format("DD")),
      moment(e.workDate + " " + e.hourTo).hours(),
      moment(e.workDate + " " + e.hourTo).minutes()
    );
  }
  let newDateTemp = moment(dateTemp).add(moment().utcOffset(), "minutes");
  return moment(newDateTemp).toDate();
};

export const addWorkDayOffset = (tempStartDate: any) => {
  let convertedStartTime = moment(tempStartDate);
  let workDate = convertedStartTime.add(moment().utcOffset(), "minutes");
  return workDate;
};
