import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import cc from "classcat";

import { BASE_URL } from "../../../../utilities";
import CustomCheckbox from "../../../../common/CustomCheckbox";
import defaultUser from "../../../../assets/images/default-user.png";
import editIcon from "../../../../assets/images/edit.svg";
import viewIcon from "../../../../assets/images/view.svg";
import addedDocument from "../../../../assets/images/added-document.svg";
import addedDouble from "../../../../assets/images/added-double.svg";
import noDocument from "../../../../assets/images/no-document.svg";
import { removeActivityUtcOffset } from "../../../../utils/timeUtils";
import CustomModal from "../../../../common/CustomModal";
import FileModal from "../FileModal";
import styles from "./styles.module.scss";

interface ActivityStudentRowProps {
  student: any;
}

const ActivityStudentTableRow: React.FC<ActivityStudentRowProps> = ({
  student,
}) => {
  const { courseId, academicTermId, activityId }: any = useParams();
  const history = useHistory();

  const [ModalVisiblity, setModalVisiblity] = useState(false);

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/students/${student.id}/confirmation`
    );
  };

  const status = () => {
    switch (student.status) {
      case 0:
        return "Pending";
      case 1:
        return "System Validated";
      case 2:
        return "System Rejected";
      case 3:
        return "Professor Validated";
      case 4:
        return "Professor Rejected";
      default:
        break;
    }
  };

  return (
    <>
      <tr>
        <td className={styles.tableCellProfileImage} data-label="ID">
          <img
            className={styles.profileIcon}
            src={
              student.imageFileName
                ? `${BASE_URL}File/GetFile/${student.imageFileName}`
                : defaultUser
            }
            onClick={() => onTableDetailsClick()}
          />
        </td>

        <td
          className={styles.tableCell}
          data-label="Name"
          onClick={() => onTableDetailsClick()}
        >
          {student.studentName}
        </td>

        <td
          className={styles.tableCell}
          data-label="Date"
          onClick={() => onTableDetailsClick()}
        >
          {removeActivityUtcOffset(student.submittedDate)}
        </td>
        <td
          className={cc([
            styles.tableCell,
            {
              [styles.pendingColor]: student.status === 0,
            },
            {
              [styles.redText]: student.status === 2 || student.status === 4,
            },
            {
              [styles.greenText]: student.status === 1 || student.status === 3,
            },
          ])}
          data-label="Validation Status"
          onClick={() => onTableDetailsClick()}
        >
          {status()}
        </td>
        <td className={styles.iconCell} data-label="Documents">
          {student.fileList[1] ? (
            <img
              alt="DocumentIcon"
              className={styles.fileIcon}
              src={addedDouble}
            />
          ) : student.fileList[0] ? (
            <img
              alt="DocumentIcon"
              className={styles.clickableFileIcon}
              src={addedDocument}
              onClick={() => setModalVisiblity(true)}
            />
          ) : (
            <img
              alt="DocumentIcon"
              className={styles.fileIcon}
              src={noDocument}
            />
          )}
        </td>

        <td>
          <div className={styles.iconCell}>
            <img
              alt="Edit"
              className={styles.icon}
              src={editIcon}
              onClick={() => onTableDetailsClick()}
            />
            <img
              alt="View"
              className={styles.icon}
              src={viewIcon}
              onClick={() => onTableDetailsClick()}
            />
          </div>
        </td>
        <CustomModal
          handleClose={() => setModalVisiblity(false)}
          open={ModalVisiblity}
        >
          <FileModal value={student.fileList[0]?.fileName} />
        </CustomModal>
      </tr>
    </>
  );
};

export default ActivityStudentTableRow;
