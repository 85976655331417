import React from "react";

import { BASE_URL } from "../../../../utilities";
import styles from "./styles.module.scss";

interface FileModalProps {
  value: string;
}

const FileModal: React.FC<FileModalProps> = ({ value }) => {
  return (
    <div className={styles.fileWrapper + " p-4"}>
      <iframe
        src={`${BASE_URL}File/GetFile/${value}.`}
        title="Document preview"
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
};

export default FileModal;
