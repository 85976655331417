import React from "react";
import { MenuItem } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import Select, { SelectProps } from "@material-ui/core/Select";
import cc from "classcat";

import { convertIntToDay } from "../../utils/timeUtils";
import styles from "./styles.module.scss";

export type SelectInputProps = {
  errorMessage?:
    | string
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  isAcademicTerm?: boolean;
  isAttendance?: boolean;
  isAvatar?: boolean;
  isCoTeach?: boolean;
  isCourseCode?: boolean;
  isLogin?: boolean;
  isSectionTitle?: boolean;
  label?: string;
  placeholder?: any;
  values?: any;
  isSmall?: boolean;
} & SelectProps;

const SelecWithLabel: React.FC<SelectInputProps> = ({
  errorMessage,
  isAttendance,
  isAvatar,
  isCoTeach,
  isCourseCode,
  isLogin,
  isSectionTitle,
  label,
  placeholder,
  tableInput,
  touched,
  values,
  isSmall,
  ...props
}: any) => {
  return (
    <div className={styles.selectContainer}>
      <label
        className={cc([styles.label, { [styles.loginSelectWrapper]: isLogin }])}
      >
        {label}
        <Select
          className={cc([styles.select, { [styles.smallSelect]: isSmall }])}
          {...props}
        >
          {placeholder && (
            <MenuItem
              //className={styles.defaultOption}
              selected={true}
              value="-1"
              disabled
            >
              {placeholder}
            </MenuItem>
          )}

          {values &&
            values.map((value: any, index: number) => (
              <MenuItem value={value.id} key={index} className={styles.item}>
                {/* {!isAvatar && isCoTeach ? value.fullName : value.name} */}
                {isAvatar ? value.description : value.name}
                {isCourseCode && value.value}
                {isSectionTitle && value.title}
                {isAttendance && (
                  <div className=" d-inline-flex">
                    <b>
                      {value.workDate} | {convertIntToDay(value.dayOfWeek)} -
                    </b>
                    &nbsp;
                    <p className={styles.greenText}>
                      {value.hourFrom} - {value.hourTo}
                    </p>
                  </div>
                )}
              </MenuItem>
            ))}
        </Select>
        <div className={styles.selectHolder}>
          {errorMessage && touched && (
            <p className={styles.errorMsg}>{errorMessage}</p>
          )}
        </div>
      </label>
    </div>
  );
};

export default SelecWithLabel;
