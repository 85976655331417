import React from "react";
import { Checkbox } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { Student } from "../../../../models/state.models";
import { useActivities } from "../../../../stores";
import styles from "./styles.module.scss";

interface StudentItemProps {
  student: Student;
}

const StudentItem: React.FC<StudentItemProps> = ({ student }) => {
  const { handleGroupCheckboxChange, validationSubmit } = useActivities();
  return (
    <li className={styles.listItem}>
      <Checkbox
        className={styles.checkBox}
        checked={validationSubmit.selectedStudents.includes(student)}
        id={student.id.toString()}
        onChange={(event: any) => {
          handleGroupCheckboxChange(event, student);
        }}
      />
      <p>{student.fullName ? student.fullName : student.email}</p>
    </li>
  );
};

export default observer(StudentItem);
