import React from "react";

import { UserForList } from "../../../../models/state.models";
import editIcon from "../../../../assets/images/edit.svg";

import check from "../../../../assets/images/check.svg";

import styles from "./styles.module.scss";
import { useAuth, useDialog } from "../../../../stores";
import { useHistory } from "react-router-dom";
import { RegistrationRequest } from "../../../../models/request.models";
interface TableRowProps {
  userfromlist: UserForList;
  list?: any;
}

const TableRow: React.FC<TableRowProps> = ({
  userfromlist: userfromlist,
  list,
}) => {
  const { role, editUser, getCurrentUser } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  const activateUser = () => {
    const request: RegistrationRequest = {
      ID: userfromlist.id,
      Email: userfromlist.email,
      FirstName: userfromlist.firstName,
      IsActive: true,
      LastName: userfromlist.lastName,
      Telephone: userfromlist.telephone,
      UserRoleCode: userfromlist.userRoleCode,
    };

    editUser(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        getCurrentUser();
        showDialog("User successfully activated", null, null, false);
        list.refresh();
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };
  return (
    <>
      <tr>
        <td data-label="Firstname">{userfromlist.firstName}</td>
        <td data-label="Lastname">{userfromlist.lastName}</td>
        <td data-label="Email Address">{userfromlist.email}</td>
        <td data-label="Mobile">{userfromlist.telephone}</td>
        <td data-label="Role">{userfromlist.userRole}</td>
        <td data-label="Status">
          {userfromlist.isActive ? (
            <p className={styles.active}>
              {" "}
              <b>Active</b>
            </p>
          ) : (
            <p className={styles.inactive}> Inactive </p>
          )}
        </td>
        <td>
          {role === "Professor" && (
            <div className={styles.iconCell}>
              {!userfromlist.isActive && (
                <img
                  alt="Activate"
                  className={styles.icon}
                  src={check}
                  onClick={(e) => {
                    e.stopPropagation();
                    activateUser();
                  }}
                />
              )}
              <img
                alt="Edit"
                className={styles.icon}
                src={editIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/user/edit/${userfromlist.id}`);
                }}
              />
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default TableRow;
