import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";

import {
  CourseUserCourseGroupList,
  SectionGroupForList,
} from "../../../../../../models/state.models";
import {
  useDialog,
  useSectionGroups,
  useAuth,
  useScreenSize,
} from "../../../../../../stores";
import editIcon from "../../../../../../assets/images/edit.svg";
import arrowDownIcon from "../../../../../../assets/images/arrow-down-gray.svg";
import arrowUpIcon from "../../../../../../assets/images/arrow-up-gray.svg";
import MessageBoxWithButtons from "../../../../../Components/MessageBoxWithButtons";
import StudentSecondaryGroupItem from "../StudentSecondaryGroupItem";
import trashIcon from "../../../../../../assets/images/trash.svg";
import styles from "./styles.module.scss";

interface SecondaryGroupProps {
  group: SectionGroupForList;
  openModalHandler: (value: boolean) => void;
  openMoveStudentModal: () => void;
}

const SecondaryGroup: React.FC<SecondaryGroupProps> = ({
  group,
  openModalHandler,
  openMoveStudentModal,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { courseId, sectionId }: any = useParams();
  const { deleteSectionGroup, setSectionGroup, setIsGroupDataEdit } =
    useSectionGroups();
  const { role } = useAuth();
  const { showDialog } = useDialog();

  const deleteGroup = () => {
    deleteSectionGroup(group.id, courseId, sectionId).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Group is successfully deleted", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  const handleConfirm = () => {
    deleteGroup();
  };

  const handlerEditGroup = () => {
    setSectionGroup(group);
    setIsGroupDataEdit(true);
    openModalHandler(true);
  };

  const [isListVisible, setIsListVisible] = useState(false);
  const { isTabs } = useScreenSize();
  return (
    <>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete ${group.name} group?`}
        handleClose={() => setIsDialogOpen(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isDialogOpen}
      />
      <div className={styles.card + " p-4"}>
        <Row className=" mb-2 mt-0">
          <Col md={10} xs="auto">
            {group.groupLabel !== "" ? (
              <div
                className={styles.colorLabel + " mb-3"}
                style={{ background: group.groupLabel }}
              ></div>
            ) : (
              ""
            )}
          </Col>
          <Col md={2} xs="auto">
            <span>
              <h4>{group.isAccessibility === true ? "AG" : ""}</h4>
            </span>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={8} xs="auto">
            <h2 className={styles.groupName}>{group.name}</h2>
          </Col>
          {role !== "Assistant" && (
            <>
              <Col md={2} xs="auto">
                <img
                  src={editIcon}
                  className={styles.icon}
                  alt="edit"
                  onClick={handlerEditGroup}
                />
              </Col>
              <Col md={2} xs="auto">
                <img
                  className={styles.iconWrapper}
                  src={trashIcon}
                  onClick={() => setIsDialogOpen(true)}
                  alt="Delete"
                />
              </Col>
            </>
          )}
          {!isTabs && (
            <Col xs="auto">
              <img
                className={styles.iconWrapper}
                src={isListVisible ? arrowUpIcon : arrowDownIcon}
                onClick={() => setIsListVisible(!isListVisible)}
                alt={isListVisible ? "hide" : "show"}
              />
            </Col>
          )}
        </Row>
        {(isListVisible || isTabs) && (
          <>
            <Row className="m-0 mt-4">
              <h4>{group.type === 1 ? "Primary" : "Secondary"}</h4>
            </Row>
            <Row>
              <Col>
                <ul className={styles.studentListPrimary + " mt-1"}>
                  {group.courseUserCourseGroupList?.map(
                    (item: CourseUserCourseGroupList) => (
                      <StudentSecondaryGroupItem
                        key={item.id}
                        studentGroup={item}
                        openMoveStudentModal={openMoveStudentModal}
                      />
                    )
                  )}
                </ul>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default observer(SecondaryGroup);
