import styles from "./styles.module.scss";
import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import TableRow from "../TableRow";
import { UserForList } from "../../../../models/state.models";
const UserTable = ({ list }: any) => {
  return (
    <table key="user-table" className={styles.table}>
      <thead>
        <tr>
          <td> FirstName</td>
          <td> Lastname </td>
          <td> Email </td>
          <td> Phone </td>
          <td> Role </td>
          <td> Status </td>
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((user: UserForList) => (
            <TableRow list={list} userfromlist={user} />
          ))}
      </tbody>
    </table>
  );
};

export default UserTable;
