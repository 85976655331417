import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useActivities } from "../../../../../stores";
import editIcon from "../../../../../assets/images/edit.svg";
import styles from "../styles.module.scss";

const ValidatonTermsTable = (props: any) => {
  const history = useHistory();
  const { courseId, activityId, academicTermId, sectionId }: any = useParams();
  const {
    activity,
    activityValidation,
    selectedActivitySection,
    getActivityValidation,
  } = useActivities();

  const validationTerms = activity.validationTermList.filter(
    (item: any) => item.sectionID == selectedActivitySection
  );

  useEffect(() => {
    if (selectedActivitySection !== 0) {
      try {
        getActivityValidation(courseId, selectedActivitySection, activityId);
      } catch (e) {}
    }
  }, [selectedActivitySection]);

  return (
    <Row>
      <Col>
        {activityValidation.validationTermList.length === 0 &&
          !activity.isPresence && (
            <>
              <Row className="mt-4">
                <Col md="auto" className={styles.tableCellWidth + " mx-5"}>
                  1.
                </Col>
                <Col md="auto" className={styles.tableCellWidth + " mx-5"}>
                  <p
                    className={styles.cursorPointer}
                    onClick={() => {
                      history.push(
                        `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/activitySectionsValidation/${selectedActivitySection}`
                      );
                    }}
                  >
                    <u>Add validation</u>
                  </p>
                </Col>

                <div className={styles.listLimitWrapper + " col-12 "}></div>
              </Row>
            </>
          )}
        {activityValidation.validationTermList &&
          activityValidation.validationTermList.map(
            (item: any, index: number) => (
              <>
                <Row className="mt-4">
                  <Col md="auto" className={styles.tableCellWidth + " mx-5"}>
                    {activity.isPresence ? item.scheduleDate : index + 1 + "."}
                  </Col>
                  <Col md="auto" className={styles.tableCellWidth + " mx-5"}>
                    {item.validationTerm ? (
                      <p className={styles.textColorGreen}>
                        {item.validationTerm}
                      </p>
                    ) : (
                      <p
                        className={styles.cursorPointer}
                        onClick={() => {
                          history.push(
                            `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/activitySectionsValidation/${selectedActivitySection}`
                          );
                        }}
                      >
                        <u>Add validation</u>
                      </p>
                    )}
                  </Col>
                  <Col md="auto" className="mx-5">
                    {item.validationTerm ? (
                      <img
                        alt="Edit"
                        className={styles.cursorPointer}
                        src={editIcon}
                        onClick={() => {
                          history.push(
                            `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/activitySectionsValidation/${selectedActivitySection}`
                          );
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                  <div className={styles.listLimitWrapper + " col-12 "}></div>
                </Row>
              </>
            )
          )}
      </Col>
    </Row>
  );
};

export default ValidatonTermsTable;
