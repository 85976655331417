import { action, makeObservable, observable } from "mobx";

import { DashboardPoints } from "../../models/state.models";
import { FetchApi } from "../../utilities";

class SectionStore {
  @observable isScoreLoading: boolean = false;
  @observable highestName: string = "";
  @observable lowestName: string = "";
  @observable groupHighestName: string = "";
  @observable groupLowestName: string = "";

  @observable dashboardPoints: DashboardPoints = {
    groupAverage: 0,
    groupHighest: 0,
    groupLow: 0,
    groupOverallPoints: 0,
    individualAverage: 0,
    individualHighest: 0,
    individualLow: 0,
    individualOverallPoints: 0,
    courseGroupHighestCredit: [],
    courseGroupLowestCredit: [],
    userHighestCredit: [],
    userLowestCredit: [],
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async getScoreCalculationDetails(courseId: number) {
    this.isScoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseSectionActivityOverview",
      params: { courseId },
    });
    this.isScoreLoading = false;
    this.dashboardPoints = data;
    if (status === "success") {
      this.highestName =
        data.userHighestCredit.firstName +
        " " +
        data.userHighestCredit.lastName;
      this.lowestName =
        data.userLowestCredit.firstName + " " + data.userLowestCredit.lastName;
      if (data.courseGroupHighestCredit) {
        this.groupHighestName = data.courseGroupHighestCredit.name;
        this.groupLowestName = data.courseGroupLowestCredit.name;
      }
    }
    return { status, message };
  }

  @action.bound
  async setScoreForStudent(courseId: number) {
    this.isScoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseSectionActivityOverviewForStudents",
      params: { courseId },
    });
    this.isScoreLoading = false;
    this.dashboardPoints = data;
    return { status, message };
  }

  @action.bound
  resetDashboardPoints() {
    this.dashboardPoints = {
      groupAverage: 0,
      groupHighest: 0,
      groupLow: 0,
      groupOverallPoints: 0,
      individualAverage: 0,
      individualHighest: 0,
      individualLow: 0,
      individualOverallPoints: 0,
      courseGroupHighestCredit: [],
      courseGroupLowestCredit: [],
      userHighestCredit: [],
      userLowestCredit: [],
    };
  }
}
export default SectionStore;
