import moment from "moment";
import * as Yup from "yup";

export const ActivitySchema = Yup.object().shape({
  name: Yup.string().required("Enter activity name"),
  startDate: Yup.string().required("Enter activity start date"),
  endDate: Yup.string()
  .required("Enter to date")
  .test("is-greater", "It must be greater then From date", function (value) {
    const { startDate } = this.parent;
    return moment(value, "MM-DD-YYYY").isSameOrAfter(
      moment(startDate, "MM-DD-YYYY")
    );
  }),
  // activityVisibilityList: Yup.array().when("isPresence", {
  //   is: false,
  //   then: Yup.array().required().min(1, "Enter activity view"),
  // }),
  credit: Yup.string().required("Enter credits"),
});
