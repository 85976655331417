import React from "react";

import { useSection } from "../../stores";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SectionNavigation from "../Components/SectionNavigation";
import SectionOverview from "./Components/SectionOverview";

const SectionDetails = () => {
  const { sectionTitle, courseTitle } = useSection();
  return (
    <DashboardBase componentName={courseTitle} location={sectionTitle}>
      <SectionNavigation isSectionDetails={true} >
        <SectionOverview />
      </SectionNavigation>
    </DashboardBase>
  );
};

export default SectionDetails;
