import React from "react";
import CUUser from "../Components/CUUser";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const EditUser = () => {
  return (
    <div>
      <DashboardBase componentName="Edit user" havePadding={true}>
        <CUUser isEdit={true} />
      </DashboardBase>
    </div>
  );
};

export default EditUser;
