import React from "react";

import { BASE_URL } from "../../../../utilities";
import editIcon from "../../../../assets/images/edit.svg";
import viewIcon from "../../../../assets/images/view.svg";
import defaultUserImg from "../../../../assets/images/default-user.png";
import styles from "./styles.module.scss";
interface RecentPointsSubmittedProps {
  student: any;
}

const RecentPointsSubmitted: React.FC<RecentPointsSubmittedProps> = ({
  student,
}) => {
  return (
    <tr>
      <td className={styles.tableCellProfileImage}>
        <img
          className={styles.profileIcon}
          src={
            student.imageFileName
              ? `${BASE_URL}File/GetFile/${student.imageFileName}`
              : defaultUserImg
          }
          alt="Profile"
        />
      </td>

      <td className={styles.tableCell} data-label="Name">
        {student.studentName}
      </td>

      <td className={styles.tableCell} data-label="Submitted Date">
        {student.submittedDate.substr(0, student.submittedDate.length - 6)}
      </td>

      <td>
        <div className={styles.iconCell}>
          <img alt="View" className={styles.icon} src={viewIcon} />
        </div>
      </td>
    </tr>
  );
};

export default RecentPointsSubmitted;
