import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

import { SaveScheduleRequest } from "../../models/request.models";
import {
  DataSource,
  Event,
  ScheduleEdit,
  ListsForSchedulerSelect,
  User,
} from "../../models/state.models";
import { FetchApi } from "../../utilities";
import { schedulerTimeFromRemoveUtcOffset } from "../../utils/timeUtils";

class SchedulerStore {
  @observable eventList: Event[] = [];

  @observable parsedData: DataSource[] = [];

  @observable isSchedulerLoading: boolean = false;

  @observable sectionList: ListsForSchedulerSelect[] = [];

  @observable activityList: ListsForSchedulerSelect[] = [];

  @observable courseList: ListsForSchedulerSelect[] = [];

  @observable selectedCourse: number = -1;

  @observable schedule: ScheduleEdit = {
    id: -1,
    sectionId: -1,
    subject: "",
    date: "",
    hourFrom: "",
    hourTo: "",
    activityId: -1,
    coInstructors: [],
    teachingAssistant: [],
    scheduleDayType: 1,
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async saveEvent(schedule: SaveScheduleRequest) {
    this.isSchedulerLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ScheduleApi/SaveScheduleDay",
      params: schedule,
    });
    this.isSchedulerLoading = false;
    return { status, message };
  }

  @action.bound
  async getAssitantsList(SectionId: number) {
    this.isSchedulerLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/GetAllTeachingAssistantsForSectionId",
      params: { SectionId: SectionId },
    });
    this.schedule.teachingAssistant = data;
    this.isSchedulerLoading = false;
    return { status, message };
  }

  @action.bound
  async deleteEvent(ID: number, CourseID: number) {
    this.isSchedulerLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/ScheduleApi/DeleteScheduleDay",
      params: { CourseID: CourseID, ScheduleDayID: ID },
    });
    this.isSchedulerLoading = false;
    return { status, message };
  }

  @action.bound
  async getSectionList(courseId: number) {
    this.isSchedulerLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseSectionCodeList",
      params: { CourseID: courseId },
    });
    this.isSchedulerLoading = false;
    this.sectionList = data;

    return { status, message };
  }

  @action.bound
  async getPrsenceActivityCodeList(courseId: number) {
    this.isSchedulerLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/ActivityApi/GetPresenceActivityCodeList",
      params: { CourseID: courseId },
    });
    this.isSchedulerLoading = false;
    this.activityList = data;
    return { status, message };
  }

  @action.bound
  async getCourseCodeList() {
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseCodeListForUser",
    });
    this.courseList = data;
    return { status, message };
  }

  @action.bound
  async getScheduleDay(courseId: number, scheduleDayID: number) {
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/scheduleApi/GetScheduleDay",
      params: { CourseID: courseId, ScheduleDayID: scheduleDayID },
    });
    this.schedule.scheduleDayType = data.type;
    this.schedule.activityId = data.activityID && data.activityID;
    this.schedule.subject = data.subject;
    this.schedule.sectionId = data.sectionID;
    return { status, message };
  }

  @action.bound
  async getEventsList(courseID: number, dateFrom: string, dateTo: string) {
    this.isSchedulerLoading = true;
    const { data, message, status, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/ScheduleApi/GetScheduleDayList",
      params: { CourseID: courseID, DateFrom: dateFrom, DateTo: dateTo },
    });
    this.isSchedulerLoading = false;
    if (status === "success") {
      this.eventList = data;
      this.parsedData = this.eventList.map((e: Event) => {
        const dataSource: DataSource = {
          Subject: e.subject,
          StartTime: schedulerTimeFromRemoveUtcOffset(e, true),
          EndTime: schedulerTimeFromRemoveUtcOffset(e, false),
          IsAllDay: false,
          Id: e.id,
        };
        return dataSource;
      });
    }
    return { status, message, breadCrumb };
  }

  @action.bound
  resetParesdList = () => {
    this.parsedData = [];
  };

  @action.bound
  changeDataForSchedule = (data: any) => {
    const month = moment(data.StartTime).month() + 1;
    this.schedule.subject = data.Subject;
    this.schedule.date =
      month +
      "." +
      moment(data.StartTime).date() +
      "." +
      moment(data.StartTime).year();
    if (data.IsAllDay) {
      this.schedule.hourFrom = moment("12:00 ", ["h:mm A"]);
      this.schedule.hourTo = moment("12:45 ", ["h:mm A"]);
    } else {
      this.schedule.hourFrom = moment(
        moment(data.StartTime).hour() + ":" + moment(data.StartTime).minute(),
        ["h:mm A"]
      );
      this.schedule.hourTo = moment(
        moment(data.EndTime).hour() + ":" + moment(data.EndTime).minute(),
        ["h:mm A"]
      );
    }
    if (data.Id) {
      this.schedule.id = data.Id;
    }
  };

  @action.bound
  handleDateChange(date: MaterialUiPickersDate) {
    this.schedule.date = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.schedule[event.target.name] = event.target.value;
  }

  @action.bound
  handleTimeInputChange(time: any, name: string) {
    this.schedule[name] = time;
  }

  @action.bound
  handleSelectChange(event: any) {
    this.schedule[event.target.name] = event.target.value;
  }

  @action.bound
  handleSelecdCourseChange(event: any) {
    this.selectedCourse = event.target.value;
  }

  @action.bound
  handleSectionTitleChange(value: number) {
    const section = this.sectionList.find(
      (section: ListsForSchedulerSelect) => section.codeInt === value
    );
    this.schedule.subject = section?.value;
  }

  @action.bound
  addSchedulerUser(user: User) {
    this.schedule.teachingAssistant.push(user);
  }

  @action.bound
  removeSchedulerUser(email: string) {
    this.schedule.teachingAssistant = this.schedule.teachingAssistant.filter(
      (user: User) => user.email !== email
    );
  }

  @action.bound
  handleCheckoBoxChange(event: any) {
    if (this.schedule.scheduleDayType === 1) {
      this.schedule.scheduleDayType = 2;
    } else if (this.schedule.scheduleDayType === 2) {
      this.schedule.scheduleDayType = 1;
    }
  }

  @action.bound
  resetSchedule() {
    this.selectedCourse = -1;
    this.schedule = {
      id: -1,
      sectionId: -1,
      activityId: -1,
      subject: "",
      date: "",
      hourFrom: "",
      hourTo: "",
      scheduleDayType: 1,
      coInstructors: [],
      teachingAssistant: [],
    };
  }
}

export default SchedulerStore;
