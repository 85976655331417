import styles from "./styles.module.scss";
import cc from "classcat";

const Step = (props: any) => {
  return (
    <div
      className={cc([styles.stepBlock, { [styles.selected]: props.selected }])}
    >
      <div
        className={styles.circleWrapper}
        onClick={() => {
          if (props.passed) props.updateStep(props.index + 1);
        }}
      >
        <div
          className={cc([styles.circle, { [styles.circleGray]: props.passed }])}
        >
          {props.index + 1}
        </div>
      </div>
    </div>
  );
};

export default Step;
