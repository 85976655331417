import React from "react";

import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";
import ApplicationsTable from "../ApplicationsTable";

const ApplicationsList = () => {
  return (
    <BaseTable
      list={{
        url: "/CourseUserApi/GetStudentApplicationList",
        data: { ApplicationType: null },
        filterParameters: [
          {
            name: "ApplicationType",
            dataType: "string",
            operator: "like",
            type: "select",
            value: "",
          },
          { name: "Name", dataType: "string", operator: "like" },
        ],
        orderBy: "CreatedOn",
        orderByDirection: "desc",
        OrderByExecuted: false,
      }}
    >
      <TableFilter />

      <ApplicationsTable />
      <TablePager />
    </BaseTable>
  );
};

export default ApplicationsList;
