import React from "react";
import { observer } from "mobx-react";

import { useAuth, useActivities } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import CUActivity from "../Components/CUActivity";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const EditActivity = () => {
  const { courseName } = useAuth();
  const { activity } = useActivities();

  return (
    <DashboardBase
      componentName={courseName}
      isActivity={true}
      location={activity.name}
    >
      <CourseNavigation isActivities={true} />
      <Card
        isFullScreen={true}
        isWithOutTopMargin={true}
        isCoursesTabs={true}
        isDetails={true}
      >
        <CUActivity isEdit={true} />
      </Card>
    </DashboardBase>
  );
};

export default observer(EditActivity);
