import React, { useEffect } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";

import { useActivities } from "../../../../stores";
import ActivityTable from "../ActivityStudentTable";
import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";

const ActivityStudentList = () => {
  const { courseId, academicTermId }: any = useParams();
  const { setIsIndividual } = useActivities();

  useEffect(() => {
    setIsIndividual(true);
  }, []);

  return (
    <BaseTable
      list={{
        url: "/ActivityApi/GetSubmittedActivities",
        data: { IsIndividual: true },
        filterParameters: [
          { name: "Name", dataType: "string", operator: "like" },
        ],
      }}
    >
      <TableFilter isWithoutButton={true} />

      <ActivityTable />
      <TablePager />
    </BaseTable>
  );
};

export default observer(ActivityStudentList);
