import React from "react";
import cc from "classcat";

import styles from "./styles.module.scss";

const ButtonWithImage: React.FC<any> = ({
  icon,
  isSmall,
  isSmallCancel,
  isExtraSmall,
  title,
  isButtonWithoutText,
  isFilter,
  isAddCourse,
  ...props
}) => {
  return (
    <div
      className={cc([
        styles.buttonWrapperWithIcon,
        { [styles.smallButtonWrapper]: isSmall },
        { [styles.extraSmallButtonWrapper]: isExtraSmall },
      ])}
    >
      <button
        className={cc([
          styles.buttonWithImage,
          { [styles.smallWithoutPadding]: isSmall },
          { [styles.extraSmallButton]: isExtraSmall },
          { [styles.isButtonWithoutText]: isButtonWithoutText },
          { [styles.filterButton]: isFilter },
          { [styles.addCourseButton]: isAddCourse },
        ])}
        {...props}
      >
        {title}
        {icon && (
          <img
            className={cc([
              {
                [styles.bigIconSize]: isExtraSmall,
                [styles.iconSize]: !isExtraSmall,
                [styles.iconWithoutMargin]: isButtonWithoutText,
              },
            ])}
            src={icon}
            alt="add"
          />
        )}
      </button>
    </div>
  );
};

export default ButtonWithImage;
