import React from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../stores";
import CUCourse from "../Components/CUCourse";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const EditCourse = () => {
  const { courseName } = useAuth();

  return (
    <div>
      <DashboardBase componentName={courseName}>
        <CUCourse isEdit={true} />
      </DashboardBase>
    </div>
  );
};

export default observer(EditCourse);
