import React from "react";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import CUAssignments from "../Components/CUAssignments";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const EditAssignment = () => {
  return (
    <DashboardBase componentName="Assignment">
      <CourseNavigation isAssignments={true} />
      <Card isFullScreen={true} isWithOutTopMargin={true} isDetails={true}>
        <CUAssignments isEdit={true} />
      </Card>
    </DashboardBase>
  );
};

export default EditAssignment;
