import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Form, Formik } from "formik";
import moment from "moment";

import { SaveActivityValidation } from "../../../../models/request.models";
import { useDialog, useActivities, useAuth } from "../../../../stores";
import { ValidationDateSchema } from "../ValidationSchema";
import { ValidationTerm } from "../../../../models/state.models";
import Button from "../../../../common/Button";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import copyIcon from "../../../../assets/images/file-svg.svg";
import CreateEditModal from "../CreateEditModal";
import DatePicker from "../../../../common/DatePicker";
import iconLeftArrow from "../../../../assets/images/arrow-left-gray.svg";
import PlusIcon from "../../../../assets/images/plus.svg";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import Terms from "../Terms";
import styles from "./styles.module.scss";

const RegularActivity = (props: any) => {
  const { courseId, sectionId, academicTermId, activityId }: any = useParams();
  const [dateFrom, setDateFrom] = useState<string>(
    props.list.validationTermDateFrom || ""
  );

  const [dateTo, setDateTo] = useState<string>(
    props.list.validationTermDateTo || ""
  );
  const [activityCopyID, setActivityCopyID] = useState<string>("");
  const [newValidation, setNewValidation] = useState<boolean>(false);
  const [sectionCopyID, setSectionCopyID] = useState<string>("");
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>("");
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const {
    activityListForCopy,
    activityValidation,
    copyActivityTerms,
    getActivityValidation,
    saveValidationDate,
    SectionListForCopy,
    selectedActivitySection,
  } = useActivities();

  useEffect(() => {
    setDateFrom(
      props.list.validationTermDateFrom
        ? props.list.validationTermDateFrom
        : props.list.activityStartDate
    );
    setDateTo(
      props.list.validationTermDateTo
        ? props.list.validationTermDateTo
        : props.list.activityEndDate
    );
  }, [props.list]);

  const handleDateChange = () => {
    const request: SaveActivityValidation = {
      ActivityID: activityId,
      CourseID: courseId,
      SectionID: props.isFromUrl ? selectedActivitySection : sectionId,
      ValidationTermDateFrom: dateFrom,
      ValidationTermDateTo: dateTo,
    };
    saveValidationDate(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        getActivityValidation(
          courseId,
          props.isFromUrl ? selectedActivitySection : sectionId,
          activityId
        );
        setNewValidation(false);
      } else {
        showDialog(resp.message, null, null);
      }
    });
  };

  const handleCopy = () => {
    if (
      sectionCopyID ==
        (props.isFromUrl ? selectedActivitySection : sectionId) &&
      activityCopyID == activityId
    ) {
      setIsTouched(true);
      setIsError("cant copy");
    } else {
      if (sectionCopyID !== "" && activityCopyID !== "") {
        copyActivityTerms(
          activityId,
          props.isFromUrl ? selectedActivitySection : sectionId,
          activityCopyID,
          sectionCopyID
        ).then((resp: any) => {
          if (resp.status && !resp.message) {
            getActivityValidation(
              courseId,
              props.isFromUrl ? selectedActivitySection : sectionId,
              activityId
            );
            setNewValidation(false);
          } else {
            showDialog(resp.message, null, null);
          }
        });
      }
    }
  };

  const handleClick = () => {
    window.open(
      `/academicTerms/${academicTermId}/courses/${courseId}/activities/${props.list.activityID}/details`,
      "_blank"
    );
  };

  return (
    <>
      <Row className=" justify-content-start pb-4">
        <div className=" pt-3">
          <h4>{props.list.activityName}</h4>
        </div>

        <Col className=" pl-5 pt-3">
          <Row>
            <p onClick={handleClick} className={styles.activityView + " mt-1"}>
              view Activity
            </p>
            <img
              alt="view"
              className={styles.iconView + " mt-2 ml-2"}
              src={iconLeftArrow}
              onClick={handleClick}
            />
          </Row>
        </Col>
      </Row>
      <Formik
        initialValues={{
          ValidationTermDateFrom: dateFrom,
          ValidationTermDateTo: dateTo,
        }}
        enableReinitialize
        validationSchema={ValidationDateSchema}
        onSubmit={(values) => {
          if (values) {
            handleDateChange();
          }
        }}
      >
        {({ errors, setFieldValue, values, handleBlur, touched }) => {
          return (
            <Form>
              <Row>
                <Col sm={12} className="mb-3 pt-2">
                  Validation duration
                </Col>
                <Col md="auto" className="">
                  <Row>
                    <Col md="auto" className={styles.DatePickerText}>
                      <p> From:</p>
                    </Col>
                    <Col md="auto">
                      <DatePicker
                        endDate={activityValidation.activityEndDate}
                        errorMessage={errors.ValidationTermDateFrom}
                        labelPar=""
                        onBlur={handleBlur}
                        disabled={role === "Assistant"}
                        placeholder="mm/dd/yyyy"
                        startDate={activityValidation.activityStartDate}
                        touched={touched.ValidationTermDateFrom}
                        value={
                          values.ValidationTermDateFrom
                            ? values.ValidationTermDateFrom
                            : activityValidation.activityStartDate
                        }
                        onChange={(date) => {
                          setFieldValue("ValidationTermDateFrom", date);
                          setDateFrom(moment(date).format("MM-DD-YYYY"));
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="auto">
                  <Row>
                    <Col md="auto" className={styles.DatePickerText}>
                      <p> To:</p>
                    </Col>
                    <Col md="auto">
                      <DatePicker
                        endDate={activityValidation.activityEndDate}
                        errorMessage={errors.ValidationTermDateTo}
                        labelPar=""
                        onBlur={handleBlur}
                        disabled={role === "Assistant"}
                        placeholder="mm/dd/yyyy"
                        startDate={activityValidation.activityStartDate}
                        touched={touched.ValidationTermDateTo}
                        value={
                          values.ValidationTermDateTo
                            ? values.ValidationTermDateTo
                            : activityValidation.activityEndDate
                        }
                        onChange={(date) => {
                          setFieldValue("ValidationTermDateTo", date);
                          setDateTo(moment(date).format("MM-DD-YYYY"));
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {role !== "Assistant" && (
                  <Col md="4" className=" pt-2 mt-1">
                    <Button title="Change" type="submit" isExtraSmall={true} />
                  </Col>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
      <Row>
        <Col>
          <Row>
            <Col>
              {" "}
              {role !== "Assistant" && (
                <>
                  {newValidation ? (
                    <Row>
                      <Col md={12} className=" pt-5 mt-2">
                        <div className={styles.modal}>
                          <CreateEditModal
                            handleCancel={() => setNewValidation(false)}
                            isNew={true}
                            term={props.list}
                            dateFrom={
                              dateFrom
                                ? dateFrom
                                : activityValidation.activityStartDate
                            }
                            dateTo={
                              dateTo
                                ? dateTo
                                : activityValidation.activityEndDate
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Col className=" pl-2">
                      <Row className="mt-4">
                        <Col className="pt-3">
                          <p>Copy validation terms</p>
                        </Col>
                        <Col>
                          <SelectWithLabel
                            isSectionTitle={true}
                            errorMessage={isError}
                            touched={isTouched}
                            name="sectionNameForCopy"
                            placeholder="Section"
                            value={sectionCopyID !== "" ? sectionCopyID : "-1"}
                            values={SectionListForCopy}
                            onChange={(event: any) => {
                              setSectionCopyID(event.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <SelectWithLabel
                            name="activityNameForCopy"
                            placeholder="Activity"
                            value={
                              activityCopyID !== "" ? activityCopyID : "-1"
                            }
                            values={activityListForCopy}
                            onChange={(event: any) => {
                              setActivityCopyID(event.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3} className={"pt-2"}>
                          <ButtonWithImage
                            title="Copy"
                            style={{ width: "150px" }}
                            type="button"
                            icon={copyIcon}
                            isExtraSmall={true}
                            onClick={() => {
                              handleCopy();
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className=" d-flex justify-content-end ">
                        <Col md="auto" className=" pt-3 mt-2">
                          <ButtonWithImage
                            icon={PlusIcon}
                            isSmallCancel={true}
                            title="Add New validation term "
                            onClick={() => {
                              setNewValidation(true);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={styles.scroll + " mt-5 pl-3 pt-2"}>
              {props.list &&
                props.list.validationTermList.map(
                  (term: ValidationTerm, key: any) => (
                    <Terms term={term} list={props.list} />
                  )
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default observer(RegularActivity);
