import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";

import TextInput from "../../../../common/TextInput";
import Button from "../../../../../src/common/Button/index";
import DatePicker from "../../../../common/DatePicker";
import { useDialog, useGradebookStore } from "../../../../stores";
import { useParams } from "react-router";
import { SubmittedAssignmentRequestDTO } from "../../../../models/request.models";
import { AddAttemptModalSchema } from "../AddAttemptModalSchema";
import styles from "./styles.module.scss";

const AddAttemptModal: React.FC<any> = ({ handleCancel, refreshData }) => {
  const {
    handlerInput,
    handleDateChange,
    submittedAssignmentDTO,
    saveAssignmentAttempt,
    resetAssignmentAttempt,
  } = useGradebookStore();

  const { courseId, idAssignment, courseUserID }: any = useParams();
  const { showDialog } = useDialog();

  const handlerFormSubmit = () => {
    submittedAssignmentDTO.courseID = courseId;
    submittedAssignmentDTO.assignmentID = idAssignment;
    submittedAssignmentDTO.courseUserID = courseUserID;
    saveAssignmentAttempt(submittedAssignmentDTO).then((response: any) => {
      if (response.status === "success") {
        showDialog(
          "Attempt successfully saved!",
          null,
          null,
          false,
          "Notification"
        );
        resetAssignmentAttempt();
        handleCancel();
        let request: SubmittedAssignmentRequestDTO = {
          courseID: Number(courseId),
          assignmentID: Number(idAssignment),
          courseUserID: Number(courseUserID),
        };
        refreshData(request);
      } else {
        showDialog(response.message, null, null, false);
      }
    });
  };

  return (
    <Formik
      initialValues={submittedAssignmentDTO}
      validationSchema={AddAttemptModalSchema}
      onSubmit={(values, actions) => {
        handlerFormSubmit();
        actions.setSubmitting(false);
      }}
    >
      {({ errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form>
            <div className={styles.mainModalWrapper + " p-5"}>
              <div>
                <TextInput
                  label="Points"
                  name="points"
                  onChange={(e) => {
                    handleChange(e);
                    handlerInput(e);
                  }}
                  value={submittedAssignmentDTO.points}
                  touched={touched.points}
                  errorMessage={errors.points}
                />
              </div>
              <div className="mt-5">
                <DatePicker
                  name="passedDate"
                  value={submittedAssignmentDTO.passedDate}
                  labelPar="Date"
                  placeholder="mm/dd/yyyy"
                  onChange={(date) => {
                    setFieldValue("passedDate", date);
                    handleDateChange(date);
                  }}
                  touched={touched.passedDate}
                  errorMessage={errors.passedDate}
                />
              </div>
              <Row className="justify-content-end + m-0 mt-5">
                <Col>
                  <Button
                    isDialogCancel={true}
                    onClick={handleCancel}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col>
                  <Button isDialog={true} title="Save" type="submit" />
                </Col>
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(AddAttemptModal);
