import React, { useState, useEffect } from "react";
import { calculateNumberOfPages } from "../../../utils/paginationUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useAsync } from "../../../hooks/useAsync";
import { postApi } from "../../../utilities";
import useMessageBox from "../../../hooks/useMessageBox";
import {
  useAuth,
  useDialog,
  useSection,
  useActivities,
  useAssignments,
} from "../../../stores";
import Loader from "../../../common/Loader";

const BaseTable = (props: any) => {
  const { showDialog } = useDialog();
  const { selectedAcademicTermId, setSectionName, setCourseName } = useAuth();
  const { setSectionTitle, setCourseTitle } = useSection();
  const { setActivityTitle } = useActivities();
  const { setAssignmentTitle } = useAssignments();
  const [needsExecute, setNeedsExecute] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [filterParameters, setFilterParameters] = useState(
    props.list.filterParameters || []
  );
  const [page, setPage] = useState(props.list.page);
  const [pageSize, setPageSize] = useState(props.list.pageSize);
  const [orderBy, setOrderBy] = useState(props.list.orderBy);
  const [orderByDirection, setOrderByDirection] = useState(
    props.list.orderByDirection
  );
  const [url, setUrl] = useState(props.list.url || "");
  const [data, setData] = useState(props.list.data || "");
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const parameters = {
    filterParameters,
    page,
    pageSize,
    orderBy,
    orderByDirection,
  };

  // const { setLoading } = useCourses();
  const [academicTermIdTemp, setAcademicTermIdTemp] = useState(1);

  const getList = () => {
    // setLoading(true);
    return postApi({
      url: url,
      params: { ...parameters, data },
    })
      .then((result) => {
        // setLoading(false);
        if (result.data && result.data.isSuccess) {
          setEntityList(result.data.data);
          if (result.data.additionalData) {
            if (result.data.additionalData.sectionName) {
              setSectionTitle(result.data.additionalData.sectionName);
              setCourseTitle(result.data.additionalData.courseName);
            }
          }
          if (result.data.breadCrumb) {
            if (result.data.breadCrumb.sectionName) {
              setSectionName(result.data.breadCrumb.sectionName);
            }
            if (result.data.breadCrumb.courseName) {
              setCourseName(result.data.breadCrumb.courseName);
            }
            setAssignmentTitle(result.data.additionalData);
            setActivityTitle(result.data.additionalData);
          }
          const {
            filterParameters: _filterParameters = 0,
            totalCount: _totalCount = 10,
            page: _page = 1,
            pageSize: _pageSize = 10,
            orderBy: _orderBy = "",
            orderByDirection: _orderByDirection = "asc",
          } = { ...result.data, ...result.data.parameters };
          if (_page > 0) setPage(_page);
          if (_pageSize > 0) setPageSize(_pageSize);
          if (_orderBy) setOrderBy(_orderBy);
          if (_orderByDirection) setOrderByDirection(_orderByDirection);
          if (_totalCount) {
            var total = _totalCount > 0 ? _totalCount : 0;
            setTotalCount(total);
            if (_pageSize > 0 || pageSize > 0) {
              setPageCount(calculateNumberOfPages(_totalCount, pageSize));
            } else setPageCount(1);
          }
          if (_filterParameters && _filterParameters.length > 0)
            _filterParameters.forEach((fp: any) =>
              changeFilterParameter(fp.name, fp.value)
            );
        } else {
          const err =
            result.data && result.data.message
              ? result.data.message
              : "There was an error executing api call!";
          showMessage("Error", err, () => clearErrorMessage());
          if (
            err.includes("not authorized") ||
            err.includes("not found") ||
            err.includes("Authentication error")
          ) {
            showDialog(
              "Authentication error. Please log in",
              `/academicTerms/${selectedAcademicTermId}/courses`,
              urlHistory,
              true,
              "Errror"
            );
          } else {
            showDialog(err, null, null, true, "Error");
          }
        }
      })
      .catch((reason) => {
        if (
          reason.message.includes("not authorized") ||
          reason.message.includes("not found")
        ) {
          showDialog(
            "Authentication error. Please log in",
            `/academicTerms/${selectedAcademicTermId}/courses`,
            urlHistory,
            true,
            "Errror"
          );
        } else {
          showDialog(reason.message, null, null, true, "Error");
        }
        showMessage("Error", reason.message, () => clearErrorMessage());
      });
  };
  const showMessage = (
    title: string,
    message: string,
    callback: Function | null = null
  ) => {
    showMessageBox(title, message, callback);
  };
  const clearErrorMessage = (doClose?: boolean) => {
    clearStatus();
    if (doClose) close();
  };

  const urlParams = useParams();
  const { academicTermId }: any = urlParams;
  const urlHistory = useHistory();
  const urlLocation = useLocation();
  const { execute, status, error, clearStatus } = useAsync(getList, false);

  const executeQueue = () => {
    setNeedsExecute(true);
  };

  const changeOrderBy = (newOrderBy: string) => {
    if (orderBy === newOrderBy) {
      setOrderByDirection(orderByDirection === "desc" ? "asc" : "desc");
    } else {
      setOrderBy(newOrderBy);
      setOrderByDirection("asc");
    }
    executeQueue();
  };

  const changeFilterParameter = (parameterName: string, newValue: any) => {
    const fps = [...filterParameters] || [];
    const param = getFilterParameter(parameterName, fps, false);
    param.value = newValue;
    setFilterParameters(fps);
    return param;
  };

  const initFilterParameter = (
    { name, operator, dataType, value }: any,
    fps = [...filterParameters],
    callSetParams = true
  ) => {
    var param = getFilterParameter(name, fps, false);
    param = Object.assign(param, { ...param, operator, dataType, value });
    if (callSetParams) setFilterParameters(fps);
    return param;
  };

  const initFilterParameterList = (parameterList: any) => {
    const fps = [...filterParameters] || [];
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((element: any) => {
        initFilterParameter(element, fps, false);
      });
      setFilterParameters(fps);
    }
  };

  const getFilterParameter = (
    parameterName: string,
    fps = [...filterParameters],
    callSetParams = true
  ) => {
    var fnd;
    fps = fps || [];
    fnd = fps.find((item) => item.name === parameterName);
    if (!fnd) {
      fnd = { name: parameterName };
      fps.push(fnd);
      if (callSetParams) setFilterParameters(fps);
    }
    return fnd;
  };

  const changePageSize = (newPageSize: number) => {
    if (newPageSize > 0) {
      setPage(1);
      setPageSize(newPageSize);
      executeQueue();
    }
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
    executeQueue();
  };

  const getPrevPath = (path: string) => {
    var retval = path;
    if (path && path.length > 0) {
      var p = path.split("/");
      if (p && p.length > 1) {
        p.pop();
        var str = p[p.length - 1];
        if (
          str.toLowerCase().startsWith("view") ||
          str.toLowerCase().startsWith("edit")
        ) {
          retval = getPrevPath(p.join("/"));
        } else {
          retval = p.join("/");
        }
      }
    }
    return retval;
  };

  const close = () => {
    urlHistory.push(
      getPrevPath(
        urlLocation.pathname
      ) /*+ (urlLocation.search ? urlLocation.search : '') + (urlLocation.hash ? urlLocation.hash : '')*/
    );
  };

  const refresh = () => {
    setPage(1);
    setNeedsExecute(true);
  };

  const { MessageBox, showMessageBox, hideMessageBox } = useMessageBox();

  function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj;
  }
  useEffect(() => {
    var hasParams = false;
    var param: { [index: string]: string } = {};
    for (let p in urlParams) {
      param[p] = hasKey(urlParams, p) ? urlParams[p] : ""; //  getKeyValue(pp)(urlParams);
      hasParams = true;
    }
    if (hasParams) {
      setData({ ...data, ...param });
      setNeedsExecute(true);
    } else {
      execute();
    }
    if (hasParams) {
      setNeedsExecute(true);
    } else {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academicTermId]);

  useEffect(() => {
    if (needsExecute) {
      var param: { [index: string]: string } = {};
      for (let p in urlParams) {
        param[p] = hasKey(urlParams, p) ? urlParams[p] : ""; //  getKeyValue(pp)(urlParams);
      }
      setData({ ...data, ...param });
      execute();
      setNeedsExecute(false);
    }
  }, [execute, needsExecute]);

  // useEffect(() => {
  //   if (needsReloadTable) {
  //     execute();
  //     setNeedsReloadTable(false);
  //   }
  // }, [needsReloadTable]);

  const functions = {
    changeOrderBy,
    changeFilterParameter,
    getFilterParameter,
    changePageSize,
    changePage,
    refresh,
    close,
  };

  return (
    <>
      {status === "pending" && <Loader />}
      {/* <MessageBox /> */}
      {/* {status === "error" && <div>{error}</div>}
      {(status === "success" || status === "idle") && ( */}
      {/* status === "idle" && <div>Start your journey by clicking a button</div> */}
      {/* {status === "error" && <div>{error}</div>} */}
      {/*<Col md={1}>
        <Button
          title={status !== "pending" ? "Refresh" : "Loading..."}
          onClick={refresh}
          disabled={status === "pending"}
        />
  </Col>*/}
      {status === "success" && (
        <>
          {React.Children.map(props.children, (child, index) => {
            // props
            return React.cloneElement(child, {
              list: {
                ...parameters,
                entityList,
                pageCount,
                totalCount,
                ...functions,
              },
            });
          })}
          {entityList.length === 0 && (
            <h1 className="mt-3">Currently there are no results to show</h1>
          )}
        </>
      )}
    </>
  );
};

export default observer(BaseTable);
