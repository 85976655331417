import React from "react";

import DashboardBase from "../Dashboard/Components/DashboardBase";
import SettingsForm from "./Components/SettingsForm";

const Settings = () => {
  return (
    <DashboardBase componentName="Settings">
      {/* <SettingsForm /> */}
    </DashboardBase>
  );
};

export default Settings;
