import React, { useEffect } from "react";
import cc from "classcat";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { observer } from "mobx-react";

import { useActivities, useScreenSize } from "../../../stores";
import TabNavigationItem from "../TabNavigationItem";
import styles from "./style.module.scss";

interface ActivityNavigationProps {
  isActivityDetails?: boolean;
  isActivitySectionsValidation?: boolean;
  isGroups?: boolean;
  isStudents?: boolean;
  children?: React.ReactNode;
}

const ActivityNavigation: React.FC<ActivityNavigationProps> = ({
  isActivityDetails,
  isActivitySectionsValidation,
  isStudents,
  isGroups,
  children,
}) => {
  const { courseId, activityId, academicTermId }: any = useParams();
  const { activity, getActivity } = useActivities();

  const { isTabs } = useScreenSize();

  useEffect(() => {
    if (activity.id != activityId) {
      getActivity(courseId, activityId);
    }
  }, []);

  return (
    <>
      <Col className={cc([" p-0", { ["mt-4"]: isTabs }])} md={12}>
        <Row className={isTabs ? styles.navRow + " mx-0 " : " mx-0"}>
          <TabNavigationItem
            component={children}
            isDetails={true}
            isSelected={isActivityDetails}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/details`}
            title="Activity Details"
            index={0}
          />

          {(activity.isIndividual || activity.isPresence) && (
            <TabNavigationItem
              component={children}
              isSelected={isStudents}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/students`}
              title="Students"
              index={1}
            />
          )}

          {!activity.isIndividual && !activity.isPresence && (
            <TabNavigationItem
              component={children}
              isSelected={isGroups}
              path={`/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/groups`}
              title="Groups"
              index={1}
            />
          )}

          <TabNavigationItem
            component={children}
            isSelected={isActivitySectionsValidation}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/activitySectionsValidation`}
            title="Activity Sections Validation"
            index={2}
          />
        </Row>
      </Col>
      {isTabs && children}
    </>
  );
};

export default observer(ActivityNavigation);
