import React from "react";
import { Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useDialog } from "../../../stores";
import { Row, Col } from "react-bootstrap";

import Button from "../../../common/Button";
import styles from "./styles.module.scss";

const classes = {
  closeButton: {
    position: "absolute",
    right: "2px",
    top: "2px",
    color: "#fafafa",
  },
};

const MessageBox = () => {
  const {
    message,
    isOpen,
    closeDialog,
    title,
    isResponseNotification,
    redirectionPath,
    history,
    setIsErrorDialog,
    isErrorDialog,
  } = useDialog();

  setTimeout(function () {
    HandleTimeOut();
  }, 2000);

  const HandleTimeOut = () => {
    closeDialog();
    if (isErrorDialog) {
      setIsErrorDialog(false);
    }
    if (history) {
      history.push(redirectionPath);
    }
  };

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      className={styles.dialog}
    >
      <Row className="p-4 ">
        <Col>
          <h4 className={isErrorDialog && styles.errorDialog}>{title}</h4>
          <p>{message}</p>
          <Button isDialog={true} title="Ok" onClick={closeDialog} />
        </Col>
      </Row>
    </Dialog>
  );
};

export default observer(MessageBox);
