import React, { ReactNode } from "react";
import MomentUtils from "@date-io/moment";
import cc from "classcat";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FormikErrors, FormikTouched } from "formik";

import styles from "./styles.module.scss";

export type CustomDatePickerProps = {
  errorMessage?:
    | string
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];

  labelPar: string;
  tableDate?: boolean;
  startDate?: string;
  endDate?: string;
  children?: ReactNode;
} & KeyboardDatePickerProps;

export const DatePicker: React.FC<CustomDatePickerProps> = ({
  errorMessage,
  format = "MM/DD/YYYY",
  label,
  labelPar,
  tableDate = false,
  touched,
  variant = "inline",
  endDate,
  startDate,
  children,
  ...props
}) => {
  const disablePrevDates = (startDate: string) => {
    const startSeconds = Date.parse(startDate);
    return (date: string) => {
      return Date.parse(date) < startSeconds;
    };
  };

  const disableNextDates = (endDate: string) => {
    const startSeconds = Date.parse(endDate);
    return (date: string) => {
      return Date.parse(date) > startSeconds;
    };
  };

  return (
    <label className={styles.label}>
      <div className=" row align-items-center pl-3">
        {labelPar}
        {children && children}
      </div>
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk={true}
            className={cc([
              styles.datePicker,
              { [styles.errorWrapper]: errorMessage && touched },
            ])}
            disableToolbar={true}
            format={format}
            invalidDateMessage={null}
            label={label}
            minDateMessage={null}
            variant={variant}
            {...props}
          />
        </MuiPickersUtilsProvider>
        {errorMessage && touched && (
          <p className={styles.errorMsg}>{errorMessage}</p>
        )}
      </div>
    </label>
  );
};

export default DatePicker;
