import React from "react";
import cc from "classcat";
import { Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";

import { ActivityValidation } from "../../../../models/state.models";
import ArrowRightIcon from "../../../../assets/images/arrow-left-gray.svg";
import CardSharpCorners from "../../../../common/CardSharpCorners";
import styles from "./styles.module.scss";

const ActivityNames = ({ list }: any) => {
  const history = useHistory();
  const { courseId, sectionId, academicTermId, activityId }: any = useParams();

  return (
    <>
      {list &&
        list.map((activity: ActivityValidation, key: number) => (
          <CardSharpCorners isFullScreen={false} isWithOutTopMargin={false}>
            <Row
              className={
                cc([
                  styles.pointer + " justify-content-between p-2",
                  {
                    [styles.selectedNameWrapper +
                    " justify-content-between py-2"]:
                      activity.code === activityId,
                  },
                ]) + " p-3"
              }
              onClick={() =>
                history.push(
                  `/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/activityValidations/${activity.code}`
                )
              }
            >
              <p>{activity.value}</p>
              <img
                className={styles.iconSize}
                src={ArrowRightIcon}
                alt="arrow"
              />
            </Row>
          </CardSharpCorners>
        ))}
    </>
  );
};

export default observer(ActivityNames);
