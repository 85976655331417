import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { observer } from "mobx-react";

import { useActivities, useAuth, useDialog } from "../../stores";
import ActivityList from "./Components/ActivityList";
import ButtonWithImage from "../../common/ButtonWithImage";
import CardSharpCorners from "../../common/CardSharpCorners";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import Icon from "../../assets/images/back.svg";
import Loader from "../../common/Loader";
import SectionNavigation from "../Components/SectionNavigation";
import Validations from "./Components/Validations";
import styles from "./styles.module.scss";

const ActivityValidations = () => {
  const { activityId, sectionId, courseId, academicTermId }: any = useParams();
  const { showDialog } = useDialog();
  const { selectedAcademicTermId } = useAuth();
  const history = useHistory();

  const {
    activityValidation,
    activityValidationCodeList,
    getActivityValidation,
    getActivityValidationCodeList,
    loading,
    resetActivityValidationCode,
  } = useActivities();

  const { sectionTitle, courseTitle } = useActivities();

  const handleList = () => {
    getActivityValidationCodeList(courseId, sectionId).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Error"
          );
          resetActivityValidationCode();
        }
        showDialog(
          resp.message,
          `/academicTerms/${selectedAcademicTermId}/courses`,
          history,
          true,
          "Error"
        );
      }
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  useEffect(() => {
    if (activityId) {
      getActivityValidation(courseId, sectionId, activityId).then(
        (resp: any) => {
          if (resp.status === "error") {
            if (
              resp.message.includes("not found") ||
              resp.message.includes("authorized")
            ) {
              showDialog(
                resp.message,
                `/academicTerms/${selectedAcademicTermId}/courses`,
                history,
                true,
                "Errror"
              );
            }
          }
        }
      );
    }
  }, [activityId]);

  return (
    <DashboardBase componentName={courseTitle} location={sectionTitle}>
      <SectionNavigation isActivityValidations={true}>
        {loading && <Loader />}

        <CardSharpCorners
          isFullScreen={false}
          isWithOutTopMargin={true}
          noPaddingLeft={true}
        >
          <Row className=" p-0 m-0">
            <Col md="auto" className={styles.wrapper + " pt-2 pl-0"}>
              <ActivityList list={activityValidationCodeList} />
            </Col>
            <Col>
              <Row className="justify-content-end pt-2">
                <Col md="auto">
                  <ButtonWithImage
                    title="Back"
                    onClick={() => {
                      history.push(
                        `/academicTerms/${academicTermId}/courses/${courseId}/sections/`
                      );
                    }}
                    icon={Icon}
                  />
                </Col>
              </Row>
              {activityId && !loading && (
                <Validations list={activityValidation} />
              )}
            </Col>
          </Row>
        </CardSharpCorners>
      </SectionNavigation>
    </DashboardBase>
  );
};

export default observer(ActivityValidations);
