import React from "react";

import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const Profile = () => {
  return (
    <DashboardBase componentName="Profile">
      <Card>
        <p>Profile</p>
      </Card>
    </DashboardBase>
  );
};

export default Profile;
