import React from "react";
import { observer } from "mobx-react";

import BaseTable from "../../../Components/BaseTable";
import StudentsTable from "../StudentsTable";
import TablePager from "../../../Components/TablePager";
import TableFilter from "../../../Components/TableFilter";

const StudentList = () => {
  return (
    <BaseTable
      list={{
        url: "/CourseUserApi/GetCourseUserList",
        data: { UserType: 1 },
        filterParameters: [
          {
            name: "StudentName",
            dataType: "string",
            operator: "like",
            label: "Student Name",
          },
          {
            name: "User.Email",
            dataType: "string",
            operator: "like",
            label: "Email",
          },
        ],
      }}
    >
      <TableFilter isWithoutButton={true}></TableFilter>
      <StudentsTable />
      <TablePager />
    </BaseTable>
  );
};

export default observer(StudentList);
