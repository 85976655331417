import React from "react";
import { useHistory, useParams } from "react-router";

import EditViewButtons from "../../../Components/EditViewButtons";
import { CourseForList } from "../../../../models/state.models";
import { BASE_URL } from "../../../../utilities";
import { useCourses } from "../../../../stores";
import styles from "./styles.module.scss";

interface CourseTableRowProps {
  course: CourseForList;
  list?: any;
}

const CourseTableRow: React.FC<CourseTableRowProps> = ({ course, list }) => {
  const history = useHistory();
  const { academicTermId }: any = useParams();
  const { setSelectedCourseTitle } = useCourses();

  const onTableDetailsClick = () => {
    setSelectedCourseTitle(course.title);
    history.push(
      `/academicTerms/${academicTermId}/courses/${course.id}/details`
    );
  };
  const status = () => {
    switch (course.status) {
      case 1:
        return "Draft";
      case 2:
        return "Pre-Published";
      case 3:
        return "Published";
      default:
        break;
    }
  };

  return (
    <tr>
      <td
        className={styles.tableCell}
        data-label="Title"
        onClick={onTableDetailsClick}
      >
        <div className={styles.titleValueWrapper}>
          <img
            src={`${BASE_URL}File/GetFile/${course.avatarName}`}
            alt="avatar"
            className={styles.avatar}
          />
          {course.title}
        </div>
      </td>
      <td
        className={styles.tableCell}
        data-label="ID"
        onClick={onTableDetailsClick}
      >
        {course.courseCode}
      </td>
      <td
        className={styles.tableCell}
        data-label="Start Date"
        onClick={onTableDetailsClick}
      >
        {course.startDate}
      </td>
      <td
        className={styles.tableCell}
        data-label="End Date"
        onClick={onTableDetailsClick}
      >
        {course.endDate}
      </td>
      <td
        className={styles.tableCell}
        data-label="Status"
        onClick={onTableDetailsClick}
      >
        {status()}
      </td>
      <td>
        <EditViewButtons
          isCourses={true}
          courseId={course.id}
          courseTitle={course.title}
          list={list}
        />
      </td>
    </tr>
  );
};

export default CourseTableRow;
