import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import Button from "../../../common/Button";
import Card from "../../../common/Card";
import DatePicker from "../../../common/DatePicker";
import SelectWithLabel from "../../../common/SelectWithLabel";
import TextInput from "../../../common/TextInput";
import { SaveCourseRequest } from "../../../models/request.models";
import { AcademicTerm } from "../../../models/state.models";
import { useAuth, useCourses, useDialog } from "../../../stores";
import { CourseSchema } from "./Components/CourseSchema";
import { BASE_URL } from "../../../utilities";
import MultipleSelector from "../../../common/MultipleSelector";
import iconArrowDown from "../../../assets/images/arrow-down-gray.svg";
import styles from "./styles.module.scss";

interface CUCourseProps {
  isEdit?: boolean;
}

const CUCourse: React.FC<CUCourseProps> = ({ isEdit }) => {
  const history = useHistory();
  const { courseId, academicTermId }: any = useParams();
  const { showDialog } = useDialog();
  const { selectedAcademicTermId, role, setCourseName } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);

  const {
    academicTerms,
    addCourseUser,
    avatarList,
    course,
    getAcademicTerms,
    getAvatars,
    getCourseForEdit,
    handleDateChange,
    handleDefaultAcademicTerm,
    handleInputChange,
    handleAvatarChange,
    handleSelectChange,
    removeCourseUser,
    resetCourse,
    saveCourse,
    isClone,
  } = useCourses();

  useEffect(() => {
    getAvatars();
    if (!isEdit) {
      getAcademicTerms();
    }
    if (isEdit) {
      getCourseForEdit(courseId).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Errror"
            );
          }
          showDialog(resp.message, null, null, true, "Error");
        }
      });
    } else {
      resetCourse();
    }
  }, [
    getAvatars,
    getAcademicTerms,
    courseId,
    isEdit,
    getCourseForEdit,
    resetCourse,
  ]);

  useEffect(() => {
    if (role != "Professor") {
      showDialog(
        "User not authorized",
        `/academicTerms/${selectedAcademicTermId}/courses`,
        history,
        true,
        "Errror"
      );
    }
    if (course) {
      setCourseName(course.title);
    }
  });

  useEffect(() => {
    if (academicTerms.length === 0) {
      getAcademicTerms();
    }
    var term = academicTerms.find(
      (academicTerm: AcademicTerm) => academicTerm.id == academicTermId
    );
    term && handleDefaultAcademicTerm(term);
  }, [academicTerms, handleDefaultAcademicTerm]);

  const handleSubmit = () => {
    const request: SaveCourseRequest = {
      Title: course.title,
      CourseCode: course.courseCode,
      CourseIdentification: course.courseIdentification,
      CurrencyName: course.currencyName,
      MaxBuybackPrice: parseFloat(course.maxBuybackPrice),
      StartDate: course.startDate,
      EndDate: course.endDate,
      AcademicTermName: course.academicTermName,
      AcademicTermYear: course.academicTermYear,
      AvatarName: course.avatarName,
      AvatarFileID: course.avatarFileID,
      HasSection: true,
      ParentID: null,
      ScheduleList: course.schedules,
      CoInstructorList: course.coInstructorList,
      TeachingAssistanList: course.teachingAssistanList,
      ValidationTimeAfter: parseInt(course.validationTimeAfter),
      ValidationTimeBefore: parseInt(course.validationTimeBefore),
    };

    if (isEdit) {
      if (isClone) {
        request.SourceCourseID = courseId;
      } else {
        request.id = courseId;
      }
    }

    saveCourse(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        if (isEdit) {
          showDialog(
            "Course changed succesfully",
            `/academicTerms/${academicTermId}/courses`,
            history,
            false
          );
        } else {
          showDialog(
            "Course added succesfully",
            `/academicTerms/${academicTermId}/courses`,
            history,
            false
          );
        }
      } else if (resp.status === "success" && resp.message) {
        showDialog(resp.message, null, null, false);
      } else if (resp.status === "error") {
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  };

  const addInstructorUser = (value: string) => {
    addCourseUser({ email: value, roleCode: "prof" });
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleInputChange(event);
  };

  useEffect(() => {
    if (avatarList.length === count && count != 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [count]);

  const addCount = () => {
    setCount(count + 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const years = [
    { name: "2021", id: 2021 },
    { name: "2022", id: 2022 },
    { name: "2023", id: 2023 },
    { name: "2024", id: 2024 },
    { name: "2025", id: 2025 },
    { name: "2026", id: 2026 },
    { name: "2027", id: 2027 },
    { name: "2028", id: 2028 },
    { name: "2029", id: 2029 },
    { name: "2030", id: 2030 },
    { name: "2031", id: 2031 },
    { name: "2032", id: 2032 },
  ];

  const AcademicTerms = [
    { name: "Fall", id: "Fall" },
    { name: "Winter", id: "Winter" },
    { name: "Spring", id: "Spring" },
    { name: "Summer", id: "Summer" },
    { name: "Intersession", id: "Intersession" },
  ];

  return (
    <Formik
      initialValues={{ ...course }}
      enableReinitialize
      validationSchema={CourseSchema}
      onSubmit={() => {
        handleSubmit();
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur,
      }) => {
        return (
          <Col>
            <Row>
              <Col md={12}>
                <h1 className={styles.header + " mb-0 mt-3 pt-3"}>
                  {isEdit ? "Edit course" : "Add new course"}
                </h1>
              </Col>
              <Col md={12} className={styles.editCourseHolder}>
                <Card>
                  <Row>
                    <Form className={styles.editCourseForm + " col-12"}>
                      <Row>
                        {course.status !== 0 && (
                          <Col className="mt-4 col-12">
                            <Row className="pl-3">
                              <p>Course Status:&nbsp;</p>
                              <p> {course.status === 1 && <>Draft</>}</p>
                              <p>{course.status === 2 && <>Prepublished</>}</p>
                              <p> {course.status === 3 && <>Published</>} </p>
                            </Row>
                          </Col>
                        )}
                        <Col
                          className={
                            styles.singleEditCourseCol + " my-4 mt-4 pl-4"
                          }
                        >
                          <Row>
                            <Col>
                              <TextInput
                                onBlur={handleBlur}
                                label="Course Title"
                                name="title"
                                touched={touched.title}
                                errorMessage={errors.title}
                                value={values.title}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  textIntputHandleChange(event, handleChange)
                                }
                              />
                            </Col>
                          </Row>

                          <Row className="my-4 align-items-end">
                            <Col md={6}>
                              <Row>
                                <Col md="auto">
                                  {values.avatarFileID > 0 && (
                                    <img
                                      alt="avatar"
                                      src={`${BASE_URL}File/GetFile/${values.avatarName}`}
                                      className={styles.avatarStyle}
                                      onClick={(event: any) => {
                                        setOpen(!open);
                                        if (anchorEl === null)
                                          setAnchorEl(event.target);
                                      }}
                                    />
                                  )}
                                </Col>
                                <Col className=" d-flex align-items-center">
                                  {avatarList[0] != undefined &&
                                    avatarList.map((avatar: any) => {
                                      if (avatar.id === course.avatarFileID) {
                                        return (
                                          <div
                                            onClick={(event: any) => {
                                              setOpen(!open);
                                            }}
                                          >
                                            <p
                                              className={styles.cursor}
                                              onClick={(event: any) => {
                                                setAnchorEl(event.target);
                                              }}
                                            >
                                              {avatar.description}
                                              <span>
                                                <img
                                                  src={iconArrowDown}
                                                  className={styles.icon}
                                                />
                                              </span>
                                            </p>
                                            <Popover
                                              open={open}
                                              anchorEl={anchorEl}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "center",
                                              }}
                                              transformOrigin={{
                                                vertical: "center",
                                                horizontal: "left",
                                              }}
                                            >
                                              <Typography>
                                                <div
                                                  className={
                                                    styles.popupCard + " p-3"
                                                  }
                                                >
                                                  {loading && (
                                                    <div className=" text-center">
                                                      <CircularProgress
                                                        size={40}
                                                        className={
                                                          styles.progressBar
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                  {avatarList &&
                                                    avatarList.map(
                                                      (avatar: any) => {
                                                        return (
                                                          <img
                                                            alt="avatar"
                                                            hidden={loading}
                                                            src={`${BASE_URL}File/GetFile/${avatar.fileName}`}
                                                            onLoad={(
                                                              e: any
                                                            ) => {
                                                              if (loading) {
                                                                addCount();
                                                              }
                                                            }}
                                                            className={
                                                              styles.avatarPickStyle
                                                            }
                                                            onClick={(
                                                              e: any
                                                            ) => {
                                                              handleAvatarChange(
                                                                avatar.fileName,
                                                                avatar.id
                                                              );
                                                              setAnchorEl(null);
                                                            }}
                                                          />
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </Typography>
                                            </Popover>
                                          </div>
                                        );
                                      }
                                    })}
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <SelectWithLabel
                                onBlur={handleBlur}
                                label="Academic term"
                                name="academicTermName"
                                values={AcademicTerms}
                                value={course.academicTermName}
                                onChange={(event: any) => {
                                  handleSelectChange(event);
                                  handleChange(event);
                                }}
                              />
                            </Col>
                            <Col>
                              <SelectWithLabel
                                onBlur={handleBlur}
                                label="Year"
                                name="academicTermYear"
                                values={years}
                                value={course.academicTermYear}
                                onChange={(event: any) => {
                                  handleSelectChange(event);
                                  handleChange(event);
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="my-4">
                            <Col md={12}>
                              <p className={styles.label}>Course Duration</p>
                            </Col>
                            <Col md={6} className={styles.datePickerHolder}>
                              <DatePicker
                                onBlur={handleBlur}
                                value={
                                  moment(values.startDate, "MM/DD/YYYY") || null
                                }
                                labelPar="From"
                                name="startDate"
                                touched={touched.startDate}
                                errorMessage={errors.startDate}
                                placeholder="mm/dd/yyyy"
                                onChange={(date) => {
                                  setFieldValue("startDate", date);
                                  handleDateChange(date, "startDate");
                                }}
                              />
                            </Col>
                            <Col md={6} className={styles.datePickerHolder}>
                              <DatePicker
                                onBlur={handleBlur}
                                value={
                                  moment(values.endDate, "MM/DD/YYYY") || null
                                }
                                labelPar="To"
                                name="endDate"
                                touched={touched.endDate}
                                errorMessage={errors.endDate}
                                placeholder="mm/dd/yyyy"
                                onChange={(date) => {
                                  setFieldValue("endDate", date);
                                  handleDateChange(date, "endDate");
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className={
                            styles.singleEditCourseCol + " my-4 mt-4 pl-4"
                          }
                        >
                          <MultipleSelector
                            textInputLabel="Co-instructor"
                            textInputName="coInstructorList"
                            list={values.coInstructorList}
                            removeValue={removeCourseUser}
                            firstRowClassName="align-items-baseline"
                            onButtonClick={addInstructorUser}
                          />

                          <Row className="mt-4">
                            <Col md={6} className=" mb-3 mb-md-0">
                              <TextInput
                                onBlur={handleBlur}
                                label="Course Code"
                                name="courseCode"
                                touched={touched.courseCode}
                                errorMessage={errors.courseCode}
                                value={values.courseCode}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  textIntputHandleChange(event, handleChange)
                                }
                              />
                            </Col>
                            <Col md={6}>
                              <TextInput
                                onBlur={handleBlur}
                                label="Name of Currency"
                                name="currencyName"
                                touched={touched.currencyName}
                                errorMessage={errors.currencyName}
                                value={values.currencyName}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  textIntputHandleChange(event, handleChange)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="justify-content-end">
                        <Col sm="auto">
                          <Button
                            isDialogCancel={true}
                            title="Cancel"
                            type="button"
                            onClick={() => {
                              resetCourse();
                              history.goBack();
                            }}
                          />
                        </Col>
                        <Col sm="auto">
                          <Button
                            name="addCourse"
                            id="addCourse"
                            type="submit"
                            title={isEdit ? "Save" : "Add Course"}
                            isDialog={true}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        );
      }}
    </Formik>
  );
};

export default observer(CUCourse);
