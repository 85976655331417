import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import {
  AssignmentRequestDTO,
  GradebookCourseUserRequest,
} from "../../../../models/request.models";
import { CourseUserGradebookDTO } from "../../../../models/state.models";
import { GradebookSaveDTO } from "../../../../models/request.models";
import { useDialog, useGradebookStore, useAuth } from "../../../../stores";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import Iconback from "../../../../assets/images/arrow-right.svg";
import StudentGradebookPointRow from "../StudentGradebookPointRow";
import styles from "./styles.module.scss";

const StudentGradebookPoint = () => {
  const {
    getCourseUserList,
    courseUserGradebookList,
    getAssignmentCodeList,
    resetStudentsList,
    saveGradebookList,
    selectedAssignmentID,
    resetInput,
  } = useGradebookStore();
  const { setCourseName } = useAuth();

  const { courseId, sectionId, academicTermId }: any = useParams();
  const { showDialog } = useDialog();
  const history = useHistory();

  useEffect(() => {
    resetStudentsList();
  }, []);

  useEffect(() => {
    let assignmentRequest: AssignmentRequestDTO = {
      id: 0,
      assignmentID: 0,
      courseID: courseId,
    };
    getAssignmentCodeList(assignmentRequest).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${academicTermId}/courses`,
            history,
            true,
            "Error"
          );
        }
        showDialog(resp.message, null, null, true, "Error");
      }
      if (resp.status === "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
    });
  }, [courseId]);

  const prepareRequest = () => {
    let request: GradebookSaveDTO = {
      courseID: courseId,
      gradebookList: [],
    };
    for (let student of courseUserGradebookList) {
      let gradebookObject = {
        id: 0,
        gradebookID: student.gradebookID,
        courseID: courseId,
        assignmentID: student.assignmentID,
        points: student.points,
        courseUserID: student.courseUserID,
      };
      request.gradebookList.push(gradebookObject);
    }
    return request;
  };

  const handlerSavePoint = () => {
    let request = prepareRequest();
    saveGradebookList(request).then((response: any) => {
      if (response.status === "success") {
        showDialog(
          "Points successfully saved!",
          null,
          null,
          false,
          "Notification"
        );

        let request: GradebookCourseUserRequest = {
          id: 0,
          courseUserID: 0,
          courseID: courseId,
          sectionID: sectionId,
          assignmentID: selectedAssignmentID,
        };
        getCourseUserList(request);
        resetInput();
      } else {
        showDialog(response.message, null, null, false);
      }
    });
  };

  const handlerFilterSelect = (event: any) => {
    let sectionID = Number(event?.target.value);
    let request: GradebookCourseUserRequest = {
      id: 0,
      courseUserID: 0,
      courseID: 0,
      assignmentID: 0,
      sectionID: 0,
    };
    getCourseUserList(request);
  };

  return (
    <>
      <Row className="justify-content-between px-3">
        <table className="mt-4">
          <thead>
            {selectedAssignmentID != 0 && (
              <tr className={styles.mainTableHolder}>
                <th>Name</th>
                <th>Current Points</th>
                <th>Points</th>
                <th>Is Buyback</th>
              </tr>
            )}
          </thead>
          <tbody>
            {courseUserGradebookList &&
              courseUserGradebookList.map((student: CourseUserGradebookDTO) => {
                return (
                  <StudentGradebookPointRow
                    key={student.courseUserID}
                    student={student}
                  />
                );
              })}
          </tbody>
        </table>
      </Row>
      <Row className="justify-content-end mt-4">
        <Col sm="auto">
          <div className={styles.mainButtonWrapper}>
            <ButtonWithImage
              isDialogCancel={true}
              icon={Iconback}
              onClick={() => {
                history.goBack();
              }}
              title="Go back"
              type="button"
            />
          </div>
        </Col>
        <Col sm="auto">
          <div className={styles.mainButtonWrapper}>
            <ButtonWithImage
              title="Save"
              type="button"
              onClick={handlerSavePoint}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default observer(StudentGradebookPoint);
