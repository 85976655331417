import React from "react";
import { action, makeObservable, observable, runInAction } from "mobx";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import {
  AssignmentDetails,
  AssignmentsForList,
  Opportunity,
  AssignmentOrder,
  UserCurrencyNameAndValue,
} from "../../models/state.models";
import { SaveAssignmentRequest } from "../../models/request.models";
import { FetchApi } from "../../utilities";

class AssignmentSore {
  @observable status: string = "inital";

  @observable message: string = "";

  @observable ID: number = 0;

  @observable userCredits: UserCurrencyNameAndValue = {
    credit: 0,
    currencyName: "tokens",
  };

  @observable courseName: string = "";

  @observable searchActivityList: AssignmentsForList[] = [];

  @observable totalCountOfAssignments: number = 0;

  @observable assignmentStoreLoading: boolean = false;

  @observable opportunityList: Opportunity[] = [];

  @observable assignmentsOrder: AssignmentOrder[] = [];

  @observable AssignmentTitle: string = "";

  @observable assignment: AssignmentDetails = {
    id: 0,
    courseID: 0,
    name: "",
    isExtraCredit: false,
    isBuyBack: false,
    maxPoints: "",
    buyBackNumber: 0,
    minBuyBackPrice: "",
    isLimitedBuyBacks: false,
    buyBackDateTo: "",
    maxBuyBackPrice: "",
    totalPointsLost: "",
    totalPointsEarned: "",
    dateTo: "",
    additionalBuyBackRequirements: "",
    numberOfPurchases: "",
    unresolvedPurchases: "",
    isEntireSemester: false,
  };

  @observable opportunity: Opportunity = {
    id: 0,
    name: "",
    additionalBuyBackRequirements: "",
    assignmentCreditCost: 0,
    isPending: false,
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async saveAssignment(params: SaveAssignmentRequest) {
    this.assignmentStoreLoading = true;
    const { data, status, totalAssignmentCount }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/SaveAssignment",
      params,
    });
    runInAction(() => {
      this.assignmentStoreLoading = false;
      if (status === "success") {
        this.searchActivityList = data;
        this.totalCountOfAssignments = totalAssignmentCount;
      }
    });

    return { status: status };
  }

  @action.bound
  async getOpportunity(courseId: number) {
    this.getUserCredits(courseId);
    this.assignmentStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/GetPurchasingStudentAssignments",
      params: { CourseID: courseId },
    });
    runInAction(() => {
      this.assignmentStoreLoading = false;
      if (status === "success") {
        this.opportunityList = data;
      }
    });

    return { status, message };
  }

  @action.bound
  async getUserCredits(courseId: number) {
    this.assignmentStoreLoading = true;
    const { data, status, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/GetUserCreditAmount",
      params: { CourseID: courseId },
    });
    runInAction(() => {
      this.assignmentStoreLoading = false;
      if (status === "success") {
        this.courseName = breadCrumb.courseName;
        this.userCredits = data;
      }
    });

    return { status: status };
  }

  @action.bound
  async deleteAssignment(idCourse: number, assignmentID: number) {
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/DeleteAssignment",
      params: { courseID: idCourse, ID: assignmentID },
    });
    return { status, message };
  }

  @action.bound
  async getAssignment(id: number, courseId: number) {
    this.assignmentStoreLoading = true;
    const { data, status, message }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/GetAssignment",
      params: { assignmentId: id, CourseID: courseId },
    });
    runInAction(() => {
      this.assignmentStoreLoading = false;
      if (status === "success") {
        this.assignment = data;
        this.assignment.dateTo = moment(data.dateTo).format("MM-DD-YYYY");
        this.assignment.buyBackDateTo = moment(data.buyBackDateTo).format(
          "MM-DD-YYYY"
        );

        if (data.buyBackNumber === 0 || data.buyBackNumber === undefined) {
          this.handleLimitBuyBackChange(false);
        } else {
          this.handleLimitBuyBackChange(true);
        }
        if (data.buyBackDateTo === "" || data.buyBackDateTo === undefined) {
          this.assignment.buyBackDateTo = "";
        }
      }
    });
    return { status, message };
  }

  @action.bound
  async changeSubmittedAssignmentStatus(
    courseId: number,
    submittedAssignmentId: number,
    assignmentStatus: number,
    note: string
  ) {
    this.assignmentStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/ChangeSubmittedAssignmentStatus",
      params: {
        CourseID: courseId,
        submittedAssignmentID: submittedAssignmentId,
        Status: assignmentStatus,
        Note: note,
      },
    });
    this.assignmentStoreLoading = false;
    return { status, message };
  }

  @action.bound
  async saveAssignmentPurchase(courseID: number, assignmentID: number) {
    this.assignmentStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/PurchaseAssignment",
      params: { courseId: courseID, assignmentId: assignmentID },
    });
    runInAction(() => {
      this.assignmentStoreLoading = false;
      if (status === "success") {
      }
    });

    return { status, message };
  }

  @action.bound
  async saveChangeAssignmentOrder() {
    this.assignmentStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/AssignmentApi/SaveAssignmentOrdering",
      params: this.assignmentsOrder,
    });
    this.assignmentStoreLoading = false;
    return { status, message };
  }

  @action.bound
  handleAssignmentInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.assignment[event.target.name] = event.target.value;
  }

  @action.bound
  handleChangeAssignmentOrder = (items: any) => {
    let order = 1;
    this.assignmentsOrder = [];
    items.map((item: any) => {
      this.assignmentsOrder.push({ ID: item.id, OrderNo: order });
      order += 1;
    });
    this.saveChangeAssignmentOrder();
  };

  @action.bound
  setAssignmentTitle = (name: string) => {
    this.AssignmentTitle = name;
  };

  @action.bound
  handleDateChange(date: MaterialUiPickersDate, name: string) {
    this.assignment[name] = moment(date).format("MM-DD-YYYY");
  }

  @action.bound
  handleLimitBuyBackChange(state: boolean) {
    this.assignment.isLimitedBuyBacks = state;
  }

  @action.bound
  handleCheckboxActivity(event: React.ChangeEvent<HTMLInputElement>) {
    this.assignment[event.target.name] = !this.assignment[event.target.name];
    if (
      event.target.name === "isBuyBack" &&
      this.assignment.isBuyBack === false
    ) {
      this.assignment.isLimitedBuyBacks = false;
    }
    if (
      event.target.name === "isLimitedBuyBacks" &&
      this.assignment.isLimitedBuyBacks === true
    ) {
      this.assignment.buyBackNumber = 1;
    }
  }

  @action.bound
  resetAssignment() {
    this.assignment = {
      id: 0,
      courseID: 0,
      name: "",
      isExtraCredit: false,
      isBuyBack: false,
      maxPoints: "",
      buyBackNumber: 0,
      buyBackDateTo: "",
      minBuyBackPrice: "",
      maxBuyBackPrice: "",
      dateTo: "",
      totalPointsLost: "",
      totalPointsEarned: "",
      isLimitedBuyBacks: false,
      additionalBuyBackRequirements: "",
      numberOfPurchases: "",
      unresolvedPurchases: "",
      isEntireSemester: false,
    };
  }
}
export default AssignmentSore;
