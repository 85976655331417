import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BaseTable from "./Pages/Components/BaseTable";
import TableFilter from "./Pages/Components/TableFilter";
import TablePager from "./Pages/Components/TablePager";
import CourseTable from "./Pages/Courses/Components/CourseTable";
import { useDialog } from "./stores";
import { postApi } from "./utils";

// Usage
export function TestListControl(props) {
  const [needsExecute, setNeedsExecute] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [filterParameters, setFilterParameters] = useState(
    props.list.filterParameters || []
  );
  const [page, setPage] = useState(props.list.page);
  const [pageSize, setPageSize] = useState(props.list.pageSize);
  const [orderBy, setOrderBy] = useState(props.list.orderBy);
  const [orderByDirection, setOrderByDirection] = useState(
    props.list.orderByDirection
  );
  const [url, setUrl] = useState(props.list.url || "");
  const [data, setData] = useState(props.list.data || "");
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const parameters = {
    filterParameters,
    page,
    pageSize,
    orderBy,
    orderByDirection,
  };
  const getList = () => {
    return postApi({
      url: url,
      params: { ...parameters, data },
    }).then((result) => {
      if (result.data && result.data.isSuccess) {
        setEntityList(result.data.data);
        const {
          filterParameters: _filterParameters,
          totalCount: _totalCount,
          page: _page,
          pageSize: _pageSize,
          orderBy: _orderBy,
          orderByDirection: _orderByDirection,
        } = { ...result.data, ...result.data.parameters };
        if (_page > 0) setPage(_page);
        if (_pageSize > 0) setPageSize(_pageSize);
        if (_orderBy) setOrderBy(_orderBy);
        if (_orderByDirection) setOrderByDirection(_orderByDirection);
        if (_totalCount) {
          var total = _totalCount > 0 ? _totalCount : 0;
          setTotalCount(total);
          if (_pageSize > 0 || pageSize > 0)
            setPageCount(_totalCount / (_pageSize || pageSize));
          else setPageCount(1);
        }
        if (_filterParameters && _filterParameters.length > 0)
          _filterParameters.forEach((fp) =>
            changeFilterParameter(fp.name, fp.value)
          );
      } else {
        //Promise.reject(result.data && result.data.message ? result.data.message : 'There was an error executing api call');
        throw result.data && result.data.message
          ? result.data.message
          : "There was an error executing api call";
      }
    });
  };
  const { execute, status, value, error } = useAsync(getList, false);
  const urlParams = useParams();
  const urlHistory = useHistory();
  const urlLocation = useLocation();
  const executeQueue = () => {
    setNeedsExecute(true);
  };
  const changeOrderBy = (newOrderBy) => {
    if (orderBy === newOrderBy) {
      setOrderByDirection(orderByDirection === "desc" ? "asc" : "desc");
    } else {
      setOrderBy(newOrderBy);
      setOrderByDirection("asc");
    }
    executeQueue();
  };
  const changeFilterParameter = (parameterName, newValue) => {
    const fps = [...filterParameters] || [];
    const param = getFilterParameter(parameterName, fps, false);
    param.value = newValue;
    setFilterParameters(fps);
    return param;
  };
  const initFilterParameter = (
    { name, operator, dataType, value },
    fps = [...filterParameters],
    callSetParams = true
  ) => {
    var param = getFilterParameter(name, fps, false);
    param = Object.assign(param, { ...param, operator, dataType, value });
    if (callSetParams) setFilterParameters(fps);
    return param;
  };
  const initFilterParameterList = (parameterList) => {
    const fps = [...filterParameters] || [];
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((element) => {
        initFilterParameter(element, fps, false);
      });
      setFilterParameters(fps);
    }
  };
  const getFilterParameter = (
    parameterName,
    fps = [...filterParameters],
    callSetParams = true
  ) => {
    var fnd;
    fps = fps || [];
    fnd = fps.find((item) => item.name === parameterName);
    if (!fnd) {
      fnd = { name: parameterName };
      fps.push(fnd);
      if (callSetParams) setFilterParameters(fps);
    }
    return fnd;
  };
  const changePageSize = (newPageSize) => {
    if (newPageSize > 0) {
      setPage(1);
      setPageSize(newPageSize);
      executeQueue();
    }
  };
  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= pageCount) {
      setPage(newPage);
      executeQueue();
    }
  };
  const getPrevPath = (path) => {
    var retval = path;
    if (path && path.length > 0) {
      var p = path.split("/");
      if (p && p.length > 1) {
        p.pop();
        var str = p[p.length - 1];
        if (
          str.toLowerCase().startsWith("view") ||
          str.toLowerCase().startsWith("edit")
        ) {
          retval = getPrevPath(p.join("/"));
        } else {
          retval = p.join("/");
        }
      }
    }
    return retval;
  };
  const close = () => {
    urlHistory.push(
      getPrevPath(
        urlLocation.pathname
      ) /*+ (urlLocation.search ? urlLocation.search : '') + (urlLocation.hash ? urlLocation.hash : '')*/
    );
  };

  useEffect(() => {
    var hasParams = false;
    var param = {};
    if (urlParams) {
      for (var p in urlParams) {
        param[p] = urlParams[p];
        hasParams = true;
      }
    }
    if (hasParams) {
      setData({ ...data, ...param });
      setNeedsExecute(true);
    } else {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (needsExecute) {
      execute();
      setNeedsExecute(false);
    }
  }, [execute, needsExecute]);
  const functions = {
    changeOrderBy,
    changeFilterParameter,
    initFilterParameter,
    initFilterParameterList,
    getFilterParameter,
    changePageSize,
    changePage,
  };

  useEffect(() => {
    if (status === "error") {
      alert(error);
    }
  }, [error]);
  return (
    <div>
      {status === "idle" && <div>Start your journey by clicking a button</div>}
      {status === "error" && <div>{error}</div>}
      <button
        onClick={() => {
          setPage(1);
          setNeedsExecute(true);
        }}
        disabled={status === "pending"}
      >
        {status !== "pending" ? "Refresh" : "Loading..."}
      </button>
      <button
        onClick={() => {
          close();
        }}
        disabled={status === "pending"}
      >
        Close
      </button>
      {status === "success" && (
        <>
          {React.Children.map(props.children, (child, i) => {
            // props
            return React.cloneElement(child, {
              list: {
                ...parameters,
                entityList,
                pageCount,
                totalCount,
                ...functions,
              },
            });
          })}
        </>
      )}
    </div>
  );
}

export function TestTable({ list }) {
  return (
    <table>
      <tr>
        <td onClick={() => list.changeOrderBy("id")}>
          ID{" "}
          {list.orderBy === "id" &&
            (list.orderByDirection === "desc" ? "↓" : "↑")}
        </td>
        <td onClick={() => list.changeOrderBy("title")}>
          Title{" "}
          {list.orderBy === "title" &&
            (list.orderByDirection === "desc" ? "↓" : "↑")}
        </td>
        <td onClick={() => list.changeOrderBy("startDate")}>
          Start Date{" "}
          {"startDate".localeCompare(list.orderBy, undefined, {
            sensitivity: "accent",
          }) === 0 && (list.orderByDirection === "desc" ? "↓" : "↑")}
        </td>
        <td onClick={() => list.changeOrderBy("endDate")}>
          End Date{" "}
          {list.orderBy === "endDate" &&
            (list.orderByDirection === "desc" ? "↓" : "↑")}
        </td>
      </tr>
      {list &&
        list.entityList &&
        list.entityList.map((entity) => (
          <tr>
            <td>{entity.id}</td>
            <td>{entity.title}</td>
            <td>{entity.startDate}</td>
            <td>{entity.endDate}</td>
          </tr>
        ))}
    </table>
  );
}

export function TestPager({ list }) {
  const pages = [];
  if (list.pageCount > 0) {
    for (var i = 1; i <= list.pageCount; i++) pages.push(i);
  }
  return (
    <div>
      <label for="id_pager_page_size">Pg Size</label>
      <input
        id="id_pager_page_size"
        type="text"
        value={list.pageSize}
        onChange={(e) => list.changePageSize(e.target.value)}
      ></input>
      <ul>
        {pages.map((pg) => (
          <li onClick={() => list.changePage(pg)}>
            {pg} {pg === list.page ? "*" : ""}
          </li>
        ))}
      </ul>
    </div>
  );
}

export function TestFilter({ list }) {
  /*
  useEffect(() => {
    list.initFilterParameterList([
      {name: 'ID', dataType: 'int', operator: '='},
      {name: 'Title', dataType: 'string', operator: 'like'},
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */
  return (
    <div>
      <label for="id_filter_id">ID</label>
      <input
        id="id_filter_id"
        type="number"
        value={list.getFilterParameter("ID").value}
        onChange={(e) => list.changeFilterParameter("ID", e.target.value)}
      ></input>
      <label for="id_filter_title">Title</label>
      <input
        id="id_filter_title"
        type="text"
        value={list.getFilterParameter("Title").value}
        onChange={(e) => list.changeFilterParameter("Title", e.target.value)}
      ></input>
    </div>
  );
}

// Hook
export const useAsync = (asyncFunction, immediate = true) => {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const { showDialog } = useDialog();
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus("pending");
    setValue(null);
    setError(null);
    return asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus("success");
      })
      .catch((error) => {
        setError(
          error && error.message
            ? error.message
            : error
            ? JSON.stringify(error)
            : error
        );
        setStatus("error");
      });
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
};

export function TestList() {
  return (
    <BaseTable
      list={{
        url: "/CourseApi/GetCourseList",
        filterParameters: [
          { name: "ID", dataType: "int", operator: "=" },
          { name: "Title", dataType: "string", operator: "like" },
        ],
      }}
    >
      <TableFilter></TableFilter>
      <CourseTable></CourseTable>
      <TablePager></TablePager>
    </BaseTable>
  );
}

// export default withRouter(TestList);
// export default TestList;
