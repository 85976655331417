import React from "react";

import AddNewSection from "../../../Pages/AddNewSection";
import EditSection from "../../../Pages/EditSection";
import Section from "../../../Pages/Section";
import SectionDetails from "../../../Pages/SectionDetails";
import SectionGroups from "../../../Pages/SectionGroups";
import SectionStudents from "../../../Pages/SectionStudents";
import PrivateRoute from "../../PrivateRoute";
import StudentPage from "../../../Pages/StudentPage";
import ActivityValidations from "../../../Pages/ActivityValidations";

const SectionRoutes = () => {
  return (
    <>
      <PrivateRoute
        component={Section}
        path="/academicTerms/:academicTermId/courses/:courseId/sections"
        exact
      />
      <PrivateRoute
        component={AddNewSection}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/add"
      />
      <PrivateRoute
        component={EditSection}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/edit/:sectionId"
      />
      <PrivateRoute
        component={SectionDetails}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/details"
        exact
      />
      <PrivateRoute
        component={SectionStudents}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/students"
        exact
      />
      <PrivateRoute
        component={SectionGroups}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/groups"
      />
      <PrivateRoute
        component={StudentPage}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/students/student/:CourseUserID/:userId"
      />
      <PrivateRoute
        component={ActivityValidations}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/activityValidations"
        exact
      />
      <PrivateRoute
        component={ActivityValidations}
        path="/academicTerms/:academicTermId/courses/:courseId/sections/:sectionId/activityValidations/:activityId"
      />
    </>
  );
};

export default SectionRoutes;
