import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { BASE_URL } from "../../../../utilities";
import { File } from "../../../../models/state.models";
import { useActivities, useAuth } from "../../../../stores";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import Card from "../../../../common/Card";
import editIcon from "../../../../assets/images/edit.svg";
import fileIcon from "../../../../assets/images/file-svg.svg";
import Icon from "../../../../assets/images/back.svg";
import StudnetForms from "../StudentForms";
import RecentPointsSubmittedTable from "../RecentPointsSubmittedTable";
import styles from "./styles.module.scss";
import SectionNames from "../../../ActivitySectionsValidation/Component/SectionNames";
import ValidatonTermsTable from "./Component";

const ActivityOverview: React.FC = () => {
  const { courseId, activityId, academicTermId, sectionId }: any = useParams();
  const history = useHistory();

  const {
    activity,
    listOfDocumentsForActivity,
    resetActivity,
    getActivity,
    getActivitySectionNames,
    activitySections,
    selectedActivitySection,
    getSectionsForActivityDetails,
    validationSubmitReset,
    currencyName,
    setSelectedActivitySection,
  } = useActivities();
  const { role, setCourseName } = useAuth();

  useEffect(() => {
    getActivity(courseId, activityId).then((resp: any) => {
      if (resp.status == "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
      if (role != "Student") {
        getSectionsForActivityDetails(courseId);
      }

      setSelectedActivitySection(0);
    });
  }, [getActivity, activityId]);

  useEffect(() => {
    getActivity(courseId, activityId).then((resp: any) => {
      if (resp.status == "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
    });

    getActivitySectionNames(courseId, activityId);
  }, [getActivity, activityId, sectionId]);

  const activityType = activity.isIndividual ? "students" : "groups";
  return (
    <Card
      isCard={true}
      isFullScreen={true}
      isWithOutTopMargin={role === "Student" ? false : true}
      isDetails={true}
      isCoursesTabs={true}
    >
      <Col>
        <Row className="justify-content-end pb-3 m-0">
          <ButtonWithImage
            title="Back"
            onClick={() => {
              resetActivity();
              validationSubmitReset();
              history.push(
                `/academicTerms/${academicTermId}/courses/${courseId}/activities/`
              );
            }}
            icon={Icon}
          />
        </Row>
        <Row className={styles.courseOverviewWrapper + " mb-0"}>
          <Col md="auto" className={styles.singleCol + " mt-3"}>
            <h2 className={styles.nameFont}>{activity.name}</h2>
            <Row className=" pl-3">
              {!activity.isPresence ? (
                <>
                  <h4>
                    {activity.isIndividual ? "individual" : "group"}
                    &nbsp;&nbsp;|
                  </h4>
                  <h4 className={styles.greenText + " pl-2"}>
                    {activity.isInClass ? "in class" : "out of class"}
                  </h4>
                </>
              ) : (
                <h4 className="pt-3">Attendance</h4>
              )}
            </Row>
          </Col>
          <Col
            md="auto"
            xs={12}
            className={
              styles.courseDetailsSectionWrapper +
              " " +
              styles.singleCol +
              " mt-3"
            }
          >
            <p>Duration</p>
            <h4>
              {activity.startDate} - {activity.endDate}
            </h4>
          </Col>
          <Col
            md="auto"
            xs={12}
            className={
              styles.courseDetailsSectionWrapper +
              " " +
              styles.singleCol +
              " mt-3"
            }
          >
            <p>{currencyName}</p>
            <h4 className={styles.greenText}>{activity.credit}</h4>
          </Col>
          {role === "Professor" && (
            <div className={styles.iconCell + " " + styles.editIconWrapper}>
              <img
                alt="Edit"
                className={styles.icon}
                src={editIcon}
                onClick={() => {
                  resetActivity();
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/activities/edit/${activityId}`
                  );
                }}
              />
            </div>
          )}
        </Row>
        <Row className="pt-3 pb-3">
          <Col md={6}>
            <Row>
              <p className={styles.greyFontColor}>{activity.description}</p>
            </Row>
          </Col>
        </Row>
        <Row className="pt-3 pb-3">
          <Col>
            {role !== "Student" && (
              <Row>
                <Col>
                  <Row className=" pb-2">
                    <h4>Accompanying documentation</h4>
                  </Row>
                  {listOfDocumentsForActivity.map(
                    (file: File, index: number) =>
                      file.type === 2 && (
                        <Row className=" pt-3" key={index}>
                          <img className=" pr-3" src={fileIcon} alt="Delete" />
                          <p
                            className={styles.fileCursor}
                            onClick={() =>
                              file.fileName &&
                              window.open(
                                `${BASE_URL}File/GetFile/${file.fileName}`,
                                "_blank"
                              )
                            }
                          >
                            {file.name}
                          </p>
                        </Row>
                      )
                  )}
                  <Row className=" pt-5">
                    <h4>Validation terms</h4>
                  </Row>
                  <Row className="mt-3">
                    <Col md="auto">
                      <SectionNames list={activitySections} isFromUrl={false} />
                    </Col>

                    {selectedActivitySection !== 0 && activityId && (
                      <>
                        <Col className="ml-3 mr-4">
                          <ValidatonTermsTable />
                        </Col>
                        <Col md={12}>
                          <Row className="justify-content-end pb-3 m-0 mt-3">
                            <ButtonWithImage
                              title="View Submited Validations"
                              onClick={() => {
                                history.push(
                                  `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/` +
                                    activityType
                                );
                              }}
                            />
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {role != "Student" && activity && activity.latestSubmissions && (
          <>
            <h4 className="my-4">Recent points submitted</h4>
            <RecentPointsSubmittedTable list={activity} />
          </>
        )}
        {role === "Student" && listOfDocumentsForActivity && (
          <Row className="  pb-4 pt-3 pl-3 d-inline-flex">
            {listOfDocumentsForActivity.map(
              (file: File, index: number) =>
                file.type === 2 && (
                  <Col className={styles.maxContent + " pr-5"} key={index}>
                    <Row>
                      <img className=" pr-3" src={fileIcon} alt="Delete" />

                      <p
                        className={styles.fileCursor}
                        onClick={() =>
                          file.fileName &&
                          window.open(
                            `${BASE_URL}File/GetFile/${file.fileName}`,
                            "_blank"
                          )
                        }
                      >
                        {file.name}
                      </p>
                    </Row>
                  </Col>
                )
            )}
          </Row>
        )}
        {role === "Student" && <StudnetForms />}
      </Col>
    </Card>
  );
};

export default observer(ActivityOverview);
