import React, { useState } from "react";
import cc from "classcat";
import { FormikErrors, FormikTouched } from "formik";

import styles from "./styles.module.scss";

type CustomInputProps = {
  errorMessage?:
    | string
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];

  label?: string;
  name: string;
  maxWidth?: boolean;
  handler: any;
  fileType?: number;
} & React.HTMLProps<HTMLInputElement>;

const CustomInput: React.FC<CustomInputProps> = ({
  errorMessage,
  touched,
  label,
  fileType,
  handler,
  name,
}) => {
  const [documentName, setDocumentName] = useState<string>("");

  return (
    <div className="form-group">
      <p className={styles.accName + " pb-2"}>{label}</p>
      <div className=" custom-file">
        <input
          type="file"
          id="activityFiles2"
          name={name}
          accept=".pdf, .doc, docx, image/*"
          onChange={(event: any) => {
            setDocumentName(event.target.files[0].name);

            handler(event, fileType);
          }}
        />
        <label
          htmlFor="activityFiles2"
          className={cc([
            styles.rounded4 + " custom-file-label cursor-pointer",
            { [styles.errorWrapper]: touched && errorMessage },
          ])}
        >
          <span>{documentName}</span>
        </label>
      </div>
      {errorMessage && touched && (
        <p className={styles.errorMsg}>{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInput;
