import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Notifications from "../../Pages/Notifications";
import Settings from "../../Pages/Settings";
import SpendOpportunities from "../../Pages/SpendOpportunities";
import StudentScheduler from "../../Pages/StudentScheduler";
import { useAuth, useDialog } from "../../stores";
import PrivateRoute from "../PrivateRoute";
import ActivityRoutes from "./ActivityRoutes";
import AssignmentsRoutes from "./AssignmentsRoutes";
import CourseRoutes from "./CourseRoutes";
import GradebookRoutes from "./GradebookRoutes";
import ProfileRoutes from "./ProfileRoutes";
import SectionRoutes from "./SectionRoutes";
import UserRoutes from "./UserRoutes";

const Routes = () => {
  const { showDialog } = useDialog();
  const { getCurrentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getCurrentUser().then((resp: any) => {
      if (resp.status === "error") {
        showDialog(resp.message, "/", history, true, "Error");
      }
    });
  }, [getCurrentUser]);

  return (
    <>
      <UserRoutes />
      <ProfileRoutes />
      <CourseRoutes />
      <SectionRoutes />
      <ActivityRoutes />
      <AssignmentsRoutes />
      <GradebookRoutes />

      <PrivateRoute component={Notifications} path="/notification" />
      <PrivateRoute component={Settings} path="/settings" />

      <PrivateRoute
        component={SpendOpportunities}
        path="/academicTerms/:academicTermId/courses/:courseId/spendOpportunities"
      />
      <PrivateRoute
        component={StudentScheduler}
        path="/academicTerms/:academicTermId/scheduler"
      />
    </>
  );
};

export default Routes;
