import React from "react";
import { action, makeObservable, observable } from "mobx";

class DialogNotificationStore {
  @observable title: string = "Notification";
  @observable message: string = "";
  @observable isOpen: boolean = false;
  @observable isResponseNotification: boolean = true;
  @observable redirectionPath: string | null = null;
  @observable history: any = null;
  @observable isErrorDialog: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setDialogTitle(title: string) {
    this.title = title;
  }

  @action.bound
  showDialog(
    message: string,
    redirectionPath: string | null,
    history: any,
    isError: boolean,
    title: string = "Notification"
  ) {
    this.history = history;
    this.redirectionPath = redirectionPath;
    this.message = message;
    this.isOpen = true;
    this.isErrorDialog = isError;
    this.title = title;
  }

  @action.bound
  closeDialog() {
    this.isOpen = false;
  }

  @action.bound
  setIsErrorDialog(value: boolean) {
    this.isErrorDialog = value;
  }
}

export default DialogNotificationStore;
