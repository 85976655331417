import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { useHistory, useParams } from "react-router";

import { ActivityForList } from "../../../../models/state.models";
import { useActivities, useDialog, useAuth } from "../../../../stores";
import editIcon from "../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../Components/MessageBoxWithButtons";
import trashIcon from "../../../../assets/images/trash.svg";
import viewIcon from "../../../../assets/images/view.svg";
import styles from "./styles.module.scss";
import {
  removeUtcOffset,
  UseAddUtcOffsetWithDate,
} from "../../../../utils/timeUtils";

interface ActivityRowProps {
  activity: ActivityForList;
  list: any;
}

const ActivityTableRow: React.FC<ActivityRowProps> = ({ activity, list }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [timeFrom, setTimeFrom] = useState("");
  const { courseId, academicTermId }: any = useParams();
  const { deleteActivity } = useActivities();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activity.id}/details`
    );
  };

  const onUnresolvedAppllicationsClick = () => {
    if (activity.isIndividual)
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activity.id}/students`
      );
    else {
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activity.id}/groups`
      );
    }
  };

  const handleConfirm = () => {
    deleteActivity(courseId, activity.id).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        list.refresh();
        showDialog("Activity successfully deleted", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  useEffect(() => {
    if (activity.hourTo) {
      const { endDate, time, startDate, timeStart } = UseAddUtcOffsetWithDate(
        activity.endDate,
        activity.hourTo,
        activity.startDate,
        activity.hourFrom
      );
      setTimeTo(time);
      setEndDate(endDate);
      setStartDate(startDate);
      setTimeFrom(timeStart);
    }
  }, [activity.hourTo, activity.hourFrom]);

  return (
    <>
      <MessageBoxWithButtons
        handleClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isOpenDialog}
        message={`Are you sure you want to delete ${activity.name} activity?`}
        title="Alert"
      />
      <tr onClick={onTableDetailsClick}>
        <td className={styles.tableCell} data-label="Title">
          {activity.name}
        </td>

        {role !== "Student" && (
          <td className={styles.tableCell} data-label="ID">
            {activity.id}
          </td>
        )}

        <td className={styles.tableCell} data-label="Type">
          <Row className="mx-0">
            {!activity.isPresence ? (
              <>
                <p className={styles.singleTypeEl}>
                  {activity.isIndividual ? "Individual" : "Group"}
                </p>
                |
                <p className={styles.singleTypeEl}>
                  {activity.isInClass ? "in class" : "out of class"}
                </p>
              </>
            ) : (
              <p className="mr-4">Attendance</p>
            )}
          </Row>
        </td>
        <td className={styles.tableCell} data-label="Credits">
          {activity.credit}
        </td>
        {role !== "Student" && (
          <>
            <td
              className={styles.tableCell}
              data-label="Unresolved applications"
              onClick={(e) => {
                e.stopPropagation();
                onUnresolvedAppllicationsClick();
              }}
            >
              <Row className="justify-content-center d-flex align-items-center">
                {activity.unresolvedSubmissions}

                <img alt="View" className={styles.icon} src={viewIcon} />
              </Row>
            </td>
            <td
              className={styles.tableCell}
              data-label="Rejections"
              onClick={(e) => {
                e.stopPropagation();
                onUnresolvedAppllicationsClick();
              }}
            >
              <Row className="justify-content-center d-flex align-items-center">
                {activity.rejectedSubmissions}

                <img alt="View" className={styles.icon} src={viewIcon} />
              </Row>
            </td>
          </>
        )}
        <td
          className={styles.tableCell}
          data-label="Duration"
          onClick={() => onTableDetailsClick()}
        >
          <Col>
            {activity.hourTo ? startDate : activity.startDate} -{" "}
            {activity.hourTo ? endDate : activity.endDate}{" "}
          </Col>
          <Col>
            {activity.hourFrom ? " " + timeFrom : null}
            {activity.hourTo ? " - " + timeTo : null}
          </Col>
        </td>
        <td>
          <div className={styles.iconCell}>
            {role === "Professor" && (
              <img
                alt="Edit"
                className={styles.icon}
                src={editIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/activities/edit/${activity.id}`
                  );
                }}
              />
            )}
            <img
              alt="View"
              className={styles.icon}
              src={viewIcon}
              onClick={onTableDetailsClick}
            />
            {role === "Professor" && (
              <img
                alt="Delete"
                src={trashIcon}
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenDialog(true);
                }}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default ActivityTableRow;
