import React from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";

import { useActivities, useAuth } from "../../stores";
import ActivityNavigation from "../Components/ActivityNavigation";
import ActivityStudentList from "./Components/ActivityStudentList";
import ButtonWithImage from "../../common/ButtonWithImage";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import Icon from "../../assets/images/back.svg";

const ActivityStudents = () => {
  const { activityTitle } = useActivities();
  const { courseId, activityId, academicTermId }: any = useParams();
  const { courseName } = useAuth();
  const history = useHistory();

  return (
    <DashboardBase
      componentName={courseName}
      location={activityTitle}
      isActivity={true}
    >
      <ActivityNavigation isStudents={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCard={true}
          isCoursesTabs={true}
        >
          <Row className="justify-content-end mb-2">
            <Col md="auto" xs="auto">
              <ButtonWithImage
                title="Back"
                onClick={() => {
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/activities`
                  );
                }}
                icon={Icon}
              />
            </Col>
          </Row>
          <ActivityStudentList />
        </Card>
      </ActivityNavigation>
    </DashboardBase>
  );
};

export default observer(ActivityStudents);
