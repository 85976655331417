import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { observer } from "mobx-react";

import NavBar from "./Components/NavBar";
import UserHeader from "./Components/UserHeader";
import styles from "./styles.module.scss";
import { useScreenSize } from "../../../../stores";

const DashboardBase = ({
  children,
  componentName,
  havePadding,
  isActivity,
  isAssignment,
  location,
}: any) => {
  const [padding, setPadding] = useState(" row m-0 p-0");

  const { isTabs } = useScreenSize();

  useEffect(() => {
    if (havePadding) {
      setPadding(" row m-0 px-3 ");
    }
  }, [havePadding]);

  return (
    <Container fluid>
      <Row>
        <div className={styles.navContainer}>
          <NavBar />
        </div>

        <Col className={styles.contentContainer} md={12}>
          {isTabs && (
            <UserHeader
              childComponetName={componentName}
              childLocation={location}
              isActivity={isActivity}
              isAssignment={isAssignment}
            />
          )}
          <div className={styles.childWrapper + padding}>{children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(DashboardBase);
