import * as Yup from "yup";

export const ValidationDateSchema = Yup.object().shape({
  ValidationTermDateFrom: Yup.string().required("Enter validations start date"),
  ValidationTermDateTo: Yup.string().required("Enter validations end date"),
});

export const ValidationSchema = Yup.object().shape({
  validationTerm: Yup.string().required("Enter validation term"),
});
