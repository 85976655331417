import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { Form, Formik } from "formik";
import { CircularProgress } from "@material-ui/core";

import { SaveActivityValidation } from "../../../../models/request.models";
import { useDialog, useActivities } from "../../../../stores";
import { ValidationSchema } from "../ValidationSchema";
import Button from "../../../../common/Button";
import Card from "../../../../common/CardSharpCorners";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";

interface CreateEditModalProps {
  dateFrom?: string;
  dateTo?: string;
  handleCancel: () => void;
  isNew: boolean;
  isPresence?: boolean;
  term: any;
}

const CreateEditModal: React.FC<CreateEditModalProps> = ({
  dateFrom,
  dateTo,
  handleCancel,
  isNew,
  isPresence,
  term,
}) => {
  const { courseId, sectionId, activityId }: any = useParams();
  const {
    saveValidation,
    searchActivityList,
    selectedActivitySection,
    getActivityValidation,
    setActivityValidationAdded,
  } = useActivities();

  const { showDialog } = useDialog();

  const [validationName, setValidationName] = useState<string>(
    term.validationTerm || ""
  );
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    setValidationName(event.target.value);
  };

  const handleSubmit = () => {
    const request: SaveActivityValidation = {
      ActivityID: activityId ?? searchActivityList.id,
      CourseID: courseId,
      SectionID: sectionId ?? selectedActivitySection,
      ValidationTerm: validationName,
    };

    if (!isNew) {
      request.id = term.id;
    }
    if (isPresence) {
      request.ScheduleDayID = term.scheduleDayID;
    }
    if (!isPresence) {
      request.ValidationTermDateFrom = dateFrom;
      request.ValidationTermDateTo = dateTo;
    }

    if (Duplicate()) {
      setIsTouched(true);
      setIsError("Duplicated term");
    } else {
      setLoading(true);
      setIsTouched(false);
      saveValidation(request).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          getActivityValidation(
            courseId,
            sectionId ?? selectedActivitySection,
            activityId ?? searchActivityList.id,
            true
          ).then((resp: any) => {
            setLoading(false);
            setValidationName("");
            setActivityValidationAdded(true);
            handleCancel();
          });
        } else {
          if (resp.status === "error" && resp.message) {
            if (resp.message.includes("Validation failed")) {
              setIsTouched(true);
              setIsError("long term");
            } else {
              showDialog(resp.message, null, null);
            }
            setLoading(false);
          }
        }
      });
    }
  };

  const Duplicate = () => {
    let duplicates = [];
    duplicates = term.validationTermList.filter(
      (temp: any) => temp.validationTerm === validationName
    );
    if (duplicates.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card isWithOutTopMargin={true} isFullScreen={false}>
      <Formik
        initialValues={{ validationTerm: validationName }}
        enableReinitialize
        validationSchema={isPresence ? null : ValidationSchema}
        onSubmit={(values) => {
          if (values) {
            handleSubmit();
            values.validationTerm = "";
          }
        }}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <Form>
              <Row
                className={
                  +!loading
                    ? styles.borderBottom +
                      " py-3 justify-content-between mx-1 align-items-center"
                    : " py-3 justify-content-between mx-1 align-items-center"
                }
              >
                <Col md={8}>
                  <TextInput
                    errorMessage={isTouched ? isError : errors.validationTerm}
                    touched={touched.validationTerm || isTouched}
                    name="validationTerm"
                    value={validationName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      textIntputHandleChange(event, handleChange);
                    }}
                  />
                </Col>
                {loading && (
                  <CircularProgress size={30} className={styles.progressBar} />
                )}
                <Col md={4} className=" justify-content-end">
                  <Button
                    hidden={loading}
                    title={isNew ? "Add +" : "Save"}
                    isEditButton={true}
                    type="button"
                    onClick={() => {
                      if (validationName === "") {
                        setIsTouched(true);
                        setIsError("Enter validation term!");
                      } else handleSubmit();
                    }}
                  />
                </Col>
              </Row>
              <Row className=" justify-content-end mx-1 align-items-center">
                <Col md={4}>
                  <Button
                    hidden={loading}
                    isEditButton={true}
                    title="Cancel"
                    type="button"
                    onClick={loading ? null : handleCancel}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CreateEditModal;
