import React, { useState, useRef, useEffect } from "react";
import cc from "classcat";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import arrowIconDown from "../../../assets/images/arrow-down-gray.svg";
import arrowIconUp from "../../../assets/images/arrow-up-gray.svg";
import { useScreenSize } from "../../../stores";
import styles from "./styles.module.scss";

interface TabNavigationItemProps {
  component?: React.ReactNode;
  isSection?: boolean;
  isSelected: boolean | undefined;
  path: string;
  title: string;
  isDetails?: boolean;
  index: number;
}

const TabNavigationItem: React.FC<TabNavigationItemProps> = ({
  component,
  isDetails,
  isSelected,
  path,
  title,
  index,
}) => {
  const history = useHistory();
  const { isTabs } = useScreenSize();
  const componentRef = useRef<null | HTMLDivElement>(null);

  const [isVisbile, setisVisbile] = useState(isSelected);

  const handleRedirection = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    if (isSelected) {
      window.scrollTo({
        top: 81 * index,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Col
      lg="auto"
      className={
        cc([
          styles.navField,
          { [styles.selectedNavField]: isSelected },
          { ["p-0"]: !isTabs },
        ]) + " pt-2"
      }
      onClick={() => isTabs && handleRedirection(path)}
    >
      {!isTabs ? (
        <Col
          ref={componentRef}
          className={styles.courseDetailsContentWrapper + " p-0"}
        >
          <Row
            className={styles.navHeader + " justify-content-between py-4 px-2"}
            onClick={() => handleRedirection(path)}
          >
            <Col
              xs="auto"
              className={cc([
                styles.selectedNavFieldSmall,
                // { [styles.selectedNavFieldSmall]: isSelected },
              ])}
            >
              {title}
            </Col>
            <Col className={styles.imageCol} xs="auto">
              <img
                className={styles.iconNav}
                src={isVisbile ? arrowIconUp : arrowIconDown}
                onClick={() => setisVisbile(!isVisbile)}
              />
            </Col>
          </Row>
          {!isTabs && isSelected && isVisbile && (
            <Row className={isDetails ? "m-0 py-4" : "m-0 "}>{component}</Row>
          )}
        </Col>
      ) : (
        <span className="pb-1">{title}</span>
      )}
    </Col>
  );
};

export default TabNavigationItem;
