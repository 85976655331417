import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";

import Button from "../Button";
import TextInput from "../TextInput";
import deleteIcon from "../../assets/images/delete.svg";
import { EMAIL } from "../../constants/RegularExpressions";
import styles from "./styles.module.scss";

interface MultipleSelectorProps {
  errorMessage?: string;
  firstRowClassName: string;
  list: any;
  onButtonClick: (value: string) => void;
  removeValue: (value: string) => void;
  textInputLabel: string;
  textInputName: string;
  touched?: boolean;
}

const MultipleSelector: React.FC<MultipleSelectorProps> = ({
  textInputLabel,
  textInputName,
  onButtonClick,
  list,
  removeValue,
  firstRowClassName,
  errorMessage,
  touched,
}) => {
  const [value, setValue] = useState("");
  const [inputErorMessage, setErrorMessage] = useState("");
  const [inputTouched, setTouched] = useState(false);

  const validate = () => {
    if (!EMAIL.test(value)) {
      setTouched(true);
      setErrorMessage("Enter valid email addres ");
      return false;
    } else {
      let duplicates = [];
      duplicates = list.filter((item: any) => item.email === value);
      if (duplicates.length !== 0) {
        setTouched(true);
        setErrorMessage("Duplicated");
        return false;
      } else {
        setTouched(false);
        setErrorMessage("");
        setValue("");
        return true;
      }
    }
  };

  const editEmail = (value: any) => {
    setValue(value.email);
    removeValue(value.email);
  };

  return (
    <>
      <Row className={firstRowClassName}>
        <Col className="pr-sm-0">
          <TextInput
            errorMessage={inputErorMessage}
            touched={inputTouched}
            label={textInputLabel}
            name={textInputName}
            multiple
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
          />
        </Col>
        <Col sm="auto">
          <Button
            isExtraSmall={true}
            title="add"
            onClick={() => {
              validate() && onButtonClick(value);
            }}
            type="button"
          />
        </Col>
      </Row>
      <Row className="px-3">
        <div className={styles.listItemWrapper + " col-12 mt-3 pt-0 px-0"}>
          {list &&
            list.map((value: any, index: number) => (
              <div className={styles.courseDaySelectedWrapper} key={index}>
                <p
                  className={styles.itemTime}
                  onClick={() => {
                    editEmail(value);
                  }}
                >
                  {value.email}
                </p>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className={styles.deleteIcon}
                  onClick={() => removeValue(value.email)}
                />
              </div>
            ))}
        </div>
      </Row>
      {errorMessage && touched && (
        <p className={styles.errorMsg}>{errorMessage}</p>
      )}
    </>
  );
};

export default observer(MultipleSelector);
