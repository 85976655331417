import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import cc from "classcat";
import { SortableHandle } from "react-sortable-hoc";

import { AssignmentsForList } from "../../../../models/state.models";
import dragIcon from "../../../../assets/images/drag.svg";
import { useAssignments, useDialog, useAuth } from "../../../../stores";
import deleteIcon from "../../../../assets/images/trash.svg";
import editIcon from "../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../Components/MessageBoxWithButtons";
import viewIcon from "../../../../assets/images/view.svg";
import styles from "./styles.module.scss";

interface AssignmentRowProps {
  assignment: AssignmentsForList;
  list: any;
}

const AssignmentRow: React.FC<AssignmentRowProps> = ({ assignment, list }) => {
  const { courseId, academicTermId }: any = useParams();
  const { deleteAssignment } = useAssignments();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const history = useHistory();

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/assignments/${assignment.id}/assignmentPurchase`
    );
  };

  const RowHandler = SortableHandle(() => (
    <img className={styles.handle} src={dragIcon} alt="Resend" />
  ));

  const handleConfirm = () => {
    deleteAssignment(courseId, assignment.id).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        list.refresh();
        showDialog("Assignment successfully  deleted", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  return (
    <>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete ${assignment.name} assignment?`}
        handleClose={() => setIsOpenDialog(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isOpenDialog}
      />
      <tr onClick={onTableDetailsClick}>
        <td className={styles.titleText} data-label="Title">
          <div className={styles.handleWrapper}>
            <RowHandler />
            <p>{assignment.name}</p>
          </div>
        </td>
        <td className={styles.tableCell} data-label="Number of Purchases">
          <p className={styles.tableFont}>
            {assignment.isBuyBack ? assignment.numberOfPurchases : "-"}
          </p>
        </td>
        <td className={styles.tableCell} data-label="Unresolved purchases">
          <div className={styles.tableFont}>
            {assignment.isBuyBack ? assignment.unresolvedPurchases : "-"}
            {assignment.isBuyBack && (
              <img alt="View" src={viewIcon} className={styles.icon} />
            )}
          </div>
        </td>
        <td className={styles.tableCell} data-label="Average points">
          <p
            className={
              assignment.averagePoints
                ? styles.tableFontPositive
                : styles.tableFont
            }
          >
            {assignment.averagePoints
              ? Math.round(assignment.averagePoints)
              : "-"}
          </p>
        </td>
        <td className={styles.tableCell} data-label="Assignment due date">
          <p className={styles.tableFont}>
            {moment(assignment.dateTo).format("YYYY-MM-DD")}
          </p>
        </td>
        <td
          className={cc([
            { [styles.fontColorPositive]: assignment.isBuyBack },
            { [styles.fontColorNegative]: !assignment.isBuyBack },
          ])}
          data-label="Allowed for buyback"
        >
          {assignment.isBuyBack ? "Yes" : "No"}
        </td>
        <td className={styles.assignmentsLastTd}>
          <div className={styles.iconsWrapper}>
            <>
              <img
                alt="Edit"
                src={editIcon}
                className={styles.iconWihtCursor}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/assignments/edit/${assignment.id}`
                  );
                }}
              />
              {role === "Professor" && (
                <img
                  alt="Delete"
                  src={deleteIcon}
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenDialog(true);
                  }}
                />
              )}
            </>
          </div>
        </td>
      </tr>
    </>
  );
};

export default AssignmentRow;
