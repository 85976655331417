import React from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";

import AssignmentTable from "../AssignmentTable";
import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";

const AssignmentsList = () => {
  const { courseId, academicTermId }: any = useParams();

  return (
    <>
      <BaseTable
        list={{
          url: "/AssignmentApi/GetAssignmentList",
          filterParameters: [
            { name: "Name", dataType: "string", operator: "like" },
          ],
        }}
      >
        <TableFilter
          buttonDestination={`/academicTerms/${academicTermId}/courses/${courseId}/assignments/add`}
          buttonTitle="Add new assignment"
        ></TableFilter>

        <AssignmentTable />
        <TablePager />
      </BaseTable>
    </>
  );
};

export default observer(AssignmentsList);
