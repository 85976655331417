import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Col, Container, Row } from "react-bootstrap";

import logo from "../../../assets/images/V-logo-svg.svg";
import styles from "./styles.module.scss";

const BaseLogin = ({ children }: any) => {
  const history = useHistory();
  return (
    <Container fluid>
      <Row>
        <Col
          md={5}
          className={styles.backgroundImage}
          onClick={() => history.push("/")}
        >
          <img src={logo} className={styles.logoImage} alt="test" />
        </Col>
        <Col md={7}>
          <div className={styles.formWrapper}>{children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(observer(BaseLogin));
