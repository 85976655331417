import React from "react";

import EditProfile from "../../../Pages/EditProfile";
import Profile from "../../../Pages/Profile";
import PrivateRoute from "../../PrivateRoute";

const ProfileRoutes = () => {
  return (
    <>
      <PrivateRoute component={Profile} path="/profile" exact />
      <PrivateRoute component={EditProfile} path="/profile/edit" />
    </>
  );
};

export default ProfileRoutes;
