import * as Yup from "yup";

export const validationSchemaAttendance = Yup.object().shape({
  validationPhrase: Yup.string().required("Enter validation phrase"),
  dateID: Yup.number().min(1, "Enter schedule day").required(),
});

export const validationSchemaIndividual = Yup.object().shape({
  validationPhrase: Yup.string().when("validationType", {
    is: (value: any) => value === 2,
    then: Yup.string().required("Enter validation phrase"),
  }),
});

export const validationSchemaGroup = Yup.object().shape({
  validationPhrase: Yup.string().when("validationType", {
    is: (value: any) => value === 2,
    then: Yup.string().required("Enter validation phrase"),
  }),
});
