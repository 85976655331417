import React from "react";

import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import UserList from "./Components/UsersList";

const Users = () => {
  return (
    <DashboardBase componentName="Users">
      <Card isFullScreen={true}>
        <UserList />
      </Card>
    </DashboardBase>
  );
};

export default Users;
