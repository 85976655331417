import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";

import { SectionGroupForList } from "../../../../models/state.models";
import { useAuth, useDialog, useSectionGroups } from "../../../../stores";
import AddGroupModal from "./Components/AddGroupModal";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import CustomModal from "../../../../common/CustomModal";
import Icon from "../../../../assets/images/back.svg";
import MainGroup from "./Components/MainGroup";
import MoveStudentModal from "./Components/MoveStudentModal";
import plusIcon from "../../../../assets/images/plus.svg";
import SecondaryGroup from "./Components/SecondaryGroup";
import SelectWithoutLabel from "../../../../common/SelectWithOutLabel";
import styles from "./styles.module.scss";

const GroupLists = () => {
  const history = useHistory();
  const { sectionId, courseId, academicTermId }: any = useParams();

  const { showDialog } = useDialog();
  const { selectedAcademicTermId, role } = useAuth();
  const {
    filterGroupByCategory,
    getCourseGroupForList,
    getCourseUserList,
    resetGroup,
    sectionGroupList,
    sectionStudentList,
    selectedFilterGroupValue,
    setIsGroupDataEdit,
    setSelectedSectionID,
  } = useSectionGroups();

  useEffect(() => {
    resetGroup();
    getCourseGroupForList(sectionId, courseId).then((resp: any) => {
      if (resp.message) {
        if (resp.message !== "success" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Errror"
            );
          } else {
            showDialog(resp.message, null, null, true, "Errror");
          }
        }
      }
    });
    getCourseUserList(courseId, sectionId);
    setSelectedSectionID(sectionId);
    return () => {
      resetGroup();
    };
  }, [
    getCourseGroupForList,
    getCourseUserList,
    sectionId,
    setSelectedSectionID,
  ]);

  const [addingGroupModalVisibility, setAddingGroupModalVisibility] =
    useState(false);
  const [movingStudentModalVisiblity, setMovingStudentModalVisiblity] =
    useState(false);

  return (
    <Col>
      <Row className={styles.container + " py-3"}>
        {role === "Professor" && (
          <Col className={styles.buttonWrapper} xs="auto">
            <ButtonWithImage
              icon={plusIcon}
              isSmall={true}
              title="add"
              onClick={() => {
                setIsGroupDataEdit(false);
                setAddingGroupModalVisibility(true);
              }}
            />
          </Col>
        )}
        <Col className=" my-3" xs="auto">
          <SelectWithoutLabel
            onChange={filterGroupByCategory}
            values={[
              { label: "All", value: 0 },
              { label: "Primary", value: 1 },
              { label: "Others", value: 2 },
            ]}
            value={selectedFilterGroupValue}
          />
        </Col>
        <Col md="auto" className=" my-3" xs="auto">
          <ButtonWithImage
            title="Back"
            onClick={() => {
              history.push(
                `/academicTerms/${academicTermId}/courses/${courseId}/sections`
              );
            }}
            icon={Icon}
          />
        </Col>
      </Row>
      <Row className={styles.wrapper + " p-4"}>
        <MainGroup
          openMoveStudentModal={() => setMovingStudentModalVisiblity(true)}
          studentList={sectionStudentList}
        />
        <div className={styles.listWrapper}>
          <div className={styles.listContent}>
            {sectionGroupList.map((group: SectionGroupForList) => (
              <SecondaryGroup
                key={group.id}
                group={group}
                openMoveStudentModal={() =>
                  setMovingStudentModalVisiblity(true)
                }
                openModalHandler={setAddingGroupModalVisibility}
              />
            ))}
          </div>
        </div>
      </Row>
      <CustomModal
        open={addingGroupModalVisibility}
        handleClose={() => setAddingGroupModalVisibility(false)}
      >
        <AddGroupModal
          handleCancel={() => setAddingGroupModalVisibility(false)}
        />
      </CustomModal>
      <CustomModal
        open={movingStudentModalVisiblity}
        handleClose={() => setMovingStudentModalVisiblity(false)}
      >
        <MoveStudentModal
          handleCancel={() => setMovingStudentModalVisiblity(false)}
          sectionGroupList={sectionGroupList}
        />
      </CustomModal>
    </Col>
  );
};

export default observer(GroupLists);
