import React from "react";
import cc from "classcat";
import { useHistory, useParams } from "react-router";
import { Col, Row } from "react-bootstrap";

import { useScreenSize } from "../../../stores";
import TabNavigationItem from "../TabNavigationItem";

interface CourseNavigationProps {
  children?: React.ReactNode;
  isActivityValidations?: boolean;
  isGroups?: boolean;
  isSectionDetails?: boolean;
  isStudents?: boolean;
}

const CourseNavigation: React.FC<CourseNavigationProps> = ({
  children,
  isActivityValidations,
  isGroups,
  isSectionDetails,
  isStudents,
}) => {
  const history = useHistory();
  const { courseId, sectionId, academicTermId }: any = useParams();

  const { isTabs } = useScreenSize();

  const handleRedirection = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <Col className={cc([" p-0", { ["mt-4"]: isTabs }])} md={12}>
        <Row className=" mx-0 ">
          <TabNavigationItem
            component={children}
            isSection={true}
            isDetails={true}
            isSelected={isSectionDetails}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/details`}
            title="Section Details"
            index={0}
          />

          <TabNavigationItem
            component={children}
            isSection={true}
            isSelected={isStudents}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/students`}
            title="Students"
            index={1}
          />

          <TabNavigationItem
            component={children}
            isSection={true}
            isSelected={isGroups}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/groups`}
            title=" Groups"
            index={2}
          />

          <TabNavigationItem
            component={children}
            isSection={true}
            isSelected={isActivityValidations}
            path={`/academicTerms/${academicTermId}/courses/${courseId}/sections/${sectionId}/activityValidations`}
            title="Activity Validations"
            index={3}
          />
        </Row>
      </Col>
      {isTabs && children}
    </>
  );
};

export default CourseNavigation;
