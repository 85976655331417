import { useState } from "react";
import MBox from "../../Pages/Components/MessageBoxBase";

const useMessageBox = (/*callback = (()=>{})*/) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [callbackFunc, setCallbackFunc] = useState<Function | null>(null);
  const MessageBox = () => {
    return (
      <MBox
        open={isOpen}
        title={title}
        message={message}
        callback={callbackFunc}
      />
    );
  };
  const showMessageBox = (
    title: string,
    message: string,
    callback: Function | null = null
  ) => {
    setIsOpen(true);
    if (callback && typeof callback === "function")
      setCallbackFunc(() => callback);
    setTitle(title);
    setMessage(message);
  };
  const hideMessageBox = () => {
    setIsOpen(false);
  };
  return { MessageBox, showMessageBox, hideMessageBox };
};

export default useMessageBox;
