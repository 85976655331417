import * as Yup from "yup";

export const RestPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is Required")
    .min(8, " 8 Chars is minimum."),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Repeat Password is Requred"),
});
