import * as Yup from "yup";
import moment from "moment";

export const CourseSchema = Yup.object().shape({
  title: Yup.string().required("Enter course title"),
  courseCode: Yup.string().required("Enter course number"),
  avatarFileID: Yup.number().required().min(-1, "Selecet avatar"),
  // academicTermID: Yup.number().required().min(1, "Selecet academic term"),
  // startDate: Yup.date().required("Enter start date").nullable(),
  // endDate: Yup.date()
  //   .min(Yup.ref("startDate"), "It must be greater then From date")
  //   .required("Enter end date")
  //   .nullable(),

  startDate: Yup.string().required("Enter from date"),
  endDate: Yup.string()
    .required("Enter to date")
    .test("is-greater", "It must be greater then From date", function (value) {
      const { startDate } = this.parent;
      return moment(value, "MM-DD-YYYY").isSameOrAfter(
        moment(startDate, "MM-DD-YYYY")
      );
    }),
});
