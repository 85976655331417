import React, { useState } from "react";
import cc from "classcat";
import { useHistory, useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { useScreenSize } from "../../../../stores";
import styles from "./styles.module.scss";

interface StudentNavigationProps {
  selectedNav: (navItem: number) => void;
}

const StudentNavigation: React.FC<StudentNavigationProps> = ({
  selectedNav,
}) => {
  const { courseId, sectionId, academicTermId }: any = useParams();

  const { isTabs } = useScreenSize();
  const [navItem, setNavItem] = useState(0);

  return (
    <>
      <Col className={cc([" p-0 ml-3", { ["mt-4"]: isTabs }])} md={12}>
        <Row className=" mx-0 ">
          <Tabs
            className={styles.navWrapper}
            onChange={(e, page) => {
              setNavItem(page);
              selectedNav(page);
            }}
            value={navItem}
          >
            <Tab label="Activity applied" />
            <Tab label="Attendance record" />
          </Tabs>
        </Row>
      </Col>
    </>
  );
};

export default StudentNavigation;
