import React from "react";
import { BrowserRouter as ReactRouter } from "react-router-dom";
import CustomRouter from "./CustomRouter";
import { StoreProvider } from "./stores";

function App() {
  return (
    <div>
      <StoreProvider>
        <ReactRouter>
          <CustomRouter />
        </ReactRouter>
      </StoreProvider>
    </div>
  );
}

export default App;
