import React from "react";
import { Col } from "react-bootstrap";
import { observer } from "mobx-react";

import ActivityNames from "../ActivityNames";
import styles from "./styles.module.scss";

const ActivityList = ({ list }: any) => {
  return (
    <Col className={styles.wrap}>
      <ActivityNames list={list} />
    </Col>
  );
};

export default observer(ActivityList);
