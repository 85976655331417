import React, { useState } from "react";
import cc from "classcat";
import { useHistory, useParams } from "react-router";

import CustomCheckbox from "../../../../common/CustomCheckbox";
import editIcon from "../../../../assets/images/edit.svg";
import viewIcon from "../../../../assets/images/view.svg";
import addedDocument from "../../../../assets/images/added-document.svg";
import addedDouble from "../../../../assets/images/added-double.svg";
import noDocument from "../../../../assets/images/no-document.svg";
import CustomModal from "../../../../common/CustomModal";
import FileModal from "../FileModal";
import { removeActivityUtcOffset } from "../../../../utils/timeUtils";
import styles from "./styles.module.scss";

interface ActivityGroupTableRowProps {
  group: any;
}

const ActivityGroupTableRow: React.FC<ActivityGroupTableRowProps> = ({
  group,
}) => {
  const { courseId, academicTermId, activityId }: any = useParams();
  const history = useHistory();
  const [ModalVisiblity, setModalVisiblity] = useState(false);

  const onTableDetailsClick = () => {
    history.push(
      `/academicTerms/${academicTermId}/courses/${courseId}/activities/${activityId}/students/${group.id}/confirmation`
    );
  };

  const submissionStatus = (number: number) => {
    switch (number) {
      case 0:
        return "pending";

      case 1:
        return "System Validated";

      case 2:
        return "System Rejected";

      case 3:
        return "Professor Validated";

      case 4:
        return "Professor Rejected";
    }
  };

  return (
    <>
      <tr>
        <td className={styles.tableCellCb} data-label="ID">
          {/* <CustomCheckbox checked={true} /> */}
        </td>

        <td
          className={styles.tableCell}
          data-label="Name"
          onClick={() => onTableDetailsClick()}
        >
          {group.groupName}
        </td>

        <td
          className={styles.tableCell}
          data-label="Date"
          onClick={() => onTableDetailsClick()}
        >
          {removeActivityUtcOffset(group.submittedDate)}
        </td>
        <td
          className={cc([
            styles.tableCell,
            {
              [styles.pendingColor]: group.status === 0,
            },
            {
              [styles.redText]: group.status === 2 || group.status === 4,
            },
            {
              [styles.greenText]: group.status === 1 || group.status === 3,
            },
          ])}
          data-label="Validation Status"
          onClick={() => onTableDetailsClick()}
        >
          {submissionStatus(group.status)}
        </td>
        <td className={styles.iconCell} data-label="Documents">
          {group.fileList[1] ? (
            <img
              alt="DocumentIcon"
              className={styles.fileIcon}
              src={addedDouble}
            />
          ) : group.fileList[0] ? (
            <img
              alt="DocumentIcon"
              className={styles.clickableFileIcon}
              src={addedDocument}
              onClick={() => setModalVisiblity(true)}
            />
          ) : (
            <img
              alt="DocumentIcon"
              className={styles.fileIcon}
              src={noDocument}
            />
          )}
        </td>
        <td>
          <div className={styles.iconCell}>
            <img
              alt="Edit"
              className={styles.icon}
              src={editIcon}
              onClick={() => onTableDetailsClick()}
            />
            <img
              alt="View"
              className={styles.icon}
              src={viewIcon}
              onClick={() => onTableDetailsClick()}
            />
          </div>
        </td>
        <CustomModal
          handleClose={() => setModalVisiblity(false)}
          open={ModalVisiblity}
        >
          <FileModal value={group.fileList[0]?.fileName} />
        </CustomModal>
      </tr>
    </>
  );
};

export default ActivityGroupTableRow;
