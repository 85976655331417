import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";

import { BASE_URL } from "../../../../utilities";
import {
  CourseUserGradebookDTO,
  GradebookPointsDTO,
} from "../../../../models/state.models";
import defaultUser from "../../../../assets/images/default-user.png";
import { useAuth, useGradebookStore } from "../../../../stores";
import styles from "./styles.module.scss";

interface GradebookTableRowProps {
  student: CourseUserGradebookDTO;
}

const GradebookTableRow: React.FC<GradebookTableRowProps> = ({ student }) => {
  const { gradebook } = useGradebookStore();
  const { role } = useAuth();

  const { courseId, academicTermId, idAssignment }: any = useParams();
  const history = useHistory();
  return (
    <tr className={styles.tableHolder}>
      <td className={styles.contentWrapper} data-label="Student Name">
        <ul className={styles.nameWrapper}>
          <li className=" d-flex align-items-center ">
            <img
              src={
                student.imageFileName
                  ? `${BASE_URL}File/GetFile/${student.imageFileName}`
                  : defaultUser
              }
              className={styles.icon}
            />
            <p className={styles.nameFont + " pl-2"}>{student.studentName}</p>
          </li>
        </ul>
      </td>
      <td className={styles.contentWrapper} data-label="Grade">
        {student.grade}
      </td>
      {student.gradebookPointsList &&
        student.gradebookPointsList.map(
          (point: GradebookPointsDTO, index: number) => (
            <td
              className={styles.contentWrapper}
              data-label={gradebook.assignmmentList[index]?.name}
              key={point.ID}
              onClick={() => {
                if (role !== "Student") {
                  history.push(
                    `/academicTerms/${academicTermId}/courses/${courseId}/student/${student.courseUserID}/assignmentDetails/${gradebook.assignmmentList[index]?.id}`
                  );
                }
              }}
            >
              <div className={styles.tableWrapper}>
                <div className={styles.textWrapperStyle}>
                  percentage
                  <p>{point.percentage} %</p>
                </div>
                <div className={styles.tableInnerHolder}>
                  <div className={styles.textWrapperStyle}>change</div>
                  {point.points}
                  <span
                    className={
                      point.pointsDifference > 0
                        ? `${styles.diffPoints}`
                        : `${styles.diffPointsNegative}`
                    }
                  >
                    <span>{point.pointsDifference > 0 ? "+" : ""}</span>
                    {point.pointsDifference}
                  </span>
                </div>
              </div>
            </td>
          )
        )}
    </tr>
  );
};

export default observer(GradebookTableRow);
