import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";

import Icon from "../../../../assets/images/back.svg";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import { useCourses, useAuth } from "../../../../stores";
import styles from "./styles.module.scss";

const ApplicationOverview = () => {
  const history = useHistory();
  const { academicTermId, courseId, applicationId }: any = useParams();
  const { getApplicationDetails, resetApplication, application } = useCourses();
  const { role } = useAuth();

  const urlLocation = useLocation();
  const [isExtraCredit, setIsExtraCredit] = useState(false);

  useEffect(() => {
    if (urlLocation.pathname.includes("spendOpportunitiesDetails")) {
      getApplicationDetails(courseId, "Spend opportunity", applicationId);
    } else if (urlLocation.pathname.includes("activityDetails")) {
      getApplicationDetails(courseId, "Activity", applicationId);
    } else {
      getApplicationDetails(courseId, "Extra credit", applicationId);
      setIsExtraCredit(true);
    }

    return () => {
      resetApplication();
    };
  }, []);

  return (
    <>
      <Row className="justify-content-end pb-3 m-0">
        <ButtonWithImage
          title="Back"
          onClick={() => {
            if (role === "Student") {
              history.push(
                `/academicTerms/${academicTermId}/courses/${courseId}/applications`
              );
            } else {
              history.goBack();
            }
          }}
          icon={Icon}
        />
      </Row>
      <Row className={styles.courseOverviewWrapper + " mb-5"}>
        <Col md="auto">
          <h2>{application.name && application.name}</h2>
          {application.category ||
            (application.applicationType === "Extra credit" && (
              <Row>
                <Col>
                  <p className={styles.label}>Category</p>
                  <p className={styles.values}>
                    {isExtraCredit
                      ? application.applicationType
                      : application.category}
                  </p>
                </Col>
              </Row>
            ))}
        </Col>
        <Col md="auto" xs={12} className={styles.courseDetailsSectionWrapper}>
          <p className={styles.label}>{isExtraCredit ? "Date" : "End date"}</p>
          <p className={styles.values}>
            {moment(application.applicationDateTime).format("MM-DD-YYYY")}
          </p>
        </Col>
        <Col md="auto" xs={12} className={styles.courseDetailsSectionWrapper}>
          <p className={styles.label}>Status</p>
          <p className={styles.values}>{application.status}</p>
        </Col>
        <Col md="auto" xs={12} className={styles.courseDetailsSectionWrapper}>
          <p className={styles.label}>Credit</p>
          <p className={styles.values}>{application.credit}</p>
        </Col>
      </Row>
      {application.note && application.note != "" && (
        <Row>
          <Col md="auto">
            <p className={styles.label}>Note</p>
            <p className={styles.values}>{application.note}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ApplicationOverview;
