import React from "react";
import { Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import Icon from "../../../../assets/images/back.svg";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import TablePager from "../../../Components/TablePager";
import AssignmentTable from "../AssignmentTable";

const StudentList = () => {
  const history = useHistory();
  const { academicTermId, courseId }: any = useParams();

  return (
    <>
      <Row className="justify-content-end pr-3">
        <ButtonWithImage
          title="Back"
          onClick={() => {
            history.push(
              `/academicTerms/${academicTermId}/courses/${courseId}/assignments`
            );
          }}
          icon={Icon}
        />
      </Row>
      <BaseTable
        list={{
          url: "/AssignmentApi/GetUnresolvedSubmittedAssignmentList",
          filterParameters: [
            { name: "Name", dataType: "string", operator: "like" },
            { name: "Date", dataType: "string", operator: "=" },
          ],
        }}
      >
        <TableFilter isWithoutButton={true} />

        <AssignmentTable />
        <TablePager />
      </BaseTable>
    </>
  );
};

export default StudentList;
