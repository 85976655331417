import { action, makeObservable, observable, runInAction } from "mobx";
import { SaveSectionRequest } from "../../models/request.models";
import {
  Scheduler,
  SectionDetails,
  User,
  CourseUser,
  SchedulerRequest,
  StudentDetails,
} from "../../models/state.models";
import { FetchApi } from "../../utilities";
import { removeOffset } from "../../utils/timeUtils";

class SectionStore {
  @observable status: string = "inital";
  @observable message: string = "";

  @observable totalCountofSections: number = 0;

  @observable selectedSectionID: number = 0;

  @observable sectionTitle: string = "";

  @observable courseTitle: string = "";

  @observable courseUserList: CourseUser[] = [];

  @observable studentDetails: StudentDetails = {
    accommodationRate: 1,
    creditsSpent: 0,
    currentCourseGrade: 0,
    creditsEarned: 0,
    creditsOwned: 0,
    studentName: "",
    studentID: "",
    userEmail: "",
    imageFileID: 0,
    imageFileName: "",
    groupName: "",
    activityList: [],
  };

  @observable isSectionLoading: boolean = false;

  @observable section: SectionDetails = {
    id: 0,
    title: "",
    courseCode: "",
    currencyName: "",
    maxBuybackPrice: 0,
    minimumTransactionCost: 0,
    academicTerm: "",
    academicTermName: "",
    academicTermYear: 0,
    startDate: "",
    endDate: "",
    coursUsers: [],
    avatarFileID: 0,
    avatarName: "",
    academicTermID: 0,
    validationTimeBefore: "",
    validationTimeAfter: "",
    courseIdentification: "",
    schedules: [],
    hasSection: false,
    coInstructorList: [],
    teachingAssistanList: [],
  };

  @observable student: CourseUser = {
    email: "",
    roleCode: "stud",
    firstName: "",
    lastName: "",
    primaryGroupName: "",
  };

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async getSectionForEdit(id: number, courseId: number) {
    this.isSectionLoading = true;
    const { message, status, data, breadCrumb }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetCourseSetionForEdit",
      params: { sectionId: id, courseId: courseId },
    });
    runInAction(() => {
      this.isSectionLoading = false;
      if (status === "success") {
        this.section = data;
        this.section.schedules = data.schedules.map(
          (value: SchedulerRequest) => {
            let removeTimeOffset = removeOffset(
              value.hourFrom,
              value.hourTo,
              value.dayOfWeek
            );
            const formatedScheduleValue: Scheduler = {
              dayOfWeek: removeTimeOffset.dayOfWeek,
              hourFrom: removeTimeOffset.hourFrom.format("h:mm A"),
              hourTo: removeTimeOffset.hourTo.format("h:mm A"),
              id: value.id,
              activityID: value.activityID,
              activityName: value.activityName,
              isDeletable: value.isDeletable,
            };
            return formatedScheduleValue;
          }
        );
        if (this.section.validationTimeBefore === 0) {
          this.section.validationTimeBefore = "";
        }
        if (this.section.validationTimeAfter === 0) {
          this.section.validationTimeAfter = "";
        }
      }
    });

    return { status, message, breadCrumb };
  }

  @action.bound
  async getSectionDetails(id: number, courseId: number) {
    this.isSectionLoading = true;
    const { message, status, data, additionalData }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/GetSectionDetails",
      params: { sectionId: id, courseId: courseId },
    });
    runInAction(() => {
      this.isSectionLoading = false;
      if (status === "success") {
        this.section = data;
        this.sectionTitle = additionalData.sectionName;
        this.courseTitle = additionalData.courseName;
      }
    });
    return { status, message };
  }

  @action.bound
  async handleResendMail(id: number) {
    this.isSectionLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/ResendRegistrationEmail",
      params: { UserID: id },
    });
    runInAction(() => {
      this.isSectionLoading = false;
      this.status = status;
      this.message = message;
    });
    return { status, message };
  }

  @action.bound
  async saveSection(params: SaveSectionRequest) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/SaveSection",
      params,
    });
    runInAction(() => {
      this.isSectionLoading = false;
      this.status = status;
      this.message = message;
    });

    return { status, message };
  }

  @action.bound
  async getCourseUserList(id: number) {
    const { message, status, data, additionalData }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/GetCourseUserList",
      params: id,
    });
    runInAction(() => {
      if (status === "success") {
        this.courseUserList = data;
      }
    });

    return { status, message };
  }
  @action.bound
  async saveCourseUser(params: SaveSectionRequest) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserapi/SaveCourseUser",
      params,
    });
    runInAction(() => {
      this.isSectionLoading = false;
      this.status = status;
      this.message = message;
    });

    return { status, message };
  }

  @action.bound
  async deleteStudentFromSection(courseId: number, studentId: number) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/DeleteCourseUser",
      params: { courseID: courseId, ID: studentId },
    });
    this.isSectionLoading = false;
    return { status, message };
  }

  @action.bound
  async getSectionStudent(id: number, userId: number, courseId: number) {
    this.isSectionLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/GetCourseUserDetails",
      params: { id: id, userID: userId, courseID: courseId },
    });
    this.isSectionLoading = false;
    this.studentDetails = data;
    return { status, message };
  }

  @action.bound
  async addCourseUSerCredit(
    courseId: number,
    courseUserId: number,
    credit: number,
    note: string
  ) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/AddCourseUserCredit",
      params: {
        CourseID: courseId,
        CourseUserID: courseUserId,
        Credit: credit,
        Note: note,
      },
    });
    this.isSectionLoading = false;
    return { status, message };
  }

  @action.bound
  async deleteSection(id: number) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseApi/DeleteSection",
      params: { ID: id },
    });
    this.isSectionLoading = false;
    return { status, message };
  }

  @action.bound
  async updateAccommodationRate(
    courseId: number,
    courseUserId: number,
    accommodationRate: number
  ) {
    this.isSectionLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/UpdateAccommodationRate",
      params: {
        CourseID: courseId,
        CourseUserID: courseUserId,
        AccommodationRate: accommodationRate,
      },
    });
    this.isSectionLoading = false;
    return { status, message };
  }

  @action.bound setSelectedSectionID(id: number) {
    this.selectedSectionID = id;
  }

  @action.bound setSectionTitle(title: string) {
    this.sectionTitle = title;
  }

  @action.bound setCourseTitle(title: string) {
    this.courseTitle = title;
  }

  @action.bound
  handleSectionInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.section[event.target.name] = event.target.value;
  }

  @action.bound
  handleStudentInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.student[event.target.name] = event.target.value;
  }

  @action.bound
  handleSectionSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.section[event.target.name] = event.target.value;
  }

  @action.bound
  addSectionTime(time: Scheduler) {
    this.section.schedules.push(time);
  }

  @action.bound
  removeSectionTime(time: Scheduler) {
    this.section.schedules = this.section.schedules.filter(
      (value: Scheduler) => value !== time
    );
  }

  @action.bound
  addSectionUser(user: User) {
    if (user.roleCode === "prof") {
      this.section.coInstructorList.push(user);
    } else {
      this.section.teachingAssistanList.push(user);
    }
  }

  @action.bound
  removeSectionUser(email: string) {
    this.section.coInstructorList = this.section.coInstructorList.filter(
      (user: User) => user.email !== email
    );
    this.section.teachingAssistanList =
      this.section.teachingAssistanList.filter(
        (user: User) => user.email !== email
      );
  }

  @action.bound
  searchStudent = (filter: string) => {
    if (filter && filter !== "") {
    }
  };

  @action.bound
  resetSection() {
    this.section = {
      id: 0,
      title: "",
      courseCode: "",
      courseIdentification: "",
      academicTermID: 0,
      academicTerm: "",
      academicTermName: "",
      academicTermYear: 0,
      startDate: "",
      endDate: "",
      maxBuybackPrice: 0,
      coursUsers: [],
      validationTimeBefore: "",
      validationTimeAfter: "",
      hasSection: false,
      schedules: [],
      coInstructorList: [],
      teachingAssistanList: [],
    };
  }

  @action.bound
  resetStudent() {
    this.student = {
      email: "",
      roleCode: "stud",
      firstName: "",
      lastName: "",
    };
  }

  @action.bound
  resetStudentDetails() {
    this.studentDetails = {
      accommodationRate: 1,
      creditsSpent: 0,
      creditsOwned: 0,
      currentCourseGrade: 0,
      creditsEarned: 0,
      studentName: "",
      studentID: "",
      userEmail: "",
      imageFileID: 0,
      imageFileName: "",
      groupName: "",
      activityList: [],
    };
  }
}

export default SectionStore;
