import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CircularProgress } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import cc from "classcat";

import { BASE_URL } from "../../../../utilities";
import {
  File,
  Student,
  SelectValue,
  GroupLists,
} from "../../../../models/state.models";
import { useActivities, useAuth, useDialog } from "../../../../stores";
import { ValidationSubmitRequest } from "../../../../models/request.models";
import arrowRight from "../../../../assets/images/arrow-left.svg";
import Button from "../../../../common/Button";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import CustomInput from "../../../../common/CustomInput";
import deleteIcon from "../../../../assets/images/delete.svg";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import StudentList from "../StudentList";
import TextArea from "../../../../common/TextArea";
import TextInput from "../../../../common/TextInput";
import {
  validationSchemaGroup,
  validationSchemaAttendance,
  validationSchemaIndividual,
} from "../ValidationSubmitSchema";
import styles from "./styles.module.scss";

const StudentForms: React.FC = () => {
  const { courseId, activityId, academicTermId }: any = useParams();
  const history = useHistory();
  const {
    activity,
    activitySubmit,
    attachFileHandler,
    getPresenceActivityDays,
    getStudentGruopList,
    getStudentList,
    handleActivitySubmitInputChange,
    handleDate,
    handleRemoveStudent,
    listOfDocumentsForActivity,
    loading,
    removeActivityFile,
    resetActivity,
    scheduleDays,
    StudentGroupList,
    studentList,
    StudentsID,
    validationSubmit,
    validationSubmitReset,
  } = useActivities();
  const { role } = useAuth();
  const { showDialog } = useDialog();

  const [selectStudent, setSelectStudent] = useState<boolean>(false);
  const [filterGroup, setFilterGroup] = useState<SelectValue[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    setFilterGroup(
      StudentGroupList.map((activity: GroupLists) => {
        return { id: activity.codeInt, name: activity.value };
      })
    );
  }, [StudentGroupList]);

  useEffect(() => {
    if (!activity.isIndividual) {
      getStudentGruopList(courseId);
    }
  }, [activity.isIndividual]);

  useEffect(() => {
    if (activity.isPresence) {
      getPresenceActivityDays(courseId, activityId);
    }
  }, [activity.isPresence]);

  const validation = () => {
    if (activity.isPresence === false && activity.isIndividual === false) {
      if (StudentsID.length < 2) {
        setTouched(true);
        setErrorMessage("Select minimum 2 students");
        return false;
      } else {
        setTouched(false);
        setErrorMessage("");
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (validation()) {
      const request: ValidationSubmitRequest = {
        CourseID: parseInt(courseId),
        ActivityID: parseInt(activityId),
        SubmittedValidationText: validationSubmit.validationPhrase,
        StudentNote: validationSubmit.comment,
      };
      if (!activity.isPresence) {
        request.FileList = validationSubmit.validationFiles;
        if (!activity.isIndividual) {
          request.CourseUserIDList = StudentsID;
          request.CourseGroupID = validationSubmit.groupID;
        }
      } else {
        request.ScheduleDayID = validationSubmit.dateID;
      }
      activitySubmit(request).then((resp: any) => {
        if (resp.status === "success") {
          if (resp.status === "success" && !resp.message) {
            showDialog("Activity submitted succesfully", null, null, false);
            history.goBack();
          } else {
            showDialog(resp.message, null, null, false);
          }

          resetActivity();
          validationSubmitReset();
        } else {
          if (resp.message === "Activity is already submitted.") {
            showDialog(resp.message, null, null, false);
            history.goBack();
          } else {
            showDialog(resp.message, null, null, false);
          }
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{ ...validationSubmit }}
      enableReinitialize
      validationSchema={
        activity.isPresence
          ? validationSchemaAttendance
          : activity.isIndividual
          ? validationSchemaIndividual
          : validationSchemaGroup
      }
      onSubmit={(values) => {
        if (values) {
          handleSubmit();
        }
      }}
    >
      {({ errors, touched, handleBlur }) => {
        return (
          <Form>
            <Row className={styles.formWrapper + " pt-3 "}>
              <Col md={5} className={styles.borderRight}>
                {selectStudent && (
                  <StudentList
                    handleCancel={() => setSelectStudent(false)}
                    students={studentList}
                  />
                )}
                {role === "Student" &&
                  !activity.isIndividual &&
                  !activity.isPresence && (
                    <>
                      <Row>
                        <Col md={9} className="px-0">
                          <SelectWithLabel
                            touched={touched}
                            errorMessage={errorMessage}
                            label="Select group"
                            name="group"
                            values={filterGroup}
                            onChange={(event: any) => {
                              getStudentList(event.target.value, courseId);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className=" pt-3">
                        <Col md={4} xs={12} className="px-0">
                          <ButtonWithImage
                            title="Select students"
                            type="button"
                            onClick={() => setSelectStudent(true)}
                            icon={arrowRight}
                          />
                        </Col>
                      </Row>
                      {validationSubmit.selectedStudents && (
                        <Row>
                          <Col
                            className={
                              styles.listLimitWrapper + " mt-3 pt-0 pb-3 px-0 "
                            }
                            md={12}
                          >
                            {validationSubmit.selectedStudents &&
                              validationSubmit.selectedStudents.map(
                                (user: Student, index: number) => (
                                  <div
                                    className={
                                      styles.courseActivityLimitWrapper
                                    }
                                    key={index}
                                  >
                                    <p className={styles.activityLimit}>
                                      {user.fullName
                                        ? user.fullName
                                        : user.email}
                                    </p>
                                    <img
                                      src={deleteIcon}
                                      alt="Delete"
                                      className={styles.deleteIcon}
                                      onClick={() => handleRemoveStudent(user)}
                                    />
                                  </div>
                                )
                              )}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                {role === "Student" && (
                  <Row className=" pt-3">
                    <Col md={9} className="px-0">
                      <TextInput
                        errorMessage={errors.validationPhrase}
                        touched={touched.validationPhrase}
                        onBlur={handleBlur}
                        label="Validation phrase"
                        name="validationPhrase"
                        value={validationSubmit.validationPhrase}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleActivitySubmitInputChange(event)}
                      />
                    </Col>
                  </Row>
                )}
                {role === "Student" && !activity.isPresence && (
                  <Row className=" pt-4">
                    <Col md={9} className="px-0">
                      <Row>
                        <Col>
                          <CustomInput
                            errorMessage={errors.validationFiles}
                            touched={touched.validationFiles}
                            label="Validation files"
                            handler={attachFileHandler}
                            name="validationFiles"
                            fileType={3}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            className={
                              styles.listLimitWrapper + " col-12 pt-0 pb-3 pl-0"
                            }
                          >
                            {loading && (
                              <div
                                style={{
                                  width: "100%",
                                  minHeight: "300px",
                                  position: "absolute",
                                }}
                              >
                                <CircularProgress
                                  className={styles.progressBar}
                                />
                              </div>
                            )}
                            {listOfDocumentsForActivity.map(
                              (file: File, index: number) =>
                                file.type === 3 && (
                                  <div
                                    className={
                                      styles.courseActivityLimitWrapper
                                    }
                                    key={index}
                                  >
                                    <p
                                      className={cc([
                                        {
                                          [styles.noCursor]:
                                            file.fileName === undefined,
                                        },
                                        {
                                          [styles.activityLimit]: file.fileName,
                                        },
                                      ])}
                                      onClick={() =>
                                        file.fileName &&
                                        window.open(
                                          `${BASE_URL}File/GetFile/${file.fileName}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {file.name}
                                    </p>
                                    <img
                                      src={deleteIcon}
                                      alt="Delete"
                                      className={styles.deleteIcon}
                                      onClick={() => {
                                        removeActivityFile(file.fileID);
                                      }}
                                    />
                                  </div>
                                )
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {role === "Student" && activity.isPresence && (
                  <Row className="mt-4 px-0">
                    <Col md={9} className="px-0">
                      <SelectWithLabel
                        errorMessage={errors.dateID}
                        touched={touched.dateID}
                        onBlur={handleBlur}
                        isAttendance={true}
                        label="Date / Time"
                        name="dateID"
                        values={scheduleDays}
                        onChange={(event: any) => {
                          handleDate(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
              {role === "Student" && (
                <Col className="px-0">
                  <Row className=" justify-content-center">
                    <Col md={7}>
                      <TextArea
                        label="Comments"
                        name="comment"
                        value={validationSubmit.comment}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => handleActivitySubmitInputChange(event)}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {role === "Student" && (
              <Row className=" justify-content-end ">
                <Col md="auto" className="px-1" xs={12}>
                  <Button
                    isDialogCancel={true}
                    onClick={() => {
                      validationSubmitReset();
                      history.push(
                        `/academicTerms/${academicTermId}/courses/${courseId}/activities/`
                      );
                    }}
                    title="Cancel"
                    type="button"
                  />
                </Col>
                <Col md="auto" className="px-1" xs={12}>
                  <Button isDialog={true} title="Submit" type="submit" />
                </Col>
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(StudentForms);
