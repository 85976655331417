import React from "react";

import { CourseForList } from "../../../../models/state.models";
import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import CourseTableRow from "../CourseTableRow";
import styles from "./styles.module.scss";

const CourseTable = ({ list }: any) => {
  return (
    <table key="course-table" className={styles.table}>
      <thead>
        <tr>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("title")}
            >
              Title
              {list.orderBy === "title" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("courseCode")}
            >
              Course Code
              {list.orderBy === "courseCode" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("startDate")}
            >
              Start Date
              {"startDate".localeCompare(list.orderBy, undefined, {
                sensitivity: "accent",
              }) === 0 && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("endDate")}
            >
              End Date
              {list.orderBy === "endDate" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td>
            <div
              className={styles.headerCell}
              onClick={() => list.changeOrderBy("Status")}
            >
              Status
              {"Status".localeCompare(list.orderBy, undefined, {
                sensitivity: "accent",
              }) === 0 && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td />
        </tr>
      </thead>
      <tbody className={styles.cardBodyMobile}>
        {list &&
          list.entityList &&
          list.entityList.map((course: CourseForList) => (
            <CourseTableRow course={course} key={course.id} list={list} />
          ))}
      </tbody>
    </table>
  );
};

export default CourseTable;
