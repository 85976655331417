import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router";
import { Row } from "react-bootstrap";

import { Opportunity } from "../../models/state.models";
import { useAssignments, useDialog, useScreenSize } from "../../stores";
import CardSharpCorners from "../../common/CardSharpCorners";
import CourseNavigation from "../Components/CourseNavigation";
import CreditInfo from "./Components/CreditInfo";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SpendOpportunitiesCard from "./Components/SpendOpportunitiesCard";

const SpendOpportunities = () => {
  const { courseId, academicTermId }: any = useParams();
  const history = useHistory();
  const { getOpportunity, opportunityList, userCredits, courseName } =
    useAssignments();
  const { isTabs } = useScreenSize();
  const { showDialog } = useDialog();

  useEffect(() => {
    getOpportunity(courseId).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${academicTermId}/courses`,
            history,
            true,
            "Error"
          );
        }
        showDialog(resp.message, null, null, true, "Error");
      }
    });
  }, [getOpportunity, courseId]);

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isSpendOpportunities={true}>
        <CardSharpCorners isFullScreen={false}>
          <CreditInfo
            credits={userCredits.credit}
            currencyName={userCredits.currencyName}
          />
        </CardSharpCorners>
        <Row className={isTabs ? "px-3 pt-2" : "pt-2"}>
          {opportunityList &&
            opportunityList.map((opportunity: Opportunity) => {
              return (
                <SpendOpportunitiesCard
                  description={opportunity.additionalBuyBackRequirements}
                  id={opportunity.id}
                  isPurchased={opportunity.isPending}
                  title={opportunity.name}
                  tokens={opportunity.assignmentCreditCost}
                  currencyName={userCredits.currencyName}
                />
              );
            })}
        </Row>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(SpendOpportunities);
