import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";

import { useActivities, useAuth, useDialog } from "../../stores";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import ButtonWithImage from "../../common/ButtonWithImage";
import Card from "../../common/Card";
import ActivityNavigation from "../Components/ActivityNavigation";
import Icon from "../../assets/images/back.svg";
import Validations from "../ActivityValidations/Components/Validations";
import styles from "./styles.module.scss";
import SectionNames from "./Component/SectionNames";

const ActivitySectionsValidation = () => {
  const { courseId, activityId, academicTermId, sectionId }: any = useParams();
  const {
    activity,
    getActivity,
    getActivitySectionNames,
    activitySections,
    activityValidation,
    getActivityValidation,
    loading,
  } = useActivities();
  const { showDialog } = useDialog();
  const { role, courseName, setCourseName } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getActivity(courseId, activityId).then((resp: any) => {
      if (resp.status == "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
    });

    getActivitySectionNames(courseId, activityId);

    if (activityId) {
      getActivityValidation(courseId, sectionId, activityId).then(
        (resp: any) => {
          if (resp.status === "error") {
            if (
              resp.message.includes("not found") ||
              resp.message.includes("authorized")
            ) {
              showDialog(
                resp.message,
                `/academicTerms/${academicTermId}/courses`,
                history,
                true,
                "Errror"
              );
            }
          }
        }
      );
    }
  }, [getActivity, activityId, sectionId]);

  return (
    <DashboardBase
      componentName={courseName}
      location={activity.name}
      isActivity={true}
    >
      <ActivityNavigation isActivitySectionsValidation={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCard={true}
          isCoursesTabs={true}
        >
          <Row className=" p-0 m-0">
            {activitySections.length === 0 ? (
              <Col>
                <h1 className="mt-3">
                  Currently there are no sections for this activity.
                </h1>
              </Col>
            ) : (
              <Col>
                <Row>
                  <Col md="auto" className={styles.wrapper + " pt-2 pl-0"}>
                    <SectionNames list={activitySections} isFromUrl={true} />
                  </Col>
                  <Col>
                    {activityId && sectionId && !loading && (
                      <Validations list={activityValidation} />
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            <Row className="justify-content-end pt-2">
              <Col md="auto">
                <ButtonWithImage
                  title="Back"
                  onClick={() => {
                    history.push(
                      `/academicTerms/${academicTermId}/courses/${courseId}/activities`
                    );
                  }}
                  icon={Icon}
                />
              </Col>
            </Row>
          </Row>
        </Card>
      </ActivityNavigation>
    </DashboardBase>
  );
};

export default observer(ActivitySectionsValidation);
