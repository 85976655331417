import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Form, Formik } from "formik";

import BaseLogin from "../../../Components/BaseLogin";
import Button from "../../../../common/Button";
import TextInput from "../../../../common/TextInput";
import { useAuth, useDialog } from "../../../../stores";
import { ActivateUserSchema } from "../ActivateUserSchema";
import { RestPasswordSchema } from "../ResetPasswordSchema";
import styles from "./styles.module.scss";

const ActivateUserForm = () => {
  const { uid }: any = useParams();
  const url = useLocation();
  const history = useHistory();
  const {
    activateUser,
    changePassword,
    handleInputChangeChangePassword,
    handleInputChangeUserActivation,
    resetPassword,
    userForActivation,
    userInformacion,
  } = useAuth();

  console.log(userForActivation);
  const { showDialog } = useDialog();

  const [isResetPassword, setIsResetPassword] = useState(false);

  const submit = () => {
    if (isResetPassword) {
      changePassword(resetPassword.password, uid).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog("Password changed successfully", `/`, history, false);
        } else {
          showDialog(resp.message, null, null, false);
        }
      });
    } else {
      activateUser({
        RegistrationConfirmationLink: uid,
        Password: userForActivation.password,
        FirstName: userForActivation.firstName,
        LastName: userForActivation.lastName,
        MiddleName: userForActivation.middleName,
      }).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog("Profile successfully  activated", `/`, history, false);
        } else {
          showDialog(resp.message, null, null, false);
        }
      });
    }
  };

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    if (isResetPassword) {
      handleInputChangeChangePassword(event);
    } else {
      handleInputChangeUserActivation(event);
    }
  };

  useEffect(() => {
    if (url.pathname.includes("passwordrecovery")) {
      setIsResetPassword(true);
    }
  }, []);

  return (
    <Formik
      initialValues={
        isResetPassword ? { ...resetPassword } : { ...userForActivation }
      }
      validationSchema={
        isResetPassword ? RestPasswordSchema : ActivateUserSchema
      }
      onSubmit={() => {
        submit();
      }}
    >
      {({ errors, touched, values, handleChange, handleBlur }) => {
        return (
          <BaseLogin>
            <h1 className={styles.header}>
              {isResetPassword ? "Password is reseted" : "Welcome to Vineyard"}
            </h1>
            <p className={styles.subtitle}>
              {isResetPassword
                ? " Please set new password"
                : "  Please set up your password to finish the activation"}
            </p>
            <Form>
              {!isResetPassword && (
                <>
                  <TextInput
                    errorMessage={errors.firstName}
                    isLogin={true}
                    name="firstName"
                    onBlur={handleBlur}
                    placeholder="First Name"
                    touched={touched.firstName}
                    value={values.firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      textIntputHandleChange(event, handleChange)
                    }
                  />
                  <TextInput
                    errorMessage={errors.lastName}
                    isLogin={true}
                    name="lastName"
                    onBlur={handleBlur}
                    placeholder="Last Name"
                    touched={touched.lastName}
                    value={values.lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      textIntputHandleChange(event, handleChange)
                    }
                  />
                  <TextInput
                    errorMessage={errors.middleName}
                    isLogin={true}
                    name="middleName"
                    onBlur={handleBlur}
                    placeholder="Middle Name"
                    touched={touched.middleName}
                    value={values.middleName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      textIntputHandleChange(event, handleChange)
                    }
                  />
                </>
              )}

              <TextInput
                errorMessage={errors.password}
                isLogin={true}
                name="password"
                onBlur={handleBlur}
                placeholder={isResetPassword ? "New password" : "Password"}
                touched={touched.password}
                type="password"
                value={values.password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  textIntputHandleChange(event, handleChange)
                }
              />

              <TextInput
                errorMessage={errors.repeatPassword}
                isLogin={true}
                name="repeatPassword"
                onBlur={handleBlur}
                placeholder={
                  isResetPassword ? "Retype new password" : "Repeat password"
                }
                touched={touched.repeatPassword}
                type="password"
                value={values.repeatPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  textIntputHandleChange(event, handleChange)
                }
              />
              <div className="pt-3">
                <Button
                  title={isResetPassword ? "Save " : "Activate Profile"}
                  type="submit"
                  value="email"
                />
              </div>
            </Form>
          </BaseLogin>
        );
      }}
    </Formik>
  );
};

export default ActivateUserForm;
