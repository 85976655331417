import React, { useState } from "react";
import { useParams } from "react-router";

import { BASE_URL } from "../../../../../../utilities";
import { CourseUserCourseGroupList } from "../../../../../../models/state.models";
import { useDialog, useSectionGroups, useAuth } from "../../../../../../stores";
import defaultUser from "../../../../../../assets/images/default-user.png";
import editIcon from "../../../../../../assets/images/edit.svg";
import MessageBoxWithButtons from "../../../../../Components/MessageBoxWithButtons";
import trashIcon from "../../../../../../assets/images/trash.svg";
import styles from "./styles.module.scss";

interface StudentSecondaryGroupItemProps {
  openMoveStudentModal: () => void;
  studentGroup: CourseUserCourseGroupList;
}

const StudentSecondaryGroupItem: React.FC<StudentSecondaryGroupItemProps> = ({
  openMoveStudentModal,
  studentGroup,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { courseId }: any = useParams();
  const { role } = useAuth();
  const { setSelectedUser, setIsGroupEdit, deleteUserFromCourse } =
    useSectionGroups();
  const { showDialog } = useDialog();
  const openModalHandler = () => {
    openMoveStudentModal();
    setIsGroupEdit(true);
    setSelectedUser(studentGroup);
  };

  const deleteCourseUserCourseGroup = () => {
    deleteUserFromCourse(studentGroup.id, courseId).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("User removed from group successfully!", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  const handleConfirm = () => {
    deleteCourseUserCourseGroup();
  };

  return (
    <div className=" d-flex">
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to remove student from group?`}
        handleClose={() => setIsDialogOpen(false)}
        handleConfirm={() => handleConfirm()}
        isOpen={isDialogOpen}
      />
      <li className={styles.listItem + " justify-content-between py-2"}>
        <img
          className={styles.avatarIcon}
          src={
            studentGroup.imageFileName
              ? `${BASE_URL}File/GetFile/${studentGroup.imageFileName}`
              : defaultUser
          }
        />
        <p className={styles.nameFont + " pl-2"}>{studentGroup.studentName}</p>
        <div className=" ml-auto">
          {role !== "Assistant" && (
            <>
              <img
                src={editIcon}
                className={styles.icon}
                alt="edit"
                onClick={openModalHandler}
              />
              <img
                src={trashIcon}
                className={styles.icon}
                alt="delete"
                onClick={() => setIsDialogOpen(true)}
              />
            </>
          )}
        </div>
      </li>
    </div>
  );
};

export default StudentSecondaryGroupItem;
