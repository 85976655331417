import React from "react";

import ActivateUserForm from "./Components/ActivateUserF";

const ActivateUser = (urlParams: any) => {
  return (
    <div>
      <ActivateUserForm />
    </div>
  );
};

export default ActivateUser;
