import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "react-bootstrap";

import { Student } from "../../../../models/state.models";
import { useActivities } from "../../../../stores";
import StudentItem from "../StudentItem";
import styles from "./styles.module.scss";

interface StudentListProps {
  handleCancel: () => void;
  students: Student[];
}

const StudentList: React.FC<StudentListProps> = ({
  handleCancel,
  students,
}) => {
  const { clearCheckedStudents } = useActivities();
  const clearAllHandler = () => {
    clearCheckedStudents();
  };

  return (
    <div className={styles.studentCard + " p-3"}>
      <Row className="  d-flex">
        <Col>
          <h2>Students List</h2>
        </Col>
        <Col
          className={styles.closeWrapperText + " d-flex justify-content-center"}
        >
          <p onClick={handleCancel}>Close</p>
        </Col>
      </Row>
      <Row>
        <Col className={styles.clearAllWrapperText + " d-flex"}>
          <p onClick={clearAllHandler}>Clear All</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className={styles.studentList + " my-2"}>
            {students &&
              students.map((student: Student) => (
                <StudentItem key={student.id} student={student} />
              ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default observer(StudentList);
