import React from "react";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SchedulerComponent from "../Scheduler/Components/SchedulerComponent";

const StudentScheduler = () => {
  return (
    <DashboardBase componentName="Scheduler" havePadding={false}>
      <Card>
        <SchedulerComponent isStudent={true} />
      </Card>
    </DashboardBase>
  );
};

export default StudentScheduler;
