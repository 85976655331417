import React from "react";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import EditProfileForm from "./Components/EditProfileForm";

const EditProfile = () => {
  return (
    <DashboardBase componentName="Edit user" havePadding={true}>
      <EditProfileForm />
    </DashboardBase>
  );
};

export default EditProfile;
