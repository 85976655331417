import React from "react";

import GradeBook from "../../../Pages/GradeBook";
import StudentAssignmentActivity from "../../../Pages/GradeBook/Components/StudentAssignmentActivity";
import GradebookAddPoints from "../../../Pages/GradebookAddPoints";
import PrivateRoute from "../../PrivateRoute";

const GradebookRoutes = () => {
  return (
    <>
    <PrivateRoute
        component={GradeBook}
        path="/academicTerms/:academicTermId/courses/:courseId/gradebook"
      />
      <PrivateRoute 
        component = {GradebookAddPoints}
        path="/academicTerms/:academicTermId/courses/:courseId/section/:sectionId/addPoints"
      />
       <PrivateRoute 
        component = {StudentAssignmentActivity}
        path="/academicTerms/:academicTermId/courses/:courseId/student/:courseUserID/assignmentDetails/:idAssignment"
      />
    </>
  );
};

export default GradebookRoutes;