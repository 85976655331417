import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import { SaveActivityValidation } from "../../../../models/request.models";
import { useAuth } from "../../../../stores";
import { useDialog, useActivities } from "../../../../stores";
import checkIcon from "../../../../assets/images/check.svg";
import editIcon from "../../../../assets/images/edit.svg";
import exitIcon from "../../../../assets/images/cross.png";
import TextInput from "../../../../common/TextInput";
import { addWorkDayOffset } from "../../../../utils/timeUtils";
import styles from "./styles.module.scss";

const Terms = ({ term, activityID }: any) => {
  const {
    academicTermId,
    courseId,
    searchActivityList,
    sectionId,
    activityId,
  }: any = useParams();
  const { role } = useAuth();
  const {
    activity,
    saveValidation,
    getActivityValidation,
    selectedActivitySection,
  } = useActivities();
  const { showDialog } = useDialog();
  const [newValidation, setNewValidation] = useState<boolean>(false);
  const [validationName, setValidationName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [termErrorMessage, setTermErrorMessage] = useState<string>("");
  const [termTouched, setTermTouched] = useState<boolean>(false);
  const [workDay, setWorkDay] = useState<string>("");

  useEffect(() => {
    setWorkDay(moment(addWorkDayOffset(term.hourFrom)).format("MM.DD.YYYY"));
  }, []);

  const handleSubmit = () => {
    const request: SaveActivityValidation = {
      ActivityID: activityID,
      CourseID: courseId,
      SectionID: sectionId ?? selectedActivitySection,
      ValidationTerm: validationName,
      id: term.id ?? academicTermId,
      ScheduleDayID: term.scheduleDayID,
    };
    if (validationName !== term.validationTerm) {
      setLoading(true);
      saveValidation(request).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          getActivityValidation(
            courseId,
            sectionId || selectedActivitySection,
            activityID,
            true
          ).then((resp: any) => {
            setLoading(false);
            setValidationName("");
            setNewValidation(false);
            setTermTouched(false);
          });
        } else {
          if (resp.status === "error" && resp.message) {
            if (resp.message.includes("Validation failed")) {
              setNewValidation(true);
              setTermTouched(true);
              setTermErrorMessage("long term");
            } else {
              showDialog(resp.message, null, null);
            }
          }

          setLoading(false);
        }
      });
    }
  };

  return (
    <Row className=" py-1 pr-3">
      <Col
        md={4}
        className={styles.borderBottom + " py-3 d-flex align-items-center"}
      >
        <p>{workDay}</p>
        <p className={styles.greenText + " pl-2"}>
          {moment(addWorkDayOffset(term.hourFrom)).format("HH:mm")}
        </p>
      </Col>
      {newValidation ? (
        <Col md={6} className={styles.borderBottom}>
          <TextInput
            errorMessage={termErrorMessage}
            touched={termTouched}
            name="validationTerm"
            value={validationName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValidationName(event.target.value);
            }}
          />
        </Col>
      ) : (
        <Col md={6} className={styles.borderBottom + " pt-2"}>
          <div className=" pt-2">
            <p className={styles.valTerm}>
              {loading ? validationName : term.validationTerm}
            </p>
          </div>
        </Col>
      )}
      <Col
        md={2}
        className={
          styles.valEdit +
          " d-flex align-items-center pb-2" +
          (loading ? " justify-content-between" : "")
        }
      >
        {role !== "Assistant" && (
          <img
            alt="Edit"
            className={styles.icon}
            src={newValidation ? checkIcon : editIcon}
            onClick={() => {
              if (newValidation) {
                handleSubmit();
              } else {
                setValidationName(term.validationTerm);
              }
              setNewValidation(!newValidation);
              //   setValidationName(term.validationTerm);
            }}
          />
        )}
        {newValidation && (
          <img
            alt="Cancel"
            className={styles.icon}
            src={exitIcon}
            onClick={() => {
              setNewValidation(false);
              setValidationName(term.validationTerm);
            }}
          />
        )}
        {loading && (
          <CircularProgress size={30} className={styles.progressBar} />
        )}
      </Col>
    </Row>
  );
};

export default observer(Terms);
