import React, { useState, useEffect } from "react";
import cc from "classcat";

import styles from "./styles.module.scss";

interface CardProps {
  isCUUser?: boolean;
  isFullScreen?: boolean;
  isWithOutTopMargin?: boolean;
  isTableFullWidth?: boolean;
  isCard?: boolean;
  children: React.ReactNode;
  isNavigation?: boolean;
  isProfileEdit?: boolean;
  isMobileCourses?: boolean;
  isCoursesTabs?: boolean;
  isDetails?: boolean;
}

const Card: React.FC<CardProps> = ({
  isCUUser,
  children,
  isFullScreen,
  isCard,
  isTableFullWidth,
  isWithOutTopMargin,
  isNavigation,
  isProfileEdit,
  isMobileCourses,
  isCoursesTabs,
  isDetails,
}) => {
  const [bootstrapStyle, setBootstrapStyle] = useState(" col-12 py-4 ");

  useEffect(() => {
    if (isWithOutTopMargin) {
      setBootstrapStyle(bootstrapStyle + " mt-0");
    } else {
      setBootstrapStyle(bootstrapStyle + " mt-4");
    }
  }, [isWithOutTopMargin, setBootstrapStyle]);
  return (
    <div
      className={
        cc([
          styles.card,
          { [styles.cuUserContainerPadding]: isCUUser },
          { [styles.cardFullWidth]: isFullScreen },
          { [styles.tableFullWidth]: isTableFullWidth },
          { [styles.cardItem]: isCard },
          { [styles.navigationColor]: isNavigation },
          { [styles.profileEdit]: isProfileEdit },
          { [styles.mobileCourses]: isMobileCourses },
          { [styles.courseDetailsHolder]: isCoursesTabs },
          { [styles.details]: isDetails },
        ]) + bootstrapStyle
      }
    >
      {children}
    </div>
  );
};

export default Card;
