import React from "react";
import { useParams, useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";

import { useAssignments, useDialog } from "../../../../stores";
import Button from "../../../../common/Button";
import styles from "./styles.module.scss";

interface ModalProps {
  handleCancel: () => void;
  itemId: number;
}

const Modal: React.FC<ModalProps> = ({ handleCancel, itemId }) => {
  const { courseId, academicTermId }: any = useParams();
  const history = useHistory();
  const { showDialog } = useDialog();

  const { saveAssignmentPurchase, getOpportunity } = useAssignments();

  const saveAssignment = () => {
    saveAssignmentPurchase(courseId, itemId).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog(
          "Opportunity purchased succesfully",
          `/academicTerms/${academicTermId}/courses/${courseId}/SpendOpportunities`,
          history,
          false
        );
        getOpportunity(courseId);
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  return (
    <div className={styles.card + " px-5 py-5 mx-5 my-3"}>
      <Col>
        <Row className=" pb-5">
          <Col>
            <h2 className=" text-center">
              Are you sure you want to purchase this item?
            </h2>
          </Col>
        </Row>
        <Row className=" pt-5 ">
          <Col>
            <Button
              isDialog={true}
              onClick={() => {
                handleCancel();
                saveAssignment();
              }}
              title="Purchase"
              type="button"
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Modal;
