import React from "react";
import { Col, Row } from "react-bootstrap";

import styles from "./styles.module.scss";

interface CreditInfoProps {
  credits: number;
  currencyName: string;
}

const CreditInfo: React.FC<CreditInfoProps> = ({ credits, currencyName }) => {
  return (
    <Row>
      <Col className=" px-4 pt-4">
        <Row>
          <Col className={styles.textAlign}>
            <h2>Purchase Course Items</h2>
          </Col>
        </Row>
        <Row>
          <Col className={styles.textAlign + " d-flex pb-4"}>
            <p>Available {currencyName ? currencyName : "tokens"} -&nbsp;</p>
            <p className={styles.price}>{credits ? credits : 0}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreditInfo;
