import React, { useState, useEffect } from "react";
import cc from "classcat";

import styles from "./styles.module.scss";

interface CardProps {
  isFullScreen?: boolean;
  isWithOutTopMargin?: boolean;
  isTopPadding?: boolean;
  noPaddingLeft?: boolean;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  children,
  isFullScreen,
  isTopPadding,
  noPaddingLeft,
  isWithOutTopMargin,
}) => {
  const [bootstrapStyle, setBootstrapStyle] = useState(" col-12 ");

  useEffect(() => {
    if (isWithOutTopMargin) {
      setBootstrapStyle(bootstrapStyle + " mt-0 pb-2");
    } else {
      setBootstrapStyle(bootstrapStyle);
    }
  }, [isWithOutTopMargin, setBootstrapStyle]);
  return (
    <div
      className={
        cc([
          styles.card,
          { [styles.cardFullScreen]: isFullScreen },
          { [styles.cardTopPadding]: isTopPadding },
          { [styles.cardNoPadding]: noPaddingLeft },
        ]) + bootstrapStyle
      }
    >
      {children}
    </div>
  );
};

export default Card;
