import React from "react";
import cc from "classcat";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UseAutocompleteProps } from "@material-ui/lab/useAutocomplete";
import { FormikErrors, FormikTouched } from "formik";

import styles from "./styles.module.scss";

type Props = {
  errorMessage?:
    | string
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];

  name: string;
  isSearching: boolean;
  label: string;
  variant: "outlined" | "standard" | "filled";
  labelShrink?: boolean;
} & UseAutocompleteProps<any, boolean, undefined, undefined>;

const AutocompleteSelect: React.FC<Props> = ({
  errorMessage,
  getOptionLabel,
  getOptionSelected,
  label,
  isSearching,
  name,
  touched,
  value,
  variant,
  labelShrink,
  ...props
}) => (
  <div
    className={cc([
      styles.root,
      { [styles.errorRoot]: touched && errorMessage },
    ])}
  >
    {label && <InputLabel shrink={labelShrink}>{label}</InputLabel>}
    <Autocomplete
      noOptionsText={isSearching ? "Searching..." : "No Options"}
      className={styles.root}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <TextField {...params} variant={variant} />}
      value={value ?? ""}
      {...props}
    />
    {errorMessage && touched && (
      <p className={styles.errorMsg}>{errorMessage}</p>
    )}
  </div>
);

export default AutocompleteSelect;
