import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { Scheduler, User } from "../../../../models/state.models";
import { convertIntToDay, removeUtcOffset } from "../../../../utils/timeUtils";
import { useAuth, useDialog, useSection } from "../../../../stores";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import Card from "../../../../common/Card";
import IconBack from "../../../../assets/images/arrow-right.svg";
import ScoreBoard from "../../../Components/ScoreBoard";
import styles from "./styles.module.scss";

const SectionOverview: React.FC = () => {
  const { courseId, sectionId, academicTermId }: any = useParams();
  const { section, getSectionDetails } = useSection();
  const { selectedAcademicTermId } = useAuth();
  const { showDialog } = useDialog();
  const history = useHistory();

  useEffect(() => {
    getSectionDetails(sectionId, courseId).then((resp: any) => {
      if (resp.status !== "success" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Errror"
          );
        } else {
          showDialog(resp.message, null, null, true, "Errror");
        }
      }
    });
  }, [getSectionDetails, sectionId]);

  return (
    <Card
      isFullScreen={true}
      isWithOutTopMargin={true}
      isDetails={true}
      isCoursesTabs={true}
    >
      <Row className="justify-content-between pb-3 m-0">
        <Col md="auto" xs={6}>
          <h2>{section.title}</h2>
        </Col>
        <Col md="auto" xs={6}>
          <ButtonWithImage
            title="Go Back"
            icon={IconBack}
            onClick={() =>
              history.push(
                `/academicTerms/${academicTermId}/courses/${courseId}/sections`
              )
            }
          />
        </Col>
      </Row>
      <div className={styles.sectionOverviewWrapper + "  row m-0"}>
        <Col md={2} className="m-0 p-0">
          <div className={"pr-5"}>
            {section &&
              section.coursUsers &&
              section.coursUsers.map((user: User) => (
                <p key={user.email}>
                  {user.roleCode === "prof"
                    ? user.fullName
                      ? user.fullName
                      : user.email
                    : ""}
                </p>
              ))}
          </div>
        </Col>
        <Col md={3} className="px-0">
          <div className={styles.sectionDetailsWrapper}>
            <p className={styles.label}>Teaching Assistant</p>
            {section &&
              section.coursUsers &&
              section.coursUsers.map((user: User) => (
                <p className={styles.values} key={user.email}>
                  {user.roleCode === "tcAss"
                    ? user.fullName
                      ? user.fullName
                      : user.email
                    : ""}
                </p>
              ))}
          </div>
        </Col>
      </div>
      <h3>Time</h3>
      <Row className={styles.sectionOverviewWrapper}>
        <Col md="auto" className={styles.time}>
          {section.schedules.map((value: Scheduler, index: number) => {
            return (
              <p className={styles.values} key={index}>
                {convertIntToDay(value.dayOfWeek) +
                  " - " +
                  removeUtcOffset(value.hourFrom).format("h:mm A") +
                  "-" +
                  removeUtcOffset(value.hourTo).format("h:mm A") +
                  " " +
                  value.activityName}
              </p>
            );
          })}
        </Col>
      </Row>
      <h3>Score overview</h3>
      <Row>
        <ScoreBoard
          title="Individual student"
          isFirst={true}
          isSection={true}
        />
        <ScoreBoard title="By group" isSection={true} />
      </Row>
    </Card>
  );
};

export default observer(SectionOverview);
