import React from "react";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import CUSection from "../Components/CUSection";

const AddNewSection = () => {
  return (
    <DashboardBase componentName="Add a New Section">
      <CourseNavigation isSection={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCoursesTabs={true}
          isDetails={true}
        >
          <CUSection />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default AddNewSection;
