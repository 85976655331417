import React from "react";

import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import { ApplicationsForList } from "../../../../models/state.models";
import ApplicationsRow from "../ApplicationsRow";
import styles from "./styles.module.scss";

const ApplicationsTable = ({ list }: any) => {
  return (
    <table key="applications-table" className={styles.table}>
      <thead>
        <tr>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("applicationType")}>
              ApplicationType
              {list.orderBy === "applicationType" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("name")}>
              Name
              {list.orderBy === "name" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("_status")}>
              {" "}
              Status
              {list.orderBy === "_status" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td className={styles.headerCell}>
            {/* <div onClick={() => list.changeOrderBy("_status")}> */}
              {" "}
              Credit
              {/* {list.orderBy === "_status" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div> */}
          </td>

          <td className={styles.headerCell}>
            <div onClick={() => list.changeOrderBy("ApplicationDateTime")}>
              Date
              {list.orderBy === "ApplicationDateTime" && (
                <img
                  src={
                    list.orderByDirection === "desc"
                      ? iconArrowDown
                      : iconArrowUp
                  }
                  alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )}
            </div>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((applications: ApplicationsForList) => (
            <ApplicationsRow application={applications} />
          ))}
      </tbody>
    </table>
  );
};

export default ApplicationsTable;
