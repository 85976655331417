import { action, makeObservable, observable, runInAction } from "mobx";
import React from "react";
import {
  SectionGroupForList,
  SectionUserCourseList,
  CourseUserCourseGroupList,
} from "../../models/state.models";
import { CourseUserRequest } from "../../models/request.models";
import { FetchApi } from "../../utilities";

class SectionGroupsStore {
  @observable status: string = "inital";
  @observable message: string = "";
  @observable isSuccessRequest = false;
  @observable sectionGroupList: SectionGroupForList[] = [];
  @observable sectionGroupListCopy: SectionGroupForList[] = [];
  @observable groupList: SectionGroupForList[] = [];
  @observable selectedFilterGroupValue: number = 0;
  @observable sectionStudentList: SectionUserCourseList[] = [];
  @observable selectedSectionID: number = 0;
  @observable sectionTitle: string = "";
  @observable courseTitle: string = "";
  @observable isSectionStoreLoading: boolean = false;
  @observable selectedUser: CourseUserCourseGroupList = {
    id: 0,
    studentName: "",
    courseUserID: 0,
    courseGroupID: 0,
    imageFileName: "",
  };
  @observable sectionGroup: SectionGroupForList = {
    id: 0,
    courseID: 0,
    name: "",
    groupLabel: "",
    description: "",
    type: 2,
    isAccessibility: false,
    courseUserCourseGroupList: [],
  };
  @observable selectedStudentsID: number[] = [];
  @observable isGroupEdit: boolean = false;
  @observable sectionStudentListCopy: SectionUserCourseList[] = [];
  @observable selectedGroupID: number = 0;
  @observable isGroupDataEdit: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async getCourseGroupForList(sectionId: number, courseId: number) {
    this.isSectionStoreLoading = true;
    const { message, status, data }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/GetCourseGroupList",
      params: { sectionId: sectionId, courseId: courseId },
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.sectionGroupList = data;
        this.sectionGroupListCopy = data;
        this.groupList = data;
      } else {
        this.sectionGroupList = [];
      }
    });
    return { status, message };
  }

  @action.bound
  async getCourseUserList(
    courseID: number,
    sectionID: number,
    userType: number = 2
  ) {
    this.isSectionStoreLoading = true;
    let requestObject: CourseUserRequest = {
      CourseID: courseID,
      UserType: userType,
      SectionID: sectionID,
    };
    const { message, status, data, additionalData }: any = await FetchApi({
      method: "POST",
      url: "/CourseUserApi/GetCourseUserList",
      params: requestObject,
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.sectionTitle = additionalData.sectionName;
        this.courseTitle = additionalData.courseName;
        if (data) {
          this.sectionStudentList = data;
          this.sectionStudentListCopy = data;
        }
      }
    });
    return { status, message };
  }

  @action.bound
  async deleteSectionGroup(
    groupID: number,
    courseId: number,
    sectionId: number
  ) {
    this.isSectionStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/DeleteGroup",
      params: {
        CourseGroupID: groupID,
        courseId: courseId,
        sectionId: sectionId,
      },
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        let groupForDelete = this.sectionGroupList.find(
          (g) => g.id === groupID
        );
        if (groupForDelete) {
          let groupIndex = this.sectionGroupList.indexOf(groupForDelete);
          this.sectionGroupList.splice(groupIndex, 1);
        }
      }
    });
    return { status, message };
  }

  @action.bound
  async deleteUserFromCourse(
    courseUserCourseGroupID: number,
    courseId: number
  ) {
    this.isSectionStoreLoading = true;
    const { message, status }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/DeleteStudentFromGroup",
      params: {
        CourseUserCourseGroupID: courseUserCourseGroupID,
        courseId: courseId,
      },
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.findAndRemoveUserFromGroup(courseUserCourseGroupID);
      }
    });
    return { status, message };
  }

  @action.bound
  async saveCourseGroup(params: SectionGroupForList) {
    this.isSectionStoreLoading = true;
    const { status }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/SaveCourseGroup",
      params,
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
        this.resetStudentGroup();
      }
    });
    return { status: status };
  }

  @action.bound
  async saveCourseUserCourseGroup(
    params: CourseUserCourseGroupList[],
    courseID: number
  ) {
    let request = {
      courseID: courseID,
      courseuserCourseGroupList: params,
    };
    this.isSectionStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/SaveCourseUserCourseGroupList",
      params: request,
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
        this.selectedStudentsID = [];
      }
    });
    return { status, message };
  }

  @action.bound
  async moveStudentToGroup(params: any) {
    this.isSectionStoreLoading = true;
    const { status, message }: any = await FetchApi({
      method: "POST",
      url: "/CourseGroupApi/MoveStudentToGroup",
      params: params,
    });
    this.isSectionStoreLoading = false;
    runInAction(() => {
      if (status === "success") {
        this.isSuccessRequest = true;
      }
    });
    return { status, message };
  }

  @action.bound
  findAndRemoveUserFromGroup = (courseUserCourseGroupID: number) => {
    for (let group of this.sectionGroupList) {
      let student = group.courseUserCourseGroupList.find(
        (u) => u.id === courseUserCourseGroupID
      );
      if (student) {
        let studentIndex = group.courseUserCourseGroupList.indexOf(student);
        if (studentIndex > -1) {
          group.courseUserCourseGroupList.splice(studentIndex, 1);
          return;
        }
      }
    }
  };

  @action.bound
  resetSectionGroup = () => {
    this.sectionGroup = {
      id: 0,
      courseID: 0,
      name: "",
      groupLabel: "",
      description: "",
      type: 2,
      isAccessibility: false,
      courseUserCourseGroupList: [],
    };
  };

  @action.bound
  searchStudent = (filter: string) => {
    if (filter && filter !== "") {
      this.sectionStudentList = this.sectionStudentListCopy.filter((s) =>
        s.fullName.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      this.sectionStudentList = this.sectionStudentListCopy;
    }
  };

  @action.bound
  setIsGroupDataEdit = (isEdit: boolean) => {
    this.isGroupDataEdit = isEdit;
  };

  @action.bound
  filterGroupByCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let groupType = Number(event.target.value);
    if (groupType === 0) {
      this.sectionGroupList = this.groupList;
    } else {
      this.sectionGroupList = this.sectionGroupListCopy.filter(
        (g) => g.type === groupType
      );
    }
    this.selectedFilterGroupValue = groupType;
  };

  @action.bound
  handleSectionGroupInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.sectionGroup[event.target.name] = event.target.value;
  };

  @action.bound
  handlerColorPickerChange = (color: any) => {
    let selectedColor = "#" + color.hex;
    if (color.css && color.css.backgroundColor) {
      selectedColor = color.css.backgroundColor;
    }
    this.sectionGroup.groupLabel = selectedColor;
  };

  @action.bound
  handlerSelectedGroup = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let selectedGroupNumber = Number(event.target.value);
    this.selectedGroupID = selectedGroupNumber;
  };

  @action.bound
  setSelectedGroupID = (groupID: number) => {
    this.selectedGroupID = groupID;
  };

  @action.bound
  setSectionGroup = (group: SectionGroupForList) => {
    this.sectionGroup = group;
  };

  @action.bound
  handleSectionGroupSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.sectionGroup[event.target.name] = event.target.value;
  }

  @action.bound
  sectionGroupsStore(event: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedUser[event.target.name] = event.target.value;
  }

  @action.bound
  handlerSectionGroupCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    let isSelected = false;
    let selectedStudentID = Number(event.target.id);
    isSelected = event.target.checked;
    if (isSelected === true) {
      this.selectedStudentsID.push(selectedStudentID);
    } else {
      let index = this.selectedStudentsID.indexOf(selectedStudentID);
      if (index > -1) {
        this.selectedStudentsID.splice(index, 1);
      }
    }
  }

  @action.bound
  handlerCheckboxGroupType(event: React.ChangeEvent<HTMLInputElement>) {
    let isSelected = false;
    let checkboxType = event.target.id;
    isSelected = event.target.checked;
    if (isSelected === true) {
      if (checkboxType === "isPrimary") {
        this.sectionGroup.type = 1;
      } else {
        this.sectionGroup.isAccessibility = true;
      }
    } else {
      if (checkboxType === "isPrimary") {
        this.sectionGroup.type = 2;
      } else {
        this.sectionGroup.isAccessibility = false;
      }
    }
  }

  @action.bound
  clearCheckedStudents = () => {
    this.selectedStudentsID = [];
  };

  @action.bound
  setIsGroupEdit = (value: boolean) => {
    this.isGroupEdit = value;
  };

  @action.bound
  setSelectedUser = (user: CourseUserCourseGroupList) => {
    this.selectedUser = user;
  };

  @action.bound
  setSelectedSectionID = (id: number) => {
    this.selectedSectionID = id;
  };

  @action.bound
  resetStudentGroup = () => {
    this.sectionGroup = {
      id: 0,
      courseID: 0,
      name: "",
      groupLabel: "",
      description: "",
      type: 2,
      isAccessibility: true,
      courseUserCourseGroupList: [],
    };
  };

  @action.bound
  resetGroup() {
    this.sectionGroupList = [];
    this.sectionGroupListCopy = [];
    this.groupList = [];
    this.selectedFilterGroupValue = 0;
    this.sectionStudentList = [];
    this.sectionStudentListCopy = [];
  }

  @action.bound
  selectAllCourseUsers(value: boolean) {
    if (value) {
      this.selectedStudentsID = this.sectionStudentList.map(
        (user: SectionUserCourseList) => user.id
      );
    } else {
      this.selectedStudentsID = [];
    }
  }
}

export default SectionGroupsStore;
