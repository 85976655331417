import { observer } from "mobx-react-lite";
import React, { FormEvent, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import Button from "../../../../../../common/Button";
import {
  CourseUserCourseGroupList,
  SectionGroupForList,
} from "../../../../../../models/state.models";
import { useAuth, useDialog, useSectionGroups } from "../../../../../../stores";
import SelectWithLabel from "../../../../../../common/SelectWithLabel";
import styles from "./styles.module.scss";

interface MoveStudentModalProps {
  handleCancel: () => void;
  sectionGroupList: SectionGroupForList[];
  courseGroupID?: number;
}

const MoveStudentModal: React.FC<MoveStudentModalProps> = ({
  handleCancel,
  sectionGroupList,
}) => {
  const history = useHistory();
  const { sectionId, courseId }: any = useParams();
  const { selectedAcademicTermId } = useAuth();
  const { showDialog } = useDialog();
  const {
    selectedUser,
    saveCourseUserCourseGroup,
    getCourseGroupForList,
    selectedSectionID,
    isGroupEdit,
    selectedStudentsID,
    selectedGroupID,
    handlerSelectedGroup,
    setSelectedGroupID,
    moveStudentToGroup,
  } = useSectionGroups();
  console.log(JSON.stringify(selectedStudentsID));
  useEffect(() => {
    if (isGroupEdit === true) {
      setSelectedGroupID(selectedUser.courseGroupID);
    } else {
      setSelectedGroupID(sectionGroupList[0]?.id);
    }
  }, []);

  const prepareRequest = () => {
    let requestData: CourseUserCourseGroupList[] = [];
    if (isGroupEdit === true) {
      for (let group of sectionGroupList) {
        let student = group.courseUserCourseGroupList.find(
          (x) => x.courseUserID === selectedUser.courseUserID
        );
        if (student) {
          student.courseGroupID = selectedGroupID;
          let editRequest = {
            ID: student.id,
            courseGroupID: student.courseGroupID,
            courseID: selectedSectionID,
          };
          return editRequest;
        }
      }
    } else {
      for (let selectedID of selectedStudentsID) {
        let courseUserCourseGroupObject: CourseUserCourseGroupList = {
          id: 0,
          studentName: "",
          courseUserID: selectedID,
          courseGroupID: selectedGroupID,
          imageFileName: "",
        };
        requestData.push(courseUserCourseGroupObject);
      }
    }
    return requestData;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let request = prepareRequest();
    if (isGroupEdit === true) {
      moveStudentToGroup(request).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog(
            "User moved in group successfully!",
            null,
            null,
            false,
            "Notification"
          );
          getCourseGroupForList(sectionId, courseId).then((resp: any) => {
            if (resp.message) {
              if (resp.message !== "success" && resp.message) {
                if (
                  resp.message.includes("not found") ||
                  resp.message.includes("authorized")
                ) {
                  showDialog(
                    resp.message,
                    `/academicTerms/${selectedAcademicTermId}/courses`,
                    history,
                    true,
                    "Errror"
                  );
                } else {
                  showDialog(resp.message, null, null, true, "Errror");
                }
              }
            }
          });
          handleCancel();
        } else {
          showDialog(resp.message, null, null, false);
        }
      });
    } else {
      if (Array.isArray(request) && request.length === 0) {
        showDialog("Please select at least one user!", null, null, false);
        return;
      }
      saveCourseUserCourseGroup(request, selectedSectionID).then(
        (resp: any) => {
          if (resp.status === "success" && !resp.message) {
            showDialog("User added in group!", null, null, false);
            getCourseGroupForList(sectionId, courseId).then((resp: any) => {
              if (resp.message) {
                if (resp.message !== "success" && resp.message) {
                  if (
                    resp.message.includes("not found") ||
                    resp.message.includes("authorized")
                  ) {
                    showDialog(
                      resp.message,
                      `/academicTerms/${selectedAcademicTermId}/courses`,
                      history,
                      true,
                      "Errror"
                    );
                  } else {
                    showDialog(resp.message, null, null, true, "Errror");
                  }
                }
              }
            });
            handleCancel();
          } else {
            showDialog(resp.message, null, null, false);
            getCourseGroupForList(sectionId, courseId).then((resp: any) => {
              if (resp.message) {
                if (resp.message !== "success" && resp.message) {
                  if (
                    resp.message.includes("not found") ||
                    resp.message.includes("authorized")
                  ) {
                    showDialog(
                      resp.message,
                      `/academicTerms/${selectedAcademicTermId}/courses`,
                      history,
                      true,
                      "Errror"
                    );
                  } else {
                    showDialog(resp.message, null, null, true, "Errror");
                  }
                }
              }
            });
          }
        }
      );
    }
  };

  return (
    <form action="#" onSubmit={handleSubmit}>
      <div className={styles.moveStudnetWrapper + " p-5"}>
        <Row>
          <Col>
            <SelectWithLabel
              label="Select Group"
              values={sectionGroupList}
              value={selectedGroupID}
              onChange={handlerSelectedGroup}
              name="courseGroupID"
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Button
              isDialogCancel={true}
              onClick={handleCancel}
              title="Cancel"
              type="button"
            />
          </Col>
          <Col>
            <Button
              isDialog={true}
              title="Move To Group"
              type="submit"
              onClick={handleSubmit}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default observer(MoveStudentModal);
