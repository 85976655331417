import React from "react";
import { observer } from "mobx-react";
import { Col } from "react-bootstrap";

import { useAuth, useAssignments } from "../../stores";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import StudentList from "./Components/StudentList";

const AssignmentPurchases = () => {
  const { courseName } = useAuth();
  const { AssignmentTitle } = useAssignments();

  return (
    <DashboardBase
      componentName={courseName}
      location={AssignmentTitle}
      isAssignment={true}
    >
      <Col className="mt-4 ">
        <p>Students</p>
        <Card isFullScreen={true} isWithOutTopMargin={true}>
          <StudentList />
        </Card>
      </Col>
    </DashboardBase>
  );
};

export default observer(AssignmentPurchases);
