import React from "react";
import cc from "classcat";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from "@material-ui/pickers";

import styles from "./styles.module.scss";

export type TimePickerProps = {
  errorMessage?: any;
  onBlur?: (e: React.FocusEvent) => void;
  touched?: any;
  topLabel: string;
} & KeyboardTimePickerProps;

const TimePicker: React.FC<TimePickerProps> = ({
  errorMessage,
  onBlur,
  touched,
  topLabel,
  ...props
}) => {
  return (
    <div
      className={cc([
        styles.root,
        { [styles.hasError]: touched && errorMessage },
      ])}
    >
      <label className={styles.label}>
        {topLabel}
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              ampm={true}
              className={styles.timePicker}
              keyboardIcon={<AccessTimeIcon />}
              format="h:mm A"
              minutesStep={5}
              mask="__:__ _M"
              onBlur={onBlur}
              variant="inline"
              {...props}
            />
          </MuiPickersUtilsProvider>
          {errorMessage && touched && (
            <div className={styles.errorMsg}>{errorMessage}</div>
          )}
        </div>
      </label>
    </div>
  );
};

export default TimePicker;
