import React from "react";
import { Pagination } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";

import SelectWithLabel from "../../../common/SelectWithLabel";
import { calculateNumberOfPages } from "../../../utils/paginationUtils";
import styles from "./styles.module.scss";

const TablePager = ({ list }: any) => {
  return (
    <>
      {list.entityList.length > 0 && (
        <Row
          className="mt-2 justify-content-between align-items-end"
          key="table-pager"
        >
          <Col md="auto" xs={4} className="d-flex align-items-end">
            <SelectWithLabel
              label="List Size"
              name="isActive"
              defaultValue={list.pageSize}
              onChange={(event: any) => list.changePageSize(event.target.value)}
              values={[
                { name: 5, id: 5 },
                { name: 10, id: 10 },
                { name: 25, id: 25 },
                { name: 50, id: 50 },
              ]}
            />
          </Col>
          <Col md="auto" xs="auto" className="d-flex justify-content-end">
            {/* {calculateNumberOfPages(list.totalCount, list.pageSize) > 1 && ( */}
            <Pagination
              className={styles.pagination}
              page={list.page}
              count={calculateNumberOfPages(list.totalCount, list.pageSize)}
              onChange={(event: object, page: number) => list.changePage(page)}
            />
            {/* )} */}
          </Col>
        </Row>
      )}
    </>
  );
};

export default TablePager;
