import { observer } from "mobx-react";
import { useAuth } from "../../../../stores";
import TablePager from "../../../Components/TablePager";
import BaseTable from "../../../Components/BaseTable";
import TableFilter from "../../../Components/TableFilter";
import UserTable from "../UserTable";
const UserList = () => {
  const { usersList } = useAuth();

  return (
    <BaseTable
      list={{
        url: "/UserApi/GetUsers",
        data: { IsIndividual: false },
        filterParameters: [
          { name: "Firstname", dataType: "string", operator: "like" },
          { name: "Lastname", dataType: "string", operator: "like" },
          { name: "Email", dataType: "string", operator: "like" },
          {
            name: "UserRoles",
            dataType: "string",
            operator: "any",
            type: "select",
          },
          {
            name: "IsActive",
            dataType: "boolean",
            operator: "eq",
            type: "select",
          },
        ],
      }}
    >
      <TableFilter
        isWithoutButton={false}
        buttonDestination={`/user/add`}
        buttonTitle="Add new user"
      ></TableFilter>

      <UserTable list={usersList}></UserTable>
      <TablePager></TablePager>
    </BaseTable>
  );
};
export default observer(UserList);
