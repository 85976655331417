import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";

import editIcon from "../../../assets/images/edit.svg";
import cloneIcon from "../../../assets/images/clone.svg";
import viewIcon from "../../../assets/images/view.svg";
import trashIcon from "../../../assets/images/trash.svg";
import { useCourses, useDialog, useAuth } from "../../../stores";
import MessageBoxWithButtons from "../MessageBoxWithButtons";
import styles from "./styles.module.scss";

interface EditViewButtonsProps {
  courseId?: number;
  isCourses?: boolean;
  userId?: number;
  courseTitle?: string;
  list?: any;
}

const EditViewButtons: React.FC<EditViewButtonsProps> = ({
  courseId,
  isCourses,
  userId,
  courseTitle,
  list,
}) => {
  const history = useHistory();
  const {
    setSelectedCourseID,
    deleteCourse,
    setSelectedCourseTitle,
    setIsClone,
  } = useCourses();
  const { academicTermId }: any = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { showDialog } = useDialog();
  const { role } = useAuth();

  const submitView = () => {
    if (isCourses) {
      setSelectedCourseTitle(courseTitle);
      setSelectedCourseID(courseId);
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/details`
      );
    }
  };

  const submitEdit = (clone: boolean) => {
    if (isCourses) {
      if (clone) {
        setIsClone(true);
      } else {
        setIsClone(false);
      }
      history.push(`/academicTerms/${academicTermId}/courses/edit/${courseId}`);
    } else {
      history.push(`/user/edit/${userId}`);
    }
  };

  const handleConfirm = () => {
    deleteCourse(courseId).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        list.refresh();
        showDialog("Course successfully  deleted", null, null, false);
      } else {
        showDialog(resp.message, null, null, false);
      }
      list.refresh();
    });
  };

  return (
    <div className={styles.iconCell}>
      <MessageBoxWithButtons
        title="Alert"
        message={`Are you sure you want to delete ${courseTitle} course ?`}
        handleClose={() => setIsOpen(false)}
        handleConfirm={handleConfirm}
        isOpen={isOpen}
      />
      {role !== "Student" && (
        <>
          <img
            alt="Clone"
            className={styles.icon}
            src={cloneIcon}
            onClick={() => submitEdit(true)}
          />
          <img
            alt="Edit"
            className={styles.icon}
            src={editIcon}
            onClick={() => submitEdit(false)}
          />
        </>
      )}
      {!userId && (
        <img
          alt="View"
          className={styles.icon}
          src={viewIcon}
          onClick={submitView}
        />
      )}
      {role !== "Student" && (
        <img
          alt="delete"
          className={styles.icon}
          src={trashIcon}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        />
      )}
    </div>
  );
};

export default observer(EditViewButtons);
