import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";

import {
  CourseUserGradebookDTO,
  GradebookAsignmentDTO,
} from "../../../../models/state.models";
import { GradebookRequest } from "../../../../models/request.models";
import { useAuth, useDialog, useGradebookStore } from "../../../../stores";
import { useHistory, useParams } from "react-router";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import GradebookTableRow from "../GradebookTableRow";
import plusIcon from "../../../../assets/images/plus.svg";
import SelectWithoutLabel from "../../../../common/SelectWithOutLabel";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";

const GradebookList: React.FC<any> = () => {
  const [filter, setFilter] = useState(0);
  const [noStudents, setNoStudents] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const { courseId, academicTermId }: any = useParams();
  const { showDialog } = useDialog();
  const history = useHistory();

  const {
    courseSectionList,
    filterHandlerInput,
    getCourseSectionList,
    getGradebookList,
    gradebook,
    gradebookStudents,
    setSelectedSectionForPoint,
  } = useGradebookStore();
  const { selectedAcademicTermId, role, setCourseName } = useAuth();

  const prepareItemsForSelect = () => {
    let items = [];
    for (let section of courseSectionList) {
      let itemObject = {
        label: section.value,
        value: section.codeInt,
      };
      items.push(itemObject);
    }
    return items;
  };

  useEffect(() => {
    getCourseSectionList(courseId).then((resp: any) => {
      if (resp.status === "error") {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Errror"
          );
        }
      }
      if (resp.status == "success") {
        setCourseName(resp.breadCrumb.courseName);
      }
    });
  }, [courseId, getCourseSectionList, getGradebookList]);

  useEffect(() => {
    if (courseSectionList.length !== 0 && filter === 0) {
      let request: GradebookRequest = {
        id: 0,
        gradebookID: 0,
        courseID: courseId,
        sectionID: courseSectionList[0].codeInt,
        assignmentID: 0,
        points: 0,
        courseUserID: 0,
      };
      setFilter(courseSectionList[0].codeInt);
      getGradebookList(request).then((resp: any) => {
        if (resp.status === "error" && resp.message) {
          if (
            resp.message.includes("not found") ||
            resp.message.includes("authorized")
          ) {
            showDialog(
              resp.message,
              `/academicTerms/${selectedAcademicTermId}/courses`,
              history,
              true,
              "Errror"
            );
          }
          if (resp.message.includes("There are no enrolled students")) {
            setErrorText(resp.message);
            setNoStudents(true);
          } else {
            setErrorText(resp.message);
            setNoStudents(true);
          }
        }
        if (resp.status === "success" && !resp.message) {
          setNoStudents(false);
        }
      });
      setSelectedSectionForPoint(courseSectionList[0].codeInt);
    }
  }, [courseSectionList]);

  const handlerFilter = (event: any) => {
    let sectionID = Number(event.target.value);
    let request: GradebookRequest = {
      id: 0,
      gradebookID: 0,
      courseID: courseId,
      sectionID: sectionID,
      assignmentID: 0,
      points: 0,
      courseUserID: 0,
    };
    setFilter(sectionID);
    getGradebookList(request).then((resp: any) => {
      if (resp.status === "error" && resp.message) {
        if (
          resp.message.includes("not found") ||
          resp.message.includes("authorized")
        ) {
          showDialog(
            resp.message,
            `/academicTerms/${selectedAcademicTermId}/courses`,
            history,
            true,
            "Errror"
          );
        }
        setNoStudents(true);
      }
      if (resp.status === "success" && !resp.message) {
        setNoStudents(false);
      }
    });
    setSelectedSectionForPoint(sectionID);
  };

  const renderStudents = () => {
    return (
      gradebookStudents &&
      gradebookStudents.map((student: CourseUserGradebookDTO) => (
        <GradebookTableRow key={student.courseUserID} student={student} />
      ))
    );
  };

  const handlerAddPoints = () => {
    if (filter === 0) {
      showDialog("Please select one section");
    } else {
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/section/${filter}/addPoints`
      );
    }
  };

  return (
    <>
      {role !== "Student" && (
        <Row className="justify-content-between px-3">
          <Col>
            <Row>
              <Col md="auto" xs="auto">
                <TextInput
                  isSearch={true}
                  placeholder="Search Student"
                  onChange={filterHandlerInput}
                />
              </Col>
              <Col className=" mb-2" md="auto" xs="auto">
                <SelectWithoutLabel
                  value={filter}
                  values={prepareItemsForSelect()}
                  onChange={handlerFilter}
                />
              </Col>
              <Col className={styles.buttonWrapper} md="auto" xs="auto">
                <ButtonWithImage
                  icon={plusIcon}
                  title="add points"
                  type="button"
                  onClick={handlerAddPoints}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {noStudents ? (
        <h1 className="mt-3">{errorText}</h1>
      ) : (
        <table className={styles.table + " mt-4"}>
          <thead>
            <tr>
              <td className={styles.alignItemsTable}>Name</td>
              <td className={styles.alignItemsTable}>Grade</td>
              {gradebook &&
                gradebook.assignmmentList &&
                gradebook.assignmmentList.map(
                  (assignment: GradebookAsignmentDTO) => (
                    <td
                      key={assignment.ID}
                      className={styles.tableStyleWrapper}
                    >
                      <div className={styles.innerTableHolder}>
                        {assignment.name}
                        <div className={styles.innerWrapperTable}>
                          <span className={styles.textWrapperStyle}>
                            max:{assignment.maxPoints}
                          </span>
                        </div>
                      </div>
                    </td>
                  )
                )}
            </tr>
          </thead>
          <tbody>{renderStudents()}</tbody>
        </table>
      )}
    </>
  );
};

export default observer(GradebookList);
