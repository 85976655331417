import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { useParams } from "react-router";

import CardSharpCorners from "../../../../common/CardSharpCorners";
import PresenceActivity from "../PresenceActivity";
import RegularActivity from "../RegularActivity";
import styles from "./styles.module.scss";
import { useActivities } from "../../../../stores";

const Validations = (props: any) => {
  const { activityId, courseId, academicTermId, sectionId }: any = useParams();

  const {
    selectedActivitySection,
    getActivity,
    activity,
    getSelectedActivitySection,
    activityValidation,
    searchActivityList,
    getActivityValidation,
  } = useActivities();
  const activityID = activityId ?? activity.Id ?? searchActivityList.id;
  useEffect(() => {
    getSelectedActivitySection();

    getActivityValidation(
      courseId,
      sectionId ?? selectedActivitySection,
      activityID,
      true
    );
  }, [selectedActivitySection]);

  return (
    <Col className={styles.wrap}>
      {props.list.isPresence && activityID ? (
        props.list.validationTermList.length === 0 ? (
          <h1>This section is not added to schedule. </h1>
        ) : (
          <PresenceActivity list={props.list} />
        )
      ) : (
        <CardSharpCorners isFullScreen={true} isWithOutTopMargin={true}>
          <RegularActivity
            list={props.list}
            isFromUrl={props.isFromUrl}
            activityID={activityID}
          />
        </CardSharpCorners>
      )}
    </Col>
  );
};

export default observer(Validations);
