import React from "react";
import { observer } from "mobx-react";

import { useSection } from "../../stores";
import Card from "../../common/Card";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SectionNavigation from "../Components/SectionNavigation";
import StudentList from "./Components/StudentList";

const SectionStudents = () => {
  const { sectionTitle, courseTitle } = useSection();

  return (
    <DashboardBase componentName={courseTitle} location={sectionTitle}>
      <SectionNavigation isStudents={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCoursesTabs={true}
        >
          <StudentList />
        </Card>
      </SectionNavigation>
    </DashboardBase>
  );
};

export default observer(SectionStudents);
