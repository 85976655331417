import React from "react";

import styles from "./styles.module.scss";
import StudentRow from "../StudentRow";
import { AssignmentStudent } from "../../../../models/state.models";

const AssignmentTable = ({ list }: any) => {
  return (
    <table key="assignmnet-table" className={styles.table}>
      <thead>
        <tr>
          <td />
          <td>
            <div
              className={styles.headerCell}
              // onClick={() => list.changeOrderBy("name")}
            >
              Name
              {/* {list.orderBy === "name" && (
                <img
                  src={
                    // list.orderByDirection === "desc"
                    //   ? iconArrowDown
                    //   : iconArrowUp
                    iconArrowUp
                  }
                  // alt={list.orderByDirection === "desc" ? "desc" : "asc"}
                  className={styles.arrow}
                />
              )} */}
            </div>
          </td>
          <td>Date</td>
          <td />
          <td />
          <td />
        </tr>
      </thead>
      <tbody>
        {list &&
          list.entityList &&
          list.entityList.map((student: AssignmentStudent) => (
            <StudentRow student={student} key={student.id} list={list} />
          ))}
      </tbody>
    </table>
  );
};

export default AssignmentTable;
