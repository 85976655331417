import React from "react";
import AddNewAssignment from "../../../Pages/AddNewAssignment";
import Assignments from "../../../Pages/Assignments";
import EditAssignment from "../../../Pages/EditAssignment";
import AssignmentPurchases from "../../../Pages/AssignmentPurchases";
import PrivateRoute from "../../PrivateRoute";

const AssignmentsRoutes = () => {
  return (
    <>
      <PrivateRoute
        component={Assignments}
        path="/academicTerms/:academicTermId/courses/:courseId/assignments"
        exact
      />
      <PrivateRoute
        component={AddNewAssignment}
        path="/academicTerms/:academicTermId/courses/:courseId/assignments/add"
        exact
      />
      <PrivateRoute
        component={EditAssignment}
        path="/academicTerms/:academicTermId/courses/:courseId/assignments/edit/:idAssignment"
        exact
      />
      <PrivateRoute
        component={AssignmentPurchases}
        path="/academicTerms/:academicTermId/courses/:courseId/assignments/:assignmentId/assignmentPurchase"
      />
    </>
  );
};

export default AssignmentsRoutes;
