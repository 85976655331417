import React, { useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import cc from "classcat";
import { useFormikContext } from "formik";

import { CourseUser } from "../../../../models/state.models";
import { SaveSectionStudent } from "../../../../models/request.models";
import { useDialog, useSection } from "../../../../stores";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import cancel from "../../../../assets/images/cancel.svg";
import PlusIcon from "../../../../assets/images/plus.svg";
import resend from "../../../../assets/images/resend.svg";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";

interface AddStudentProps {
  visibility: boolean;
  isEdit?: boolean;
  student?: CourseUser;
  list: any;
  visibleRow?: any;
  handleCancel?: () => void;
}

const AddStudent: React.FC<AddStudentProps> = ({
  visibility,
  isEdit,
  student,
  list,
  visibleRow,
  handleCancel,
}) => {
  const { handleStudentInputChange, saveCourseUser, handleResendMail } =
    useSection();
  const { sectionId, courseId }: any = useParams();
  const { showDialog } = useDialog();

  const [email, setEmail] = useState(student?.email);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);

  const { errors, handleChange, touched, values } = useFormikContext<any>();

  const textIntputHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: any
  ) => {
    handleChange(event);
    handleStudentInputChange(event);
  };

  const validation = () => {
    let valid = true;

    if (email === "") {
      setEmailErrorMessage("Requred");
      setEmailTouched(true);
      valid = false;
    } else {
      setEmailErrorMessage("");
      setEmailTouched(false);
    }
    return valid;
  };

  const handleClick = () => {
    const request: SaveSectionStudent = {
      CourseID: courseId,
      SectionID: sectionId,
      RoleCode: "stud",
      Email: email,
      ID: student?.id,
    };
    if (validation()) {
      saveCourseUser(request).then((resp: any) => {
        if (resp.status === "success" && !resp.message) {
          showDialog("Student updated successfully", null, null, false);
          list.refresh();
        } else {
          showDialog(resp.message, null, null, false);
        }
      });
    }
  };

  const handleResend = () => {
    handleResendMail(student?.userID).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        showDialog("Email send successfully", null, null, false);
        list.refresh();
      } else {
        showDialog(resp.message, null, null, false);
      }
    });
  };

  return (
    <tr
      className={cc([
        styles.addStudentRowVisibie,
        { [styles.addStudnetRowInvisible]: visibility },
      ])}
    >
      <td>-</td>
      <td className={styles.emailWrapper}>
        <TextInput
          data-label="email"
          name="email"
          errorMessage={isEdit ? emailErrorMessage : errors.email}
          value={isEdit ? email : values.email}
          touched={isEdit ? emailTouched : touched.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            isEdit
              ? setEmail(event.target.value)
              : textIntputHandleChange(event, handleChange);
          }}
        />
      </td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className={styles.contentWrapper}>
        {!isEdit ? (
          <div className="d-flex align-items-center">
            <ButtonWithImage title={"Add "} type="submit" icon={PlusIcon} />
            <img
              src={cancel}
              className={styles.cancel}
              onClick={() => handleCancel && handleCancel()}
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <ButtonWithImage
              title="Save"
              type="button"
              onClick={() => handleClick()}
            />
            <img
              src={cancel}
              className={styles.cancel}
              onClick={() => visibleRow(!visibility)}
            />
            <img
              src={resend}
              className={styles.resend}
              onClick={() => handleResend()}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default observer(AddStudent);
