import React from "react";

import CUActivity from "../Components/CUActivity";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";

const AddNewActivity = () => {
  return (
    <DashboardBase componentName="New Activity">
      <CourseNavigation isActivities={true}>
        <Card
          isFullScreen={true}
          isWithOutTopMargin={true}
          isCoursesTabs={true}
          isDetails={true}
        >
          <CUActivity />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default AddNewActivity;
