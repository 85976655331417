import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { observer } from "mobx-react";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import cc from "classcat";

import { CourseUser } from "../../../../models/state.models";
import { SaveSectionStudent } from "../../../../models/request.models";
import { StudentSchema } from "../StudentSchema";
import { useDialog, useSection, useAuth } from "../../../../stores";
import AddStudent from "../AddStudent";
import ButtonWithImage from "../../../../common/ButtonWithImage";
import Icon from "../../../../assets/images/back.svg";
import iconArrowDown from "../../../../assets/images/arrow-down.svg";
import iconArrowUp from "../../../../assets/images/arrow-up.svg";
import PlusIcon from "../../../../assets/images/plus.svg";
import StudentsRow from "../StudentRow";
import styles from "./styles.module.scss";

const StudentsTable = ({ list }: any) => {
  const { courseId, sectionId, academicTermId }: any = useParams();
  const { role } = useAuth();
  const { showDialog } = useDialog();
  const { student, saveCourseUser, resetStudent } = useSection();
  const history = useHistory();

  const [addStudentRowVisibility, setAddStudentRowVisibility] = useState(true);
  const handleSubmit = () => {
    const request: SaveSectionStudent = {
      CourseID: courseId,
      Email: student.email,
      RoleCode: "stud",
      SectionID: sectionId,
    };

    saveCourseUser(request).then((resp: any) => {
      if (resp.status === "success" && !resp.message) {
        list.refresh();
        showDialog("Student added successfully", null, null, false);
        list.refresh();
        resetStudent();
        setAddStudentRowVisibility(true);
      } else {
        showDialog(resp.message, null, null, false);
        setAddStudentRowVisibility(false);
      }
    });
  };

  return (
    <>
      <Row className="justify-content-end px-3">
        {role === "Professor" && (
          <div
            className={cc([
              styles.addButtonInvisible,
              { [styles.addButtonVisible]: addStudentRowVisibility },
            ])}
          >
            <ButtonWithImage
              title="Add New"
              onClick={() => {
                setAddStudentRowVisibility(false);
              }}
              icon={PlusIcon}
            />
          </div>
        )}
        <ButtonWithImage
          title="Back"
          onClick={() => {
            history.push(
              `/academicTerms/${academicTermId}/courses/${courseId}/sections`
            );
          }}
          icon={Icon}
        />
      </Row>
      <Formik
        initialValues={{ ...student }}
        validationSchema={StudentSchema}
        enableReinitialize
        onSubmit={() => {
          handleSubmit();
        }}
      >
        {() => {
          return (
            <Row className="mx-0">
              <Form className={styles.fullWidth}>
                <div className={styles.tableMainWrapper}>
                  <table key="student-table" className={styles.table}>
                    <thead>
                      <tr>
                        <td>
                          <div
                            className={styles.headerCell}
                            onClick={() => list.changeOrderBy("StudentName")}
                          >
                            Student Name
                            {list.orderBy === "StudentName" && (
                              <img
                                src={
                                  list.orderByDirection === "desc"
                                    ? iconArrowDown
                                    : iconArrowUp
                                }
                                alt={
                                  list.orderByDirection === "desc"
                                    ? "desc"
                                    : "asc"
                                }
                                className={styles.arrow}
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className={styles.headerCell}
                            onClick={() => list.changeOrderBy("user.email")}
                          >
                            Email
                            {list.orderBy === "user.email" && (
                              <img
                                src={
                                  list.orderByDirection === "desc"
                                    ? iconArrowDown
                                    : iconArrowUp
                                }
                                alt={
                                  list.orderByDirection === "desc"
                                    ? "desc"
                                    : "asc"
                                }
                                className={styles.arrow}
                              />
                            )}
                          </div>
                        </td>

                        <td>
                          <div
                            className={styles.headerCell}
                            onClick={() =>
                              list.changeOrderBy("PrimaryGroupName")
                            }
                          >
                            Primary Group Name
                            {list.orderBy === "PrimaryGroupName" && (
                              <img
                                src={
                                  list.orderByDirection === "desc"
                                    ? iconArrowDown
                                    : iconArrowUp
                                }
                                alt={
                                  list.orderByDirection === "desc"
                                    ? "desc"
                                    : "asc"
                                }
                                className={styles.arrow}
                              />
                            )}
                          </div>
                        </td>
                        <td>Credits earned</td>
                        <td>Credits spent</td>
                        <td>Current course grade</td>
                        <td>Number of absences</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <AddStudent
                        visibility={addStudentRowVisibility}
                        handleCancel={() => setAddStudentRowVisibility(true)}
                        list={list}
                      />
                      {list &&
                        list.entityList &&
                        list.entityList.map((student: CourseUser) => (
                          <StudentsRow
                            student={student}
                            key={student.id}
                            list={list}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </Form>
            </Row>
          );
        }}
      </Formik>
    </>
  );
};

export default observer(StudentsTable);
