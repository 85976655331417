import React from "react";
import { action, makeObservable, observable } from "mobx";

class ScreenSizeStore {
  @observable size: number = window.innerWidth;
  @observable isTabs: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setSize(size: number) {
    this.size = size;
    if (size >= 1024) {
      this.isTabs = true;
    } else {
      this.isTabs = false;
    }
  }
}

export default ScreenSizeStore;
