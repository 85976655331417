import React, { useState, useEffect } from "react";
import cc from "classcat";
import moment from "moment";
import { useHistory, useParams } from "react-router";

import viewIcon from "../../../../assets/images/view.svg";
import { ApplicationsForList } from "../../../../models/state.models";
import styles from "./styles.module.scss";

export interface ApplicationRowProps {
  application: ApplicationsForList;
}

const ApplicationsRow: React.FC<ApplicationRowProps> = ({ application }) => {
  const history = useHistory();
  const { courseId, academicTermId }: any = useParams();
  const [submitedId, setSubmitedId] = useState(0);
  const [url, setUrl] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (application.submittedActivityID) {
      setSubmitedId(
        application.submittedActivityID ? application.submittedActivityID : 0
      );
      setUrl("activityDetails");
    } else if (application.submittedAssignmentID) {
      setSubmitedId(
        application.submittedAssignmentID
          ? application.submittedAssignmentID
          : 0
      );
      setUrl("spendOpportunitiesDetails");
    } else if (application.transactionID) {
      setSubmitedId(application.transactionID ? application.transactionID : 0);
      setUrl("extraCredit");
    }
    setIsClicked(true);
  };

  useEffect(() => {
    if (isClicked) {
      history.push(
        `/academicTerms/${academicTermId}/courses/${courseId}/applications/${submitedId}/${url}`
      );
      setIsClicked(false);
    }
  }, [submitedId]);

  return (
    <tr onClick={handleClick} className={styles.tableRow}>
      <td data-label="Application Type">
        {application.applicationType}
        <p className={styles.category}>{application.category}</p>
      </td>
      <td data-label="Name" className={styles.applicationName}>
        {application.name}
      </td>
      <td
        className={cc([
          styles.tableCell,
          {
            [styles.pendingColor]: application.status === "Pending",
          },
          {
            [styles.redText]:
              application.status === "Professor Rejected" ||
              application.status === "Denied" ||
              application.status === "System Rejected",
          },
          {
            [styles.greenText]:
              application.status === "Professor Validated" ||
              application.status === "System Validated",
          },
        ])}
        data-label="Status"
      >
        {application.status}
      </td>
      <td data-label="Credit">
        {application.applicationType === "Activity" ||
        application.applicationType === "Extra credit"
          ? application.credit && application.credit < 0
            ? ""
            : "+ "
          : "- "}
        {application.credit}
      </td>
      <td data-label="Date">
        {moment(application.createdOn).format("MM-DD-YYYY")}
      </td>
      <td>
        <div className={styles.iconCell}>
          <img
            alt="View"
            className={styles.icon}
            src={viewIcon}
            onClick={handleClick}
          />
        </div>
      </td>
    </tr>
  );
};

export default ApplicationsRow;
