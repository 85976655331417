import React from "react";
import cc from "classcat";
import { useHistory, useParams } from "react-router-dom";

import NavButton from "../../../../../../common/NavButton";
import UserHeader from "../UserHeader";
import bookIcon from "../../../../../../assets/images/book.svg";
import dateIcon from "../../../../../../assets/images/date-white.svg";
import featherIcon from "../../../../../../assets/images/feather.svg";
import loginLogo from "../../../../../../assets/images/V-logo-svg.svg";
import navIcon from "../../../../../../assets/images/nav.svg";
import settingsIcon from "../../../../../../assets/images/settings.svg";
import useComponentVisible from "../../../../../../hooks/useComponentVisible";
import { useAuth } from "../../../../../../stores";
import styles from "./styles.module.scss";

const NavBar = () => {
  const { role, selectedAcademicTermId, isSuperAdmin } = useAuth();
  const history = useHistory();
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.headerContainer}>
        <img
          className={styles.headerLogo}
          src={loginLogo}
          alt="logo"
          onClick={() =>
            history.push(`/academicTerms/${selectedAcademicTermId}/courses`)
          }
        />
        <img
          alt="logo"
          className={styles.menuButton}
          src={navIcon}
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);
          }}
        />
      </div>
      <div
        className={cc([
          styles.navButtonsContainer,
          { [styles.navButtonsContainerShift]: isComponentVisible },
        ])}
      >
        <div className={styles.UserHeaderWrapper}>
          <UserHeader isPurple={true} />
        </div>
        <NavButton
          destination="/profile/edit"
          image={featherIcon}
          title="My Profile"
        />
        {(role === "Administrator" ||
          (isSuperAdmin && role == "Professor")) && (
          <NavButton title="Users" destination="/users" image={featherIcon} />
        )}
        <NavButton
          title="Courses"
          image={bookIcon}
          destination={`/academicTerms/${selectedAcademicTermId}/courses`}
        />
        {role === "Student" && (
          <NavButton
            title="Scheduler"
            image={dateIcon}
            destination={`/academicTerms/${selectedAcademicTermId}/scheduler`}
          />
        )}
        {/* {role !== "Student" && (
          <NavButton
            destination="/notification"
            image={notificationIcon}
            title="Notification"
          />
        )} */}

        {role !== "Student" && (
          <NavButton
            destination="/settings"
            image={settingsIcon}
            title="Settings"
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
