import Step from "../Step";
import styles from "./styles.module.scss";
const StepProgressBar = (props: any) => {
  return (
    <div className={styles.stepWrapper}>
      {props.labelArray.map((item: any, index: number) => (
        <Step
          key={index}
          index={index}
          lable={item}
          updateStep={props.updateStep}
          passed={props.currentStep > index + 1}
          selected={props.currentStep === index + 1}
        ></Step>
      ))}
    </div>
  );
};

export default StepProgressBar;
