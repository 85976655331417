import React from "react";
import { observer } from "mobx-react";

import { useAuth } from "../../stores";
import Card from "../../common/Card";
import CourseNavigation from "../Components/CourseNavigation";
import DashboardBase from "../Dashboard/Components/DashboardBase";
import SectionsList from "./Components/SectionsList";

const Section = () => {
  const { courseName } = useAuth();

  return (
    <DashboardBase componentName={courseName}>
      <CourseNavigation isSection={true}>
        <Card isFullScreen={true} isWithOutTopMargin={true} isCoursesTabs={true}>
          <SectionsList />
        </Card>
      </CourseNavigation>
    </DashboardBase>
  );
};

export default observer(Section);
